import React from 'react'
import styles from "../../assets/Dashboard/Navbar.module.css"
import { ChevronDown, LogOut, Settings, User } from 'lucide-react'
import { useNavigate } from 'react-router-dom' 
import { useAuth } from '../../ResumeBuilder/builder/components/context/auth'
import toast from 'react-hot-toast'
const Navbar = ({image,auth}) => { 
    const {setAuth}  = useAuth(); 
  const navigate = useNavigate(); 
  const handleLogout = () => {
    setAuth({
      ...auth,
      user: null,
      token: "",
    });
    localStorage.removeItem("auth");
    toast.success("Logout Successful!!");
    navigate("/");
  };
  return (
    <div className={styles.main}>
        <div className={styles.container}>
             {/* left side */}
             <div className={styles.leftside}>
                <span className={styles.active}>Dashboard <span className={styles.underline}></span> </span>
                <span onClick={()=>navigate('/jobs-in-india')}>Jobs</span>
                <span onClick={()=>navigate('/resume-score')}>Resume Score</span>
                <span onClick={()=>navigate('/pricing')}>Pricing</span>
                 {/* <span onClick={()=>navigate('/welcome/account')}>Settings</span> */}
             </div> 
             {/* right side */} 
             <div className={styles.rightSide}>
                 <div className={styles.profileDropdown}>
                     <div className={styles.profileTrigger}>
                         <img src={image} alt='profile' />
                         <span>{auth?.user?.[0]?.firstname} {auth?.user?.[0]?.lastname} <ChevronDown size={16} color={"#909AB6"}/></span>
                     </div>
                     <div className={styles.dropdownMenu}>
                         <div className={styles.dropdownItem} onClick={() => navigate('/account')}>
                             <User size={16} />
                             <span>Profile</span>
                         </div>
                         
                         <div className={styles.divider}></div>
                         <div className={styles.dropdownItem} onClick={() => {
                             // Add logout logic here
                             handleLogout(); 
                         }}>
                             <LogOut size={16} />
                             <span>Logout</span>
                         </div>
                     </div>
                 </div>
             </div>
        </div>
    </div>
  )
}

export default Navbar