import styles from "../assets/Navbar.module.css";
import { useEffect, useState } from "react";
import MobileNav from "../components/Navbar/MobileNav";
import MiddleWala from "../components/Navbar/MiddleWala";
import LastOne from "../components/Navbar/LastOne";
import userIcon from "../assets/userIcon.webp"
import { useNavigate } from "react-router-dom";
import { useAuth } from "../ResumeBuilder/builder/components/context/auth";

function MyResponsiveNavbar() {
  const navigate = useNavigate();
  const { auth} = useAuth();
  const [imgSrc, setImgSrc] = useState("https://media.lordicon.com/icons/wired/flat/21-avatar.gif");
  const handleError = () => {
    setImgSrc(
      userIcon
    );
  };

  useEffect(() => {
    const storedAuth = JSON.parse(localStorage.getItem("auth"));
    if (storedAuth && storedAuth.pic) {
      setImgSrc(storedAuth.pic);
    }
  }, []);
  return (
    
      <nav className={` ${styles.main} `}>
        <div className="container">
          <div className="d-flex justify-content-between align-items-center"> 

         <MobileNav imgSrc={imgSrc} />
           <div  onClick={()=>(navigate('/'))} className={`d-flex align-items-center justify-content-center justify-content-lg-start   ${styles.onsmallwidth}`}>
              <span
                className={styles.heading}
              > 
                <img className={styles.logo} src="https://res.cloudinary.com/dmsbvsful/image/upload/v1741092080/MyFuseMascot_tpzjwr.png" alt="MyFuse Logo" />
                MyFuse<span style={{ color: "#f9be59" }}>.</span>In 
              </span>
            </div>
           <MiddleWala/>
           <LastOne handleError={handleError}  imgSrc={imgSrc}  />
             
            <div className="d-lg-none d-sm-flex justify-content-end flex-row"> 
            <span style={{display:'flex'}}>
               
            <div className={styles.imageSection}>
              <img
                className={styles.imggggg}
                src={auth?.user?.[0]?.images ? auth?.user?.[0]?.images : imgSrc}
                alt="sfas"
              />
            </div>
            </span>
           
          </div>
          </div>
        </div>
      </nav>
    );
}

export default MyResponsiveNavbar;
