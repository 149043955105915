import React, { useState } from 'react'
import styles from '../assets/Verify.module.css'
import { useNavigate, useParams } from 'react-router-dom';
import {toast} from 'react-hot-toast'
import axios from 'axios'; 
import HashLoader from "react-spinners/HashLoader";
import { publicApi } from '../ResumeBuilder/utils/aixos';

 
const ForgotVerify = () => {

    const params = useParams();  

    const [otp , setOtp] = useState(); 
    const [password , setpassword] = useState(''); 
    const [confirmPassword , setConfirmPassword] = useState('');
    let [loading, setLoading] = useState(false);
    const [isVerified , setIsVerified] = useState(false); 
      const Navigate = useNavigate(); 
       const handleSubmit = async() =>{
         
          try {
            setIsVerified(true)
          } catch (error) {
            setLoading(false);
            console.log(error); 
            toast.error("Please Enter Correct OTP")
          }
      } 




       const handleSetPassword = async() =>{
        try { 
            setLoading(true);  

           if(password!==confirmPassword) {
            setLoading(false);
            toast.error("Password and Confirm Password should be same")
            return;
           }

            let email=params.email; 
             const resposnse = await publicApi.post (`/api/auth/forgot-verify`,
             {
              email:email,
              otp :otp,
              password :password,
             },
             );
              
              if(resposnse?.data?.success) {
                toast.success(resposnse.data.message); 
                Navigate( `/login`);
             } 
             else {
                setIsVerified(false)
                toast.error(resposnse.data.message); 
             }
            
            
             setLoading(false);
        } catch (error) {
            console.log(error); 
            toast.error("Something Went Wrong"); 
        }
       }


  return (
    <div className={`container-fluid ${styles.main} `} >
        <div className={`row ${styles.container}`} > 

         {isVerified ? (
             <div className={`col-11 col-md-4 ${styles.box}`}  >
             <h1 className={`${styles.title}`}>Set New Password</h1>
             <div  className={ ` text-center ${styles.para}`}>
             Please enter a new password and guard it like it's the last slice of pizza! 🍕
             </div>
             <input  type='password' placeholder='Password'   className={`  text-start ${styles.button}`} value={password} onChange={(e)=>{setpassword(e.target.value)}} required />
             <input  type='password' placeholder='Confirm Password'   className={` text-start mt-2 ${styles.button}`} value={confirmPassword} onChange={(e)=>{setConfirmPassword(e.target.value)}} required />
             {/* <button className={styles.btn}  onClick={handleSubmit}>Verify </button> */}

                    {loading ? 
                          (
                           <div className='text-center d-flex justify-content-center'>  <div className={` d-flex  justify-content-center ${styles.btn}`}>
                           <HashLoader color="#000000" size={25} className='mt-1' />
                            </div></div>
                          )
                          : 
                          
                          (
                         //  <div className='text-center '>  <button type="submit" className={`${styles.btn}`}>Verify</button></div>
                          <div className='text-center '>   
                               <button className={styles.btn}  onClick={handleSetPassword}> Confirm </button>
                           </div>
                          
                          )
                          }

             <p className={styles.down}>Haven’t got an OTP? <span className={styles.signup}>Resend</span></p>
          </div>
         ) : (
             <div className={`col-11 col-md-4 ${styles.box}`}  >
                <h1 className={`${styles.title}`}>OTP Verification</h1>
                <div className={` text-center ${styles.para}`}>
                "Alert! 🔔 Your OTP is in route to your email address. It's like getting a digital love letter from our security team!"
                </div>
                <input  type='number' placeholder='OTP'   className={` text-align-center ${styles.button}`} value={otp} onChange={(e)=>{setOtp(e.target.value)}} required />
                {/* <button className={styles.btn}  onClick={handleSubmit}>Verify </button> */}

                       {loading ? 
                             (
                              <div className='Alert🔔ext-center d-flex justify-content-center'>  <div className={` d-flex  justify-content-center ${styles.btn}`}>
                              <HashLoader color="#000000" size={25} className='mt-1' />
                               </div></div>
                             )
                             : 
                             
                             (
                            //  <div className='text-center '>  <button type="submit" className={`${styles.btn}`}>Verify</button></div>
                             <div className='text-center '>   
                                  <button className={styles.btn}  onClick={handleSubmit}>Verify </button>
                              </div>
                             
                             )
                             }

                <p className={styles.down}>Haven’t got an OTP? <span className={styles.signup}>Resend</span></p>
             </div>
         )}
             
            
        </div>    
    </div>
  )
}

 

export default ForgotVerify