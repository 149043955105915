 const template1 = {
    "name": "Untitled",
    "sections": {
      "profile": {
        "name": "Profile",
        "key": "sections.profile",
        "data": {
          "name": "Ashutosh Kumar",
          "address": "Banglore",
          "email": "ashutosh@gmail.com ",
          "phone": "98XXXXXXXX",
          "githubUsername": "Github Url",
          "position": "HR Coordinator",
          "linkedinUsername": "linkedin/in/username"
        }
      },
      "education": {
        "name": "Education",
        "key": "sections.education",
        "data": [
          {
            "degree": "Bachelor of Science ",
            "college": "IIT (ISM) ",
            "year": "2021-2025",
            "branch": "Human Resource",
            "percentage": "89"
          }
        ]
      },
      "experience": {
        "name": "Experience",
        "key": "sections.experience",
        "data": [
          {
            "description": "<p>As an HR Coordinator at Myfuse.In (Feb 2025 - Jul 2025), adeptly managed recruitment processes, coordinated employee onboarding, and facilitated HR administrative tasks. Streamlined procedures, enhancing organizational efficiency and employee satisfaction.</p>",
            "points": [
              "Led implementation of new employee onboarding process using BambooHR, reducing onboarding time by 25%",
              "Developed employee training program with Word, increasing employee satisfaction by 20%",
              "Implemented innovative recruitment strategy through social media platforms, resulting in 15% increase in qualified candidate applications"
            ],
            "company": "Myfuse.In",
            "role": "HR Coordinator",
            "timePeriod": "Feb 2025 - Jul 2025",
            "location": "Bengaluru, Karnataka"
          }
        ]
      },
      "projects": {
        "name": "Projects",
        "key": "sections.projects",
        "data": [
          {
            "description": "<p>\"TechoNinja leveraged TATA Technologies to develop cutting-edge software solutions for optimizing manufacturing processes. Through advanced AI algorithms and IoT integration, the project successfully streamlined operations, reducing costs and enhancing productivity. Feb 2025 - Aug 2025.\"</p>",
            "points": [
              "Led HR reporting initiatives through MS Office and Slack, improving data accuracy by 20% and reducing reporting time by 4 hours",
              "Spearheaded the implementation of new reporting standards with MS Office, leading to a 25% improvement in data quality and consistency",
              "Implemented reporting automation with MS Office, reducing manual errors by 30% and saving 8 hours per week"
            ],
            "title": "TechoNinja ",
            "organisation": "TATA Technologies",
            "year": "Feb 2025 - Aug 2025"
          }
        ]
      },
      "technicalSkills": {
        "name": "Technical Skills",
        "key": "sections.technicalSkills",
        "data": [
          {
            "skills": [
              "Industry Knowledge",
              "IoT Integration",
              "HR Management",
              "Recruitment",
              "Analytical Thinking"
            ]
          }
        ]
      },
      "Professionalsummary": {
        "name": "Professional Summary",
        "key": "sections.Professionalsummary",
        "data": {
          "summary": "<p>Experienced HR Coordinator with 5+ years of success in managing recruitment, onboarding, and employee relations. Skilled in HRIS systems and compliance. Recognized for streamlining processes, reducing turnover, and enhancing employee engagement. Committed to driving organizational success through strategic HR initiatives. Seeking to leverage expertise in a dynamic corporate environment.</p>"
        }
      },
      "awards": {
        "name": "Awards",
        "key": "sections.awards",
        "data": [
          {
            "name": "",
            "description": "<p>Responsible for overseeing and managing various human resource functions, including recruitment, onboarding, employee engagement, and maintaining personnel records.</p>"
          }
        ]
      },
      "optional": {
        "name": "Optional",
        "key": "sections.optional",
        "data": []
      }
    }
  } 


  const template2 = {
    "name": "Untitled",
    "sections": {
      "profile": {
        "name": "Profile",
        "key": "sections.profile",
        "data": {
          "name": "Ashutosh Kumar",
          "address": "Banglore",
          "email": "ashutosh@gmail.com ",
          "phone": "98XXXXXXXX",
          "githubUsername": "Github Url",
          "position": "HR Coordinator",
          "linkedinUsername": "linkedin/in/username"
        }
      },
      "education": {
        "name": "Education",
        "key": "sections.education",
        "data": [
          {
            "degree": "Bachelor of Science ",
            "college": "IIT (ISM) ",
            "year": "2021-2025",
            "branch": "Human Resource",
            "percentage": "89"
          }
        ]
      },
      "experience": {
        "name": "Experience",
        "key": "sections.experience",
        "data": [
          {
            "description": "<p>As an HR Coordinator at Myfuse.In (Feb 2025 - Jul 2025), adeptly managed recruitment processes, coordinated employee onboarding, and facilitated HR administrative tasks. Streamlined procedures, enhancing organizational efficiency and employee satisfaction.</p>",
            "points": [
              "Led implementation of new employee onboarding process using BambooHR, reducing onboarding time by 25%",
              "Developed employee training program with Word, increasing employee satisfaction by 20%",
              "Implemented innovative recruitment strategy through social media platforms, resulting in 15% increase in qualified candidate applications"
            ],
            "company": "Myfuse.In",
            "role": "HR Coordinator",
            "timePeriod": "Feb 2025 - Jul 2025",
            "location": "Bengaluru, Karnataka"
          }
        ]
      },
      "projects": {
        "name": "Projects",
        "key": "sections.projects",
        "data": [
          {
            "description": "<p>\"TechoNinja leveraged TATA Technologies to develop cutting-edge software solutions for optimizing manufacturing processes. Through advanced AI algorithms and IoT integration, the project successfully streamlined operations, reducing costs and enhancing productivity. Feb 2025 - Aug 2025.\"</p>",
            "points": [
              "Led HR reporting initiatives through MS Office and Slack, improving data accuracy by 20% and reducing reporting time by 4 hours",
              "Spearheaded the implementation of new reporting standards with MS Office, leading to a 25% improvement in data quality and consistency",
              "Implemented reporting automation with MS Office, reducing manual errors by 30% and saving 8 hours per week"
            ],
            "title": "TechoNinja ",
            "organisation": "TATA Technologies",
            "year": "Feb 2025 - Aug 2025"
          }
        ]
      },
      "technicalSkills": {
        "name": "Technical Skills",
        "key": "sections.technicalSkills",
        "data": [
          {
            "skills": [
              "Industry Knowledge",
              "IoT Integration",
              "HR Management",
              "Recruitment",
              "Analytical Thinking"
            ]
          }
        ]
      },
      "Professionalsummary": {
        "name": "Professional Summary",
        "key": "sections.Professionalsummary",
        "data": {
          "summary": "<p>Experienced HR Coordinator with 5+ years of success in managing recruitment, onboarding, and employee relations. Skilled in HRIS systems and compliance. Recognized for streamlining processes, reducing turnover, and enhancing employee engagement. Committed to driving organizational success through strategic HR initiatives. Seeking to leverage expertise in a dynamic corporate environment.</p>"
        }
      },
      "awards": {
        "name": "Awards",
        "key": "sections.awards",
        "data": [
          {
            "name": "",
            "description": "<p>Responsible for overseeing and managing various human resource functions, including recruitment, onboarding, employee engagement, and maintaining personnel records.</p>"
          }
        ]
      },
      "optional": {
        "name": "Optional",
        "key": "sections.optional",
        "data": []
      }
    }
  } 

  const template3 = {
    "name": "Untitled",
    "sections": {
      "profile": {
        "name": "Profile",
        "key": "sections.profile",
        "data": {
          "name": "Ashutosh Kumar",
          "address": "Banglore",
          "email": "ashutosh@gmail.com ",
          "phone": "98XXXXXXXX",
          "githubUsername": "Github Url",
          "position": "HR Coordinator",
          "linkedinUsername": "linkedin/in/username"
        }
      },
      "education": {
        "name": "Education",
        "key": "sections.education",
        "data": [
          {
            "degree": "Bachelor of Science ",
            "college": "IIT (ISM) ",
            "year": "2021-2025",
            "branch": "Human Resource",
            "percentage": "89"
          }
        ]
      },
      "experience": {
        "name": "Experience",
        "key": "sections.experience",
        "data": [
          {
            "description": "<p>As an HR Coordinator at Myfuse.In (Feb 2025 - Jul 2025), adeptly managed recruitment processes, coordinated employee onboarding, and facilitated HR administrative tasks. Streamlined procedures, enhancing organizational efficiency and employee satisfaction.</p>",
            "points": [
              "Led implementation of new employee onboarding process using BambooHR, reducing onboarding time by 25%",
              "Developed employee training program with Word, increasing employee satisfaction by 20%",
              "Implemented innovative recruitment strategy through social media platforms, resulting in 15% increase in qualified candidate applications"
            ],
            "company": "Myfuse.In",
            "role": "HR Coordinator",
            "timePeriod": "Feb 2025 - Jul 2025",
            "location": "Bengaluru, Karnataka"
          }
        ]
      },
      "projects": {
        "name": "Projects",
        "key": "sections.projects",
        "data": [
          {
            "description": "<p>\"TechoNinja leveraged TATA Technologies to develop cutting-edge software solutions for optimizing manufacturing processes. Through advanced AI algorithms and IoT integration, the project successfully streamlined operations, reducing costs and enhancing productivity. Feb 2025 - Aug 2025.\"</p>",
            "points": [
              "Led HR reporting initiatives through MS Office and Slack, improving data accuracy by 20% and reducing reporting time by 4 hours",
              "Spearheaded the implementation of new reporting standards with MS Office, leading to a 25% improvement in data quality and consistency",
              "Implemented reporting automation with MS Office, reducing manual errors by 30% and saving 8 hours per week"
            ],
            "title": "TechoNinja ",
            "organisation": "TATA Technologies",
            "year": "Feb 2025 - Aug 2025"
          }
        ]
      },
      "technicalSkills": {
        "name": "Technical Skills",
        "key": "sections.technicalSkills",
        "data": [
          {
            "skills": [
              "Industry Knowledge",
              "IoT Integration",
              "HR Management",
              "Recruitment",
              "Analytical Thinking"
            ]
          }
        ]
      },
      "Professionalsummary": {
        "name": "Professional Summary",
        "key": "sections.Professionalsummary",
        "data": {
          "summary": "<p>Experienced HR Coordinator with 5+ years of success in managing recruitment, onboarding, and employee relations. Skilled in HRIS systems and compliance. Recognized for streamlining processes, reducing turnover, and enhancing employee engagement. Committed to driving organizational success through strategic HR initiatives. Seeking to leverage expertise in a dynamic corporate environment.</p>"
        }
      },
      "awards": {
        "name": "Awards",
        "key": "sections.awards",
        "data": [
          {
            "name": "",
            "description": "<p>Responsible for overseeing and managing various human resource functions, including recruitment, onboarding, employee engagement, and maintaining personnel records.</p>"
          }
        ]
      },
      "optional": {
        "name": "Optional",
        "key": "sections.optional",
        "data": []
      }
    }
  } 

  const template4 = {
    "name": "Untitled",
    "sections": {
      "profile": {
        "name": "Profile",
        "key": "sections.profile",
        "data": {
          "name": "Ashutosh Kumar",
          "address": "Banglore",
          "email": "ashutosh@gmail.com ",
          "phone": "98XXXXXXXX",
          "githubUsername": "Github Url",
          "position": "HR Coordinator",
          "linkedinUsername": "linkedin/in/username"
        }
      },
      "education": {
        "name": "Education",
        "key": "sections.education",
        "data": [
          {
            "degree": "Bachelor of Science ",
            "college": "IIT (ISM) ",
            "year": "2021-2025",
            "branch": "Human Resource",
            "percentage": "89"
          }
        ]
      },
      "experience": {
        "name": "Experience",
        "key": "sections.experience",
        "data": [
          {
            "description": "<p>As an HR Coordinator at Myfuse.In (Feb 2025 - Jul 2025), adeptly managed recruitment processes, coordinated employee onboarding, and facilitated HR administrative tasks. Streamlined procedures, enhancing organizational efficiency and employee satisfaction.</p>",
            "points": [
              "Led implementation of new employee onboarding process using BambooHR, reducing onboarding time by 25%",
              "Developed employee training program with Word, increasing employee satisfaction by 20%",
              "Implemented innovative recruitment strategy through social media platforms, resulting in 15% increase in qualified candidate applications"
            ],
            "company": "Myfuse.In",
            "role": "HR Coordinator",
            "timePeriod": "Feb 2025 - Jul 2025",
            "location": "Bengaluru, Karnataka"
          }
        ]
      },
      "projects": {
        "name": "Projects",
        "key": "sections.projects",
        "data": [
          {
            "description": "<p>\"TechoNinja leveraged TATA Technologies to develop cutting-edge software solutions for optimizing manufacturing processes. Through advanced AI algorithms and IoT integration, the project successfully streamlined operations, reducing costs and enhancing productivity. Feb 2025 - Aug 2025.\"</p>",
            "points": [
              "Led HR reporting initiatives through MS Office and Slack, improving data accuracy by 20% and reducing reporting time by 4 hours",
              "Spearheaded the implementation of new reporting standards with MS Office, leading to a 25% improvement in data quality and consistency",
              "Implemented reporting automation with MS Office, reducing manual errors by 30% and saving 8 hours per week"
            ],
            "title": "TechoNinja ",
            "organisation": "TATA Technologies",
            "year": "Feb 2025 - Aug 2025"
          }
        ]
      },
      "technicalSkills": {
        "name": "Technical Skills",
        "key": "sections.technicalSkills",
        "data": [
          {
            "skills": [
              "Industry Knowledge",
              "IoT Integration",
              "HR Management",
              "Recruitment",
              "Analytical Thinking"
            ]
          }
        ]
      },
      "Professionalsummary": {
        "name": "Professional Summary",
        "key": "sections.Professionalsummary",
        "data": {
          "summary": "<p>Experienced HR Coordinator with 5+ years of success in managing recruitment, onboarding, and employee relations. Skilled in HRIS systems and compliance. Recognized for streamlining processes, reducing turnover, and enhancing employee engagement. Committed to driving organizational success through strategic HR initiatives. Seeking to leverage expertise in a dynamic corporate environment.</p>"
        }
      },
      "awards": {
        "name": "Awards",
        "key": "sections.awards",
        "data": [
          {
            "name": "",
            "description": "<p>Responsible for overseeing and managing various human resource functions, including recruitment, onboarding, employee engagement, and maintaining personnel records.</p>"
          }
        ]
      },
      "optional": {
        "name": "Optional",
        "key": "sections.optional",
        "data": []
      }
    }
  } 

  const template5 = {
    "name": "Untitled",
    "sections": {
      "profile": {
        "name": "Profile",
        "key": "sections.profile",
        "data": {
          "name": "Ashutosh Kumar",
          "address": "Banglore",
          "email": "ashutosh@gmail.com ",
          "phone": "98XXXXXXXX",
          "githubUsername": "Github Url",
          "position": "HR Coordinator",
          "linkedinUsername": "linkedin/in/username"
        }
      },
      "education": {
        "name": "Education",
        "key": "sections.education",
        "data": [
          {
            "degree": "Bachelor of Science ",
            "college": "IIT (ISM) ",
            "year": "2021-2025",
            "branch": "Human Resource",
            "percentage": "89"
          }
        ]
      },
      "experience": {
        "name": "Experience",
        "key": "sections.experience",
        "data": [
          {
            "description": "<p>As an HR Coordinator at Myfuse.In (Feb 2025 - Jul 2025), adeptly managed recruitment processes, coordinated employee onboarding, and facilitated HR administrative tasks. Streamlined procedures, enhancing organizational efficiency and employee satisfaction.</p>",
            "points": [
              "Led implementation of new employee onboarding process using BambooHR, reducing onboarding time by 25%",
              "Developed employee training program with Word, increasing employee satisfaction by 20%",
              "Implemented innovative recruitment strategy through social media platforms, resulting in 15% increase in qualified candidate applications"
            ],
            "company": "Myfuse.In",
            "role": "HR Coordinator",
            "timePeriod": "Feb 2025 - Jul 2025",
            "location": "Bengaluru, Karnataka"
          }
        ]
      },
      "projects": {
        "name": "Projects",
        "key": "sections.projects",
        "data": [
          {
            "description": "<p>\"TechoNinja leveraged TATA Technologies to develop cutting-edge software solutions for optimizing manufacturing processes. Through advanced AI algorithms and IoT integration, the project successfully streamlined operations, reducing costs and enhancing productivity. Feb 2025 - Aug 2025.\"</p>",
            "points": [
              "Led HR reporting initiatives through MS Office and Slack, improving data accuracy by 20% and reducing reporting time by 4 hours",
              "Spearheaded the implementation of new reporting standards with MS Office, leading to a 25% improvement in data quality and consistency",
              "Implemented reporting automation with MS Office, reducing manual errors by 30% and saving 8 hours per week"
            ],
            "title": "TechoNinja ",
            "organisation": "TATA Technologies",
            "year": "Feb 2025 - Aug 2025"
          }
        ]
      },
      "technicalSkills": {
        "name": "Technical Skills",
        "key": "sections.technicalSkills",
        "data": [
          {
            "skills": [
              "Industry Knowledge",
              "IoT Integration",
              "HR Management",
              "Recruitment",
              "Analytical Thinking"
            ]
          }
        ]
      },
      "Professionalsummary": {
        "name": "Professional Summary",
        "key": "sections.Professionalsummary",
        "data": {
          "summary": "<p>Experienced HR Coordinator with 5+ years of success in managing recruitment, onboarding, and employee relations. Skilled in HRIS systems and compliance. Recognized for streamlining processes, reducing turnover, and enhancing employee engagement. Committed to driving organizational success through strategic HR initiatives. Seeking to leverage expertise in a dynamic corporate environment.</p>"
        }
      },
      "awards": {
        "name": "Awards",
        "key": "sections.awards",
        "data": [
          {
            "name": "",
            "description": "<p>Responsible for overseeing and managing various human resource functions, including recruitment, onboarding, employee engagement, and maintaining personnel records.</p>"
          }
        ]
      },
      "optional": {
        "name": "Optional",
        "key": "sections.optional",
        "data": []
      }
    }
  } 

  const template6 = {
    "name": "Untitled",
    "sections": {
      "profile": {
        "name": "Profile",
        "key": "sections.profile",
        "data": {
          "name": "Ashutosh Kumar",
          "address": "Banglore",
          "email": "ashutosh@gmail.com ",
          "phone": "98XXXXXXXX",
          "githubUsername": "Github Url",
          "position": "HR Coordinator",
          "linkedinUsername": "linkedin/in/username"
        }
      },
      "education": {
        "name": "Education",
        "key": "sections.education",
        "data": [
          {
            "degree": "Bachelor of Science ",
            "college": "IIT (ISM) ",
            "year": "2021-2025",
            "branch": "Human Resource",
            "percentage": "89"
          }
        ]
      },
      "experience": {
        "name": "Experience",
        "key": "sections.experience",
        "data": [
          {
            "description": "<p>As an HR Coordinator at Myfuse.In (Feb 2025 - Jul 2025), adeptly managed recruitment processes, coordinated employee onboarding, and facilitated HR administrative tasks. Streamlined procedures, enhancing organizational efficiency and employee satisfaction.</p>",
            "points": [
              "Led implementation of new employee onboarding process using BambooHR, reducing onboarding time by 25%",
              "Developed employee training program with Word, increasing employee satisfaction by 20%",
              "Implemented innovative recruitment strategy through social media platforms, resulting in 15% increase in qualified candidate applications"
            ],
            "company": "Myfuse.In",
            "role": "HR Coordinator",
            "timePeriod": "Feb 2025 - Jul 2025",
            "location": "Bengaluru, Karnataka"
          }
        ]
      },
      "projects": {
        "name": "Projects",
        "key": "sections.projects",
        "data": [
          {
            "description": "<p>\"TechoNinja leveraged TATA Technologies to develop cutting-edge software solutions for optimizing manufacturing processes. Through advanced AI algorithms and IoT integration, the project successfully streamlined operations, reducing costs and enhancing productivity. Feb 2025 - Aug 2025.\"</p>",
            "points": [
              "Led HR reporting initiatives through MS Office and Slack, improving data accuracy by 20% and reducing reporting time by 4 hours",
              "Spearheaded the implementation of new reporting standards with MS Office, leading to a 25% improvement in data quality and consistency",
              "Implemented reporting automation with MS Office, reducing manual errors by 30% and saving 8 hours per week"
            ],
            "title": "TechoNinja ",
            "organisation": "TATA Technologies",
            "year": "Feb 2025 - Aug 2025"
          }
        ]
      },
      "technicalSkills": {
        "name": "Technical Skills",
        "key": "sections.technicalSkills",
        "data": [
          {
            "skills": [
              "Industry Knowledge",
              "IoT Integration",
              "HR Management",
              "Recruitment",
              "Analytical Thinking"
            ]
          }
        ]
      },
      "Professionalsummary": {
        "name": "Professional Summary",
        "key": "sections.Professionalsummary",
        "data": {
          "summary": "<p>Experienced HR Coordinator with 5+ years of success in managing recruitment, onboarding, and employee relations. Skilled in HRIS systems and compliance. Recognized for streamlining processes, reducing turnover, and enhancing employee engagement. Committed to driving organizational success through strategic HR initiatives. Seeking to leverage expertise in a dynamic corporate environment.</p>"
        }
      },
      "awards": {
        "name": "Awards",
        "key": "sections.awards",
        "data": [
          {
            "name": "",
            "description": "<p>Responsible for overseeing and managing various human resource functions, including recruitment, onboarding, employee engagement, and maintaining personnel records.</p>"
          }
        ]
      },
      "optional": {
        "name": "Optional",
        "key": "sections.optional",
        "data": []
      }
    }
  } 

  const template7 = {
    "name": "Untitled",
    "sections": {
      "profile": {
        "name": "Profile",
        "key": "sections.profile",
        "data": {
          "name": "Ashutosh Kumar",
          "address": "Banglore",
          "email": "ashutosh@gmail.com ",
          "phone": "98XXXXXXXX",
          "githubUsername": "Github Url",
          "position": "HR Coordinator",
          "linkedinUsername": "linkedin/in/username"
        }
      },
      "education": {
        "name": "Education",
        "key": "sections.education",
        "data": [
          {
            "degree": "Bachelor of Science ",
            "college": "IIT (ISM) ",
            "year": "2021-2025",
            "branch": "Human Resource",
            "percentage": "89"
          }
        ]
      },
      "experience": {
        "name": "Experience",
        "key": "sections.experience",
        "data": [
          {
            "description": "<p>As an HR Coordinator at Myfuse.In (Feb 2025 - Jul 2025), adeptly managed recruitment processes, coordinated employee onboarding, and facilitated HR administrative tasks. Streamlined procedures, enhancing organizational efficiency and employee satisfaction.</p>",
            "points": [
              "Led implementation of new employee onboarding process using BambooHR, reducing onboarding time by 25%",
              "Developed employee training program with Word, increasing employee satisfaction by 20%",
              "Implemented innovative recruitment strategy through social media platforms, resulting in 15% increase in qualified candidate applications"
            ],
            "company": "Myfuse.In",
            "role": "HR Coordinator",
            "timePeriod": "Feb 2025 - Jul 2025",
            "location": "Bengaluru, Karnataka"
          }
        ]
      },
      "projects": {
        "name": "Projects",
        "key": "sections.projects",
        "data": [
          {
            "description": "<p>\"TechoNinja leveraged TATA Technologies to develop cutting-edge software solutions for optimizing manufacturing processes. Through advanced AI algorithms and IoT integration, the project successfully streamlined operations, reducing costs and enhancing productivity. Feb 2025 - Aug 2025.\"</p>",
            "points": [
              "Led HR reporting initiatives through MS Office and Slack, improving data accuracy by 20% and reducing reporting time by 4 hours",
              "Spearheaded the implementation of new reporting standards with MS Office, leading to a 25% improvement in data quality and consistency",
              "Implemented reporting automation with MS Office, reducing manual errors by 30% and saving 8 hours per week"
            ],
            "title": "TechoNinja ",
            "organisation": "TATA Technologies",
            "year": "Feb 2025 - Aug 2025"
          }
        ]
      },
      "technicalSkills": {
        "name": "Technical Skills",
        "key": "sections.technicalSkills",
        "data": [
          {
            "skills": [
              "Industry Knowledge",
              "IoT Integration",
              "HR Management",
              "Recruitment",
              "Analytical Thinking"
            ]
          }
        ]
      },
      "Professionalsummary": {
        "name": "Professional Summary",
        "key": "sections.Professionalsummary",
        "data": {
          "summary": "<p>Experienced HR Coordinator with 5+ years of success in managing recruitment, onboarding, and employee relations. Skilled in HRIS systems and compliance. Recognized for streamlining processes, reducing turnover, and enhancing employee engagement. Committed to driving organizational success through strategic HR initiatives. Seeking to leverage expertise in a dynamic corporate environment.</p>"
        }
      },
      "awards": {
        "name": "Awards",
        "key": "sections.awards",
        "data": [
          {
            "name": "",
            "description": "<p>Responsible for overseeing and managing various human resource functions, including recruitment, onboarding, employee engagement, and maintaining personnel records.</p>"
          }
        ]
      },
      "optional": {
        "name": "Optional",
        "key": "sections.optional",
        "data": []
      }
    }
  } 


  const template8 = {
    "name": "Untitled",
    "sections": {
      "profile": {
        "name": "Profile",
        "key": "sections.profile",
        "data": {
          "name": "Ashutosh Kumar",
          "address": "Banglore",
          "email": "ashutosh@gmail.com ",
          "phone": "98XXXXXXXX",
          "githubUsername": "Github Url",
          "position": "HR Coordinator",
          "linkedinUsername": "linkedin/in/username"
        }
      },
      "education": {
        "name": "Education",
        "key": "sections.education",
        "data": [
          {
            "degree": "Bachelor of Science ",
            "college": "IIT (ISM) ",
            "year": "2021-2025",
            "branch": "Human Resource",
            "percentage": "89"
          }
        ]
      },
      "experience": {
        "name": "Experience",
        "key": "sections.experience",
        "data": [
          {
            "description": "<p>As an HR Coordinator at Myfuse.In (Feb 2025 - Jul 2025), adeptly managed recruitment processes, coordinated employee onboarding, and facilitated HR administrative tasks. Streamlined procedures, enhancing organizational efficiency and employee satisfaction.</p>",
            "points": [
              "Led implementation of new employee onboarding process using BambooHR, reducing onboarding time by 25%",
              "Developed employee training program with Word, increasing employee satisfaction by 20%",
              "Implemented innovative recruitment strategy through social media platforms, resulting in 15% increase in qualified candidate applications"
            ],
            "company": "Myfuse.In",
            "role": "HR Coordinator",
            "timePeriod": "Feb 2025 - Jul 2025",
            "location": "Bengaluru, Karnataka"
          }
        ]
      },
      "projects": {
        "name": "Projects",
        "key": "sections.projects",
        "data": [
          {
            "description": "<p>\"TechoNinja leveraged TATA Technologies to develop cutting-edge software solutions for optimizing manufacturing processes. Through advanced AI algorithms and IoT integration, the project successfully streamlined operations, reducing costs and enhancing productivity. Feb 2025 - Aug 2025.\"</p>",
            "points": [
              "Led HR reporting initiatives through MS Office and Slack, improving data accuracy by 20% and reducing reporting time by 4 hours",
              "Spearheaded the implementation of new reporting standards with MS Office, leading to a 25% improvement in data quality and consistency",
              "Implemented reporting automation with MS Office, reducing manual errors by 30% and saving 8 hours per week"
            ],
            "title": "TechoNinja ",
            "organisation": "TATA Technologies",
            "year": "Feb 2025 - Aug 2025"
          }
        ]
      },
      "technicalSkills": {
        "name": "Technical Skills",
        "key": "sections.technicalSkills",
        "data": [
          {
            "skills": [
              "Industry Knowledge",
              "IoT Integration",
              "HR Management",
              "Recruitment",
              "Analytical Thinking"
            ]
          }
        ]
      },
      "Professionalsummary": {
        "name": "Professional Summary",
        "key": "sections.Professionalsummary",
        "data": {
          "summary": "<p>Experienced HR Coordinator with 5+ years of success in managing recruitment, onboarding, and employee relations. Skilled in HRIS systems and compliance. Recognized for streamlining processes, reducing turnover, and enhancing employee engagement. Committed to driving organizational success through strategic HR initiatives. Seeking to leverage expertise in a dynamic corporate environment.</p>"
        }
      },
      "awards": {
        "name": "Awards",
        "key": "sections.awards",
        "data": [
          {
            "name": "",
            "description": "<p>Responsible for overseeing and managing various human resource functions, including recruitment, onboarding, employee engagement, and maintaining personnel records.</p>"
          }
        ]
      },
      "optional": {
        "name": "Optional",
        "key": "sections.optional",
        "data": []
      }
    }
  } 


  const template9 = {
    "name": "Untitled",
    "sections": {
      "profile": {
        "name": "Profile",
        "key": "sections.profile",
        "data": {
          "name": "Ashutosh Kumar",
          "address": "Banglore",
          "email": "ashutosh@gmail.com ",
          "phone": "98XXXXXXXX",
          "githubUsername": "Github Url",
          "position": "HR Coordinator",
          "linkedinUsername": "linkedin/in/username"
        }
      },
      "education": {
        "name": "Education",
        "key": "sections.education",
        "data": [
          {
            "degree": "Bachelor of Science ",
            "college": "IIT (ISM) ",
            "year": "2021-2025",
            "branch": "Human Resource",
            "percentage": "89"
          }
        ]
      },
      "experience": {
        "name": "Experience",
        "key": "sections.experience",
        "data": [
          {
            "description": "<p>As an HR Coordinator at Myfuse.In (Feb 2025 - Jul 2025), adeptly managed recruitment processes, coordinated employee onboarding, and facilitated HR administrative tasks. Streamlined procedures, enhancing organizational efficiency and employee satisfaction.</p>",
            "points": [
              "Led implementation of new employee onboarding process using BambooHR, reducing onboarding time by 25%",
              "Developed employee training program with Word, increasing employee satisfaction by 20%",
              "Implemented innovative recruitment strategy through social media platforms, resulting in 15% increase in qualified candidate applications"
            ],
            "company": "Myfuse.In",
            "role": "HR Coordinator",
            "timePeriod": "Feb 2025 - Jul 2025",
            "location": "Bengaluru, Karnataka"
          }
        ]
      },
      "projects": {
        "name": "Projects",
        "key": "sections.projects",
        "data": [
          {
            "description": "<p>\"TechoNinja leveraged TATA Technologies to develop cutting-edge software solutions for optimizing manufacturing processes. Through advanced AI algorithms and IoT integration, the project successfully streamlined operations, reducing costs and enhancing productivity. Feb 2025 - Aug 2025.\"</p>",
            "points": [
              "Led HR reporting initiatives through MS Office and Slack, improving data accuracy by 20% and reducing reporting time by 4 hours",
              "Spearheaded the implementation of new reporting standards with MS Office, leading to a 25% improvement in data quality and consistency",
              "Implemented reporting automation with MS Office, reducing manual errors by 30% and saving 8 hours per week"
            ],
            "title": "TechoNinja ",
            "organisation": "TATA Technologies",
            "year": "Feb 2025 - Aug 2025"
          }
        ]
      },
      "technicalSkills": {
        "name": "Technical Skills",
        "key": "sections.technicalSkills",
        "data": [
          {
            "skills": [
              "Industry Knowledge",
              "IoT Integration",
              "HR Management",
              "Recruitment",
              "Analytical Thinking"
            ]
          }
        ]
      },
      "Professionalsummary": {
        "name": "Professional Summary",
        "key": "sections.Professionalsummary",
        "data": {
          "summary": "<p>Experienced HR Coordinator with 5+ years of success in managing recruitment, onboarding, and employee relations. Skilled in HRIS systems and compliance. Recognized for streamlining processes, reducing turnover, and enhancing employee engagement. Committed to driving organizational success through strategic HR initiatives. Seeking to leverage expertise in a dynamic corporate environment.</p>"
        }
      },
      "awards": {
        "name": "Awards",
        "key": "sections.awards",
        "data": [
          {
            "name": "",
            "description": "<p>Responsible for overseeing and managing various human resource functions, including recruitment, onboarding, employee engagement, and maintaining personnel records.</p>"
          }
        ]
      },
      "optional": {
        "name": "Optional",
        "key": "sections.optional",
        "data": []
      }
    }
  } 

  const template10 = {
    "name": "Untitled",
    "sections": {
      "profile": {
        "name": "Profile",
        "key": "sections.profile",
        "data": {
          "name": "Ashutosh Kumar",
          "address": "Banglore",
          "email": "ashutosh@gmail.com ",
          "phone": "98XXXXXXXX",
          "githubUsername": "Github Url",
          "position": "HR Coordinator",
          "linkedinUsername": "linkedin/in/username"
        }
      },
      "education": {
        "name": "Education",
        "key": "sections.education",
        "data": [
          {
            "degree": "Bachelor of Science ",
            "college": "IIT (ISM) ",
            "year": "2021-2025",
            "branch": "Human Resource",
            "percentage": "89"
          }
        ]
      },
      "experience": {
        "name": "Experience",
        "key": "sections.experience",
        "data": [
          {
            "description": "<p>As an HR Coordinator at Myfuse.In (Feb 2025 - Jul 2025), adeptly managed recruitment processes, coordinated employee onboarding, and facilitated HR administrative tasks. Streamlined procedures, enhancing organizational efficiency and employee satisfaction.</p>",
            "points": [
              "Led implementation of new employee onboarding process using BambooHR, reducing onboarding time by 25%",
              "Developed employee training program with Word, increasing employee satisfaction by 20%",
              "Implemented innovative recruitment strategy through social media platforms, resulting in 15% increase in qualified candidate applications"
            ],
            "company": "Myfuse.In",
            "role": "HR Coordinator",
            "timePeriod": "Feb 2025 - Jul 2025",
            "location": "Bengaluru, Karnataka"
          }
        ]
      },
      "projects": {
        "name": "Projects",
        "key": "sections.projects",
        "data": [
          {
            "description": "<p>\"TechoNinja leveraged TATA Technologies to develop cutting-edge software solutions for optimizing manufacturing processes. Through advanced AI algorithms and IoT integration, the project successfully streamlined operations, reducing costs and enhancing productivity. Feb 2025 - Aug 2025.\"</p>",
            "points": [
              "Led HR reporting initiatives through MS Office and Slack, improving data accuracy by 20% and reducing reporting time by 4 hours",
              "Spearheaded the implementation of new reporting standards with MS Office, leading to a 25% improvement in data quality and consistency",
              "Implemented reporting automation with MS Office, reducing manual errors by 30% and saving 8 hours per week"
            ],
            "title": "TechoNinja ",
            "organisation": "TATA Technologies",
            "year": "Feb 2025 - Aug 2025"
          }
        ]
      },
      "technicalSkills": {
        "name": "Technical Skills",
        "key": "sections.technicalSkills",
        "data": [
          {
            "skills": [
              "Industry Knowledge",
              "IoT Integration",
              "HR Management",
              "Recruitment",
              "Analytical Thinking"
            ]
          }
        ]
      },
      "Professionalsummary": {
        "name": "Professional Summary",
        "key": "sections.Professionalsummary",
        "data": {
          "summary": "<p>Experienced HR Coordinator with 5+ years of success in managing recruitment, onboarding, and employee relations. Skilled in HRIS systems and compliance. Recognized for streamlining processes, reducing turnover, and enhancing employee engagement. Committed to driving organizational success through strategic HR initiatives. Seeking to leverage expertise in a dynamic corporate environment.</p>"
        }
      },
      "awards": {
        "name": "Awards",
        "key": "sections.awards",
        "data": [
          {
            "name": "",
            "description": "<p>Responsible for overseeing and managing various human resource functions, including recruitment, onboarding, employee engagement, and maintaining personnel records.</p>"
          }
        ]
      },
      "optional": {
        "name": "Optional",
        "key": "sections.optional",
        "data": []
      }
    }
  } 

  const template11 = {
    "name": "Untitled",
    "sections": {
      "profile": {
        "name": "Profile",
        "key": "sections.profile",
        "data": {
          "name": "Ashutosh Kumar",
          "address": "Banglore",
          "email": "ashutosh@gmail.com ",
          "phone": "98XXXXXXXX",
          "githubUsername": "Github Url",
          "position": "HR Coordinator",
          "linkedinUsername": "linkedin/in/username"
        }
      },
      "education": {
        "name": "Education",
        "key": "sections.education",
        "data": [
          {
            "degree": "Bachelor of Science ",
            "college": "IIT (ISM) ",
            "year": "2021-2025",
            "branch": "Human Resource",
            "percentage": "89"
          }
        ]
      },
      "experience": {
        "name": "Experience",
        "key": "sections.experience",
        "data": [
          {
            "description": "<p>As an HR Coordinator at Myfuse.In (Feb 2025 - Jul 2025), adeptly managed recruitment processes, coordinated employee onboarding, and facilitated HR administrative tasks. Streamlined procedures, enhancing organizational efficiency and employee satisfaction.</p>",
            "points": [
              "Led implementation of new employee onboarding process using BambooHR, reducing onboarding time by 25%",
              "Developed employee training program with Word, increasing employee satisfaction by 20%",
              "Implemented innovative recruitment strategy through social media platforms, resulting in 15% increase in qualified candidate applications"
            ],
            "company": "Myfuse.In",
            "role": "HR Coordinator",
            "timePeriod": "Feb 2025 - Jul 2025",
            "location": "Bengaluru, Karnataka"
          }
        ]
      },
      "projects": {
        "name": "Projects",
        "key": "sections.projects",
        "data": [
          {
            "description": "<p>\"TechoNinja leveraged TATA Technologies to develop cutting-edge software solutions for optimizing manufacturing processes. Through advanced AI algorithms and IoT integration, the project successfully streamlined operations, reducing costs and enhancing productivity. Feb 2025 - Aug 2025.\"</p>",
            "points": [
              "Led HR reporting initiatives through MS Office and Slack, improving data accuracy by 20% and reducing reporting time by 4 hours",
              "Spearheaded the implementation of new reporting standards with MS Office, leading to a 25% improvement in data quality and consistency",
              "Implemented reporting automation with MS Office, reducing manual errors by 30% and saving 8 hours per week"
            ],
            "title": "TechoNinja ",
            "organisation": "TATA Technologies",
            "year": "Feb 2025 - Aug 2025"
          }
        ]
      },
      "technicalSkills": {
        "name": "Technical Skills",
        "key": "sections.technicalSkills",
        "data": [
          {
            "skills": [
              "Industry Knowledge",
              "IoT Integration",
              "HR Management",
              "Recruitment",
              "Analytical Thinking"
            ]
          }
        ]
      },
      "Professionalsummary": {
        "name": "Professional Summary",
        "key": "sections.Professionalsummary",
        "data": {
          "summary": "<p>Experienced HR Coordinator with 5+ years of success in managing recruitment, onboarding, and employee relations. Skilled in HRIS systems and compliance. Recognized for streamlining processes, reducing turnover, and enhancing employee engagement. Committed to driving organizational success through strategic HR initiatives. Seeking to leverage expertise in a dynamic corporate environment.</p>"
        }
      },
      "awards": {
        "name": "Awards",
        "key": "sections.awards",
        "data": [
          {
            "name": "",
            "description": "<p>Responsible for overseeing and managing various human resource functions, including recruitment, onboarding, employee engagement, and maintaining personnel records.</p>"
          }
        ]
      },
      "optional": {
        "name": "Optional",
        "key": "sections.optional",
        "data": []
      }
    }
  } 

  const template12 = {
    "name": "Untitled",
    "sections": {
      "profile": {
        "name": "Profile",
        "key": "sections.profile",
        "data": {
          "name": "Ashutosh Kumar",
          "address": "Banglore",
          "email": "ashutosh@gmail.com ",
          "phone": "98XXXXXXXX",
          "githubUsername": "Github Url",
          "position": "HR Coordinator",
          "linkedinUsername": "linkedin/in/username"
        }
      },
      "education": {
        "name": "Education",
        "key": "sections.education",
        "data": [
          {
            "degree": "Bachelor of Science ",
            "college": "IIT (ISM) ",
            "year": "2021-2025",
            "branch": "Human Resource",
            "percentage": "89"
          }
        ]
      },
      "experience": {
        "name": "Experience",
        "key": "sections.experience",
        "data": [
          {
            "description": "<p>As an HR Coordinator at Myfuse.In (Feb 2025 - Jul 2025), adeptly managed recruitment processes, coordinated employee onboarding, and facilitated HR administrative tasks. Streamlined procedures, enhancing organizational efficiency and employee satisfaction.</p>",
            "points": [
              "Led implementation of new employee onboarding process using BambooHR, reducing onboarding time by 25%",
              "Developed employee training program with Word, increasing employee satisfaction by 20%",
              "Implemented innovative recruitment strategy through social media platforms, resulting in 15% increase in qualified candidate applications"
            ],
            "company": "Myfuse.In",
            "role": "HR Coordinator",
            "timePeriod": "Feb 2025 - Jul 2025",
            "location": "Bengaluru, Karnataka"
          }
        ]
      },
      "projects": {
        "name": "Projects",
        "key": "sections.projects",
        "data": [
          {
            "description": "<p>\"TechoNinja leveraged TATA Technologies to develop cutting-edge software solutions for optimizing manufacturing processes. Through advanced AI algorithms and IoT integration, the project successfully streamlined operations, reducing costs and enhancing productivity. Feb 2025 - Aug 2025.\"</p>",
            "points": [
              "Led HR reporting initiatives through MS Office and Slack, improving data accuracy by 20% and reducing reporting time by 4 hours",
              "Spearheaded the implementation of new reporting standards with MS Office, leading to a 25% improvement in data quality and consistency",
              "Implemented reporting automation with MS Office, reducing manual errors by 30% and saving 8 hours per week"
            ],
            "title": "TechoNinja ",
            "organisation": "TATA Technologies",
            "year": "Feb 2025 - Aug 2025"
          }
        ]
      },
      "technicalSkills": {
        "name": "Technical Skills",
        "key": "sections.technicalSkills",
        "data": [
          {
            "skills": [
              "Industry Knowledge",
              "IoT Integration",
              "HR Management",
              "Recruitment",
              "Analytical Thinking"
            ]
          }
        ]
      },
      "Professionalsummary": {
        "name": "Professional Summary",
        "key": "sections.Professionalsummary",
        "data": {
          "summary": "<p>Experienced HR Coordinator with 5+ years of success in managing recruitment, onboarding, and employee relations. Skilled in HRIS systems and compliance. Recognized for streamlining processes, reducing turnover, and enhancing employee engagement. Committed to driving organizational success through strategic HR initiatives. Seeking to leverage expertise in a dynamic corporate environment.</p>"
        }
      },
      "awards": {
        "name": "Awards",
        "key": "sections.awards",
        "data": [
          {
            "name": "",
            "description": "<p>Responsible for overseeing and managing various human resource functions, including recruitment, onboarding, employee engagement, and maintaining personnel records.</p>"
          }
        ]
      },
      "optional": {
        "name": "Optional",
        "key": "sections.optional",
        "data": []
      }
    }
  } 

  const template13 = {
    "name": "Untitled",
    "sections": {
      "profile": {
        "name": "Profile",
        "key": "sections.profile",
        "data": {
          "name": "Ashutosh Kumar",
          "address": "Banglore",
          "email": "ashutosh@gmail.com ",
          "phone": "98XXXXXXXX",
          "githubUsername": "Github Url",
          "position": "HR Coordinator",
          "linkedinUsername": "linkedin/in/username"
        }
      },
      "education": {
        "name": "Education",
        "key": "sections.education",
        "data": [
          {
            "degree": "Bachelor of Science ",
            "college": "IIT (ISM) ",
            "year": "2021-2025",
            "branch": "Human Resource",
            "percentage": "89"
          }
        ]
      },
      "experience": {
        "name": "Experience",
        "key": "sections.experience",
        "data": [
          {
            "description": "<p>As an HR Coordinator at Myfuse.In (Feb 2025 - Jul 2025), adeptly managed recruitment processes, coordinated employee onboarding, and facilitated HR administrative tasks. Streamlined procedures, enhancing organizational efficiency and employee satisfaction.</p>",
            "points": [
              "Led implementation of new employee onboarding process using BambooHR, reducing onboarding time by 25%",
              "Developed employee training program with Word, increasing employee satisfaction by 20%",
              "Implemented innovative recruitment strategy through social media platforms, resulting in 15% increase in qualified candidate applications"
            ],
            "company": "Myfuse.In",
            "role": "HR Coordinator",
            "timePeriod": "Feb 2025 - Jul 2025",
            "location": "Bengaluru, Karnataka"
          }
        ]
      },
      "projects": {
        "name": "Projects",
        "key": "sections.projects",
        "data": [
          {
            "description": "<p>\"TechoNinja leveraged TATA Technologies to develop cutting-edge software solutions for optimizing manufacturing processes. Through advanced AI algorithms and IoT integration, the project successfully streamlined operations, reducing costs and enhancing productivity. Feb 2025 - Aug 2025.\"</p>",
            "points": [
              "Led HR reporting initiatives through MS Office and Slack, improving data accuracy by 20% and reducing reporting time by 4 hours",
              "Spearheaded the implementation of new reporting standards with MS Office, leading to a 25% improvement in data quality and consistency",
              "Implemented reporting automation with MS Office, reducing manual errors by 30% and saving 8 hours per week"
            ],
            "title": "TechoNinja ",
            "organisation": "TATA Technologies",
            "year": "Feb 2025 - Aug 2025"
          }
        ]
      },
      "technicalSkills": {
        "name": "Technical Skills",
        "key": "sections.technicalSkills",
        "data": [
          {
            "skills": [
              "Industry Knowledge",
              "IoT Integration",
              "HR Management",
              "Recruitment",
              "Analytical Thinking"
            ]
          }
        ]
      },
      "Professionalsummary": {
        "name": "Professional Summary",
        "key": "sections.Professionalsummary",
        "data": {
          "summary": "<p>Experienced HR Coordinator with 5+ years of success in managing recruitment, onboarding, and employee relations. Skilled in HRIS systems and compliance. Recognized for streamlining processes, reducing turnover, and enhancing employee engagement. Committed to driving organizational success through strategic HR initiatives. Seeking to leverage expertise in a dynamic corporate environment.</p>"
        }
      },
      "awards": {
        "name": "Awards",
        "key": "sections.awards",
        "data": [
          {
            "name": "",
            "description": "<p>Responsible for overseeing and managing various human resource functions, including recruitment, onboarding, employee engagement, and maintaining personnel records.</p>"
          }
        ]
      },
      "optional": {
        "name": "Optional",
        "key": "sections.optional",
        "data": []
      }
    }
  } 

  const template14 = {
    "name": "Untitled",
    "sections": {
      "profile": {
        "name": "Profile",
        "key": "sections.profile",
        "data": {
          "name": "Ashutosh Kumar",
          "address": "Banglore",
          "email": "ashutosh@gmail.com ",
          "phone": "98XXXXXXXX",
          "githubUsername": "Github Url",
          "position": "HR Coordinator",
          "linkedinUsername": "linkedin/in/username"
        }
      },
      "education": {
        "name": "Education",
        "key": "sections.education",
        "data": [
          {
            "degree": "Bachelor of Science ",
            "college": "IIT (ISM) ",
            "year": "2021-2025",
            "branch": "Human Resource",
            "percentage": "89"
          }
        ]
      },
      "experience": {
        "name": "Experience",
        "key": "sections.experience",
        "data": [
          {
            "description": "<p>As an HR Coordinator at Myfuse.In (Feb 2025 - Jul 2025), adeptly managed recruitment processes, coordinated employee onboarding, and facilitated HR administrative tasks. Streamlined procedures, enhancing organizational efficiency and employee satisfaction.</p>",
            "points": [
              "Led implementation of new employee onboarding process using BambooHR, reducing onboarding time by 25%",
              "Developed employee training program with Word, increasing employee satisfaction by 20%",
              "Implemented innovative recruitment strategy through social media platforms, resulting in 15% increase in qualified candidate applications"
            ],
            "company": "Myfuse.In",
            "role": "HR Coordinator",
            "timePeriod": "Feb 2025 - Jul 2025",
            "location": "Bengaluru, Karnataka"
          }
        ]
      },
      "projects": {
        "name": "Projects",
        "key": "sections.projects",
        "data": [
          {
            "description": "<p>\"TechoNinja leveraged TATA Technologies to develop cutting-edge software solutions for optimizing manufacturing processes. Through advanced AI algorithms and IoT integration, the project successfully streamlined operations, reducing costs and enhancing productivity. Feb 2025 - Aug 2025.\"</p>",
            "points": [
              "Led HR reporting initiatives through MS Office and Slack, improving data accuracy by 20% and reducing reporting time by 4 hours",
              "Spearheaded the implementation of new reporting standards with MS Office, leading to a 25% improvement in data quality and consistency",
              "Implemented reporting automation with MS Office, reducing manual errors by 30% and saving 8 hours per week"
            ],
            "title": "TechoNinja ",
            "organisation": "TATA Technologies",
            "year": "Feb 2025 - Aug 2025"
          }
        ]
      },
      "technicalSkills": {
        "name": "Technical Skills",
        "key": "sections.technicalSkills",
        "data": [
          {
            "skills": [
              "Industry Knowledge",
              "IoT Integration",
              "HR Management",
              "Recruitment",
              "Analytical Thinking"
            ]
          }
        ]
      },
      "Professionalsummary": {
        "name": "Professional Summary",
        "key": "sections.Professionalsummary",
        "data": {
          "summary": "<p>Experienced HR Coordinator with 5+ years of success in managing recruitment, onboarding, and employee relations. Skilled in HRIS systems and compliance. Recognized for streamlining processes, reducing turnover, and enhancing employee engagement. Committed to driving organizational success through strategic HR initiatives. Seeking to leverage expertise in a dynamic corporate environment.</p>"
        }
      },
      "awards": {
        "name": "Awards",
        "key": "sections.awards",
        "data": [
          {
            "name": "",
            "description": "<p>Responsible for overseeing and managing various human resource functions, including recruitment, onboarding, employee engagement, and maintaining personnel records.</p>"
          }
        ]
      },
      "optional": {
        "name": "Optional",
        "key": "sections.optional",
        "data": []
      }
    }
  } 

  const template15 = {
    "name": "Untitled",
    "sections": {
      "profile": {
        "name": "Profile",
        "key": "sections.profile",
        "data": {
          "name": "Ashutosh Kumar",
          "address": "Banglore",
          "email": "ashutosh@gmail.com ",
          "phone": "98XXXXXXXX",
          "githubUsername": "Github Url",
          "position": "HR Coordinator",
          "linkedinUsername": "linkedin/in/username"
        }
      },
      "education": {
        "name": "Education",
        "key": "sections.education",
        "data": [
          {
            "degree": "Bachelor of Science ",
            "college": "IIT (ISM) ",
            "year": "2021-2025",
            "branch": "Human Resource",
            "percentage": "89"
          }
        ]
      },
      "experience": {
        "name": "Experience",
        "key": "sections.experience",
        "data": [
          {
            "description": "<p>As an HR Coordinator at Myfuse.In (Feb 2025 - Jul 2025), adeptly managed recruitment processes, coordinated employee onboarding, and facilitated HR administrative tasks. Streamlined procedures, enhancing organizational efficiency and employee satisfaction.</p>",
            "points": [
              "Led implementation of new employee onboarding process using BambooHR, reducing onboarding time by 25%",
              "Developed employee training program with Word, increasing employee satisfaction by 20%",
              "Implemented innovative recruitment strategy through social media platforms, resulting in 15% increase in qualified candidate applications"
            ],
            "company": "Myfuse.In",
            "role": "HR Coordinator",
            "timePeriod": "Feb 2025 - Jul 2025",
            "location": "Bengaluru, Karnataka"
          }
        ]
      },
      "projects": {
        "name": "Projects",
        "key": "sections.projects",
        "data": [
          {
            "description": "<p>\"TechoNinja leveraged TATA Technologies to develop cutting-edge software solutions for optimizing manufacturing processes. Through advanced AI algorithms and IoT integration, the project successfully streamlined operations, reducing costs and enhancing productivity. Feb 2025 - Aug 2025.\"</p>",
            "points": [
              "Led HR reporting initiatives through MS Office and Slack, improving data accuracy by 20% and reducing reporting time by 4 hours",
              "Spearheaded the implementation of new reporting standards with MS Office, leading to a 25% improvement in data quality and consistency",
              "Implemented reporting automation with MS Office, reducing manual errors by 30% and saving 8 hours per week"
            ],
            "title": "TechoNinja ",
            "organisation": "TATA Technologies",
            "year": "Feb 2025 - Aug 2025"
          }
        ]
      },
      "technicalSkills": {
        "name": "Technical Skills",
        "key": "sections.technicalSkills",
        "data": [
          {
            "skills": [
              "Industry Knowledge",
              "IoT Integration",
              "HR Management",
              "Recruitment",
              "Analytical Thinking"
            ]
          }
        ]
      },
      "Professionalsummary": {
        "name": "Professional Summary",
        "key": "sections.Professionalsummary",
        "data": {
          "summary": "<p>Experienced HR Coordinator with 5+ years of success in managing recruitment, onboarding, and employee relations. Skilled in HRIS systems and compliance. Recognized for streamlining processes, reducing turnover, and enhancing employee engagement. Committed to driving organizational success through strategic HR initiatives. Seeking to leverage expertise in a dynamic corporate environment.</p>"
        }
      },
      "awards": {
        "name": "Awards",
        "key": "sections.awards",
        "data": [
          {
            "name": "",
            "description": "<p>Responsible for overseeing and managing various human resource functions, including recruitment, onboarding, employee engagement, and maintaining personnel records.</p>"
          }
        ]
      },
      "optional": {
        "name": "Optional",
        "key": "sections.optional",
        "data": []
      }
    }
  } 

  export {template1}