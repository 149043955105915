import React, { useEffect, useState } from "react";
import styles from "../assets/Dashboard/dashboard.module.css";
import { useAuth } from "../ResumeBuilder/builder/components/context/auth";
import HeadCont from "../components/DashBoard.js/HeadCont";
import Resume from "../components/DashBoard.js/Resume";
import Recomanded from "../components/DashBoard.js/Recomanded";
import Blog from "../components/DashBoard.js/Blog";
import MyResponsiveNavbar from "./Navbar";
import HeadContMobile from "../components/DashBoard.js/HeadContMobile";
import BottomBar from "../components/BottomBar";
import { useNavigate } from "react-router-dom";
import Modeluploadscratch from "../components/Modeluploadscratch";
import ModelChoseTemplate from "../components/ModelChoseTemplate";
import ModalChooseDomain from "../components/ModalChooseDomain";
import ModelAskExp from "../components/ModelAskExp";
import RightTop from "../ResumeBuilder/builder/RightTop";
import Header from "../components/DashBoard.js/Header";
import Status from "../components/DashBoard.js/Status";
import JobRecon from "../components/DashBoard.js/JobRecon";
import Upgrade from "../components/DashBoard.js/Upgrade";
const DashBoardNew = () => {
  const Navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [SecondmodalShow, setSecondModalShow] = useState(false);
  const [FourthmodalShow, setFourthModalShow] = useState(false);
  const [selectedExperience, setSelectedExperience] = useState(null);
  const [selectedSector, setSelectedSector] = useState(null);
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [imgSrc, setImgSrc] = useState(
    "https://res.cloudinary.com/dvepwlz9j/image/upload/v1729186227/all/vzw4bjslemzuuvznzdgs.svg"
  );
  const {
    auth,
    previousUrlCheatSheet,
    isCheatSheetRdirect,
    setIsCheatSheetRdirect,
    setFlagdownloadCheatSheet,
  } = useAuth();

  useEffect(() => {
    if (Array.isArray(auth?.user) && auth.user.length > 0) {
      setImgSrc(
        auth.user[0]?.images ||
          "https://res.cloudinary.com/dvepwlz9j/image/upload/v1729186227/all/vzw4bjslemzuuvznzdgs.svg"
      );
    }

    if (isCheatSheetRdirect) {
      setIsCheatSheetRdirect(false);
      setFlagdownloadCheatSheet(true);
      Navigate(previousUrlCheatSheet);
    }
  }, [auth]);

  return (
    <div className={styles.container}   >
      <div className={styles.navbarMobile}>
        {" "}
        <MyResponsiveNavbar />
      </div>  
      <div className={styles.navwrapper}>
        <RightTop auth={auth} image={imgSrc} activeNav={"Dashboard"}  setModalShow={setModalShow}  />
      </div> 
      <div className={styles.ComponentContainer}>
        <Header modalShow={modalShow} setModalShow={setModalShow} auth={auth} />
        <Status />
        <Resume
          modalShow={modalShow}
          setModalShow={setModalShow}
          SecondmodalShow={SecondmodalShow}
          setSecondModalShow={setSecondModalShow}
        />
        <JobRecon />
        <Upgrade />
      </div>
      <Modeluploadscratch
        modalShow={modalShow}
        setModalShow={setModalShow}
        setSecondModalShow={setFourthModalShow}
      />
      <ModelAskExp
        modalShow={FourthmodalShow}
        setModalShow={setFourthModalShow}
        setSecondModalShow={setSecondModalShow}
        setSelectedExperience={setSelectedExperience}
        setSelectedSector={setSelectedSector}
        setSelectedDomain={setSelectedDomain}
        selectedExperience={selectedExperience}
        selectedSector={selectedSector}
        selectedDomain={selectedDomain}
      />
      <ModelChoseTemplate
        modalShow={SecondmodalShow}
        setModalShow={setSecondModalShow}
        selectedExperience={selectedExperience}
        selectedSector={selectedSector}
        selectedDomain={selectedDomain}
      />
    </div>
  );
};

export default DashBoardNew;
