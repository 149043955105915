import React, { useState } from "react";
import ReactDOMServer from "react-dom/server";
import styles from '../assets/test.module.css';

const ResumeContent = () => (
  <div className={styles.resumeContainer}>
    {/* Header Section */}
    <div className={styles.header}>
      <h1 className={styles.headerTitle}>John Doe</h1>
      <p className={styles.headerSubtitle}>Software Engineer</p>
      <div className={styles.contactInfo}>
        📧 john.doe@email.com | 📱 (123) 456-7890 | 📍 New York, NY
        <br />
        🔗 linkedin.com/in/johndoe | 💻 github.com/johndoe
      </div>
    </div>

    {/* Summary Section */}
    <div className={styles.section}>
      <h2 className={styles.sectionTitle}>Professional Summary</h2>
      <p>
        Experienced Software Engineer with 5+ years of expertise in full-stack development,
        specializing in React, Node.js, and cloud technologies. Proven track record of
        delivering scalable solutions and improving application performance by 40%.
      </p>
    </div>

    {/* Experience Section */}
    <div className={styles.section}>
      <h2 className={styles.sectionTitle}>Professional Experience</h2>
      
      <div className={styles.section}>
        <h3 className={styles.jobTitle}>Senior Software Engineer | Tech Solutions Inc.</h3>
        <p className={styles.jobPeriod}>Jan 2020 - Present | New York, NY</p>
        <ul className={styles.jobList}>
          <li>Led development of microservices architecture, reducing system latency by 30%</li>
          <li>Managed a team of 5 developers, delivering 15+ successful projects</li>
          <li>Implemented CI/CD pipeline, reducing deployment time by 50%</li>
        </ul>
      </div>

      <div className={styles.section}>
        <h3 className={styles.jobTitle}>Software Engineer | Innovation Labs</h3>
        <p className={styles.jobPeriod}>Jun 2018 - Dec 2019 | Boston, MA</p>
        <ul className={styles.jobList}>
          <li>Developed RESTful APIs serving 1M+ daily requests</li>
          <li>Optimized database queries, improving response time by 45%</li>
          <li>Collaborated with UX team to implement responsive design patterns</li>
        </ul>
      </div>
    </div>

    {/* Skills Section */}
    <div className={styles.section}>
      <h2 className={styles.sectionTitle}>Technical Skills</h2>
      <div className={styles.skillsGrid}>
        <div>
          <strong>Languages:</strong>
          <p>JavaScript, Python, Java, SQL</p>
        </div>
        <div>
          <strong>Frontend:</strong>
          <p>React, Redux, HTML5, CSS3, SASS</p>
        </div>
        <div>
          <strong>Backend:</strong>
          <p>Node.js, Express, Django, Spring</p>
        </div>
        <div>
          <strong>Tools & Platforms:</strong>
          <p>AWS, Docker, Git, Jenkins</p>
        </div>
      </div>
    </div>

    {/* Education Section */}
    <div className={styles.section}>
      <h2 className={styles.sectionTitle}>Education</h2>
      <div className={styles.section}>
        <h3 className={styles.jobTitle}>Master of Science in Computer Science</h3>
        <p className={styles.jobPeriod}>Massachusetts Institute of Technology | 2018</p>
      </div>
      <div className={styles.section}>
        <h3 className={styles.jobTitle}>Bachelor of Science in Software Engineering</h3>
        <p className={styles.jobPeriod}>University of California, Berkeley | 2016</p>
      </div>
    </div>
  </div>
);

const Test2 = () => {
  const [status, setStatus] = useState('');
  const [loading, setLoading] = useState(false);

  const generatePDF = async () => {
    try {
      setLoading(true);
      setStatus('Generating PDF...');
      
      const htmlContent = ReactDOMServer.renderToString(<ResumeContent />);
      
      // First, create a mapping of hashed to original class names
      const classMapping = {};
      Object.entries(styles).forEach(([key, value]) => {
        classMapping[value] = key;
      });

      // Replace hashed class names with original ones in the HTML
      let processedHTML = htmlContent;
      Object.entries(classMapping).forEach(([hashed, original]) => {
        const regex = new RegExp(`class="([^"]*?)${hashed}([^"]*?)"`, 'g');
        processedHTML = processedHTML.replace(regex, (match, before, after) => {
          return `class="${before}${original}${after}"`;
        });
      });

      const cssString = Object.entries(styles).reduce((acc, [key, value]) => {
        // Replace the hashed classname with the original classname
        const originalClass = `.${key}`;
        const hashedClass = `.${value}`;
        
        // Find the style element that contains our CSS module styles
        const styleElements = document.getElementsByTagName('style');
        let moduleStyles = '';
        
        for (let styleEl of styleElements) {
          const cssText = styleEl.textContent;
          if (cssText.includes(hashedClass)) {
            // Extract the CSS rule for this class
            const regex = new RegExp(`${hashedClass}\\s*{([^}]+)}`, 'g');
            const match = regex.exec(cssText);
            if (match && match[1]) {
              moduleStyles = `${originalClass} {${match[1]}}`;
              break;
            }
          }
        }
        
        return acc + moduleStyles + '\n';
      }, '');

      const fullHTML = `
        <!DOCTYPE html>
        <html>
          <head>
            <meta charset="UTF-8">
            <style>
              ${cssString}
            </style>
          </head>
          <body>
            ${processedHTML}
          </body>
        </html>
      `;
      console.log('asdasd', fullHTML);
      const response = await fetch("http://localhost:8085/pdf/generate-pdf", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ html: fullHTML }),
      });

      const data = await response.json();
      
      if (!data.success) {
        throw new Error(data.error || 'Failed to generate PDF');
      }

      setStatus('Downloading PDF...');
      window.location.href = `http://localhost:8085/pdf/download/${data.filename}`;
      
      setStatus('PDF downloaded successfully!');

    } catch (error) {
      console.error('Error:', error);
      setStatus('Error: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <button 
        onClick={generatePDF} 
        disabled={loading}
        className={styles.generateButton}
      >
        {loading ? 'Processing...' : 'Generate Resume PDF'}
      </button>
      <div className={`${styles.statusMessage} ${status.includes('Error') ? styles.statusError : styles.statusSuccess}`}>
        {status}
      </div>
      
      <div className={styles.previewContainer}>
        <h3 style={{ marginBottom: '20px' }}>Preview:</h3>
        <ResumeContent />
      </div>
    </div>
  );
};

export default Test2;
