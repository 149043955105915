import React, { useEffect, useRef, useState } from "react";
import styles from "./Preview.module.css";
import { useResume } from "../context/Resume";
import { htmlToCanvasImage } from "../../../utils/html_to_canvas_image";
import { useJobs } from "../context/Jobs";
import { motion } from "framer-motion";
import previewStyles from "./Preview.module.css";
const PreviewTempalte = ({ values, template }) => {
  const { currentStep } = useResume();

  const { payment } = useJobs();
  const previewRef = useRef(null);
  const templateContainerRef = useRef(null);
  const [image, setImage] = useState(null);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (values) {
        if (templateContainerRef.current) {
          htmlToCanvasImage(
            templateContainerRef.current,
            payment,
            values,
            currentStep
          ).then((img) => {
            setImage(img);
          });
        }
      }
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [values]);

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  return (
    <div className={styles.preview} >
      <div className={previewStyles.print_only_resume_page} id="print_content">
        <div
          ref={templateContainerRef}
          className={previewStyles.container}
          style={{
            width: "100%",
            padding: template?.page_margins,
          }}
        >
          {template?.resume && <template.resume sections={values.sections} />}
        </div>
      </div>

      <div ref={previewRef} className={styles.preview_image}>
        {image && (
          <PreviewImage
            image={image}
            template={template}
            handleImageLoad={handleImageLoad}
            isImageLoaded={isImageLoaded}
          />
        )}
      </div>
    </div>
  );
};

const PreviewImage = ({ image, handleImageLoad, isImageLoaded }) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.5 }}
    style={{ position: "relative", display: "inline-block"}}
  >
    <motion.img
      src={image}
      alt="Resume"
      onLoad={handleImageLoad}
      className={isImageLoaded ? styles.imageLoaded : ""}
      whileHover={{ scale: 1.02 }}
      transition={{ type: "spring", stiffness: 300 }}
    />
  </motion.div>
);

export default PreviewTempalte;
