const Database = {
    "IT": {
      "roles": {
        "Software Developer": {
          "tasks": ["Coding", "Debugging", "Testing", "Designing", "Deploying", "Optimizing", "Documenting", "Reviewing", "Collaborating", "Integrating"],
          "skills": ["Problem-Solving", "Analytical Thinking", "Communication", "Time Management", "Teamwork", "Creativity", "Attention to Detail", "Adaptability", "Algorithm Design", "Continuous Learning"],
          "tools": ["Git", "Docker", "Jenkins", "Visual Studio Code", "IntelliJ IDEA", "Postman", "JIRA", "Kubernetes", "SQL Server", "Selenium"],
          "certifications": ["Oracle Certified Professional: Java SE", "Microsoft Certified: Azure Developer Associate", "AWS Certified Developer - Associate", "C# Programming Certification", "GitHub Actions CI/CD Certification"]
        },
        "Data Analyst": {
          "tasks": ["Analyzing", "Cleaning", "Visualizing", "Reporting", "Mining", "Interpreting", "Presenting", "Modeling", "Querying", "Forecasting"],
          "skills": ["Analytical Thinking", "Communication", "Statistics", "Programming", "Data Visualization", "Problem-Solving", "Collaboration", "Critical Thinking", "Adaptability", "Continuous Learning"],
          "tools": ["Excel", "Tableau", "Power BI", "SQL", "Python", "R", "Jupyter Notebook", "Google Sheets", "SAS", "AWS"],
          "certifications": ["Microsoft Data Analyst Certification", "Tableau Desktop Specialist", "Google Data Analytics Professional Certificate", "AWS Data Analytics - Specialty", "Power BI Data Analyst Certification"]
        },
        "Web Developer": {
          "tasks": ["Designing", "Coding", "Debugging", "Testing", "Updating", "Deploying", "Integrating", "Optimizing", "Researching", "Reviewing"],
          "skills": ["Problem-Solving", "Creativity", "Communication", "Programming", "Debugging", "Teamwork", "Analytical Thinking", "Adaptability", "Attention to Detail", "Continuous Learning"],
          "tools": ["Git", "Visual Studio Code", "Chrome DevTools", "Postman", "WordPress", "Bootstrap", "React", "Angular", "Node.js", "Figma"],
          "certifications": ["Certified Web Development Professional", "JavaScript Specialist Certification", "HTML5 and CSS3 Professional Certificate", "React Developer Certification", "Google Mobile Web Specialist"]
        },
        "Java Developer": {
          "tasks": ["Coding", "Debugging", "Testing", "Deploying", "Reviewing", "Optimizing", "Refactoring", "Integrating", "Documenting", "Designing"],
          "skills": ["Programming", "Problem-Solving", "Analytical Thinking", "Debugging", "Communication", "Creativity", "Teamwork", "Time Management", "Continuous Learning", "Attention to Detail"],
          "tools": ["Eclipse", "IntelliJ IDEA", "Git", "Maven", "Jenkins", "Docker", "Spring Boot", "Hibernate", "MySQL", "JIRA"],
          "certifications": ["Oracle Certified Professional: Java SE Programmer", "Spring Professional Certification", "Java EE Application Developer Certification", "Apache Maven Certification", "Microservices with Spring Boot Certification"]
        },
        "Network Engineer": {
          "tasks": ["Configuring", "Monitoring", "Troubleshooting", "Installing", "Optimizing", "Securing", "Documenting", "Analyzing", "Planning", "Testing"],
          "skills": ["Networking", "Troubleshooting", "Communication", "Problem-Solving", "Teamwork", "Analytical Thinking", "Attention to Detail", "Adaptability", "Time Management", "Continuous Learning"],
          "tools": ["Wireshark", "Cisco Packet Tracer", "Nagios", "SolarWinds", "Putty", "NetFlow Analyzer", "Zabbix", "Splunk", "Firewall", "VPN"],
          "certifications": ["Cisco Certified Network Associate (CCNA)", "CompTIA Network+", "Juniper Networks Certified Associate (JNCIA-Junos)", "Fortinet NSE Certification", "Cisco Certified Specialist - Enterprise Core"]
        },
        "Support Engineer": {
          "tasks": ["Troubleshooting", "Configuring", "Testing", "Monitoring", "Resolving", "Installing", "Reporting", "Documenting", "Supporting", "Assisting"],
          "skills": ["Communication", "Troubleshooting", "Teamwork", "Problem-Solving", "Adaptability", "Analytical Thinking", "Attention to Detail", "Time Management", "Patience", "Continuous Learning"],
          "tools": ["Zendesk", "ServiceNow", "JIRA", "Microsoft Teams", "Slack", "Remote Desktop", "TeamViewer", "Zoho Desk", "Nagios", "Splunk"],
          "certifications": ["Microsoft Certified: Modern Desktop Administrator Associate", "ITIL Foundation Certification", "CompTIA A+", "ServiceNow Certified System Administrator", "Certified Technical Support Specialist"]
        },
        "Full Stack Developer": {
          "tasks": ["Coding", "Testing", "Designing", "Debugging", "Reviewing", "Deploying", "Integrating", "Optimizing", "Documenting", "Prototyping"],
          "skills": ["Programming", "Debugging", "Communication", "Teamwork", "Analytical Thinking", "Problem-Solving", "Creativity", "Time Management", "Adaptability", "Continuous Learning"],
          "tools": ["Git", "Docker", "Jenkins", "VS Code", "React", "Node.js", "MongoDB", "PostgreSQL", "Heroku", "AWS"],
          "certifications": ["Full Stack Web Development Certificate (Coursera)", "MERN Stack Developer Certification", "Certified Kubernetes Application Developer (CKAD)", "React Developer Certification", "Node.js Developer Certification"]
        },
        "UI/UX Designer": {
          "tasks": ["Designing", "Prototyping", "Testing", "Researching", "Collaborating", "Documenting", "Optimizing", "Sketching", "Iterating", "Reviewing"],
          "skills": ["Wireframing", "Prototyping", "UX Principles", "Collaboration", "Communication", "Creativity", "Problem-Solving", "Sketching", "Research", "Attention to Detail"],
          "tools": ["Figma", "Adobe XD", "Sketch", "InVision", "Photoshop", "Illustrator", "Axure RP", "Zeplin", "Balsamiq", "Miro"],
          "certifications": ["Google UX Design", "Interaction Design Foundation", "Adobe Certified Professional", "Figma Design Masterclass", "UX Academy Certification"]
        },
        "DevOps Engineer": {
          "tasks": ["Automating", "Deploying", "Monitoring", "Testing", "Debugging", "Optimizing", "Configuring", "Scaling", "Analyzing", "Documenting"],
          "skills": ["Automation", "Problem-Solving", "Communication", "Analytical Thinking", "Programming", "Adaptability", "Teamwork", "Continuous Learning", "Debugging", "Collaboration"],
          "tools": ["Terraform", "Kubernetes", "Docker", "Git", "Jenkins", "Ansible", "AWS", "Azure", "Splunk", "Prometheus"],
          "certifications": ["AWS Certified DevOps Engineer - Professional", "Docker Certified Associate", "Kubernetes Administrator Certification (CKA)", "Microsoft Azure DevOps Solutions Certification", "HashiCorp Certified: Terraform Associate"]
        },
        "SAP Consultant": {
          "tasks": ["Configuring", "Testing", "Designing", "Debugging", "Analyzing", "Optimizing", "Documenting", "Planning", "Reporting", "Integrating"],
          "skills": ["Communication", "Problem-Solving", "Debugging", "Analytical Thinking", "Programming", "Teamwork", "Adaptability", "Time Management", "Attention to Detail", "Continuous Learning"],
          "tools": ["SAP ERP", "SAP HANA", "SAP FICO", "Oracle ERP", "Tableau", "SQL Server", "Power BI", "Microsoft Excel", "SAP MM", "SAP SD"],
          "certifications": ["SAP Certified Development Associate - ABAP", "SAP Certified Application Associate - FICO", "SAP HANA Administration Certification", "SAP SuccessFactors Employee Central Certification", "SAP Certified Integration Associate"]
        },
        "Python Developer": {
          "tasks": ["Coding", "Debugging", "Testing", "Designing", "Optimizing", "Reviewing", "Integrating", "Documenting", "Analyzing", "Refactoring"],
          "skills": ["Programming", "Debugging", "Problem-Solving", "Communication", "Analytical Thinking", "Teamwork", "Attention to Detail", "Time Management", "Adaptability", "Continuous Learning"],
          "tools": ["PyCharm", "Jupyter Notebook", "Anaconda", "VS Code", "TensorFlow", "NumPy", "Pandas", "Matplotlib", "Flask", "Django"],
          "certifications": ["Python Institute Certified Entry-Level Python Programmer (PCEP)", "Certified Python Developer (PCPP)", "TensorFlow Developer Certificate", "Data Science with Python Certification", "Python for AI & Machine Learning"]
        },
        "Data Engineer": {
          "tasks": ["Analyzing", "Extracting", "Transforming", "Loading", "Modeling", "Visualizing", "Testing", "Optimizing", "Documenting", "Reporting"],
          "skills": ["Data Analysis", "Communication", "Problem-Solving", "Collaboration", "Analytical Thinking", "Programming", "Data Modeling", "Visualization", "Time Management", "Continuous Learning"],
          "tools": ["Apache Airflow", "Spark", "Hadoop", "SQL", "AWS", "GCP", "Databricks", "Tableau", "Python", "R"],
          "certifications": ["Google Cloud Professional Data Engineer", "Microsoft Certified: Azure Data Engineer Associate", "AWS Certified Big Data - Specialty", "Apache Spark and Scala Certification", "Databricks Certified Data Engineer Associate"]
        },
        ".NET Developer": {
          "tasks": ["Coding", "Debugging", "Testing", "Reviewing", "Designing", "Deploying", "Refactoring", "Documenting", "Optimizing", "Integrating"],
          "skills": ["Programming", "Debugging", "Problem-Solving", "Communication", "Analytical Thinking", "Teamwork", "Creativity", "Time Management", "Continuous Learning", "Attention to Detail"],
          "tools": ["Visual Studio", ".NET Framework", "Git", "SQL Server", "Azure", "Docker", "Jenkins", "Postman", "JIRA", "Power BI"],
          "certifications": ["Microsoft Certified: Azure Solutions Architect Expert", "MCSD: App Builder Certification", "Xamarin Certified Mobile Developer", "ASP.NET Core Developer Certification", "Microsoft Certified: Power Platform Fundamentals"]
        },
        "React Developer": {
          "tasks": ["Designing", "Coding", "Debugging", "Reviewing", "Optimizing", "Testing", "Deploying", "Integrating", "Prototyping", "Refactoring"],
          "skills": ["Programming", "Debugging", "Problem-Solving", "Communication", "Creativity", "Analytical Thinking", "Teamwork", "Adaptability", "Continuous Learning", "Attention to Detail"],
          "tools": ["Git", "VS Code", "React", "Redux", "Webpack", "Chrome DevTools", "Node.js", "Postman", "Firebase", "AWS"],
          "certifications": ["React Professional Certification (Scrimba)", "Advanced React and Redux Certification", "Certified Front-End React Developer", "React Native Developer Certification", "JavaScript and React Bootcamp Certification"]
        },
        "Mobile App Developer": {
          "tasks": ["Designing", "Coding", "Debugging", "Testing", "Optimizing", "Prototyping", "Reviewing", "Integrating", "Updating", "Deploying"],
          "skills": ["Programming", "Creativity", "Debugging", "Problem-Solving", "Analytical Thinking", "Communication", "Teamwork", "Attention to Detail", "Adaptability", "Continuous Learning"],
          "tools": ["Android Studio", "Xcode", "Firebase", "Git", "Flutter", "React Native", "Swift", "Kotlin", "Jenkins", "VS Code"],
          "certifications": ["Google Associate Android Developer Certification", "Apple Certified iOS App Developer", "Flutter & Dart Certification", "Kotlin Android Developer Certification", "Xamarin Cross-Platform Development Certification"]
        },
        "QA Engineer": {
          "tasks": ["Testing", "Debugging", "Reporting", "Reviewing", "Documenting", "Automating", "Collaborating", "Analyzing", "Designing", "Validating"],
          "skills": ["Attention to Detail", "Communication", "Analytical Thinking", "Problem-Solving", "Teamwork", "Collaboration", "Time Management", "Creativity", "Testing Principles", "Continuous Learning"],
          "tools": ["Selenium", "JIRA", "TestNG", "Jenkins", "Git", "Postman", "SoapUI", "Cucumber", "Appium", "JMeter"],
          "certifications": ["ISTQB Certified Tester Foundation Level (CTFL)", "Certified Software Quality Analyst (CSQA)", "Selenium WebDriver Certification", "Advanced Level Test Automation Engineer (ISTQB)", "Certified Performance Testing Professional"]
        },
        "Assistant IT Project Coordinator": {
          "tasks": ["Assisting", "Coordinating", "Scheduling", "Planning", "Documenting", "Reporting", "Testing", "Supporting", "Reviewing", "Communicating"],
          "skills": ["Coordination", "Communication", "Planning", "Problem-Solving", "Analytical Thinking", "Teamwork", "Adaptability", "Time Management", "Collaboration", "Continuous Learning"],
          "tools": ["Microsoft Project", "JIRA", "Trello", "Asana", "Slack", "Excel", "Tableau", "SharePoint", "GanttProject", "MS Teams"],
          "certifications": ["CAPM: Certified Associate in Project Management", "Agile Certified Practitioner (PMI-ACP)", "ITIL 4 Foundation Certification", "Microsoft Project Certification", "Certified ScrumMaster (CSM)"]
        },
        "PHP Developer": {
          "tasks": ["Coding", "Testing", "Debugging", "Updating", "Optimizing", "Reviewing", "Deploying", "Documenting", "Integrating", "Prototyping"],
          "skills": ["Programming", "Debugging", "Problem-Solving", "Communication", "Analytical Thinking", "Teamwork", "Time Management", "Adaptability", "Creativity", "Continuous Learning"],
          "tools": ["PHPStorm", "XAMPP", "MySQL", "VS Code", "Git", "Laravel", "Symfony", "Docker", "Postman", "Apache"],
          "certifications": ["Zend Certified PHP Engineer", "PHP & MySQL Web Development Certification", "Laravel PHP Framework Certification", "WordPress Developer Certification", "Symfony Certified Developer"]
        },
        "Salesforce Developer": {
          "tasks": ["Coding", "Debugging", "Testing", "Deploying", "Reviewing", "Optimizing", "Documenting", "Refactoring", "Analyzing", "Reporting"],
          "skills": ["Programming", "Debugging", "Analytical Thinking", "Problem-Solving", "Teamwork", "Communication", "Attention to Detail", "Continuous Learning", "Creativity", "Collaboration"],
          "tools": ["Salesforce CRM", "Apex", "VS Code", "Git", "Jenkins", "Postman", "Tableau", "Heroku", "AWS", "Data Loader"],
          "certifications": ["Salesforce Certified Platform Developer I", "Salesforce Certified Platform App Builder", "Salesforce Certified Administrator", "Salesforce Marketing Cloud Developer", "Salesforce Einstein Analytics & Discovery Consultant"]
        },
        "Cloud Engineer": {
          "tasks": ["Automating", "Configuring", "Monitoring", "Deploying", "Debugging", "Optimizing", "Securing", "Documenting", "Analyzing", "Testing"],
          "skills": ["Cloud Computing", "Programming", "Automation", "Problem-Solving", "Communication", "Analytical Thinking", "Teamwork", "Debugging", "Attention to Detail", "Continuous Learning"],
          "tools": ["AWS", "Azure", "Terraform", "Kubernetes", "Docker", "Git", "Jenkins", "Splunk", "Prometheus", "Ansible"],
          "certifications": ["AWS Certified Solutions Architect - Associate", "Microsoft Certified: Azure Solutions Architect Expert", "Google Professional Cloud Architect", "CompTIA Cloud+", "Certified Kubernetes Administrator (CKA)"]
        },
        "IT Support Engineer": {
          "tasks": ["Troubleshooting", "Monitoring", "Installing", "Configuring", "Testing", "Reporting", "Supporting", "Documenting", "Securing", "Assisting"],
          "skills": ["Troubleshooting", "Communication", "Teamwork", "Problem-Solving", "Adaptability", "Analytical Thinking", "Time Management", "Attention to Detail", "Patience", "Continuous Learning"],
          "tools": ["Zendesk", "ServiceNow", "Microsoft Teams", "Remote Desktop", "Splunk", "Nagios", "TeamViewer", "JIRA", "Slack", "Zoom"],
          "certifications": ["CompTIA IT Fundamentals (ITF+)", "Microsoft Certified: Windows Server Fundamentals", "Google IT Support Professional Certificate", "ITIL 4 Foundation Certification", "ServiceNow Certified System Administrator"]
        },
        "Entry-level Developer": {
          "tasks": ["Coding", "Debugging", "Testing", "Reviewing", "Documenting", "Supporting", "Deploying", "Optimizing", "Prototyping", "Assisting"],
          "skills": ["Programming", "Debugging", "Communication", "Problem-Solving", "Analytical Thinking", "Teamwork", "Time Management", "Creativity", "Attention to Detail", "Continuous Learning"],
          "tools": ["Git", "Docker", "Jenkins", "VS Code", "Chrome DevTools", "Postman", "Heroku", "MongoDB", "Node.js", "Firebase"],
          "certifications": ["Oracle Certified Associate, Java SE Programmer", "Microsoft Certified: Azure Fundamentals", "GitHub Actions Fundamentals Certification", "Front-End Web Development Certificate", "Python Basics for Beginners Certification"]
        },
        "Systems Engineer": {
          "tasks": ["Configuring", "Monitoring", "Troubleshooting", "Testing", "Supporting", "Documenting", "Installing", "Reporting", "Designing", "Analyzing"],
          "skills": ["Networking", "Communication", "Troubleshooting", "Problem-Solving", "Adaptability", "Analytical Thinking", "Attention to Detail", "Time Management", "Collaboration", "Continuous Learning"],
          "tools": ["Splunk", "Nagios", "Jenkins", "Wireshark", "JIRA", "Remote Desktop", "Git", "Docker", "Terraform", "Ansible"],
          "certifications": ["Microsoft Certified: Azure Administrator Associate", "Red Hat Certified System Administrator (RHCSA)", "VMware Certified Professional - Data Center Virtualization", "Cisco Certified Specialist - Data Center Core", "CompTIA Linux+"]
        }
      }
    },
    "Finance": {
      "roles": {
        "Financial Analyst": {
          "tasks": ["Analyzing", "Forecasting", "Budgeting", "Reporting", "Evaluating", "Modeling", "Interpreting", "Presenting", "Researching", "Monitoring"],
          "skills": ["Analytical Thinking", "Problem-Solving", "Communication", "Financial Modeling", "Excel", "Attention to Detail", "Forecasting", "Collaboration", "Adaptability", "Continuous Learning"],
          "tools": ["Excel", "Power BI", "Tableau", "SQL", "SAP", "Oracle ERP", "Bloomberg", "Python", "R", "Microsoft Dynamics"],
          "certifications": ["CFA Level 1", "Financial Modeling Certification", "Tableau Certification", "SQL Certification", "Excel Advanced"]
        },
        "Credit Analyst": {
          "tasks": ["Evaluating", "Analyzing", "Monitoring", "Reporting", "Assessing", "Investigating", "Communicating", "Calculating", "Reviewing", "Documenting"],
          "skills": ["Risk Assessment", "Analytical Thinking", "Communication", "Attention to Detail", "Problem-Solving", "Reporting", "Teamwork", "Collaboration", "Adaptability", "Financial Analysis"],
          "tools": ["Excel", "SAP", "SQL", "Tableau", "Power BI", "Moody's Analytics", "Bloomberg", "Microsoft Access", "R", "Python"],
          "certifications": ["Credit Risk Certification", "Moody's Analytics Certification", "CFA Level 1", "Financial Risk Manager (FRM)", "SQL Certification"]
        },
        "Fund Accountant": {
          "tasks": ["Reconciling", "Reporting", "Allocating", "Calculating", "Reviewing", "Auditing", "Monitoring", "Evaluating", "Recording", "Documenting"],
          "skills": ["Reconciliation", "Problem-Solving", "Analytical Thinking", "Communication", "Attention to Detail", "Teamwork", "Excel Proficiency", "Reporting", "Adaptability", "Continuous Learning"],
          "tools": ["Excel", "SAP", "QuickBooks", "Oracle ERP", "Power BI", "Tableau", "SQL", "Bloomberg", "Sage", "Microsoft Dynamics"],
          "certifications": ["Fund Accounting Certification", "CFA Level 1", "Investment Operations Certificate", "Financial Modeling Certification", "MS Excel Advanced"]
        },
        "Equity Research Analyst": {
          "tasks": ["Researching", "Analyzing", "Reporting", "Forecasting", "Evaluating", "Presenting", "Modeling", "Interpreting", "Monitoring", "Communicating"],
          "skills": ["Research", "Analytical Thinking", "Financial Modeling", "Communication", "Attention to Detail", "Problem-Solving", "Collaboration", "Forecasting", "Teamwork", "Adaptability"],
          "tools": ["Excel", "Bloomberg", "Tableau", "Power BI", "SQL", "FactSet", "Python", "R", "Microsoft Dynamics", "SAP"],
          "certifications": ["Equity Research Certification", "CFA Level 1", "Financial Modeling Certification", "Bloomberg Market Concepts", "SQL Certification"]
        },
        "Risk Analyst": {
          "tasks": ["Assessing", "Evaluating", "Monitoring", "Reporting", "Analyzing", "Investigating", "Calculating", "Forecasting", "Planning", "Documenting"],
          "skills": ["Risk Assessment", "Analytical Thinking", "Communication", "Attention to Detail", "Problem-Solving", "Reporting", "Investigative Skills", "Forecasting", "Collaboration", "Teamwork"],
          "tools": ["Excel", "Tableau", "Power BI", "SAP", "SQL", "Bloomberg", "Moody's Analytics", "RiskMetrics", "Python", "R"],
          "certifications": ["Risk Analysis Certification", "CFA Level 1", "Financial Risk Manager (FRM)", "Tableau Certification", "SQL Certification"]
        },
        "Audit Assistant": {
          "tasks": ["Auditing", "Reviewing", "Documenting", "Evaluating", "Monitoring", "Testing", "Reporting", "Reconciling", "Calculating", "Investigating"],
          "skills": ["Auditing", "Analytical Thinking", "Communication", "Attention to Detail", "Problem-Solving", "Teamwork", "Reporting", "Adaptability", "Financial Analysis", "Continuous Learning"],
          "tools": ["Excel", "SAP", "QuickBooks", "Oracle ERP", "Power BI", "Tableau", "SQL", "AuditBoard", "CaseWare", "Sage"],
          "certifications": ["Audit Certification", "Certified Internal Auditor (CIA)", "CPA Certification", "MS Excel Advanced", "Tableau Certification"]
        },
        "Investment Analyst": {
          "tasks": ["Researching", "Analyzing", "Forecasting", "Evaluating", "Modeling", "Reporting", "Interpreting", "Monitoring", "Allocating", "Calculating"],
          "skills": ["Investment Analysis", "Research", "Analytical Thinking", "Communication", "Attention to Detail", "Problem-Solving", "Collaboration", "Forecasting", "Teamwork", "Adaptability"],
          "tools": ["Excel", "Bloomberg", "FactSet", "Power BI", "Tableau", "SQL", "Python", "R", "SAP", "Microsoft Dynamics"],
          "certifications": ["Investment Analysis Certification", "CFA Level 1", "Financial Modeling Certification", "Bloomberg Market Concepts", "SQL Certification"]
        },
        "Compliance Officer": {
          "tasks": ["Monitoring", "Assessing", "Investigating", "Reporting", "Evaluating", "Testing", "Documenting", "Planning", "Analyzing", "Communicating"],
          "skills": ["Compliance", "Analytical Thinking", "Communication", "Attention to Detail", "Problem-Solving", "Reporting", "Risk Assessment", "Teamwork", "Investigative Skills", "Adaptability"],
          "tools": ["Excel", "SAP", "Compliance360", "Oracle ERP", "Power BI", "Tableau", "SQL", "Bloomberg", "Moody's Analytics", "AuditBoard"],
          "certifications": ["Compliance Certification", "SHRM Certified Professional", "CPA Certification", "Risk Analysis Certification", "MS Excel Advanced"]
        },
        "Tax Analyst": {
          "tasks": ["Calculating", "Reviewing", "Monitoring", "Analyzing", "Documenting", "Filing", "Auditing", "Reporting", "Planning", "Communicating"],
          "skills": ["Taxation", "Analytical Thinking", "Communication", "Attention to Detail", "Problem-Solving", "Reporting", "Collaboration", "Auditing", "Teamwork", "Adaptability"],
          "tools": ["Excel", "SAP", "QuickBooks", "Power BI", "Tableau", "SQL", "Oracle ERP", "CaseWare", "AuditBoard", "Sage"],
          "certifications": ["Taxation Certification", "CPA Certification", "Enrolled Agent Certification", "Advanced MS Excel", "Tableau Certification"]
        },
        "Budget Analyst": {
          "tasks": ["Budgeting", "Forecasting", "Reporting", "Monitoring", "Evaluating", "Calculating", "Planning", "Documenting", "Communicating", "Allocating"],
          "skills": ["Budgeting", "Analytical Thinking", "Communication", "Attention to Detail", "Problem-Solving", "Forecasting", "Reporting", "Teamwork", "Adaptability", "Financial Analysis"],
          "tools": ["Excel", "SAP", "Tableau", "Power BI", "SQL", "Bloomberg", "QuickBooks", "Oracle ERP", "Sage", "Microsoft Dynamics"],
          "certifications": ["Budget Analysis Certification", "MS Excel Advanced", "Tableau Certification", "Financial Modeling Certification", "CPA Certification"]
        },
        "Insurance Analyst": {
          "tasks": ["Evaluating", "Assessing", "Monitoring", "Reporting", "Reviewing", "Investigating", "Calculating", "Communicating", "Documenting", "Analyzing"],
          "skills": ["Risk Assessment", "Analytical Thinking", "Communication", "Attention to Detail", "Problem-Solving", "Reporting", "Investigative Skills", "Collaboration", "Teamwork", "Adaptability"],
          "tools": ["Excel", "SAP", "Power BI", "Tableau", "SQL", "RiskMetrics", "Moody's Analytics", "Bloomberg", "R", "Python"],
          "certifications": ["Insurance Certification", "Risk Analysis Certification", "Financial Modeling Certification", "MS Excel Advanced", "Tableau Certification"]
        },
        "Financial Planner": {
          "tasks": ["Planning", "Budgeting", "Reporting", "Monitoring", "Evaluating", "Forecasting", "Documenting", "Communicating", "Calculating", "Advising"],
          "skills": ["Financial Planning", "Analytical Thinking", "Communication", "Attention to Detail", "Problem-Solving", "Budgeting", "Advising", "Teamwork", "Collaboration", "Adaptability"],
          "tools": ["Excel", "SAP", "Power BI", "Tableau", "SQL", "Bloomberg", "QuickBooks", "Oracle ERP", "Microsoft Dynamics", "Sage"],
          "certifications": ["Certified Financial Planner (CFP)", "Investment Analysis Certification", "Risk Analysis Certification", "CPA Certification", "Tableau Certification"]
        },
        "Wealth Management Associate": {
          "tasks": ["Advising", "Planning", "Monitoring", "Evaluating", "Calculating", "Documenting", "Communicating", "Reporting", "Allocating", "Analyzing"],
          "skills": ["Wealth Management", "Analytical Thinking", "Communication", "Financial Planning", "Problem-Solving", "Advising", "Collaboration", "Attention to Detail", "Teamwork", "Adaptability"],
          "tools": ["Excel", "SAP", "Power BI", "Tableau", "SQL", "Bloomberg", "QuickBooks", "Oracle ERP", "Microsoft Dynamics", "Sage"],
          "certifications": ["Wealth Management Certification", "CFA Level 1", "Certified Financial Planner (CFP)", "Investment Analysis Certification", "MS Excel Advanced"]
        },
        "Fixed Income Analyst": {
          "tasks": ["Analyzing", "Monitoring", "Reporting", "Evaluating", "Forecasting", "Documenting", "Communicating", "Calculating", "Researching", "Modeling"],
          "skills": ["Fixed Income", "Analytical Thinking", "Communication", "Attention to Detail", "Problem-Solving", "Forecasting", "Reporting", "Teamwork", "Collaboration", "Adaptability"],
          "tools": ["Excel", "Bloomberg", "Power BI", "Tableau", "SQL", "FactSet", "Python", "R", "Microsoft Dynamics", "SAP"],
          "certifications": ["Fixed Income Analysis Certification", "CFA Level 1", "Financial Modeling Certification", "Risk Analysis Certification", "Bloomberg Market Concepts"]
        },
        "Derivatives Analyst": {
          "tasks": ["Analyzing", "Monitoring", "Calculating", "Reporting", "Evaluating", "Modeling", "Documenting", "Forecasting", "Researching", "Communicating"],
          "skills": ["Derivatives", "Analytical Thinking", "Financial Modeling", "Communication", "Problem-Solving", "Attention to Detail", "Collaboration", "Reporting", "Teamwork", "Adaptability"],
          "tools": ["Excel", "Bloomberg", "Power BI", "Tableau", "SQL", "FactSet", "Python", "R", "Microsoft Dynamics", "SAP"],
          "certifications": ["Derivatives Analysis Certification", "CFA Level 1", "Financial Modeling Certification", "Python for Finance", "SQL Certification"]
        }
      }
    },
    "Accounts": {
      "roles": {
        "Accounts Payable Executive": {
          "tasks": ["Processing", "Verifying", "Reconciling", "Reporting", "Documenting", "Recording", "Auditing", "Monitoring", "Communicating", "Filing"],
          "skills": ["Attention to Detail", "Communication", "Time Management", "Organization", "Problem-Solving", "Analytical Thinking", "Teamwork", "Excel", "Accounting Software", "Reporting"],
          "tools": ["Excel", "SAP", "QuickBooks", "Oracle ERP", "Sage", "Microsoft Dynamics", "Tableau", "Power BI", "Xero", "NetSuite"],
          "certifications": ["Accounts Payable Certification", "Advanced MS Excel", "QuickBooks Certification", "SAP FICO", "Accounting Fundamentals"]
        },
        "Accounts Receivable Executive": {
          "tasks": ["Invoicing", "Collecting", "Recording", "Reconciling", "Reporting", "Monitoring", "Reviewing", "Communicating", "Documenting", "Filing"],
          "skills": ["Communication", "Analytical Thinking", "Problem-Solving", "Time Management", "Organization", "Excel", "Reporting", "Accounting Software", "Collaboration", "Adaptability"],
          "tools": ["Excel", "SAP", "QuickBooks", "Oracle ERP", "Sage", "Microsoft Dynamics", "Tableau", "Power BI", "Xero", "FreshBooks"],
          "certifications": ["Accounts Receivable Certification", "Advanced MS Excel", "QuickBooks Certification", "SAP FICO", "Accounting Fundamentals"]
        },
        "Accountant": {
          "tasks": ["Recording", "Reporting", "Reconciling", "Auditing", "Budgeting", "Calculating", "Monitoring", "Documenting", "Filing", "Reviewing"],
          "skills": ["Problem-Solving", "Analytical Thinking", "Communication", "Attention to Detail", "Excel", "Organization", "Teamwork", "Budgeting", "Reporting", "Continuous Learning"],
          "tools": ["Excel", "SAP", "QuickBooks", "Oracle ERP", "Sage", "Microsoft Dynamics", "Tableau", "Power BI", "NetSuite", "Xero"],
          "certifications": ["Certified Public Accountant (CPA)", "Advanced MS Excel", "QuickBooks Certification", "SAP FICO", "Financial Accounting Fundamentals"]
        },
        "Credit Analyst": {
          "tasks": ["Evaluating", "Monitoring", "Reporting", "Analyzing", "Reviewing", "Investigating", "Calculating", "Documenting", "Communicating", "Auditing"],
          "skills": ["Risk Assessment", "Analytical Thinking", "Communication", "Problem-Solving", "Teamwork", "Excel", "Attention to Detail", "Reporting", "Organization", "Adaptability"],
          "tools": ["Excel", "SAP", "Tableau", "Power BI", "SQL", "Moody's Analytics", "Oracle ERP", "Bloomberg", "R", "Microsoft Dynamics"],
          "certifications": ["Credit Risk Certification", "Moody's Analytics Certification", "CPA Certification", "Financial Risk Manager (FRM)", "SQL Certification"]
        },
        "Accounts Executive": {
          "tasks": ["Managing", "Recording", "Reporting", "Reviewing", "Reconciling", "Auditing", "Filing", "Documenting", "Budgeting", "Communicating"],
          "skills": ["Communication", "Attention to Detail", "Organization", "Time Management", "Analytical Thinking", "Problem-Solving", "Teamwork", "Excel", "Reporting", "Accounting Software"],
          "tools": ["Excel", "SAP", "QuickBooks", "Oracle ERP", "Sage", "Microsoft Dynamics", "Tableau", "Power BI", "NetSuite", "Xero"],
          "certifications": ["Accounting Fundamentals Certification", "Advanced MS Excel", "QuickBooks Certification", "SAP FICO", "Financial Accounting Fundamentals"]
        },
        "Junior Accountant": {
          "tasks": ["Recording", "Reporting", "Reconciling", "Monitoring", "Documenting", "Filing", "Reviewing", "Communicating", "Budgeting", "Supporting"],
          "skills": ["Attention to Detail", "Communication", "Organization", "Teamwork", "Problem-Solving", "Analytical Thinking", "Excel", "Reporting", "Adaptability", "Time Management"],
          "tools": ["Excel", "SAP", "QuickBooks", "Oracle ERP", "Sage", "Microsoft Dynamics", "Tableau", "Power BI", "Xero", "FreshBooks"],
          "certifications": ["Junior Accountant Certification", "Advanced MS Excel", "QuickBooks Certification", "SAP FICO", "Financial Accounting Fundamentals"]
        },
        "Accounts Assistant": {
          "tasks": ["Assisting", "Recording", "Reconciling", "Filing", "Reporting", "Documenting", "Reviewing", "Supporting", "Monitoring", "Communicating"],
          "skills": ["Attention to Detail", "Organization", "Communication", "Time Management", "Problem-Solving", "Analytical Thinking", "Reporting", "Teamwork", "Excel", "Collaboration"],
          "tools": ["Excel", "QuickBooks", "Oracle ERP", "Sage", "SAP", "Microsoft Dynamics", "Tableau", "Power BI", "NetSuite", "Xero"],
          "certifications": ["Accounting Assistant Certification", "Advanced MS Excel", "QuickBooks Certification", "SAP FICO", "Financial Accounting Fundamentals"]
        },
        "Bookkeeper": {
          "tasks": ["Recording", "Reconciling", "Reviewing", "Filing", "Reporting", "Documenting", "Monitoring", "Communicating", "Assisting", "Auditing"],
          "skills": ["Communication", "Attention to Detail", "Organization", "Time Management", "Problem-Solving", "Analytical Thinking", "Excel", "Reporting", "Collaboration", "Teamwork"],
          "tools": ["Excel", "QuickBooks", "Sage", "Oracle ERP", "SAP", "Tableau", "Power BI", "Microsoft Dynamics", "Xero", "FreshBooks"],
          "certifications": ["Certified Bookkeeper", "Advanced MS Excel", "QuickBooks Certification", "SAP FICO", "Accounting Fundamentals"]
        },
        "Cost Accountant": {
          "tasks": ["Costing", "Analyzing", "Reporting", "Budgeting", "Monitoring", "Auditing", "Documenting", "Reviewing", "Filing", "Communicating"],
          "skills": ["Cost Accounting", "Analytical Thinking", "Communication", "Attention to Detail", "Budgeting", "Excel", "Reporting", "Teamwork", "Problem-Solving", "Organization"],
          "tools": ["Excel", "SAP", "QuickBooks", "Oracle ERP", "Sage", "Microsoft Dynamics", "Tableau", "Power BI", "NetSuite", "Xero"],
          "certifications": ["Cost Accounting Certification", "Advanced MS Excel", "QuickBooks Certification", "SAP FICO", "Financial Accounting Fundamentals"]
        },
        "Financial Analyst (Entry Level)": {
          "tasks": ["Analyzing", "Forecasting", "Budgeting", "Reporting", "Reviewing", "Calculating", "Documenting", "Communicating", "Supporting", "Monitoring"],
          "skills": ["Analytical Thinking", "Communication", "Attention to Detail", "Problem-Solving", "Organization", "Budgeting", "Excel", "Reporting", "Teamwork", "Adaptability"],
          "tools": ["Excel", "Tableau", "Power BI", "SAP", "QuickBooks", "Microsoft Dynamics", "Sage", "Bloomberg", "Oracle ERP", "NetSuite"],
          "certifications": ["CFA Level 1", "Financial Modeling Certification", "Tableau Certification", "SQL Certification", "Excel Advanced"]
        },
        "Audit Assistant": {
          "tasks": ["Auditing", "Reviewing", "Reporting", "Reconciling", "Documenting", "Monitoring", "Testing", "Communicating", "Filing", "Investigating"],
          "skills": ["Auditing", "Attention to Detail", "Communication", "Problem-Solving", "Analytical Thinking", "Organization", "Teamwork", "Excel", "Reporting", "Collaboration"],
          "tools": ["Excel", "SAP", "QuickBooks", "Oracle ERP", "AuditBoard", "CaseWare", "Tableau", "Power BI", "Sage", "Microsoft Dynamics"],
          "certifications": ["Audit Certification", "Certified Internal Auditor (CIA)", "CPA Certification", "MS Excel Advanced", "Tableau Certification"]
        },
        "Compliance Officer": {
          "tasks": ["Monitoring", "Reporting", "Reviewing", "Auditing", "Investigating", "Documenting", "Communicating", "Planning", "Filing", "Analyzing"],
          "skills": ["Compliance", "Attention to Detail", "Communication", "Problem-Solving", "Analytical Thinking", "Teamwork", "Excel", "Reporting", "Risk Assessment", "Organization"],
          "tools": ["Excel", "SAP", "Compliance360", "Oracle ERP", "Tableau", "Power BI", "Sage", "Microsoft Dynamics", "NetSuite", "Xero"],
          "certifications": ["Compliance Certification", "SHRM Certified Professional", "CPA Certification", "Risk Analysis Certification", "MS Excel Advanced"]
        },
        "Payroll Administrator": {
          "tasks": ["Processing", "Calculating", "Reporting", "Documenting", "Filing", "Reviewing", "Communicating", "Reconciling", "Monitoring", "Supporting"],
          "skills": ["Payroll Processing", "Attention to Detail", "Communication", "Organization", "Time Management", "Analytical Thinking", "Reporting", "Excel", "Problem-Solving", "Collaboration"],
          "tools": ["Excel", "QuickBooks", "SAP", "Oracle ERP", "Sage", "Tableau", "Power BI", "Microsoft Dynamics", "NetSuite", "FreshBooks"],
          "certifications": ["Payroll Certification", "Advanced MS Excel", "QuickBooks Certification", "ADP Payroll Certification", "SAP Payroll"]
        },
        "Billing Coordinator": {
          "tasks": ["Invoicing", "Processing", "Reporting", "Documenting", "Reconciling", "Filing", "Reviewing", "Communicating", "Monitoring", "Auditing"],
          "skills": ["Invoicing", "Communication", "Attention to Detail", "Analytical Thinking", "Organization", "Problem-Solving", "Excel", "Reporting", "Teamwork", "Time Management"],
          "tools": ["Excel", "QuickBooks", "SAP", "Oracle ERP", "Sage", "Tableau", "Power BI", "Microsoft Dynamics", "NetSuite", "FreshBooks"],
          "certifications": ["Billing Certification", "Advanced MS Excel", "QuickBooks Certification", "SAP FICO", "Financial Accounting Fundamentals"]
        }
      }
    },
    "Marketing": {
      "roles": {
        "Marketing Coordinator": {
          "tasks": ["Coordinating", "Planning", "Executing", "Reporting", "Researching", "Communicating", "Monitoring", "Designing", "Analyzing", "Documenting"],
          "skills": ["Communication", "Organization", "Time Management", "Creativity", "Attention to Detail", "Analytical Thinking", "Teamwork", "Problem-Solving", "Marketing Tools", "Adaptability"],
          "tools": ["Canva", "Google Analytics", "Hootsuite", "Trello", "Mailchimp", "HubSpot", "Salesforce", "Asana", "Tableau", "SEMrush"],
          "certifications": ["Marketing Certification", "Google Digital Marketing", "HubSpot Inbound Marketing", "SEO Fundamentals", "Content Marketing Certification"]
        },
        "Digital Marketing Executive": {
          "tasks": ["Planning", "Executing", "Monitoring", "Optimizing", "Reporting", "Designing", "Researching", "Communicating", "Documenting", "Analyzing"],
          "skills": ["SEO", "SEM", "Analytics", "Communication", "Creativity", "Analytical Thinking", "Teamwork", "Problem-Solving", "Time Management", "Marketing Tools"],
          "tools": ["Google Ads", "Google Analytics", "SEMrush", "Ahrefs", "HubSpot", "Canva", "Facebook Ads Manager", "Mailchimp", "WordPress", "Trello"],
          "certifications": ["Digital Marketing Certification", "Google Analytics Certification", "HubSpot Digital Marketing", "Facebook Blueprint", "SEMrush Certification"]
        },
        "Social Media Coordinator": {
          "tasks": ["Posting", "Scheduling", "Monitoring", "Engaging", "Reporting", "Planning", "Researching", "Designing", "Communicating", "Documenting"],
          "skills": ["Social Media", "Communication", "Creativity", "Attention to Detail", "Organization", "Analytical Thinking", "Teamwork", "Problem-Solving", "Adaptability", "Scheduling Tools"],
          "tools": ["Hootsuite", "Buffer", "Canva", "Later", "Google Analytics", "Facebook Ads Manager", "Instagram Insights", "Trello", "Mailchimp", "HubSpot"],
          "certifications": ["Social Media Marketing Certification", "Hootsuite Platform Certification", "Facebook Blueprint", "Instagram Insights", "LinkedIn Marketing Certification"]
        },
        "Content Writer": {
          "tasks": ["Writing", "Editing", "Researching", "Optimizing", "Proofreading", "Publishing", "Collaborating", "Communicating", "Designing", "Documenting"],
          "skills": ["Writing", "Editing", "Creativity", "Communication", "Attention to Detail", "Research", "Teamwork", "Time Management", "SEO Knowledge", "Content Management Systems"],
          "tools": ["WordPress", "Grammarly", "SEMrush", "Canva", "Google Analytics", "Yoast SEO", "HubSpot", "Trello", "MS Word", "Google Docs"],
          "certifications": ["Content Marketing Certification", "HubSpot Content Marketing", "Google Digital Marketing", "SEO Writing Certification", "Copywriting Fundamentals"]
        },
        "SEO Specialist": {
          "tasks": ["Optimizing", "Analyzing", "Researching", "Monitoring", "Reporting", "Planning", "Designing", "Communicating", "Documenting", "Auditing"],
          "skills": ["SEO", "Analytics", "Problem-Solving", "Communication", "Attention to Detail", "Research", "Analytical Thinking", "Adaptability", "Teamwork", "Reporting Tools"],
          "tools": ["SEMrush", "Ahrefs", "Google Analytics", "Screaming Frog", "Yoast SEO", "HubSpot", "Google Search Console", "WordPress", "Canva", "Moz"],
          "certifications": ["SEO Certification", "Google Analytics Certification", "SEMrush Certification", "Ahrefs Certification", "Advanced SEO Techniques"]
        },
        "Email Marketing": {
          "tasks": ["Designing", "Executing", "Monitoring", "Reporting", "Optimizing", "Planning", "Analyzing", "Communicating", "Researching", "Documenting"],
          "skills": ["Email Campaigns", "Creativity", "Communication", "Time Management", "Analytical Thinking", "Organization", "Attention to Detail", "Marketing Automation", "Problem-Solving", "Reporting"],
          "tools": ["Mailchimp", "HubSpot", "Salesforce", "Canva", "Google Analytics", "Constant Contact", "SendGrid", "ActiveCampaign", "Trello", "Marketo"],
          "certifications": ["Email Marketing Certification", "HubSpot Email Marketing", "Constant Contact Certification", "Salesforce Marketing Cloud", "Advanced MS Excel"]
        },
        "Marketing Analyst": {
          "tasks": ["Analyzing", "Reporting", "Researching", "Monitoring", "Planning", "Designing", "Communicating", "Optimizing", "Documenting", "Forecasting"],
          "skills": ["Analytics", "Communication", "Problem-Solving", "Research", "Attention to Detail", "Teamwork", "Marketing Tools", "Reporting", "Creativity", "Time Management"],
          "tools": ["Google Analytics", "Tableau", "Power BI", "HubSpot", "Salesforce", "SEMrush", "Google Ads", "Canva", "Trello", "Marketo"],
          "certifications": ["Marketing Analytics Certification", "Tableau Certification", "Google Analytics Certification", "HubSpot Marketing Analytics", "Power BI Certification"]
        },
        "Market Research Analyst": {
          "tasks": ["Researching", "Analyzing", "Monitoring", "Reporting", "Planning", "Designing", "Communicating", "Forecasting", "Documenting", "Collaborating"],
          "skills": ["Research", "Analytics", "Communication", "Problem-Solving", "Attention to Detail", "Teamwork", "Marketing Tools", "Adaptability", "Creativity", "Reporting"],
          "tools": ["Qualtrics", "Tableau", "Google Analytics", "HubSpot", "Salesforce", "SEMrush", "Power BI", "Google Ads", "Canva", "Marketo"],
          "certifications": ["Market Research Certification", "Tableau Certification", "Google Analytics Certification", "MS Excel Advanced", "Power BI Certification"]
        },
        "Advertising Assistant": {
          "tasks": ["Designing", "Executing", "Monitoring", "Reporting", "Researching", "Communicating", "Coordinating", "Planning", "Documenting", "Supporting"],
          "skills": ["Creativity", "Communication", "Organization", "Attention to Detail", "Teamwork", "Problem-Solving", "Marketing Tools", "Scheduling", "Time Management", "Adaptability"],
          "tools": ["Google Ads", "Canva", "Salesforce", "HubSpot", "Trello", "Tableau", "Mailchimp", "SEMrush", "Hootsuite", "Asana"],
          "certifications": ["Advertising Certification", "Google Ads Certification", "Facebook Blueprint", "HubSpot Advertising", "Advanced MS Excel"]
        },
        "Public Relations Coordinator": {
          "tasks": ["Writing", "Monitoring", "Communicating", "Designing", "Reporting", "Planning", "Researching", "Collaborating", "Documenting", "Organizing"],
          "skills": ["Communication", "Writing", "Organization", "Creativity", "Problem-Solving", "Research", "Teamwork", "Public Relations Tools", "Attention to Detail", "Adaptability"],
          "tools": ["Hootsuite", "Canva", "Google Analytics", "WordPress", "Salesforce", "HubSpot", "Buffer", "MS Word", "Trello", "Mailchimp"],
          "certifications": ["Public Relations Certification", "Communication Skills Certification", "MS Excel Advanced", "HubSpot PR Certification", "Advanced Writing Skills"]
        },
        "Brand Ambassador": {
          "tasks": ["Promoting", "Representing", "Engaging", "Communicating", "Planning", "Monitoring", "Reporting", "Documenting", "Collaborating", "Supporting"],
          "skills": ["Communication", "Public Speaking", "Creativity", "Teamwork", "Organization", "Attention to Detail", "Time Management", "Marketing Tools", "Adaptability", "Problem-Solving"],
          "tools": ["Instagram Insights", "Canva", "Facebook Ads Manager", "Hootsuite", "Salesforce", "HubSpot", "Google Analytics", "Trello", "SEMrush", "MS Word"],
          "certifications": ["Brand Management Certification", "Marketing Analytics Certification", "HubSpot Branding", "Google Digital Marketing", "SEMrush Certification"]
        },
        "Campaign Analyst": {
          "tasks": ["Analyzing", "Monitoring", "Reporting", "Planning", "Researching", "Communicating", "Designing", "Forecasting", "Optimizing", "Documenting"],
          "skills": ["Analytics", "Communication", "Research", "Attention to Detail", "Problem-Solving", "Marketing Tools", "Reporting", "Creativity", "Time Management", "Collaboration"],
          "tools": ["Google Analytics", "Tableau", "Power BI", "Salesforce", "HubSpot", "Google Ads", "Canva", "SEMrush", "Asana", "Trello"],
          "certifications": ["Campaign Management Certification", "Google Ads Certification", "Facebook Blueprint", "HubSpot Campaign Analytics", "SEMrush Certification"]
        },
        "Event Coordinator": {
          "tasks": ["Coordinating", "Organizing", "Monitoring", "Planning", "Communicating", "Documenting", "Reporting", "Designing", "Researching", "Supporting"],
          "skills": ["Event Planning", "Communication", "Organization", "Problem-Solving", "Creativity", "Teamwork", "Time Management", "Attention to Detail", "Marketing Tools", "Adaptability"],
          "tools": ["Eventbrite", "Canva", "Trello", "Google Analytics", "HubSpot", "Salesforce", "Asana", "MS Word", "Excel", "Mailchimp"],
          "certifications": ["Event Planning Certification", "Communication Skills Certification", "Project Management Certification", "MS Excel Advanced", "Google Analytics Certification"]
        },
        "Copywriter": {
          "tasks": ["Writing", "Editing", "Researching", "Proofreading", "Collaborating", "Communicating", "Publishing", "Optimizing", "Documenting", "Designing"],
          "skills": ["Writing", "Editing", "Creativity", "Communication", "Research", "Attention to Detail", "Teamwork", "SEO Knowledge", "Time Management", "Problem-Solving"],
          "tools": ["Grammarly", "WordPress", "Google Docs", "Canva", "SEMrush", "Yoast SEO", "Google Analytics", "MS Word", "HubSpot", "Trello"],
          "certifications": ["Copywriting Certification", "Advanced Writing Skills", "SEO Writing Certification", "HubSpot Content Marketing", "SEMrush Certification"]
        },
        "Media Planner": {
          "tasks": ["Planning", "Scheduling", "Researching", "Analyzing", "Monitoring", "Communicating", "Collaborating", "Documenting", "Reporting", "Designing"],
          "skills": ["Media Planning", "Analytics", "Communication", "Research", "Attention to Detail", "Teamwork", "Creativity", "Time Management", "Problem-Solving", "Marketing Tools"],
          "tools": ["Google Ads", "Google Analytics", "Facebook Ads Manager", "Canva", "Salesforce", "HubSpot", "Tableau", "SEMrush", "Power BI", "Asana"],
          "certifications": ["Media Planning Certification", "Google Ads Certification", "Facebook Blueprint", "HubSpot Media Analytics", "Advanced MS Excel"]
        }
      }
    },
    "Sales and Development": {
      "roles": {
        "Sales Executive": {
          "tasks": ["Selling", "Prospecting", "Closing", "Negotiating", "Communicating", "Reporting", "Planning", "Networking", "Researching", "Following Up"],
          "skills": ["Communication", "Negotiation", "Time Management", "Networking", "Problem-Solving", "Persuasion", "CRM Tools", "Organization", "Teamwork", "Adaptability"],
          "tools": ["Salesforce", "HubSpot", "LinkedIn Sales Navigator", "Zoho CRM", "Pipedrive", "Trello", "Slack", "MS Excel", "Tableau", "Google Workspace"],
          "certifications": ["Sales Certification", "HubSpot Inbound Sales", "Salesforce Sales Operations", "LinkedIn Sales Navigator Certification", "Negotiation Skills Certification"]
        },
        "Business Development Associate": {
          "tasks": ["Prospecting", "Pitching", "Networking", "Negotiating", "Closing", "Communicating", "Reporting", "Planning", "Analyzing", "Researching"],
          "skills": ["Prospecting", "Communication", "Negotiation", "Time Management", "Organization", "Networking", "Problem-Solving", "CRM Tools", "Teamwork", "Adaptability"],
          "tools": ["LinkedIn Sales Navigator", "Salesforce", "HubSpot", "Zoho CRM", "Trello", "MS Excel", "Pipedrive", "Slack", "Tableau", "Google Workspace"],
          "certifications": ["Business Development Certification", "HubSpot Sales", "Salesforce Sales Operations", "Negotiation Skills Certification", "LinkedIn Sales Navigator Certification"]
        },
        "Inside Sales Representative": {
          "tasks": ["Calling", "Qualifying", "Prospecting", "Selling", "Negotiating", "Reporting", "Communicating", "Researching", "Following Up", "Closing"],
          "skills": ["Cold Calling", "Communication", "Negotiation", "Time Management", "Organization", "Networking", "Problem-Solving", "CRM Tools", "Teamwork", "Adaptability"],
          "tools": ["Salesforce", "Zoho CRM", "HubSpot", "LinkedIn Sales Navigator", "Pipedrive", "Trello", "MS Excel", "Slack", "Google Workspace", "Freshsales"],
          "certifications": ["Inside Sales Certification", "Salesforce Sales Operations", "HubSpot Sales", "Negotiation Skills Certification", "Communication Skills Certification"]
        },
        "Sales Coordinator": {
          "tasks": ["Coordinating", "Communicating", "Reporting", "Scheduling", "Organizing", "Monitoring", "Supporting", "Documenting", "Planning", "Networking"],
          "skills": ["Coordination", "Communication", "Organization", "Time Management", "Problem-Solving", "CRM Tools", "Attention to Detail", "Teamwork", "Adaptability", "Scheduling"],
          "tools": ["Salesforce", "HubSpot", "Zoho CRM", "Trello", "MS Excel", "Slack", "Google Workspace", "Monday.com", "Pipedrive", "Freshsales"],
          "certifications": ["Sales Coordination Certification", "Advanced MS Excel", "Salesforce Sales Operations", "HubSpot Sales", "Communication Skills Certification"]
        },
        "Account Executive": {
          "tasks": ["Selling", "Negotiating", "Prospecting", "Closing", "Communicating", "Reporting", "Networking", "Analyzing", "Researching", "Supporting"],
          "skills": ["Sales", "Communication", "Negotiation", "Networking", "Problem-Solving", "CRM Tools", "Time Management", "Teamwork", "Organization", "Adaptability"],
          "tools": ["Salesforce", "LinkedIn Sales Navigator", "HubSpot", "Zoho CRM", "Pipedrive", "Trello", "MS Excel", "Tableau", "Google Workspace", "Slack"],
          "certifications": ["Account Management Certification", "Salesforce Sales Operations", "Negotiation Skills Certification", "Communication Skills Certification", "LinkedIn Sales Navigator Certification"]
        },
        "Sales Support Specialist": {
          "tasks": ["Supporting", "Documenting", "Communicating", "Scheduling", "Coordinating", "Monitoring", "Reporting", "Assisting", "Organizing", "Planning"],
          "skills": ["Coordination", "Communication", "Organization", "Time Management", "Attention to Detail", "Problem-Solving", "CRM Tools", "Teamwork", "Adaptability", "Reporting"],
          "tools": ["Salesforce", "Zoho CRM", "HubSpot", "Trello", "MS Excel", "Slack", "Google Workspace", "Monday.com", "Pipedrive", "Freshsales"],
          "certifications": ["Sales Support Certification", "Advanced MS Excel", "HubSpot Sales", "Salesforce Sales Operations", "Communication Skills Certification"]
        },
        "Lead Generation Specialist": {
          "tasks": ["Prospecting", "Researching", "Communicating", "Qualifying", "Networking", "Reporting", "Planning", "Scheduling", "Analyzing", "Organizing"],
          "skills": ["Lead Generation", "Communication", "Prospecting", "Time Management", "CRM Tools", "Networking", "Problem-Solving", "Organization", "Teamwork", "Adaptability"],
          "tools": ["LinkedIn Sales Navigator", "Salesforce", "HubSpot", "Zoho CRM", "Pipedrive", "Trello", "MS Excel", "Slack", "Tableau", "Google Workspace"],
          "certifications": ["Lead Generation Certification", "LinkedIn Sales Navigator Certification", "Salesforce Sales Operations", "HubSpot Sales", "Communication Skills Certification"]
        },
        "Sales Administrator": {
          "tasks": ["Supporting", "Coordinating", "Scheduling", "Documenting", "Communicating", "Monitoring", "Reporting", "Assisting", "Organizing", "Planning"],
          "skills": ["Coordination", "Communication", "Organization", "Time Management", "Attention to Detail", "Problem-Solving", "CRM Tools", "Teamwork", "Scheduling", "Adaptability"],
          "tools": ["Salesforce", "HubSpot", "Zoho CRM", "Trello", "MS Excel", "Slack", "Google Workspace", "Monday.com", "Pipedrive", "Freshsales"],
          "certifications": ["Sales Administration Certification", "Advanced MS Excel", "Salesforce Sales Operations", "HubSpot Sales", "Communication Skills Certification"]
        },
        "Sales Analyst": {
          "tasks": ["Analyzing", "Reporting", "Monitoring", "Researching", "Communicating", "Planning", "Presenting", "Documenting", "Supporting", "Forecasting"],
          "skills": ["Data Analysis", "Reporting", "Communication", "Problem-Solving", "Forecasting", "CRM Tools", "Organization", "Teamwork", "Adaptability", "Presentation"],
          "tools": ["Salesforce", "Tableau", "MS Excel", "Power BI", "HubSpot", "Zoho CRM", "Google Workspace", "Trello", "Slack", "Monday.com"],
          "certifications": ["Sales Analysis Certification", "Tableau Certification", "Power BI Certification", "Salesforce Sales Operations", "Advanced MS Excel"]
        },
        "Sales Representative": {
          "tasks": ["Selling", "Prospecting", "Closing", "Networking", "Reporting", "Planning", "Communicating", "Negotiating", "Following Up", "Researching"],
          "skills": ["Selling", "Communication", "Negotiation", "Networking", "Problem-Solving", "CRM Tools", "Time Management", "Teamwork", "Adaptability", "Organization"],
          "tools": ["Salesforce", "LinkedIn Sales Navigator", "HubSpot", "Zoho CRM", "Pipedrive", "Trello", "MS Excel", "Google Workspace", "Slack", "Tableau"],
          "certifications": ["Sales Representative Certification", "Salesforce Sales Operations", "HubSpot Sales", "Negotiation Skills Certification", "Communication Skills Certification"]
        },
        "Channel Sales Executive": {
          "tasks": ["Prospecting", "Selling", "Networking", "Closing", "Negotiating", "Reporting", "Communicating", "Planning", "Researching", "Documenting"],
          "skills": ["Sales", "Communication", "Negotiation", "Networking", "Problem-Solving", "CRM Tools", "Time Management", "Organization", "Teamwork", "Adaptability"],
          "tools": ["LinkedIn Sales Navigator", "Salesforce", "HubSpot", "Zoho CRM", "Pipedrive", "Trello", "MS Excel", "Slack", "Tableau", "Google Workspace"],
          "certifications": ["Channel Sales Certification", "Salesforce Sales Operations", "HubSpot Sales", "Communication Skills Certification", "Advanced MS Excel"]
        },
        "Customer Success Executive": {
          "tasks": ["Onboarding", "Communicating", "Supporting", "Monitoring", "Reporting", "Networking", "Planning", "Documenting", "Coordinating", "Following Up"],
          "skills": ["Customer Support", "Communication", "Onboarding", "Networking", "Problem-Solving", "CRM Tools", "Time Management", "Teamwork", "Adaptability", "Organization"],
          "tools": ["Salesforce", "HubSpot", "Zoho CRM", "Trello", "MS Excel", "Slack", "Google Workspace", "Monday.com", "Freshsales", "Pipedrive"],
          "certifications": ["Customer Success Certification", "Salesforce Sales Operations", "HubSpot CRM", "Customer Service Excellence Certification", "Communication Skills Certification"]
        },
        "Territory Sales Manager": {
          "tasks": ["Planning", "Prospecting", "Networking", "Selling", "Closing", "Reporting", "Communicating", "Negotiating", "Analyzing", "Researching"],
          "skills": ["Territory Planning", "Sales", "Communication", "Negotiation", "Networking", "Problem-Solving", "CRM Tools", "Time Management", "Organization", "Adaptability"],
          "tools": ["Salesforce", "LinkedIn Sales Navigator", "HubSpot", "Zoho CRM", "Pipedrive", "Trello", "MS Excel", "Google Workspace", "Tableau", "Slack"],
          "certifications": ["Territory Sales Certification", "Salesforce Sales Operations", "HubSpot CRM", "Negotiation Skills Certification", "Communication Skills Certification"]
        }
      }
    },
    "Business Management": {
      "roles": {
        "Business Analyst": {
          "tasks": ["Analyzing", "Reporting", "Communicating", "Documenting", "Planning", "Researching", "Monitoring", "Forecasting", "Presenting", "Collaborating"],
          "skills": ["Analytical Thinking", "Problem-Solving", "Communication", "Data Analysis", "Attention to Detail", "Teamwork", "Reporting", "Organization", "Planning", "Adaptability"],
          "tools": ["Excel", "Tableau", "Power BI", "SQL", "JIRA", "Confluence", "Salesforce", "MS Project", "Visio", "Google Workspace"],
          "certifications": ["CBAP", "CCBA", "Six Sigma Green Belt", "Tableau Certification", "Agile Scrum Master"]
        },
        "Data Analyst": {
          "tasks": ["Analyzing", "Cleaning", "Reporting", "Visualizing", "Monitoring", "Forecasting", "Communicating", "Modeling", "Researching", "Documenting"],
          "skills": ["Data Analysis", "Visualization", "Problem-Solving", "Communication", "Reporting", "Analytical Thinking", "Attention to Detail", "Teamwork", "Organization", "Adaptability"],
          "tools": ["Excel", "Tableau", "Power BI", "Python", "R", "SQL", "Jupyter Notebook", "Google Sheets", "SAS", "MS Access"],
          "certifications": ["Microsoft Data Analyst", "Tableau Certification", "AWS Data Analytics", "SQL Certification", "Power BI Certification"]
        },
        "Management Trainee": {
          "tasks": ["Assisting", "Learning", "Researching", "Documenting", "Communicating", "Analyzing", "Coordinating", "Monitoring", "Supporting", "Reporting"],
          "skills": ["Communication", "Organization", "Time Management", "Analytical Thinking", "Problem-Solving", "Adaptability", "Teamwork", "Reporting", "Learning Agility", "Planning"],
          "tools": ["Excel", "Google Workspace", "Slack", "Asana", "Trello", "Tableau", "Power BI", "SAP", "Salesforce", "MS Word"],
          "certifications": ["Lean Six Sigma", "PMP Certification", "Leadership Essentials", "MS Excel Advanced", "Business Fundamentals"]
        },
        "Business Development Executive": {
          "tasks": ["Prospecting", "Communicating", "Networking", "Negotiating", "Selling", "Closing", "Planning", "Reporting", "Researching", "Documenting"],
          "skills": ["Communication", "Networking", "Negotiation", "Problem-Solving", "Time Management", "Persuasion", "Teamwork", "Reporting", "Adaptability", "Organization"],
          "tools": ["LinkedIn Sales Navigator", "Salesforce", "HubSpot", "Zoho CRM", "Pipedrive", "Trello", "MS Excel", "Google Workspace", "Tableau", "Slack"],
          "certifications": ["HubSpot Inbound Sales", "Salesforce Sales Operations", "LinkedIn Sales Navigator Certification", "Negotiation Skills", "Digital Marketing Certification"]
        },
        "Operations Executive": {
          "tasks": ["Coordinating", "Monitoring", "Planning", "Reporting", "Documenting", "Communicating", "Supporting", "Organizing", "Scheduling", "Analyzing"],
          "skills": ["Organization", "Communication", "Coordination", "Time Management", "Analytical Thinking", "Problem-Solving", "Reporting", "Attention to Detail", "Teamwork", "Adaptability"],
          "tools": ["Excel", "SAP", "Google Workspace", "Trello", "Tableau", "MS Project", "Monday.com", "Slack", "Salesforce", "Power BI"],
          "certifications": ["Lean Six Sigma", "Operations Management", "Supply Chain Analytics", "PMP Certification", "Logistics Fundamentals"]
        },
        "Marketing Coordinator": {
          "tasks": ["Coordinating", "Planning", "Communicating", "Monitoring", "Reporting", "Researching", "Designing", "Documenting", "Analyzing", "Supporting"],
          "skills": ["Communication", "Organization", "Analytical Thinking", "Time Management", "Creativity", "Attention to Detail", "Problem-Solving", "Teamwork", "Planning", "Reporting"],
          "tools": ["Canva", "Google Analytics", "Hootsuite", "Trello", "Mailchimp", "HubSpot", "Salesforce", "Asana", "Tableau", "SEMrush"],
          "certifications": ["Google Digital Marketing", "HubSpot Content Marketing", "Facebook Blueprint", "SEO Fundamentals", "Email Marketing Certification"]
        },
        "Sales Executive": {
          "tasks": ["Selling", "Prospecting", "Negotiating", "Closing", "Communicating", "Networking", "Reporting", "Planning", "Researching", "Following Up"],
          "skills": ["Communication", "Negotiation", "Networking", "Time Management", "Problem-Solving", "Persuasion", "CRM Tools", "Organization", "Adaptability", "Reporting"],
          "tools": ["Salesforce", "HubSpot", "LinkedIn Sales Navigator", "Zoho CRM", "Pipedrive", "Trello", "Slack", "MS Excel", "Tableau", "Google Workspace"],
          "certifications": ["Salesforce Sales Operations", "LinkedIn Sales Navigator Certification", "Negotiation Skills", "Communication Fundamentals", "CRM Certification"]
        },
        "Human Resources Coordinator": {
          "tasks": ["Recruiting", "Scheduling", "Coordinating", "Communicating", "Supporting", "Reporting", "Documenting", "Monitoring", "Onboarding", "Organizing"],
          "skills": ["Communication", "Coordination", "Time Management", "Organization", "Analytical Thinking", "Problem-Solving", "Reporting", "Attention to Detail", "Adaptability", "Teamwork"],
          "tools": ["SAP", "Zoho People", "BambooHR", "LinkedIn Recruiter", "Workday", "MS Excel", "Google Workspace", "Trello", "Slack", "ADP"],
          "certifications": ["SHRM Certified Professional", "PHR Certification", "HR Analytics", "MS Excel Advanced", "Organizational Behavior Certification"]
        },
        "Project Coordinator": {
          "tasks": ["Coordinating", "Monitoring", "Planning", "Reporting", "Communicating", "Scheduling", "Documenting", "Supporting", "Organizing", "Analyzing"],
          "skills": ["Coordination", "Communication", "Organization", "Time Management", "Attention to Detail", "Problem-Solving", "Analytical Thinking", "Teamwork", "Reporting", "Adaptability"],
          "tools": ["MS Project", "Asana", "Trello", "Google Workspace", "Slack", "Tableau", "SAP", "Salesforce", "Monday.com", "MS Excel"],
          "certifications": ["PMP Certification", "PRINCE2 Foundation", "Agile Project Management", "Scrum Master", "MS Project Certification"]
        },
        "Financial Analyst": {
          "tasks": ["Analyzing", "Forecasting", "Budgeting", "Reporting", "Monitoring", "Communicating", "Researching", "Documenting", "Planning", "Presenting"],
          "skills": ["Analytical Thinking", "Problem-Solving", "Communication", "Financial Modeling", "Reporting", "Attention to Detail", "Organization", "Teamwork", "Planning", "Adaptability"],
          "tools": ["Excel", "Power BI", "Tableau", "SQL", "SAP", "Oracle ERP", "Bloomberg", "Python", "R", "Microsoft Dynamics"],
          "certifications": ["CFA Level 1", "Financial Modeling", "MS Excel Advanced", "Tableau Certification", "Investment Banking Fundamentals"]
        },
        "Operations Analyst": {
          "tasks": ["Analyzing", "Reporting", "Forecasting", "Documenting", "Communicating", "Monitoring", "Supporting", "Planning", "Scheduling", "Optimizing"],
          "skills": ["Analytical Thinking", "Data Analysis", "Communication", "Problem-Solving", "Attention to Detail", "Reporting", "Organization", "Teamwork", "Adaptability", "Forecasting"],
          "tools": ["Excel", "Tableau", "Power BI", "SQL", "SAP", "Google Workspace", "JIRA", "Confluence", "Slack", "Trello"],
          "certifications": ["Tableau Certification", "SQL Certification", "Six Sigma Green Belt", "Process Improvement Fundamentals", "Data Analytics Professional"]
        },
        "Assistant Manager": {
          "tasks": ["Planning", "Organizing", "Coordinating", "Monitoring", "Reporting", "Communicating", "Supporting", "Documenting", "Analyzing", "Scheduling"],
          "skills": ["Planning", "Communication", "Organization", "Coordination", "Time Management", "Analytical Thinking", "Problem-Solving", "Attention to Detail", "Teamwork", "Reporting"],
          "tools": ["MS Project", "Excel", "Google Workspace", "Trello", "Slack", "Tableau", "SAP", "Salesforce", "Asana", "Power BI"],
          "certifications": ["PMP Certification", "PRINCE2 Foundation", "Leadership Essentials", "Agile Scrum Master", "MS Excel Advanced"]
        },
        "Customer Success Associate": {
          "tasks": ["Onboarding", "Supporting", "Monitoring", "Reporting", "Communicating", "Planning", "Scheduling", "Documenting", "Coordinating", "Assisting"],
          "skills": ["Customer Support", "Communication", "Onboarding", "Networking", "Problem-Solving", "Organization", "Reporting", "Teamwork", "Adaptability", "Time Management"],
          "tools": ["Salesforce", "HubSpot", "Zoho CRM", "Trello", "MS Excel", "Slack", "Google Workspace", "Monday.com", "Freshsales", "Pipedrive"],
          "certifications": ["Customer Service Excellence", "Salesforce Certification", "HubSpot CRM", "Communication Fundamentals", "Emotional Intelligence Certification"]
        },
        "Supply Chain Coordinator": {
          "tasks": ["Coordinating", "Planning", "Monitoring", "Reporting", "Scheduling", "Documenting", "Communicating", "Analyzing", "Organizing", "Supporting"],
          "skills": ["Coordination", "Communication", "Organization", "Planning", "Analytical Thinking", "Problem-Solving", "Reporting", "Attention to Detail", "Teamwork", "Adaptability"],
          "tools": ["SAP", "Oracle ERP", "Excel", "Tableau", "Power BI", "Trello", "Slack", "Salesforce", "Google Workspace", "Monday.com"],
          "certifications": ["APICS Certified Supply Chain Professional", "Lean Six Sigma", "Inventory Management Fundamentals", "Logistics Fundamentals", "Supply Chain Analytics"]
        },
        "Market Research Analyst": {
          "tasks": ["Researching", "Analyzing", "Monitoring", "Reporting", "Forecasting", "Documenting", "Communicating", "Planning", "Scheduling", "Presenting"],
          "skills": ["Market Research", "Analytical Thinking", "Communication", "Problem-Solving", "Data Analysis", "Reporting", "Attention to Detail", "Planning", "Teamwork", "Adaptability"],
          "tools": ["Qualtrics", "Tableau", "Google Analytics", "HubSpot", "Salesforce", "SEMrush", "Power BI", "Google Ads", "Canva", "Marketo"],
          "certifications": ["Market Research Fundamentals", "Google Analytics", "Tableau Certification", "Data Analytics Professional", "SEO Certification"]
        },
        "Junior Consultant": {
          "tasks": ["Consulting", "Analyzing", "Reporting", "Documenting", "Communicating", "Planning", "Monitoring", "Researching", "Forecasting", "Supporting"],
          "skills": ["Consulting", "Analytical Thinking", "Communication", "Problem-Solving", "Attention to Detail", "Organization", "Reporting", "Teamwork", "Adaptability", "Planning"],
          "tools": ["MS Project", "Excel", "Google Workspace", "Trello", "SAP", "Tableau", "Salesforce", "Power BI", "Slack", "Asana"],
          "certifications": ["Consulting Fundamentals", "Business Analytics Professional", "Problem-Solving Skills", "Leadership Essentials", "Lean Six Sigma"]
        },
        "Product Manager (Entry Level)": {
          "tasks": ["Planning", "Monitoring", "Researching", "Documenting", "Communicating", "Reporting", "Coordinating", "Scheduling", "Analyzing", "Supporting"],
          "skills": ["Planning", "Communication", "Organization", "Analytical Thinking", "Problem-Solving", "Attention to Detail", "Reporting", "Teamwork", "Adaptability", "Researching"],
          "tools": ["JIRA", "Trello", "Tableau", "Google Analytics", "Slack", "Salesforce", "MS Project", "Monday.com", "Figma", "Miro"],
          "certifications": ["Product Management Essentials", "Agile Scrum Master", "UX Fundamentals", "Leadership Essentials", "Roadmapping Certification"]
        }
      }
    },
    "Data Science and Analytics": {
      "roles": {
        "Data Analyst": {
          "tasks": ["Analyzing", "Cleaning", "Reporting", "Visualizing", "Modeling", "Monitoring", "Documenting", "Forecasting", "Communicating", "Querying"],
          "skills": ["Data Analysis", "Problem-Solving", "Communication", "Visualization", "SQL", "Python", "Analytical Thinking", "Attention to Detail", "Reporting", "Forecasting"],
          "tools": ["Excel", "Tableau", "Power BI", "Python", "R", "SQL", "Google Sheets", "Jupyter Notebook", "SAS", "AWS"],
          "certifications": ["Microsoft Data Analyst", "Tableau Certification", "SQL Certification", "Power BI Certification", "Python for Data Science"]
        },
        "Business Analyst": {
          "tasks": ["Analyzing", "Reporting", "Planning", "Documenting", "Communicating", "Researching", "Presenting", "Monitoring", "Evaluating", "Forecasting"],
          "skills": ["Analytical Thinking", "Problem-Solving", "Communication", "Planning", "Reporting", "Attention to Detail", "Teamwork", "Visualization", "SQL", "Forecasting"],
          "tools": ["Excel", "Tableau", "Power BI", "SQL", "Python", "Jira", "Confluence", "R", "Google Sheets", "MS Project"],
          "certifications": ["CBAP", "CCBA", "Six Sigma Green Belt", "Tableau Certification", "Power BI Certification"]
        },
        "Data Scientist": {
          "tasks": ["Analyzing", "Modeling", "Visualizing", "Forecasting", "Experimenting", "Cleaning", "Researching", "Monitoring", "Reporting", "Communicating"],
          "skills": ["Machine Learning", "Problem-Solving", "Communication", "Programming", "Data Cleaning", "Analytical Thinking", "Visualization", "Modeling", "Attention to Detail", "Experimentation"],
          "tools": ["TensorFlow", "PyTorch", "Excel", "Tableau", "Power BI", "Python", "R", "SQL", "Jupyter Notebook", "AWS"],
          "certifications": ["TensorFlow Developer", "AWS Data Analytics", "Google Data Scientist", "Python for Data Science", "Machine Learning by Stanford"]
        },
        "Data Engineer": {
          "tasks": ["Building", "Optimizing", "Testing", "Monitoring", "Transforming", "Loading", "Analyzing", "Documenting", "Querying", "Integrating"],
          "skills": ["ETL", "Data Warehousing", "Problem-Solving", "Communication", "Analytical Thinking", "Programming", "Attention to Detail", "SQL", "Integration", "Monitoring"],
          "tools": ["Apache Spark", "Hadoop", "SQL", "Python", "Tableau", "Power BI", "Airflow", "Databricks", "Google BigQuery", "AWS"],
          "certifications": ["AWS Data Analytics", "Microsoft Data Engineer", "SQL Certification", "Apache Spark Certification", "Tableau Certification"]
        },
        "Junior Data Analyst": {
          "tasks": ["Assisting", "Cleaning", "Reporting", "Documenting", "Querying", "Monitoring", "Visualizing", "Communicating", "Analyzing", "Forecasting"],
          "skills": ["Data Cleaning", "Communication", "Visualization", "Problem-Solving", "SQL", "Analytical Thinking", "Reporting", "Attention to Detail", "Monitoring", "Forecasting"],
          "tools": ["Excel", "Tableau", "Power BI", "Python", "SQL", "Jupyter Notebook", "Google Sheets", "SAS", "R", "AWS"],
          "certifications": ["Microsoft Data Analyst", "Tableau Certification", "Python for Data Science", "SQL Certification", "Power BI Certification"]
        },
        "Research Analyst": {
          "tasks": ["Researching", "Analyzing", "Reporting", "Monitoring", "Forecasting", "Documenting", "Communicating", "Planning", "Presenting", "Querying"],
          "skills": ["Research", "Analytical Thinking", "Problem-Solving", "Communication", "Data Analysis", "Attention to Detail", "Reporting", "Visualization", "Planning", "SQL"],
          "tools": ["Excel", "Tableau", "Power BI", "Python", "SQL", "Google Sheets", "R", "SAS", "Jupyter Notebook", "AWS"],
          "certifications": ["Research Analytics Certification", "Tableau Certification", "Data Science Fundamentals", "Python for Data Science", "SQL Certification"]
        },
        "Statistical Analyst": {
          "tasks": ["Analyzing", "Modeling", "Forecasting", "Reporting", "Documenting", "Researching", "Communicating", "Visualizing", "Querying", "Evaluating"],
          "skills": ["Statistical Analysis", "Problem-Solving", "Communication", "Attention to Detail", "Visualization", "Analytical Thinking", "Modeling", "Reporting", "SQL", "Forecasting"],
          "tools": ["Excel", "Tableau", "Power BI", "Python", "SQL", "R", "SAS", "Jupyter Notebook", "Google Sheets", "AWS"],
          "certifications": ["Statistical Analysis by Duke", "Tableau Certification", "Data Science Fundamentals", "Python for Data Science", "SQL Certification"]
        },
        "Marketing Analyst": {
          "tasks": ["Analyzing", "Reporting", "Monitoring", "Planning", "Researching", "Forecasting", "Communicating", "Documenting", "Optimizing", "Querying"],
          "skills": ["Marketing Analysis", "Problem-Solving", "Communication", "Analytical Thinking", "Visualization", "Attention to Detail", "Reporting", "Planning", "SQL", "Forecasting"],
          "tools": ["Excel", "Tableau", "Power BI", "Python", "SQL", "Google Sheets", "R", "SAS", "Jupyter Notebook", "AWS"],
          "certifications": ["Google Analytics", "Tableau Certification", "Data Analytics Professional", "Power BI Certification", "SQL Certification"]
        },
        "Financial Analyst": {
          "tasks": ["Analyzing", "Reporting", "Forecasting", "Modeling", "Monitoring", "Documenting", "Communicating", "Planning", "Presenting", "Evaluating"],
          "skills": ["Financial Modeling", "Analytical Thinking", "Problem-Solving", "Communication", "Attention to Detail", "Forecasting", "Reporting", "SQL", "Planning", "Visualization"],
          "tools": ["Excel", "Tableau", "Power BI", "SQL", "Python", "R", "Jupyter Notebook", "SAS", "Bloomberg", "AWS"],
          "certifications": ["CFA Level 1", "Financial Modeling Certification", "Tableau Certification", "SQL Certification", "Excel Advanced"]
        },
        "Operations Analyst": {
          "tasks": ["Analyzing", "Monitoring", "Reporting", "Documenting", "Planning", "Forecasting", "Communicating", "Visualizing", "Optimizing", "Querying"],
          "skills": ["Operations Analysis", "Problem-Solving", "Communication", "Analytical Thinking", "Visualization", "Reporting", "Attention to Detail", "SQL", "Planning", "Monitoring"],
          "tools": ["Excel", "Tableau", "Power BI", "SQL", "Python", "R", "Google Sheets", "SAS", "Jupyter Notebook", "AWS"],
          "certifications": ["Six Sigma Green Belt", "Tableau Certification", "SQL Certification", "Power BI Certification", "Process Improvement"]
        },
        "Healthcare Data Analyst": {
          "tasks": ["Analyzing", "Monitoring", "Reporting", "Forecasting", "Cleaning", "Researching", "Communicating", "Documenting", "Modeling", "Querying"],
          "skills": ["Healthcare Analytics", "Data Cleaning", "Communication", "Analytical Thinking", "Reporting", "Attention to Detail", "Visualization", "SQL", "Forecasting", "Monitoring"],
          "tools": ["Excel", "Tableau", "Power BI", "SQL", "Python", "R", "Jupyter Notebook", "SAS", "Google Sheets", "AWS"],
          "certifications": ["Healthcare Analytics Certification", "Tableau Certification", "SQL Certification", "Python for Data Science", "Power BI Certification"]
        },
        "Risk Analyst": {
          "tasks": ["Analyzing", "Monitoring", "Reporting", "Forecasting", "Planning", "Communicating", "Documenting", "Visualizing", "Researching", "Querying"],
          "skills": ["Risk Analysis", "Analytical Thinking", "Communication", "Attention to Detail", "Reporting", "Forecasting", "Problem-Solving", "SQL", "Visualization", "Planning"],
          "tools": ["Excel", "Tableau", "Power BI", "SQL", "Python", "R", "Google Sheets", "SAS", "Bloomberg", "AWS"],
          "certifications": ["Risk Analysis Certification", "Tableau Certification", "SQL Certification", "Python for Data Science", "Power BI Certification"]
        },
        "Machine Learning Engineer": {
          "tasks": ["Designing", "Experimenting", "Building", "Optimizing", "Testing", "Modeling", "Monitoring", "Visualizing", "Communicating", "Documenting"],
          "skills": ["Machine Learning", "Communication", "Analytical Thinking", "Problem-Solving", "Experimentation", "Programming", "Visualization", "Modeling", "Reporting", "Testing"],
          "tools": ["TensorFlow", "PyTorch", "Jupyter Notebook", "Python", "AWS", "SQL", "R", "Tableau", "Excel", "Power BI"],
          "certifications": ["TensorFlow Developer", "AWS Data Analytics", "Google AI Certification", "Python for Data Science", "Machine Learning by Stanford"]
        },
        "AI Engineer": {
          "tasks": ["Designing", "Experimenting", "Building", "Optimizing", "Testing", "Deploying", "Researching", "Communicating", "Documenting", "Monitoring"],
          "skills": ["Artificial Intelligence", "Machine Learning", "Analytical Thinking", "Communication", "Problem-Solving", "Experimentation", "Programming", "Attention to Detail", "Reporting", "Testing"],
          "tools": ["TensorFlow", "PyTorch", "Python", "Jupyter Notebook", "R", "Tableau", "AWS", "SQL", "Excel", "Power BI"],
          "certifications": ["Google AI Certification", "TensorFlow Developer", "AWS Data Analytics", "Python for Data Science", "Machine Learning by Stanford"]
        }
      }
    },
    "Design and UX": {
      "roles": {
        "UI/UX Designer": {
          "tasks": ["Designing", "Prototyping", "Testing", "Researching", "Collaborating", "Documenting", "Optimizing", "Sketching", "Iterating", "Reviewing"],
          "skills": ["Wireframing", "Prototyping", "UX Principles", "Collaboration", "Communication", "Creativity", "Problem-Solving", "Sketching", "Research", "Attention to Detail"],
          "tools": ["Figma", "Adobe XD", "Sketch", "InVision", "Photoshop", "Illustrator", "Axure RP", "Zeplin", "Balsamiq", "Miro"],
          "certifications": ["Google UX Design", "Interaction Design Foundation", "Adobe Certified Professional", "Figma Design Masterclass", "UX Academy Certification"]
        },
        "Graphic Designer": {
          "tasks": ["Designing", "Sketching", "Editing", "Collaborating", "Documenting", "Optimizing", "Illustrating", "Researching", "Reviewing", "Updating"],
          "skills": ["Graphic Design", "Creativity", "Communication", "Collaboration", "Sketching", "Editing", "Attention to Detail", "Problem-Solving", "Research", "Tools Proficiency"],
          "tools": ["Photoshop", "Illustrator", "Canva", "Sketch", "InDesign", "CorelDRAW", "Figma", "Procreate", "After Effects", "Affinity Designer"],
          "certifications": ["Adobe Certified Professional", "Graphic Design Masterclass", "Canva Certified Designer", "CorelDRAW Certification", "Photoshop Expert"]
        },
        "Web Designer": {
          "tasks": ["Designing", "Coding", "Sketching", "Collaborating", "Testing", "Optimizing", "Prototyping", "Reviewing", "Researching", "Updating"],
          "skills": ["Web Design", "Coding", "Creativity", "Communication", "Collaboration", "Problem-Solving", "Sketching", "Testing", "Attention to Detail", "UX Principles"],
          "tools": ["WordPress", "Photoshop", "Illustrator", "Sketch", "Figma", "Adobe XD", "HTML", "CSS", "JavaScript", "Webflow"],
          "certifications": ["Google Web Designer Certification", "Adobe Certified Professional", "HTML & CSS Fundamentals", "JavaScript Basics", "WordPress Advanced"]
        },
        "Visual Designer": {
          "tasks": ["Designing", "Illustrating", "Editing", "Researching", "Sketching", "Collaborating", "Documenting", "Testing", "Reviewing", "Updating"],
          "skills": ["Visual Design", "Creativity", "Collaboration", "Communication", "Sketching", "Problem-Solving", "Attention to Detail", "Editing", "Research", "Tools Proficiency"],
          "tools": ["Photoshop", "Illustrator", "Figma", "Sketch", "InDesign", "Adobe XD", "Canva", "After Effects", "Procreate", "CorelDRAW"],
          "certifications": ["Adobe Certified Professional", "Graphic Design Masterclass", "Canva Certified Designer", "CorelDRAW Certification", "Photoshop Expert"]
        },
        "Interaction Designer": {
          "tasks": ["Designing", "Prototyping", "Researching", "Collaborating", "Testing", "Sketching", "Documenting", "Reviewing", "Updating", "Optimizing"],
          "skills": ["Interaction Design", "UX Principles", "Prototyping", "Creativity", "Communication", "Collaboration", "Problem-Solving", "Research", "Sketching", "Testing"],
          "tools": ["Figma", "Adobe XD", "Sketch", "InVision", "Axure RP", "Zeplin", "Balsamiq", "Photoshop", "Illustrator", "Miro"],
          "certifications": ["Interaction Design Foundation", "Google UX Design", "Adobe Certified Professional", "Figma Design Masterclass", "UX Academy Certification"]
        }, 
         "User Experience Designer": {
            "tasks": ["Researching", "Testing", "Designing", "Prototyping", "Collaborating", "Documenting", "Sketching", "Iterating", "Reviewing", "Optimizing"],
            "skills": ["User Research", "UX Principles", "Wireframing", "Prototyping", "Collaboration", "Communication", "Problem-Solving", "Research", "Creativity", "Testing"],
            "tools": ["Figma", "Adobe XD", "Sketch", "InVision", "Photoshop", "Illustrator", "Axure RP", "Zeplin", "Miro", "Balsamiq"],
            "certifications": ["Google UX Design", "Interaction Design Foundation", "Adobe Certified Professional", "Figma Design Masterclass", "UX Academy Certification"]
          },
          "UI Designer": {
            "tasks": ["Designing", "Sketching", "Collaborating", "Documenting", "Testing", "Optimizing", "Researching", "Prototyping", "Reviewing", "Updating"],
            "skills": ["Wireframing", "Prototyping", "UI Principles", "Creativity", "Communication", "Collaboration", "Problem-Solving", "Research", "Testing", "Sketching"],
            "tools": ["Figma", "Adobe XD", "Sketch", "InVision", "Photoshop", "Illustrator", "Zeplin", "Axure RP", "Balsamiq", "Miro"],
            "certifications": ["Figma Design Masterclass", "Adobe Certified Professional", "Google UX Design", "Sketch Design Fundamentals", "UX/UI Bootcamp Certification"]
          },
          "UX Researcher": {
            "tasks": ["Researching", "Testing", "Collaborating", "Documenting", "Interviewing", "Analyzing", "Prototyping", "Iterating", "Sketching", "Reviewing"],
            "skills": ["User Research", "Collaboration", "Communication", "Analytical Thinking", "Prototyping", "Problem-Solving", "Attention to Detail", "Testing", "Research", "Creativity"],
            "tools": ["Figma", "Miro", "Adobe XD", "Sketch", "Axure RP", "Zeplin", "Google Analytics", "Photoshop", "Illustrator", "Tableau"],
            "certifications": ["Interaction Design Foundation", "Google UX Design", "UX Academy Certification", "Figma Design Masterclass", "Adobe Certified Professional"]
          },
          "Product Designer": {
            "tasks": ["Designing", "Prototyping", "Sketching", "Collaborating", "Testing", "Researching", "Documenting", "Reviewing", "Iterating", "Updating"],
            "skills": ["Product Design", "Creativity", "Wireframing", "Prototyping", "Communication", "Collaboration", "Problem-Solving", "Research", "Sketching", "Testing"],
            "tools": ["Figma", "Adobe XD", "Sketch", "Photoshop", "Illustrator", "InVision", "Axure RP", "Zeplin", "Canva", "Miro"],
            "certifications": ["Google UX Design", "Product Design Masterclass", "Figma Design Masterclass", "Adobe Certified Professional", "UX/UI Bootcamp Certification"]
          },
          "Junior Designer": {
            "tasks": ["Assisting", "Designing", "Sketching", "Documenting", "Testing", "Collaborating", "Updating", "Reviewing", "Researching", "Prototyping"],
            "skills": ["Design Fundamentals", "Creativity", "Collaboration", "Communication", "Sketching", "Problem-Solving", "Research", "Testing", "Editing", "Tools Proficiency"],
            "tools": ["Photoshop", "Illustrator", "Canva", "Figma", "Sketch", "InDesign", "CorelDRAW", "Procreate", "After Effects", "Affinity Designer"],
            "certifications": ["Adobe Certified Professional", "Graphic Design Masterclass", "Canva Certified Designer", "CorelDRAW Certification", "Photoshop Expert"]
          },
          "Motion Graphics Designer": {
            "tasks": ["Designing", "Animating", "Sketching", "Collaborating", "Researching", "Editing", "Documenting", "Testing", "Reviewing", "Updating"],
            "skills": ["Motion Graphics", "Animation", "Creativity", "Communication", "Collaboration", "Editing", "Problem-Solving", "Research", "Tools Proficiency", "Sketching"],
            "tools": ["After Effects", "Premiere Pro", "Photoshop", "Illustrator", "Figma", "Sketch", "Procreate", "Blender", "Cinema 4D", "Canva"],
            "certifications": ["Motion Graphics Certification", "Adobe Certified Professional", "After Effects Bootcamp", "Animation Fundamentals", "Blender Certification"]
          },
          "Digital Designer": {
            "tasks": ["Designing", "Sketching", "Editing", "Collaborating", "Prototyping", "Documenting", "Testing", "Researching", "Reviewing", "Updating"],
            "skills": ["Digital Design", "Creativity", "Collaboration", "Communication", "Sketching", "Editing", "Problem-Solving", "Research", "Testing", "Tools Proficiency"],
            "tools": ["Photoshop", "Illustrator", "Figma", "Sketch", "InDesign", "Adobe XD", "CorelDRAW", "Canva", "After Effects", "Procreate"],
            "certifications": ["Digital Design Certification", "Adobe Certified Professional", "Canva Certified Designer", "Graphic Design Masterclass", "Figma Design Masterclass"]
          },
          "Creative Designer": {
            "tasks": ["Designing", "Sketching", "Collaborating", "Editing", "Researching", "Documenting", "Prototyping", "Reviewing", "Testing", "Updating"],
            "skills": ["Creative Design", "Communication", "Collaboration", "Creativity", "Sketching", "Editing", "Research", "Problem-Solving", "Testing", "Tools Proficiency"],
            "tools": ["Photoshop", "Illustrator", "Figma", "Sketch", "InDesign", "CorelDRAW", "Procreate", "Canva", "After Effects", "Affinity Designer"],
            "certifications": ["Adobe Certified Professional", "Graphic Design Masterclass", "Canva Certified Designer", "CorelDRAW Certification", "Photoshop Expert"]
          },
          "UX/UI Developer": {
            "tasks": ["Designing", "Coding", "Prototyping", "Testing", "Collaborating", "Researching", "Documenting", "Reviewing", "Sketching", "Updating"],
            "skills": ["UX/UI Principles", "Coding", "Prototyping", "Testing", "Communication", "Collaboration", "Creativity", "Problem-Solving", "Research", "Sketching"],
            "tools": ["Figma", "Adobe XD", "Sketch", "InVision", "Photoshop", "Illustrator", "HTML", "CSS", "JavaScript", "Axure RP"],
            "certifications": ["Google UX Design", "Interaction Design Foundation", "Adobe Certified Professional", "Figma Design Masterclass", "UX/UI Bootcamp Certification"]
          },
          "Entry-level Designer": {
            "tasks": ["Assisting", "Sketching", "Designing", "Documenting", "Editing", "Collaborating", "Testing", "Researching", "Reviewing", "Updating"],
            "skills": ["Design Fundamentals", "Communication", "Creativity", "Collaboration", "Sketching", "Research", "Problem-Solving", "Testing", "Editing", "Tools Proficiency"],
            "tools": ["Photoshop", "Illustrator", "Canva", "Figma", "Sketch", "InDesign", "CorelDRAW", "Procreate", "After Effects", "Affinity Designer"],
            "certifications": ["Adobe Certified Professional", "Graphic Design Masterclass", "Canva Certified Designer", "CorelDRAW Certification", "Photoshop Expert"]
          }
      }
    },
    "Human Resource": {
      "roles": {
        "HR Assistant": {
          "tasks": ["Assisting", "Documenting", "Coordinating", "Communicating", "Scheduling", "Reporting", "Updating", "Organizing", "Filing", "Supporting"],
          "skills": ["Communication", "Organization", "Time Management", "Attention to Detail", "Problem-Solving", "Teamwork", "MS Office", "Scheduling", "Adaptability", "Reporting"],
          "tools": ["MS Excel", "Word", "SAP", "BambooHR", "Zoho People", "Slack", "Trello", "Asana", "G-Suite", "Outlook"],
          "certifications": ["PHR Certification", "SHRM Certified Professional", "HR Analytics", "MS Office Certification", "Time Management Essentials"]
        },
        "HR Generalist": {
          "tasks": ["Managing", "Recruiting", "Coordinating", "Communicating", "Updating", "Reporting", "Organizing", "Advising", "Planning", "Documenting"],
          "skills": ["Recruitment", "Communication", "Organization", "Problem-Solving", "Time Management", "MS Office", "Reporting", "Teamwork", "Adaptability", "Planning"],
          "tools": ["Workday", "SAP", "BambooHR", "Zoho People", "Trello", "Slack", "MS Excel", "Outlook", "Asana", "G-Suite"],
          "certifications": ["SHRM Certified Professional", "PHR Certification", "HR Management Essentials", "Talent Acquisition Certification", "Time Management Skills"]
        },
        "Talent Acquisition Specialist": {
          "tasks": ["Recruiting", "Interviewing", "Communicating", "Screening", "Onboarding", "Reporting", "Coordinating", "Planning", "Documenting", "Supporting"],
          "skills": ["Talent Acquisition", "Communication", "Screening", "Organization", "Interviewing", "Time Management", "Problem-Solving", "Teamwork", "Reporting", "Adaptability"],
          "tools": ["LinkedIn Recruiter", "SAP", "BambooHR", "Zoho People", "Workday", "MS Excel", "Outlook", "Slack", "Asana", "Trello"],
          "certifications": ["LinkedIn Recruiter Certification", "SHRM Talent Acquisition", "PHR Certification", "ATS Training", "HR Fundamentals"]
        },
        "HR Administrator": {
            "tasks": ["Documenting", "Communicating", "Updating", "Organizing", "Filing", "Coordinating", "Scheduling", "Reporting", "Supporting", "Monitoring"],
            "skills": ["Documentation", "Communication", "Organization", "Attention to Detail", "Time Management", "Teamwork", "Reporting", "Filing", "Scheduling", "Adaptability"],
            "tools": ["MS Excel", "Word", "SAP", "BambooHR", "Zoho People", "Slack", "Trello", "Asana", "G-Suite", "Outlook"],
            "certifications": ["HR Documentation Certification", "PHR Certification", "Time Management Essentials", "SHRM Certified Professional", "MS Office Advanced"]
          },
          "HRIS Analyst": {
            "tasks": ["Analyzing", "Reporting", "Managing", "Communicating", "Coordinating", "Implementing", "Documenting", "Monitoring", "Supporting", "Updating"],
            "skills": ["HRIS Systems", "Data Analysis", "Communication", "Organization", "Attention to Detail", "Reporting", "Problem-Solving", "Teamwork", "Adaptability", "MS Office"],
            "tools": ["Workday", "BambooHR", "SAP", "Kronos", "MS Excel", "Zoho People", "Slack", "Tableau", "Trello", "Outlook"],
            "certifications": ["HRIS Certification", "SHRM Certified Professional", "PHR Certification", "Data Analysis Certification", "BambooHR Training"]
          },
          "Payroll Coordinator": {
            "tasks": ["Processing", "Calculating", "Reporting", "Documenting", "Communicating", "Monitoring", "Filing", "Supporting", "Organizing", "Scheduling"],
            "skills": ["Payroll Processing", "Communication", "Time Management", "Organization", "Attention to Detail", "Reporting", "Problem-Solving", "Teamwork", "Filing", "Scheduling"],
            "tools": ["ADP", "QuickBooks", "SAP", "Kronos", "MS Excel", "BambooHR", "Zoho People", "Slack", "Asana", "Outlook"],
            "certifications": ["Payroll Certification", "SHRM Certified Professional", "QuickBooks Certification", "Time Management Essentials", "PHR Certification"]
          },
          "HR Coordinator": {
            "tasks": ["Coordinating", "Communicating", "Organizing", "Scheduling", "Reporting", "Supporting", "Documenting", "Filing", "Monitoring", "Advising"],
            "skills": ["Coordination", "Communication", "Organization", "Attention to Detail", "Scheduling", "Teamwork", "Reporting", "Problem-Solving", "Adaptability", "MS Office"],
            "tools": ["MS Excel", "Word", "SAP", "BambooHR", "Zoho People", "Slack", "Trello", "Asana", "G-Suite", "Outlook"],
            "certifications": ["Coordination Skills Certification", "PHR Certification", "SHRM Certified Professional", "MS Office Certification", "Time Management Essentials"]
          },
          "Recruitment Coordinator": {
            "tasks": ["Recruiting", "Coordinating", "Communicating", "Screening", "Documenting", "Scheduling", "Reporting", "Supporting", "Organizing", "Planning"],
            "skills": ["Recruitment", "Communication", "Coordination", "Screening", "Organization", "Scheduling", "Teamwork", "Reporting", "Planning", "Adaptability"],
            "tools": ["LinkedIn Recruiter", "BambooHR", "SAP", "Zoho People", "Workday", "MS Excel", "Outlook", "Slack", "Trello", "Asana"],
            "certifications": ["LinkedIn Recruiter Certification", "ATS Training", "SHRM Talent Acquisition", "PHR Certification", "HR Fundamentals"]
          },
          "HR Trainee": {
            "tasks": ["Assisting", "Communicating", "Supporting", "Scheduling", "Documenting", "Filing", "Reporting", "Organizing", "Updating", "Monitoring"],
            "skills": ["Communication", "Organization", "Teamwork", "Scheduling", "Attention to Detail", "Time Management", "Reporting", "Filing", "Adaptability", "Supporting"],
            "tools": ["MS Excel", "Word", "SAP", "BambooHR", "Zoho People", "Slack", "Trello", "Asana", "G-Suite", "Outlook"],
            "certifications": ["HR Essentials Certification", "Time Management Skills", "MS Office Certification", "PHR Certification", "SHRM Certified Professional"]
          },
          "Onboarding Specialist": {
            "tasks": ["Onboarding", "Communicating", "Coordinating", "Documenting", "Scheduling", "Supporting", "Updating", "Reporting", "Organizing", "Planning"],
            "skills": ["Onboarding", "Communication", "Coordination", "Time Management", "Organization", "Reporting", "Scheduling", "Problem-Solving", "Supporting", "Adaptability"],
            "tools": ["BambooHR", "SAP", "Zoho People", "MS Excel", "Slack", "Workday", "Asana", "Trello", "G-Suite", "Outlook"],
            "certifications": ["Onboarding Certification", "SHRM Certified Professional", "PHR Certification", "ATS Training", "HR Fundamentals"]
          },
          "Talent Management Associate": {
            "tasks": ["Planning", "Communicating", "Managing", "Coordinating", "Reporting", "Monitoring", "Documenting", "Advising", "Updating", "Supporting"],
            "skills": ["Talent Management", "Communication", "Planning", "Problem-Solving", "Teamwork", "Reporting", "Organization", "Coordination", "Monitoring", "Advising"],
            "tools": ["Workday", "BambooHR", "SAP", "Zoho People", "MS Excel", "Trello", "Slack", "Tableau", "Outlook", "Asana"],
            "certifications": ["Talent Management Certification", "SHRM Certified Professional", "HR Management Essentials", "PHR Certification", "Coordination Skills"]
          },
          "HR Assistant Manager": {
            "tasks": ["Managing", "Coordinating", "Advising", "Communicating", "Planning", "Reporting", "Documenting", "Organizing", "Supporting", "Scheduling"],
            "skills": ["HR Management", "Communication", "Coordination", "Advising", "Planning", "Organization", "Reporting", "Teamwork", "Scheduling", "Problem-Solving"],
            "tools": ["Workday", "BambooHR", "SAP", "Zoho People", "MS Excel", "Trello", "Slack", "Asana", "Outlook", "G-Suite"],
            "certifications": ["HR Management Certification", "SHRM Certified Professional", "PHR Certification", "Time Management Skills", "Coordination Skills"]
          },
          "Entry-level HR Consultant": {
            "tasks": ["Consulting", "Communicating", "Advising", "Coordinating", "Supporting", "Reporting", "Planning", "Documenting", "Organizing", "Monitoring"],
            "skills": ["Consulting", "Communication", "Organization", "Planning", "Advising", "Teamwork", "Reporting", "Problem-Solving", "Monitoring", "Coordination"],
            "tools": ["Workday", "SAP", "BambooHR", "Zoho People", "Trello", "Slack", "MS Excel", "Outlook", "Asana", "G-Suite"],
            "certifications": ["HR Consultant Certification", "SHRM Certified Professional", "PHR Certification", "Talent Acquisition Certification", "Coordination Skills"]
          }
      }
    }
  }

const SECTOR_GROUPS = {
    IT: {
      "No Experience": [
        {
          groupName: "Performance",
          description: "Detail programming languages, tools, and technologies learned..."
        },
        {
          groupName: "Scalability",
          description: "Describe academic or personal projects completed..."
        },
        {
          groupName: "Cost",
          description: "Outline relevant coursework and academic achievements..."
        },
        {
          groupName: "Security",
          description: "Describe report preparation and analysis..."
        },
        {
          groupName: "Automation",
          description: "Describe report preparation and analysis..."
        },
        {
          groupName: "Innovation",
          description: "Describe report preparation and analysis..."
        }
      ],
      "Less Than 3 Years": [
        {
          groupName: "Performance",
          description: "Detail programming languages, tools, and technologies learned..."
        },
        {
          groupName: "Scalability",
          description: "Describe academic or personal projects completed..."
        },
        {
          groupName: "Cost",
          description: "Outline relevant coursework and academic achievements..."
        },
        {
          groupName: "Security",
          description: "Describe report preparation and analysis..."
        },
        {
          groupName: "Automation",
          description: "Describe report preparation and analysis..."
        },
        {
          groupName: "Innovation",
          description: "Describe report preparation and analysis..."
        }
      ],
      "3-5 Years": [
        {
          groupName: "Performance",
          description: "Detail programming languages, tools, and technologies learned..."
        },
        {
          groupName: "Scalability",
          description: "Describe academic or personal projects completed..."
        },
        {
          groupName: "Cost",
          description: "Outline relevant coursework and academic achievements..."
        },
        {
          groupName: "Security",
          description: "Describe report preparation and analysis..."
        },
        {
          groupName: "Automation",
          description: "Describe report preparation and analysis..."
        },
        {
          groupName: "Innovation",
          description: "Describe report preparation and analysis..."
        }
      ],
      "5-10 Years": [
        {
          groupName: "Performance",
          description: "Detail programming languages, tools, and technologies learned..."
        },
        {
          groupName: "Scalability",
          description: "Describe academic or personal projects completed..."
        },
        {
          groupName: "Cost",
          description: "Outline relevant coursework and academic achievements..."
        },
        {
          groupName: "Security",
          description: "Describe report preparation and analysis..."
        },
        {
          groupName: "Automation",
          description: "Describe report preparation and analysis..."
        },
        {
          groupName: "Innovation",
          description: "Describe report preparation and analysis..."
        }
      ],
      "10 Years": [
        {
          groupName: "Performance",
          description: "Detail programming languages, tools, and technologies learned..."
        },
        {
          groupName: "Scalability",
          description: "Describe academic or personal projects completed..."
        },
        {
          groupName: "Cost",
          description: "Outline relevant coursework and academic achievements..."
        },
        {
          groupName: "Security",
          description: "Describe report preparation and analysis..."
        },
        {
          groupName: "Automation",
          description: "Describe report preparation and analysis..."
        },
        {
          groupName: "Innovation",
          description: "Describe report preparation and analysis..."
        }
      ]
    },
    Finance: {
      "No Experience": [
        {
          groupName: "Performance",
          description: "Detail financial concepts and theories learned..."
        },
        {
          groupName: "Risk Management",
          description: "Describe analytical skills and tools used..."
        },
        {
          groupName: "Cost Efficiency",
          description: "Outline academic or internship projects..."
        },
        {
          groupName: "Compliance",
          description: "Describe report preparation and analysis..."
        }
      ],
      "Less Than 3 Years": [
        {
          groupName: "Performance",
          description: "Detail day-to-day financial operations handled..."
        },
        {
          groupName: "Risk Management",
          description: "Describe financial analysis and reporting tasks..."
        },
        {
          groupName: "Process Improvement",
          description: "Outline regulatory compliance activities..."
        }
      ],
      "3-5 Years": [
        {
          groupName: "Performance",
          description: "Detail financial planning and strategy development..."
        },
        {
          groupName: "Risk Management",
          description: "Describe risk assessment and mitigation strategies..."
        },
        {
          groupName: "Process Improvement",
          description: "Outline team management and mentoring..."
        }
      ],
      "5-10 Years": [
        {
          groupName: "Performance",
          description: "Detail strategic financial planning and execution..."
        },
        {
          groupName: "Risk Management",
          description: "Describe investment portfolio management..."
        },
        {
          groupName: "Process Improvement",
          description: "Outline departmental leadership and growth..."
        },
        {
          groupName: "Business Growth",
          description: "Describe report preparation and analysis..."
        }
      ],
      "10 Years": [
        {
          groupName: "Investment Performance",
          description: "Detail executive financial strategy and vision..."
        },
        {
          groupName: "Risk Management",
          description: "Describe corporate financial planning and growth..."
        },
        {
          groupName: "Profitability",
          description: "Outline high-level stakeholder relationships..."
        },
        {
          groupName: "Strategic Expansion",
          description: "Describe report preparation and analysis..."
        }
      ]
    },
    Marketing: {
      "No Experience": [
        {
          groupName: "Performance",
          description: "Detail marketing principles and concepts learned..."
        },
        {
          groupName: "Brand Awareness",
          description: "Describe digital marketing tools and platforms..."
        },
        {
          groupName: "Cost Efficiency",
          description: "Outline academic or personal marketing projects..."
        },
        {
          groupName: "Innovation",
          description: "Describe report preparation and analysis..."
        }
      ],
      "Less Than 3 Years": [
        {
          groupName: "Performance",
          description: "Detail marketing principles and concepts learned..."
        },
        {
          groupName: "Brand Awareness",
          description: "Describe digital marketing tools and platforms..."
        },
        {
          groupName: "Cost Efficiency",
          description: "Outline academic or personal marketing projects..."
        },
        {
          groupName: "Automation",
          description: "Describe report preparation and analysis..."
        }
      ],
      "3-5 Years": [
        {
          groupName: "Performance",
          description: "Detail marketing principles and concepts learned..."
        },
        {
          groupName: "Brand Awareness",
          description: "Describe digital marketing tools and platforms..."
        },
        {
          groupName: "Cost Efficiency",
          description: "Outline academic or personal marketing projects..."
        },
        {
          groupName: "Automation",
          description: "Describe report preparation and analysis..."
        }
      ],
      "5-10 Years": [
        {
          groupName: "Performance",
          description: "Detail marketing principles and concepts learned..."
        },
        {
          groupName: "Brand Awareness",
          description: "Describe digital marketing tools and platforms..."
        },
        {
          groupName: "Cost Efficiency",
          description: "Outline academic or personal marketing projects..."
        },
        {
          groupName: "Automation",
          description: "Describe report preparation and analysis..."
        }
      ],
      "10 Years": [
        {
          groupName: "Revenue Growth",
          description: "Detail marketing vision and strategy..."
        },
        {
          groupName: "Brand Awareness",
          description: "Describe market expansion and innovation..."
        },
        {
          groupName: "Marketing Efficiency",
          description: "Outline executive leadership and stakeholder management..."
        },
        {
          groupName: "Engagement & Retention",
          description: "Describe report preparation and analysis..."
        }
      ]
    },
    SalesAndDevelopment: {
      "No Experience": [
        {
          groupName: "Performance",
          description: "Detail understanding of sales principles and methodologies..."
        },
        {
          groupName: "Conversion",
          description: "Describe customer interaction and service skills..."
        },
        {
          groupName: "Revenue Growth",
          description: "Outline familiarity with CRM and sales tools..."
        },
        {
          groupName: "Efficiency",
          description: "Describe report preparation and analysis..."
        }
      ],
      "Less Than 3 Years": [
        {
          groupName: "Performance",
          description: "Detail understanding of sales principles and methodologies..."
        },
        {
          groupName: "Conversion",
          description: "Describe customer interaction and service skills..."
        },
        {
          groupName: "Revenue Growth",
          description: "Outline familiarity with CRM and sales tools..."
        },
        {
          groupName: "Efficiency",
          description: "Describe report preparation and analysis..."
        }
      ],
      "3-5 Years": [
        {
          groupName: "Revenue Growth",
          description: "Detail sales targets and achievements..."
        },
        {
          groupName: "Client Retention",
          description: "Describe client relationship building and management..."
        },
        {
          groupName: "Market Expansion",
          description: "Outline sales process implementation..."
        },
        {
          groupName: "Process Optimization",
          description: "Describe report preparation and analysis..."
        }
      ],
      "5-10 Years": [
        {
          groupName: "Revenue Growth",
          description: "Detail sales team management and development..."
        },
        {
          groupName: "Client Acquisition",
          description: "Describe new market development strategies..."
        },
        {
          groupName: "Sales Efficiency",
          description: "Outline revenue generation and optimization..."
        },
        {
          groupName: "Team Performance",
          description: "Describe report preparation and analysis..."
        }
      ],
      "10 Years": [
        {
          groupName: "Revenue Growth",
          description: "Detail sales strategy and organizational vision..."
        },
        {
          groupName: "Sales Optimization",
          description: "Describe executive team management..."
        },
        {
          groupName: "Operational Efficiency",
          description: "Outline high-level partnership development..."
        },
        {
          groupName: "Client Acquisition",
          description: "Describe report preparation and analysis..."
        }
      ]
    },
    BusinessManagement: {
      "No Experience": [
        {
          groupName: "Efficiency",
          description: "Detail understanding of business principles..."
        },
        {
          groupName: "Cost Reduction",
          description: "Describe project assistance and coordination..."
        },
        {
          groupName: "Process Optimization",
          description: "Outline analytical and problem-solving abilities..."
        },
        {
          groupName: "Market Growth",
          description: "Describe report preparation and analysis..."
        }
      ],
      "Less Than 3 Years": [
        {
          groupName: "Performance",
          description: "Detail operational process improvements..."
        },
        {
          groupName: "Revenue Growth",
          description: "Describe project planning and execution..."
        },
        {
          groupName: "Cost Reduction",
          description: "Outline team collaboration and support..."
        },
        {
          groupName: "Process Improvement",
          description: "Describe report preparation and analysis..."
        }
      ],
      "3-5 Years": [
        {
          groupName: "Performance",
          description: "Detail business process improvements..."
        },
        {
          groupName: "Revenue Growth",
          description: "Describe team management and development..."
        },
        {
          groupName: "Cost Reduction",
          description: "Outline departmental strategy development..."
        },
        {
          groupName: "Process Improvement",
          description: "Describe report preparation and analysis..."
        }
      ],
      "5-10 Years": [
        {
          groupName: "Performance",
          description: "Detail strategic planning and implementation..."
        },
        {
          groupName: "Revenue Growth",
          description: "Describe organizational development..."
        },
        {
          groupName: "Cost Optimization",
          description: "Outline transformation initiatives..."
        },
        {
          groupName: "Leadership",
          description: "Describe report preparation and analysis..."
        }
      ],
      "10 Years": [
        {
          groupName: "Business Growth",
          description: "Detail organizational strategy and vision..."
        },
        {
          groupName: "Operational Efficiency",
          description: "Describe business growth and expansion..."
        },
        {
          groupName: "Financial Success",
          description: "Outline high-level relationship management..."
        },
        {
          groupName: "Leadership",
          description: "Describe report preparation and analysis..."
        }
      ]
    },
    Accounts: {
      "No Experience": [
        {
          groupName: "Performance",
          description: "Detail understanding of accounting principles..."
        },
        {
          groupName: "Cost Management",
          description: "Describe proficiency in accounting software..."
        },
        {
          groupName: "Efficiency",
          description: "Outline record-keeping and documentation..."
        },
        {
          groupName: "Compliance",
          description: "Describe report preparation and analysis..."
        }
      ],
      "Less Than 3 Years": [
        {
          groupName: "Performance",
          description: "Detail account reconciliation and maintenance..."
        },
        {
          groupName: "Cost Management",
          description: "Describe report preparation and analysis..."
        },
        {
          groupName: "Efficiency",
          description: "Outline regulatory compliance activities..."
        },
        {
          groupName: "Compliance",
          description: "Describe report preparation and analysis..."
        }
      ],
      "3-5 Years": [
        {
          groupName: "Efficiency",
          description: "Detail complex financial analysis..."
        },
        {
          groupName: "Cost Management",
          description: "Describe accounting process optimization..."
        },
        {
          groupName: "Process Optimization",
          description: "Outline team leadership and training..."
        },
        {
          groupName: "Compliance",
          description: "Describe report preparation and analysis..."
        }
      ],
      "5-10 Years": [
        {
          groupName: "Cost Reduction",
          description: "Detail financial strategy development..."
        },
        {
          groupName: "Process Optimization",
          description: "Describe accounting team leadership..."
        },
        {
          groupName: "Compliance",
          description: "Outline risk assessment and mitigation..."
        }
      ],
      "10 Years": [
        {
          groupName: "Financial Performance",
          description: "Detail financial strategy and governance..."
        },
        {
          groupName: "Operational Efficiency",
          description: "Describe organizational financial planning..."
        },
        {
          groupName: "Tax & Compliance",
          description: "Outline executive-level decision making..."
        },
        {
          groupName: "Leadership",
          description: "Detail financial strategy and governance..."
        }
      ]
    },
    HumanResource: {
      "No Experience": [
        {
          groupName: "Efficiency",
          description: "Detail understanding of HR principles..."
        },
        {
          groupName: "Engagement",
          description: "Describe HR administrative support..."
        },
        {
          groupName: "Compliance",
          description: "Outline record-keeping and compliance..."
        },
        {
          groupName: "Automation",
          description: "Describe report preparation and analysis..."
        }
      ],
      "Less Than 3 Years": [
        {
          groupName: "Recruitment Efficiency",
          description: "Detail recruitment and onboarding..."
        },
        {
          groupName: "Employee Engagement",
          description: "Describe employee support and engagement..."
        },
        {
          groupName: "Payroll & Compliance",
          description: "Outline HR processes and procedures..."
        },
        {
          groupName: "HR Analytics",
          description: "Describe report preparation and analysis..."
        }
      ],
      "3-5 Years": [
        {
          groupName: "Recruitment Efficiency",
          description: "Detail talent acquisition and development..."
        },
        {
          groupName: "Employee Engagement",
          description: "Describe HR policy implementation..."
        },
        {
          groupName: "Payroll & Compliance",
          description: "Outline HR team coordination..."
        },
        {
          groupName: "HR Analytics",
          description: "Describe report preparation and analysis..."
        }
      ],
      "5-10 Years": [
        {
          groupName: "Recruitment Efficiency",
          description: "Detail HR strategy development..."
        },
        {
          groupName: "Employee Engagement",
          description: "Describe culture and change management..."
        },
        {
          groupName: "Payroll & Compliance",
          description: "Outline HR department management..."
        },
        {
          groupName: "HR Analytics",
          description: "Describe report preparation and analysis..."
        }
      ],
      "10 Years": [
        {
          groupName: "Recruitment Optimization",
          description: "Detail HR vision and strategy..."
        },
        {
          groupName: "Employee Engagement",
          description: "Describe organizational transformation..."
        },
        {
          groupName: "HR Compliance",
          description: "Outline executive HR leadership..."
        },
        {
          groupName: "D&I Strategy",
          description: "Describe report preparation and analysis..."
        }
      ]
    },
    DataScienceandAnalytics: {
      "No Experience": [
        {
          groupName: "Performance",
          description: "Detail understanding of design principles..."
        },
        {
          groupName: "Efficiency",
          description: "Describe design software skills..."
        },
        {
          groupName: "Insights",
          description: "Outline personal/academic projects..."
        },
        {
          groupName: "Innovation",
          description: "Describe report preparation and analysis..."
        }
      ],
      "Less Than 3 Years": [
        {
          groupName: "Performance",
          description: "Detail understanding of design principles..."
        },
        {
          groupName: "Efficiency",
          description: "Describe design software skills..."
        },
        {
          groupName: "Insights",
          description: "Outline personal/academic projects..."
        },
        {
          groupName: "Innovation",
          description: "Describe report preparation and analysis..."
        }
      ],
      "3-5 Years": [
        {
          groupName: "Performance",
          description: "Detail understanding of design principles..."
        },
        {
          groupName: "Efficiency",
          description: "Describe design software skills..."
        },
        {
          groupName: "Insights",
          description: "Outline personal/academic projects..."
        },
        {
          groupName: "Innovation",
          description: "Describe report preparation and analysis..."
        }
      ],
      "5-10 Years": [
        {
          groupName: "Performance",
          description: "Detail understanding of design principles..."
        },
        {
          groupName: "Efficiency",
          description: "Describe design software skills..."
        },
        {
          groupName: "Insights",
          description: "Outline personal/academic projects..."
        },
        {
          groupName: "Innovation",
          description: "Describe report preparation and analysis..."
        }
      ],
      "10 Years": [
        {
          groupName: "Business Impact",
          description: "Detail design strategy and vision..."
        },
        {
          groupName: "Operational Efficiency",
          description: "Describe organizational influence..."
        },
        {
          groupName: "Risk Management",
          description: "Outline executive design leadership..."
        },
        {
          groupName: "Leadership",
          description: "Describe report preparation and analysis..."
        }
      ]
    },
    DesignAndUx: {
      "No Experience": [
        {
          groupName: "User Engagement",
          description: "Detail understanding of data science concepts..."
        },
        {
          groupName: "Efficiency",
          description: "Describe programming and analysis tools..."
        },
        {
          groupName: "Accessibility",
          description: "Outline data analysis projects..."
        },
        {
          groupName: "Collaboration",
          description: "Describe report preparation and analysis..."
        }
      ],
      "Less Than 3 Years": [
        {
          groupName: "User Engagement",
          description: "Detail understanding of data science concepts..."
        },
        {
          groupName: "Efficiency",
          description: "Describe programming and analysis tools..."
        },
        {
          groupName: "Accessibility",
          description: "Outline data analysis projects..."
        },
        {
          groupName: "Collaboration",
          description: "Describe report preparation and analysis..."
        }
      ],
      "3-5 Years": [
        {
          groupName: "User Engagement",
          description: "Detail understanding of data science concepts..."
        },
        {
          groupName: "Efficiency",
          description: "Describe programming and analysis tools..."
        },
        {
          groupName: "Accessibility",
          description: "Outline data analysis projects..."
        },
        {
          groupName: "Collaboration",
          description: "Describe report preparation and analysis..."
        }
      ],
      "5-10 Years": [
        {
          groupName: "User Engagement",
          description: "Detail understanding of data science concepts..."
        },
        {
          groupName: "Efficiency",
          description: "Describe programming and analysis tools..."
        },
        {
          groupName: "Accessibility",
          description: "Outline data analysis projects..."
        },
        {
          groupName: "Collaboration",
          description: "Describe report preparation and analysis..."
        }
      ],
      "10 Years": [
        {
          groupName: "Customer Experience",
          description: "Detail analytics vision and strategy..."
        },
        {
          groupName: "Design Efficiency",
          description: "Describe enterprise-wide analytics..."
        },
        {
          groupName: "Product Growth",
          description: "Outline executive analytics leadership..."
        },
        {
          groupName: "Compliance",
          description: "Describe report preparation and analysis..."
        }
      ]
    }
  };
  
const SECTOR_SKILL_GROUPS = {
    IT: {
      "No Experience": [
        {
          name: "Languages",
          skills: ["", "", ""]
        },
        {
          name: "Frameworks",
          skills: ["", "", ""]
        },
        {
          name: "Tools",
          skills: ["", "", ""]
        },
        {
          name: "Platforms",
          skills: ["", "", ""]
        },
        {
          name: "Soft Skills",
          skills: ["", "", ""]
        }
      ],
      "Less Than 3 Years": [
        {
          name: "Languages",
          skills: ["", "", ""]
        },
        {
          name: "Frameworks",
          skills: ["", "", ""]
        },
        {
          name: "Tools",
          skills: ["", "", ""]
        },
        {
          name: "Platforms",
          skills: ["", "", ""]
        },
        {
          name: "Soft Skills",
          skills: ["", "", ""]
        }
      ],
      "3-5 Years": [
        {
          name: "Languages",
          skills: ["", "", ""]
        },
        {
          name: "Frameworks",
          skills: ["", "", ""]
        },
        {
          name: "Tools",
          skills: ["", "", ""]
        },
        {
          name: "Platforms",
          skills: ["", "", ""]
        },
        {
          name: "Soft Skills",
          skills: ["", "", ""]
        }
      ],
      "5-10 Years": [
        {
          name: "Languages",
          skills: ["", "", ""]
        },
        {
          name: "Frameworks",
          skills: ["", "", ""]
        },
        {
          name: "Tools",
          skills: ["", "", ""]
        },
        {
          name: "Platforms",
          skills: ["", "", ""]
        },
        {
          name: "Soft Skills",
          skills: ["", "", ""]
        }
      ],
      "10 Years": [
        {
          name: "Languages",
          skills: ["", "", ""]
        },
        {
          name: "Frameworks",
          skills: ["", "", ""]
        },
        {
          name: "Tools",
          skills: ["", "", ""]
        },
        {
          name: "Platforms",
          skills: ["", "", ""]
        },
        {
          name: "Soft Skills",
          skills: ["", "", ""]
        }
      ]
    },
  
    Finance: {
      "No Experience": [
        {
          name: "Finance & Investment",
          skills: ["", "", ""]
        },
        {
          name: "Software & Tools",
          skills: ["", "", ""]
        },
        {
          name: "Compliance & Regulations",
          skills: ["", "", ""]
        },
        {
          name: "Soft Skills",
          skills: ["", "", ""]
        }
      ],
      "Less Than 3 Years": [
        {
          name: "Finance & Investment",
          skills: ["", "", ""]
        },
        {
          name: "Software & Tools",
          skills: ["", "", ""]
        },
        {
          name: "Compliance & Regulations",
          skills: ["", "", ""]
        },
        {
          name: "Soft Skills",
          skills: ["", "", ""]
        }
      ],
      "3-5 Years": [
        {
          name: "Finance & Investment",
          skills: ["", "", ""]
        },
        {
          name: "Software & Tools",
          skills: ["", "", ""]
        },
        {
          name: "Compliance & Regulations",
          skills: ["", "", ""]
        },
        {
          name: "Soft Skills",
          skills: ["", "", ""]
        }
      ],
      "5-10 Years": [
        {
          name: "Finance & Investment",
          skills: ["", "", ""]
        },
        {
          name: "Software & Tools",
          skills: ["", "", ""]
        },
        {
          name: "Compliance & Regulations",
          skills: ["", "", ""]
        },
        {
          name: "Soft Skills",
          skills: ["", "", ""]
        }
      ],
      "10 Years": [
        {
          name: "Finance & Investment",
          skills: ["", "", ""]
        },
        {
          name: "Financial Software & Tools",
          skills: ["", "", ""]
        },
        {
          name: "Compliance & Regulations",
          skills: ["", "", ""]
        },
        {
          name: "Soft Skills",
          skills: ["", "", ""]
        }
      ]
    },
  
    Marketing: {
      "No Experience": [
        {
          name: "Marketing & Strategy",
          skills: ["", "", ""]
        },
        {
          name: "Digital Marketing & Tools",
          skills: ["", "", ""]
        },
        {
          name: "Social Media & Content",
          skills: ["", "", ""]
        },
        {
          name: "Soft Skills",
          skills: ["", "", ""]
        }
      ],
      "Less Than 3 Years": [
        {
          name: "Marketing & Strategy",
          skills: ["", "", ""]
        },
        {
          name: "Digital Marketing & Tools",
          skills: ["", "", ""]
        },
        {
          name: "Social Media & Content",
          skills: ["", "", ""]
        },
        {
          name: "Soft Skills",
          skills: ["", "", ""]
        }
      ],
      "3-5 Years": [
        {
          name: "Marketing & Strategy",
          skills: ["", "", ""]
        },
        {
          name: "Digital Marketing & Tools",
          skills: ["", "", ""]
        },
        {
          name: "Social Media & Content",
          skills: ["", "", ""]
        },
        {
          name: "Soft Skills",
          skills: ["", "", ""]
        }
      ],
      "5-10 Years": [
        {
          name: "Marketing & Strategy",
          skills: ["", "", ""]
        },
        {
          name: "Digital Marketing & Tools",
          skills: ["", "", ""]
        },
        {
          name: "Social Media & Content",
          skills: ["", "", ""]
        },
        {
          name: "Soft Skills",
          skills: ["", "", ""]
        }
      ],
      "10 Years": [
        {
          name: "Marketing & Strategy",
          skills: ["", "", ""]
        },
        {
          name: "Digital Marketing & Tools",
          skills: ["", "", ""]
        },
        {
          name: "Branding & Customer Engagement",
          skills: ["", "", ""]
        },
        {
          name: "Soft Skills",
          skills: ["", "", ""]
        }
      ]
    },
  
    SalesAndDevelopment: {
      "No Experience": [
        {
          name: "Sales & Marketing",
          skills: ["", "", ""]
        },
        {
          name: "CRM & Tools",
          skills: ["", "", ""]
        },
        {
          name: "Strategy & Analytics",
          skills: ["", "", ""]
        },
        {
          name: "Soft Skills",
          skills: ["", "", ""]
        }
      ],
      "Less Than 3 Years": [
        {
          name: "Sales & Marketing",
          skills: ["", "", ""]
        },
        {
          name: "CRM & Tools",
          skills: ["", "", ""]
        },
         
        {
          name: "Soft Skills",
          skills: ["", "", ""]
        }
      ],
      "3-5 Years": [
        {
          name: "Sales & Marketing",
          skills: ["", "", ""]
        },
        {
          name: "CRM & Tools",
          skills: ["", "", ""]
        },
         
        {
          name: "Soft Skills",
          skills: ["", "", ""]
        }
      ],
      "5-10 Years": [
        {
          name: "Sales & Marketing",
          skills: ["", "", ""]
        },
        {
          name: "CRM & Tools",
          skills: ["", "", ""]
        },
        
        {
          name: "Soft Skills",
          skills: ["", "", ""]
        }
      ],
      "10 Years": [
        {
          name: "Sales & Business Development",
          skills: ["", "", ""]
        },
        {
          name: "CRM & Tools",
          skills: ["", "", ""]
        },
        {
          name: "Leadership & Strategy",
          skills: ["", "", ""]
        },
        {
          name: "Soft Skills",
          skills: ["", "", ""]
        }
      ]
    },
  
    BusinessManagement: {
      "No Experience": [
        {
          name: "Business Strategy & Growth",
          skills: ["", "", ""]
        },
        {
          name: "Operations & Project Management",
          skills: ["", "", ""]
        },
        {
          name: "Finance & Analytics",
          skills: ["", "", ""]
        },
        {
          name: "Soft Skills",
          skills: ["", "", ""]
        }
      ],
      "Less Than 3 Years": [
        {
          name: "Business Strategy & Growth",
          skills: ["", "", ""]
        },
        {
          name: "Operations & Project Management",
          skills: ["", "", ""]
        },
        {
          name: "Finance & Analytics",
          skills: ["", "", ""]
        },
        {
          name: "Sales & Marketing",
          skills: ["", "", ""]
        },
        {
          name: "Soft Skills",
          skills: ["", "", ""]
        }
      ],
      "3-5 Years": [
        {
          name: "Business Strategy & Growth",
          skills: ["", "", ""]
        },
        {
          name: "Operations & Project Management",
          skills: ["", "", ""]
        },
        {
          name: "Finance & Analytics",
          skills: ["", "", ""]
        }, 
        {
          name: "Sales & Marketing",
          skills: ["", "", ""]
        },
        {
          name: "Soft Skills",
          skills: ["", "", ""]
        }
      ],
      "5-10 Years": [
        {
          name: "Business Strategy & Growth",
          skills: ["", "", ""]
        },
        {
          name: "Operations & Project Management",
          skills: ["", "", ""]
        },
        {
          name: "Finance & Analytics",
          skills: ["", "", ""]
        }, 
        {
          name: "Sales & Marketing",
          skills: ["", "", ""]
        },
        {
          name: "Soft Skills",
          skills: ["", "", ""]
        }
      ],
      "10 Years": [
        {
          name: "Business Strategy & Growth",
          skills: ["", "", ""]
        },
        {
          name: "Operations & Project Management",
          skills: ["", "", ""]
        },
        {
          name: "Finance & Analytics",
          skills: ["", "", ""]
        }, 
        {
          name: "Sales & Marketing",
          skills: ["", "", ""]
        },
        {
          name: "Soft Skills",
          skills: ["", "", ""]
        }
      ]
    },
  
    Accounts: {
      "No Experience": [
        {
          name: "Accounting & Finance",
          skills: ["", "", ""]
        },
        {
          name: "Software & Tools",
          skills: ["", "", ""]
        },
        {
          name: "Compliance & Regulations",
          skills: ["", "", ""]
        },
        {
          name: "Soft Skills",
          skills: ["", "", ""]
        }
      ],
      "Less Than 3 Years": [
        {
          name: "Accounting & Finance",
          skills: ["", "", ""]
        },
        {
          name: "Software & Tools",
          skills: ["", "", ""]
        },
        {
          name: "Compliance & Regulations",
          skills: ["", "", ""]
        },
        {
          name: "Soft Skills",
          skills: ["", "", ""]
        }
      ],
      "3-5 Years": [
        {
          name: "Accounting & Finance",
          skills: ["", "", ""]
        },
        {
          name: "Software & Tools",
          skills: ["", "", ""]
        },
        {
          name: "Compliance & Regulations",
          skills: ["", "", ""]
        },
        {
          name: "Soft Skills",
          skills: ["", "", ""]
        }
      ],
      "5-10 Years": [
        {
          name: "Accounting & Finance",
          skills: ["", "", ""]
        },
        {
          name: "Software & Tools",
          skills: ["", "", ""]
        },
        {
          name: "Compliance & Regulations",
          skills: ["", "", ""]
        },
        {
          name: "Soft Skills",
          skills: ["", "", ""]
        }
      ],
      "10 Years": [
        {
          name: "Accounting & Finance",
          skills: ["", "", ""]
        },
        {
          name: "Software & Tools",
          skills: ["", "", ""]
        },
        {
          name: "Risk & Compliance",
          skills: ["", "", ""]
        },
        {
          name: "Soft Skills",
          skills: ["", "", ""]
        }
      ]
    },
  
    HumanResource: {
      "No Experience": [
        {
          name: "HR & Talent Management",
          skills: ["", "", ""]
        },
        {
          name: "HR Operations & Compliance",
          skills: ["", "", ""]
        },
        {
          name: "HR Analytics & Reporting",
          skills: ["", "", ""]
        },
        {
          name: "Soft Skills",
          skills: ["", "", ""]
        }
      ],
      "Less Than 3 Years": [
        {
          name: "HR & Talent Management",
          skills: ["", "", ""]
        },
        {
          name: "HR Operations & Compliance",
          skills: ["", "", ""]
        },
        {
          name: "HR Analytics & Reporting",
          skills: ["", "", ""]
        },
        {
          name: "Soft Skills",
          skills: ["", "", ""]
        }
      ],
      "3-5 Years": [
        {
          name: "HR & Talent Management",
          skills: ["", "", ""]
        },
        {
          name: "HR Operations & Compliance",
          skills: ["", "", ""]
        },
        {
          name: "HR Analytics & Reporting",
          skills: ["", "", ""]
        },
        {
          name: "Soft Skills",
          skills: ["", "", ""]
        }
      ],
      "5-10 Years": [
        {
          name: "HR & Talent Management",
          skills: ["", "", ""]
        },
        {
          name: "HR Technology & Tools",
          skills: ["", "", ""]
        },
        {
          name: "Compliance & Labor Laws",
          skills: ["", "", ""]
        },
        {
          name: "Soft Skills",
          skills: ["", "", ""]
        }
      ],
      "10 Years": [
        {
          name: "HR & Talent Management",
          skills: ["", "", ""]
        },
        {
          name: "HR Technology & Tools",
          skills: ["", "", ""]
        },
        {
          name: "Compliance & Labor Laws",
          skills: ["", "", ""]
        },
        {
          name: "Soft Skills",
          skills: ["", "", ""]
        }
      ]
    },
  
    DesignAndUx: {
      "No Experience": [
        {
          name: "Design Tools & Prototyping",
          skills: ["", "", ""]
        },
        {
          name: "User Experience & Research",
          skills: ["", "", ""]
        },
        {
          name: "Testing & Analytics",
          skills: ["", "", ""]
        },
        {
          name: "Soft Skills",
          skills: ["", "", ""]
        }
      ],
      "Less Than 3 Years": [
        {
          name: "Design Tools & Prototyping",
          skills: ["", "", ""]
        },
        {
          name: "User Experience & Research",
          skills: ["", "", ""]
        },
        {
          name: "Testing & Analytics",
          skills: ["", "", ""]
        },
        {
          name: "Soft Skills",
          skills: ["", "", ""]
        }
      ],
      "3-5 Years": [
        {
          name: "Design Tools & Prototyping",
          skills: ["", "", ""]
        },
        {
          name: "User Experience & Research",
          skills: ["", "", ""]
        },
        {
          name: "Testing & Analytics",
          skills: ["", "", ""]
        },
        {
          name: "Soft Skills",
          skills: ["", "", ""]
        }
      ],
      "5-10 Years": [
        {
          name: "UX Design & Strategy",
          skills: ["", "", ""]
        },
        {
          name: "UI & Product Design",
          skills: ["", "", ""]
        },
        {
          name: "User Research & Analytics",
          skills: ["", "", ""]
        },
        {
          name: "Soft Skills",
          skills: ["", "", ""]
        }
      ],
      "10 Years": [
        {
          name: "UX Design & Strategy",
          skills: ["", "", ""]
        },
        {
          name: "UI & Product Design",
          skills: ["", "", ""]
        },
        {
          name: "User Research & Analytics",
          skills: ["", "", ""]
        },
        {
          name: "Soft Skills",
          skills: ["", "", ""]
        }
      ]
    },
  
    DataScienceandAnalytics: {
      "No Experience": [
        {
          name: "Programming & Data Science",
          skills: ["", "", ""]
        },
        {
          name: "Machine Learning & AI",
          skills: ["", "", ""]
        },
        {
          name: "Data Analytics & Visualization",
          skills: ["", "", ""]
        },
        {
          name: "Soft Skills",
          skills: ["", "", ""]
        }
      ],
      "Less Than 3 Years": [
        {
          name: "Programming & Data Science",
          skills: ["", "", ""]
        },
        {
          name: "Machine Learning & AI",
          skills: ["", "", ""]
        },
        {
          name: "Data Analytics & Visualization",
          skills: ["", "", ""]
        },
        {
          name: "Soft Skills",
          skills: ["", "", ""]
        }
      ],
      "3-5 Years": [
        {
          name: "Programming & Data Science",
          skills: ["", "", ""]
        },
        {
          name: "Machine Learning & AI",
          skills: ["", "", ""]
        },
        {
          name: "Data Analytics & Visualization",
          skills: ["", "", ""]
        },
        {
          name: "Soft Skills",
          skills: ["", "", ""]
        }
      ],
      "5-10 Years": [
        {
          name: "Data Science & AI",
          skills: ["", "", ""]
        },
        {
          name: "Big Data & Data Engineering",
          skills: ["", "", ""]
        },
        {
          name: "Data Analytics & BI",
          skills: ["", "", ""]
        },
        {
          name: "Cloud & Infrastructure",
          skills: ["", "", ""]
        },
        {
          name: "Soft Skills",
          skills: ["", "", ""]
        }
      ],
      "10 Years": [
        {
          name: "Data Science & AI",
          skills: ["", "", ""]
        },
        {
          name: "Big Data & Data Engineering",
          skills: ["", "", ""]
        },
        {
          name: "Data Analytics & BI",
          skills: ["", "", ""]
        },
        {
          name: "Cloud & Infrastructure",
          skills: ["", "", ""]
        },
        {
          name: "Soft Skills",
          skills: ["", "", ""]
        }
      ]
    }
  }; 

 
  const occupationalData = {

    normalizeString: (str) => {
      if (!str) return '';
      return str
        .toLowerCase()
        .replace(/\s+/g, '') // Only remove spaces
        .trim();
    },

    findClosestMatch: (normalizedInput, validKeys) => {
      if (!normalizedInput) return null;
      
      // First try exact match
      const exactMatch = validKeys.find(key => 
        occupationalData.normalizeString(key) === normalizedInput
      );
      if (exactMatch) return exactMatch;
  
      // Then try partial match
      const partialMatch = validKeys.find(key => {
        const normalizedKey = occupationalData.normalizeString(key);
        return normalizedKey.includes(normalizedInput) || 
               normalizedInput.includes(normalizedKey);
      });
      if (partialMatch) return partialMatch;
  
      // If no match found, try matching individual words
      const inputWords = normalizedInput.match(/[a-z]+/g) || [];
      return validKeys.find(key => {
        const keyWords = occupationalData.normalizeString(key).match(/[a-z]+/g) || [];
        return inputWords.some(word => 
          keyWords.some(keyWord => 
            keyWord.includes(word) || word.includes(keyWord)
          )
        );
      });
    },
  // Get all data
  getAllData: () => Database,

  // Get specific category data
  getCategoryData: (category) => Database[category] || null,

  // Get all roles in a category
  getRolesByCategory: (category) => {
      return Object.keys(Database[category]?.roles || {});
  },

  // Get specific role details
  getRoleDetails: (category, role) => {
      return Database[category]?.roles[role] || null;
  },

  // Get specific aspects of a role
  getRoleSkills: (category, role) => {
      return Database[category]?.roles[role]?.skills || [];
  },

  getRoleTools: (category, role) => {
      return Database[category]?.roles[role]?.tools || [];
  },

  getRoleTasks: (category, role) => {
      return Database[category]?.roles[role]?.tasks || [];
  },

  getRoleCertifications: (category, role) => {
      return Database[category]?.roles[role]?.certifications || [];
  },
 // New function to get certifications by role name only
 getCertificationsByRole: (roleName) => {
  let certifications = [];
  Object.values(Database).forEach(category => {
      Object.entries(category.roles).forEach(([role, details]) => {
          if (role.toLowerCase() === roleName.toLowerCase()) {
              certifications = details.certifications;
          }
      });
  });
  
  return certifications;
},
  // Search functions
  searchRolesBySkill: (skill) => {
      const results = [];
      Object.entries(Database).forEach(([category, data]) => {
          Object.entries(data.roles).forEach(([role, details]) => {
              if (details.skills.some(s => 
                  s.toLowerCase().includes(skill.toLowerCase())
              )) {
                  results.push({ category, role, details });
              }
          });
      });
      return results;
  },

  searchRolesByTool: (tool) => {
      const results = [];
      Object.entries(Database).forEach(([category, data]) => {
          Object.entries(data.roles).forEach(([role, details]) => {
              if (details.tools.some(t => 
                  t.toLowerCase().includes(tool.toLowerCase())
              )) {
                  results.push({ category, role, details });
              }
          });
      });
      return results;
  },

  // Advanced search with multiple criteria
  searchRoles: ({ category, role, skill, tool, certification }) => {
      let results = [];
      
      Object.entries(Database).forEach(([cat, data]) => {
          if (category && !cat.toLowerCase().includes(category.toLowerCase())) {
              return;
          }

          Object.entries(data.roles).forEach(([roleName, details]) => {
              let matches = true;

              if (role && !roleName.toLowerCase().includes(role.toLowerCase())) {
                  matches = false;
              }
              if (skill && !details.skills.some(s => 
                  s.toLowerCase().includes(skill.toLowerCase())
              )) {
                  matches = false;
              }
              if (tool && !details.tools.some(t => 
                  t.toLowerCase().includes(tool.toLowerCase())
              )) {
                  matches = false;
              }
              if (certification && !details.certifications.some(c => 
                  c.toLowerCase().includes(certification.toLowerCase())
              )) {
                  matches = false;
              }

              if (matches) {
                  results.push({
                      category: cat,
                      role: roleName,
                      details
                  });
              }
          });
      });
      return results;
  },
  // Get all unique skills/tools across all roles
  getAllUniqueSkills: () => {
      const skillSet = new Set();
      Object.values(Database).forEach(category => {
          Object.values(category.roles).forEach(role => {
              role.skills.forEach(skill => skillSet.add(skill));
          });
      });
      return Array.from(skillSet);
  },

  getAllUniqueTools: () => {
      const toolSet = new Set();
      Object.values(Database).forEach(category => {
          Object.values(category.roles).forEach(role => {
              role.tools.forEach(tool => toolSet.add(tool));
          });
      });
      return Array.from(toolSet);
  },

  getAllUniqueTask : () => {

      const taskSet = new Set();
      Object.values(Database).forEach(category => {
          Object.values(category.roles).forEach(role => {
              role.tasks.forEach(task => taskSet.add(task));
          });
      });
      return Array.from(taskSet);
  } ,
  // Get related roles based on similar skills
  getRelatedRoles: (category, role, minCommonSkills = 3) => {
      const targetRole = Database[category]?.roles[role];
      if (!targetRole) return [];

      const related = [];
      Object.entries(Database).forEach(([cat, data]) => {
          Object.entries(data.roles).forEach(([roleName, details]) => {
              if (cat === category && roleName === role) return;

              const commonSkills = details.skills.filter(skill => 
                  targetRole.skills.includes(skill)
              );

              if (commonSkills.length >= minCommonSkills) {
                  related.push({
                      category: cat,
                      role: roleName,
                      commonSkills
                  });
              }
          });
      });
      return related;
  },
  // New function to get tasks by role name only
  getTasksByRole: (roleName) => {
    let tasks = [];
    Object.values(Database).forEach(category => {
        Object.entries(category.roles).forEach(([role, details]) => {
            if (role.toLowerCase() === roleName.toLowerCase()) {
                tasks = details.tasks;
            }
        });
    });
    
    // If no exact match found, try similar roles
    if (!tasks.length) {
        const similarRoles = occupationalData.getSimilarRoles(roleName);
        similarRoles.forEach(similarRole => {
            Object.values(Database).forEach(category => {
                Object.entries(category.roles).forEach(([role, details]) => {
                    if (role === similarRole) {
                        tasks = [...new Set([...tasks, ...details.tasks])];
                    }
                });
            });
        });
    }
    
    return tasks;
},
 
getToolsByRole: (roleName) => {
    let tools = [];
    Object.values(Database).forEach(category => {
        Object.entries(category.roles).forEach(([role, details]) => {
            if (role.toLowerCase() === roleName.toLowerCase()) {
                tools = details.tools;
            }
        });
    });
    
    // If no exact match found, try similar roles
    if (!tools.length) {
        const similarRoles = occupationalData.getSimilarRoles(roleName);
        similarRoles.forEach(similarRole => {
            Object.values(Database).forEach(category => {
                Object.entries(category.roles).forEach(([role, details]) => {
                    if (role === similarRole) {
                        tools = [...new Set([...tools, ...details.tools])];
                    }
                });
            });
        });
    }
    
    return tools;
},
// New function to get skills by role name only
getSkillsByRole: (roleName) => {
    let skills = [];
    Object.values(Database).forEach(category => {
        Object.entries(category.roles).forEach(([role, details]) => {
            if (role.toLowerCase() === roleName.toLowerCase()) {
                skills = details.skills;
            }
        });
    });
    
    // If no exact match found, try similar roles
    if (!skills.length) {
        const similarRoles = occupationalData.getSimilarRoles(roleName);
        similarRoles.forEach(similarRole => {
            Object.values(Database).forEach(category => {
                Object.entries(category.roles).forEach(([role, details]) => {
                    if (role === similarRole) {
                        skills = [...new Set([...skills, ...details.skills])];
                    }
                });
            });
        });
    }
    
    return skills;
},

// Add this new function to occupationalData object
getSimilarRoles: (searchRole) => {
    if (!searchRole) return [];
    
    const searchTerms = searchRole.toLowerCase().split(/[\s-]+/); // Split by spaces and hyphens
    const matches = [];
    
    Object.values(Database).forEach(category => {
        Object.entries(category.roles).forEach(([role, details]) => {
            const roleWords = role.toLowerCase().split(/[\s-]+/);
            
            // Check how many words match between search terms and role
            const matchCount = searchTerms.reduce((count, term) => {
                return roleWords.some(word => word.includes(term) || term.includes(word)) 
                    ? count + 1 : count;
            }, 0);
            
            // If at least one word matches, add to matches array with score
            if (matchCount > 0) {
                matches.push({
                    role,
                    details,
                    score: matchCount / Math.max(searchTerms.length, roleWords.length)
                });
            }
        });
    });
    
    // Sort by match score and return top 3 matches
    return matches
        .sort((a, b) => b.score - a.score)
        .slice(0, 3)
        .map(match => match.role);
},

// Add this new method
  getSectorGroups: (sector = '', experience = '') => {
    // Default values
    const defaultSector = 'IT';
    const defaultExperience = 'No Experience';

    // Normalize inputs
    const normalizedSector = occupationalData.normalizeString(sector);
    const normalizedExp = occupationalData.normalizeString(experience);

    // Get valid sectors and experiences
    const validSectors = Object.keys(SECTOR_GROUPS);

    // Find matching sector
    const matchedSector = occupationalData.findClosestMatch(normalizedSector, validSectors) || defaultSector;

  // Get valid experiences for the matched sector
  const validExperiences = Object.keys(SECTOR_GROUPS[matchedSector]);

    // Find matching experience
    const matchedExperience = occupationalData.findClosestMatch(normalizedExp, validExperiences) || defaultExperience;

    // Return appropriate groups
  return SECTOR_GROUPS[matchedSector][matchedExperience] || SECTOR_GROUPS[defaultSector][defaultExperience];
},

// Optional: Add a method to get available experiences for a sector
getExperienceLevels: (sector = '') => {
  if (!sector) return [];
  return Object.keys(SECTOR_GROUPS[sector] || {});
},

// Optional: Add a method to get all available sectors
getSectors: () => {
  return Object.keys(SECTOR_GROUPS);
},

// Add this new method
getSectorSkillGroups: (sector = '', experience = '') => {
  // Default values
  const defaultSector = 'IT';
  const defaultExperience = 'No Experience';

  // Normalize inputs
  const normalizedSector = occupationalData.normalizeString(sector);
  const normalizedExp = occupationalData.normalizeString(experience);

  // Get valid sectors and experiences
  const validSectors = Object.keys(SECTOR_SKILL_GROUPS);

  // Find matching sector
  const matchedSector = occupationalData.findClosestMatch(normalizedSector, validSectors) || defaultSector;

  // Get valid experiences for the matched sector
  const validExperiences = Object.keys(SECTOR_SKILL_GROUPS[matchedSector]);

  // Find matching experience
  const matchedExperience = occupationalData.findClosestMatch(normalizedExp, validExperiences) || defaultExperience;

  // Return appropriate skill groups
  return SECTOR_SKILL_GROUPS[matchedSector][matchedExperience] || SECTOR_SKILL_GROUPS[defaultSector][defaultExperience];
},

// Optional: Add methods to get available skill-related experiences for a sector
getSkillExperienceLevels: (sector = '') => {
  if (!sector) return [];
  return Object.keys(SECTOR_SKILL_GROUPS[sector] || {});
},

// Optional: Add method to get all available sectors for skills
getSkillSectors: () => {
  return Object.keys(SECTOR_SKILL_GROUPS);
}
};

export default occupationalData;
  