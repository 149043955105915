import React, { useEffect, useState } from "react";
import styles from "./LLPResume.module.css";
import { getSafeMarkdownString } from "../../../../utils/markdown";
import { Check } from "lucide-react";
import { EXPERIENCE_LEVELS } from "../config/ConfigSection";

const LLPResume = ({ sections }) => {
  const [experienceLevel, setExperienceLevel] = useState(
    EXPERIENCE_LEVELS.NO_EXPERIENCE
  );

  useEffect(() => {
    // Get experience level from URL
    const getExperienceLevelFromURL = () => {
      const YOE = new URLSearchParams(window.location.search).get("YOE");
      switch (YOE) {
        case "No Experience":
          return EXPERIENCE_LEVELS.NO_EXPERIENCE;
        case "Less Than 3 Years":
          return EXPERIENCE_LEVELS.ONE_TO_TWO_YEARS;
        case "3-5 Years":
          return EXPERIENCE_LEVELS.THREE_TO_FIVE_YEARS;
        case "5-10 Years":
          return EXPERIENCE_LEVELS.FIVE_TO_TEN_YEARS;
        case "10  Years":
          return EXPERIENCE_LEVELS.TEN_PLUS_YEARS;
        default:
          return EXPERIENCE_LEVELS.NO_EXPERIENCE;
      }
    };

    setExperienceLevel(getExperienceLevelFromURL());
  }, [window.location.search]);

  const getSectionOrder = () => {
    switch (experienceLevel) {
      case EXPERIENCE_LEVELS.NO_EXPERIENCE:
        return (
          <>
            <ProfessionalTitle Profile={sections.profile.data} />
            <About Professionalsummary={sections.Professionalsummary?.data} />
            <TechnicalSkills  technicalSkills={sections.technicalSkills} name="Skills" />
            <Education education={sections.education?.data} />
            <Experience experience={sections.experience}  name="Internship Experience" />
            <Projects projects={sections.projects?.data} />
            <Awards awards={sections.awards?.data} />
            <Optional optional={sections.optional?.data} />
          </>
        );

      case EXPERIENCE_LEVELS.ONE_TO_TWO_YEARS:
        return (
          <>
            <ProfessionalTitle Profile={sections.profile.data} />
            <About Professionalsummary={sections.Professionalsummary?.data} />
            <TechnicalSkills technicalSkills={sections.technicalSkills}  name="Core Skills" />
            <Experience experience={sections.experience} />
            <Projects projects={sections.projects?.data} />
            <Achievements achivements={sections.achivements?.data} />
            <Education education={sections.education?.data} />
            <Awards awards={sections.awards?.data} />
            <Optional optional={sections.optional?.data} />
          </>
        );

      case EXPERIENCE_LEVELS.THREE_TO_FIVE_YEARS:
        return (
          <>
            <ProfessionalTitle Profile={sections.profile.data} />
            <About Professionalsummary={sections.Professionalsummary?.data} />
            <Experience experience={sections.experience} />
            <Projects projects={sections.projects?.data} />
            <CareerHighlight careerHighlight={sections.careerHighlight?.data} name="Career Highlights" />
            <TechnicalSkills technicalSkills={sections.technicalSkills}  name="Core Skills & Tools"  />
             <Achievements achivements={sections.achivements?.data} />
            <Education education={sections.education?.data} />
            <Awards awards={sections.awards?.data} />
            <Optional optional={sections.optional?.data} />
          </>
        );
      case EXPERIENCE_LEVELS.FIVE_TO_TEN_YEARS:
        return (
          <>
            <ProfessionalTitle Profile={sections.profile.data} />
            <About Professionalsummary={sections.Professionalsummary?.data} />
            <TechnicalSkills technicalSkills={sections.technicalSkills}  name="Areas of Expertise" />
            <CareerHighlight careerHighlight={sections.careerHighlight?.data} name="Career Highlights" />
            <Experience experience={sections.experience}name="Work Experience" />
            <Projects projects={sections.projects?.data} name="Key Projects" />
            <Education education={sections.education?.data} name="Education & Executive Training" />
            <Awards awards={sections.awards?.data}  name="Certifications"/>
             <Achievements  achivements={sections.achivements?.data} name="Key Achievements" />
            <Optional optional={sections.optional?.data} />
          </>
        );

      case EXPERIENCE_LEVELS.TEN_PLUS_YEARS:
        return (
          <>
            <ProfessionalTitle Profile={sections.profile.data} />
            <About Professionalsummary={sections.Professionalsummary?.data} name="Executive Summary" />
            <Experience experience={sections.experience}  name="Leadership Experience"/>
            <TechnicalSkills technicalSkills={sections.technicalSkills}  name="Core Competencies" />
            <Awards awards={sections.awards?.data} />
            <Achievements achivements={sections.achivements?.data} name="Key Achievements" />
            <Membership membership={sections.membership?.data}  name="Board Memberships & Public Speaking" />
            <KeyStrength keyStrength={sections.keyStrength?.data} name="Key Strengths" />
            <CareerHighlight careerHighlight={sections.careerHighlight?.data} name="Career Highlights" />
            <Education education={sections.education?.data} name="Education & Executive Training" />
            <Optional optional={sections.optional?.data} />
          </>
        );

      default:
        return (
          <>
            <ProfessionalTitle Profile={sections.profile.data} />
            <About Professionalsummary={sections.Professionalsummary?.data} />
            <Experience experience={sections.experience} />
            <TechnicalSkills technicalSkills={sections.technicalSkills} />
            <Awards awards={sections.awards?.data} />
            <Projects projects={sections.projects?.data} />
            <Education education={sections.education?.data} />
            <CareerHighlight careerHighlight={sections.careerHighlight?.data} name="Career Highlights" />
            <Optional optional={sections.optional?.data} />
          </>
        );
    }
  };

  return (
    <div className={styles.main}>
      <Header profile={sections.profile.data} />
      <div className={styles.resume}>{getSectionOrder()}</div>
    </div>
  );
};

const sectionTitle = (title) => {
  return (
    <>
      <span className={styles.title}> {title} </span>{" "}
      <div className={styles.hr}></div>
    </>
  );
};

const Header = ({ profile }) => {
  if (!profile) return null;
  return (
    <div className={styles.headerContainer}>
      <div className={styles.nameashucontainer}>
        <div className={styles.nameashu}>{profile.name}</div>
      </div>
      <div className={styles.resumesampletexttop}>
        <span>
          {" "}
          <span className={styles.bold}>{profile.address}</span>{" "}
        </span>

        <span>
          {" "}
          {profile.phone ? "|" : ""}{" "}
          <span className={styles.bold}>{profile.phone}</span>{" "}
        </span>
        <span>
          {profile.email ? "|" : ""}{" "}
          <span className={styles.bold}>{profile.email}</span>{" "}
        </span>
        <span>
          {" "}
          {profile.linkedinUsername ? "|" : ""}{" "}
          <span className={styles.bold}>{profile.linkedinUsername}</span>{" "}
        </span>
        <span>
          {" "}
          {profile.githubUsername ? "|" : ""}{" "}
          <span className={styles.bold}>{profile.githubUsername}</span>{" "}
        </span>
      </div>
    </div>
  );
};

const ProfessionalTitle = ({ Profile }) => {
  return <div className={styles.professionalTitle}>{Profile.position} {Profile.tags ? `| ${Profile.tags}` : ""}</div>;
};

const About = ({ Professionalsummary }) => {
  if (Professionalsummary.summary === "<p></p>") {
    return null;
  }

  return (
    <div className={styles.aboutContainer}>
      {sectionTitle("Professional Summary")}
      <div className={styles.secondline}>
        <span
          className={styles.resumesampletextabout}
          dangerouslySetInnerHTML={{ __html: Professionalsummary?.summary }}
        />
      </div>
    </div>
  );
};

const Points = ({ points, years }) => {
  if (!points) return null;
  return (
    <ul className={styles.points}>
      {points?.map((point, index) => (
        <li key={index} className={styles.li}>
          <div className={styles.eeor}>
            <div
              key={index}
              className={styles.pointsMarkdown}
              dangerouslySetInnerHTML={{
                __html: getSafeMarkdownString(point),
              }}
            />
            {years && <i>{years[index]}</i>}
          </div>
        </li>
      ))}
    </ul>
  );
};

const Experience = ({ experience , name}) => {
  if (!experience || experience.length === 0) return null; 
  if(experience.activeTab === 0 || experience.activeTab === undefined || experience.activeTab === null){
    return (  
      <div>
        {sectionTitle(name || "PROFESSIONAL EXPERIENCE")}
       {experience.simpleList?.map((exp, index) => (
      <div key={index} className={styles.experienceContainer}>
      <div className={styles.her}>
        <div className={styles.resumesampleh2}>
          {exp.role}
          <span className={styles.removefontwidth}>
            {" "}
            {exp.company ? `, ${exp.company}` : ""}
            {exp.location ? `, ${exp.location}` : ""}
            {exp.timePeriod ? `| ${exp.timePeriod}` : ""}{" "}
          </span>{" "}
        </div>
      </div>
      <div className={styles.pointsContainer}>
        <Points points={exp.points} markdown={true} secondary={true} />
      </div>
    </div>
    ))}
      </div>
    )
   }
    if(experience.activeTab === 1){
    return (  
      <div>
        {sectionTitle(name || "PROFESSIONAL EXPERIENCE")}
        {experience.responsibilitiesAndAccomplishments?.map((exp, index) => (
      <div key={index}>
        <div className={styles.her}>
        <div className={styles.resumesampleh2}>
          {exp.role}
          <span className={styles.removefontwidth}>
            {" "}
            {exp.company ? `, ${exp.company}` : ""}
            {exp.location ? `, ${exp.location}` : ""}
            {exp.timePeriod ? `| ${exp.timePeriod}` : ""}{" "}
          </span>{" "}
        </div>
      </div>
        {/* for responsibilities */}
        <div> 
          <h1 className={styles.responsibilities}>Responsibilities</h1>
          <Points points={exp.responsibilities} markdown={true} secondary={true} />
        </div>
        {/* for accomplishments */}
        <div>
          <h1 className={styles.accomplishments}>Accomplishments</h1>
          <Points points={exp.accomplishments} markdown={true} secondary={true} />
        </div>
      </div>
    ))}
      </div>
    )
   }
   if(experience.activeTab === 2){
    return (  
      <div>
        {sectionTitle(name || "PROFESSIONAL EXPERIENCE")}
 
        {experience.problemSolutionImapact?.map((exp, index) => (
      <div key={index} className={styles.problemSolutionImapactContainer}>
       <div className={styles.her}>
        <div className={styles.resumesampleh2}>
          {exp.role}
          <span className={styles.removefontwidth}>
            {" "}
            {exp.company ? `, ${exp.company}` : ""}
            {exp.location ? `, ${exp.location}` : ""}
            {exp.timePeriod ? `| ${exp.timePeriod}` : ""}{" "}
          </span>{" "}
        </div>
      </div>
        {/* for problem */}
        <div> 
          <span className={styles.problem}>Problem:</span>
          {exp.problem}
          <br/>
          <span className={styles.solution}>Solution:</span>
          {exp.solution}
          <br/>
          <span className={styles.impact}>Impact:</span>
          {exp.impact}
        </div>

         
      </div>
    ))}  
      
      </div>
    )
   }
  if(experience.activeTab === 3){
    return (  
      <div>
        {sectionTitle(name || "PROFESSIONAL EXPERIENCE")}
     
        {experience.detailedGroups?.map((exp, index) => (
      <div className={styles.problemSolutionImapactContainer} key={index}>
         <div className={styles.her}>
        <div className={styles.resumesampleh2}>
          {exp.role}
          <span className={styles.removefontwidth}>
            {" "}
            {exp.company ? `, ${exp.company}` : ""}
            {exp.location ? `, ${exp.location}` : ""}
            {exp.timePeriod ? `| ${exp.timePeriod}` : ""}{" "}
          </span>{" "}
        </div>
      </div>
        {/* for p roblem */} 
        {exp.groups.map((group, index) => (
          <div key={index}> 
                <span className={styles.problem}> {group.groupName}:</span>
                {group.description}
          </div>   
        ))}

         
      </div>
    ))}
      </div>
    )
   }
};

const Projects = ({ projects }) => {
  if (!projects || projects.length === 0) return null;
  return (
    <div>
      {sectionTitle("PROJECTS")}
      {projects?.map((project, index) => (
        <div className={index > 0 ? styles.bordertop : ""} key={index}>
          <div className={styles.her}>
            <div className={styles.resumesampleh2}>
              {project.title}
              <span className={styles.removefontwidth}>
                {" "}
                {project.organisation ? `, ${project.organisation}` : ""}
                {project.year ? ` | ${project.year}` : ""}{" "}
              </span>{" "}
            </div>
          </div>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: project.description }}
          />
          <div className={styles.pointsContainer}>
            <Points points={project.points} markdown={true} secondary={true} />
          </div>
        </div>
      ))}
    </div>
  );
};

const Education = ({ education }) => {
  if (!education || education.length === 0) return null;
  return (
    <div>
      {sectionTitle("EDUCATION")}
      {education.map((edu, index) => (
        <span key={index} className={styles.doro}>
          <div className={styles.edud}>
            <span className={styles.resumesampleh2}>{edu.college}</span>
            <span>{edu.year}</span>
          </div>
          <div className={styles.edud}>
            <span>
              {" "}
              {edu.degree} {edu.branch ? `in ${edu.branch} ` : " "}{" "}
            </span>
            <span>
              {edu.cgpa
                ? `${edu.cgpa}`
                : "" || edu.percentage
                ? `${edu.percentage}%`
                : ""}
            </span>
          </div>
        </span>
      ))}
    </div>
  );
};

const TechnicalSkills = ({ technicalSkills , name }) => {
  if (!technicalSkills || technicalSkills.length === 0) return null;
  return (
    <div className={styles.technicalSkillsContainer}>
      {sectionTitle(name || "AREAS OF EXPERTISE ")}

      <div className={styles.skillsContainer}>

      {technicalSkills.activeTab === 0 && (
        <div className={styles.skillsGrid}>
          {technicalSkills.simpleList.map((skill, index) => (
            <div key={index} className={styles.skillItem}>
              <Check size={16} className={styles.tickIcon} />
              <span>{skill}</span>
            </div>
          ))}
        </div>
      )}
      
      {technicalSkills.activeTab === 1 && 
        technicalSkills.twoColumn.map((tech, index) => (
          <div key={index}>
            <strong>{tech.name}</strong> {tech.name ? ":" : ""}  
            <span>{tech.skills?.join(",")}</span>
          </div>
        ))
      }
      
      {technicalSkills.activeTab === 2 && 
        technicalSkills.detailed.map((tech, index) => (
          <div key={index}>
            <strong>{tech.name}</strong> {tech.name ? ":" : ""}  
            <span>{tech.skills?.join(",")}</span>
          </div>
        ))
      }

        
      </div>
    </div>
  );
};

const Awards = ({ awards }) => {
  if (!awards || awards.length === 0) return null; 
  console.log("tawards",awards);
  return (
    <div className={styles.awardContainer}>
      {sectionTitle("Certifications")}
      <ul
        className={`${styles.points} ${styles.noBullets} ${styles.Maindesic}`}
        style={{ marginTop: "1rem" }}
      >
        {awards.map((award, index) => (
           <div key={index}>
              <strong>{award.name}</strong>
              {award.IssuedBy ? `, ${award.IssuedBy}` : ""}
              {award.issuedOn ? `, Issued ${award.issuedOn}` : ""}
           </div>
        ))}
      </ul>
    </div>
  );
};

// const CarrerHighlight = ({ carrerHighlight }) => {
//   if (!carrerHighlight || carrerHighlight.length === 0) return null;
//   return (
//     <div className={styles.awardContainer}>
//       {sectionTitle("Career Highlights")}
//       <ul
//         className={`${styles.points} ${styles.noBullets} ${styles.Maindesic}`}
//         style={{ marginTop: "1rem" }}
//       >
//         {carrerHighlight.map((carrerHighlight, index) => (
//           <li key={index} className={styles.awardsli}>
//             <div className={styles.awardpoint}>
//               <strong>
//                 {carrerHighlight.name ? `${carrerHighlight.name} : ` : ""}
//               </strong>
//               <span
//                 className={styles.awarddescription}
//                 dangerouslySetInnerHTML={{
//                   __html: carrerHighlight.description,
//                 }}
//               />
//             </div>
//           </li>
//         ))}
//       </ul>
//     </div>
//   );
// };

const Achievements = ({ achivements , name }) => {
  if (!achivements || achivements.length === 0) return null;
  return (
    <div className={styles.awardContainer}>
      {sectionTitle(name || "ACHIVEMENTS")}
      {achivements?.map((achivement, index) => (
        <div className={index > 0 ? styles.bordertop : ""} key={index}>
          <div className={styles.her}>
             
          </div>
          <div>
            <Points points={achivement.points} markdown={true} secondary={true} />
          </div>
        </div>
      ))}
    </div>
  );
};

const CareerHighlight = ({ careerHighlight , name }) => {
  if (!careerHighlight || careerHighlight.length === 0) return null;
  return (
    <div className={styles.awardContainer}>
      {sectionTitle(name || "CAREER HIGHLIGHTS")}
      {careerHighlight?.map((careerHighlight, index) => (
        <div className={index > 0 ? styles.bordertop : ""} key={index}>
          <div className={styles.her}>
             
          </div>
          <div>
            <Points points={careerHighlight.points} markdown={true} secondary={true} />
          </div>
        </div>
      ))}
    </div>
  );
};

const Membership = ({ membership , name }) => {
  if (!membership || membership.length === 0) return null;
  return (
    <div className={styles.awardContainer}>
      {sectionTitle(name || "BOARD MEMBERS")}
      {membership?.map((membership, index) => (
        <div className={index > 0 ? styles.bordertop : ""} key={index}>
          <div className={styles.her}>
             
          </div>
          <div>
            <Points points={membership.points} markdown={true} secondary={true} />
          </div>
        </div>
      ))}
    </div>
  );
};

const KeyStrength = ({ keyStrength , name }) => {
  if (!keyStrength || keyStrength.length === 0) return null;
  return (
    <div className={styles.awardContainer}>
      {sectionTitle(name || "KEY STRENGTHS")}
      {keyStrength?.map((keyStrength, index) => (
        <div className={index > 0 ? styles.bordertop : ""} key={index}>
          <div className={styles.her}>
             
          </div>
          <div>
            <Points points={keyStrength.points} markdown={true} secondary={true} />
          </div>
        </div>
      ))}
    </div>
  );
};

const Optional = ({ optional }) => {
  if (!optional || optional.length === 0) return null;
  return (
    <div>
      {optional.map((item, index) => (
        <>
          {sectionTitle(item.name)}

          <div className={styles.pointsContainer}>
            <Points points={item.points} markdown={true} secondary={true} />
          </div>
        </>
      ))}
    </div>
  );
};

export default LLPResume;
