import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence, useMotionValue, useTransform } from 'framer-motion';
import styles from '../../assets/Dashboard/ChooseTemplate.module.css';
import { useResume } from "../../ResumeBuilder/builder/components/context/Resume";
import { useJobs } from "../../ResumeBuilder/builder/components/context/Jobs";
import { useNavigate } from "react-router-dom";
import { Eye,  Sparkles, Clock, Zap, Star, User, CheckCircle, FileText, Settings, Layout, Brain, Cpu, Layers, Linkedin } from 'lucide-react';
import { template1 } from "../../ResumeBuilder/utils/DifferentJson";
import MyResponsiveNavbar from '../../pages/Navbar';
import { privateApi, publicApi } from '../../ResumeBuilder/utils/aixos';
import PreviewImage from '../../ResumeBuilder/builder/components/Preview/PreviewImage';
import { useAuth } from '../../ResumeBuilder/builder/components/context/auth';
import RightTop from '../../ResumeBuilder/builder/RightTop';

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.12,
      delayChildren: 0.3
    }
  }
};

const headerVariants = {
  hidden: { 
    opacity: 0,
    y: -50
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      stiffness: 100,
      damping: 20
    }
  }
};

const cardVariants = {
  hidden: { 
    opacity: 0,
    y: 50,
    scale: 0.95
  },
  visible: {
    opacity: 1,
    y: 0,
    scale: 1,
    transition: {
      type: "spring",
      stiffness: 100,
      damping: 20,
      duration: 0.6
    }
  },
  hover: {
    y: -12,
    transition: {
      type: "spring",
      stiffness: 400,
      damping: 25
    }
  },
  tap: {
    scale: 0.98,
    transition: {
      type: "spring",
      stiffness: 400,
      damping: 25
    }
  }
};

// Random data for more engaging notifications
const randomUsers = [
  { name: "Rohan", location: "Mumbai" },
  { name: "Priya", location: "Delhi" },
  { name: "Amit", location: "Bangalore" },
  { name: "Sarah", location: "Pune" },
  { name: "Raj", location: "Chennai" },
  { name: "Neha", location: "Hyderabad" },
  { name: "Arjun", location: "Kolkata" },
  { name: "Zara", location: "Ahmedabad" }
];

const randomActions = [
  "selected",
  "just downloaded",
  "customized",
  "choose",
  "picked"
];

const randomTimes = ["just now", "2 mins ago", "5 mins ago", "moments ago"]; 

const ChooseTemplate = () => {
  const [hoveredTemplate, setHoveredTemplate] = useState(null); 
  const [selectedExperience, setSelectedExperience] = useState(null);
  const [selectedSector, setSelectedSector] = useState(null);
  const [notification, setNotification] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingStep, setLoadingStep] = useState(0); 
  const [selectedDomain, setSelectedDomain] = useState(null);
  const { templates, handleTemplateChange, getInitialConfigResume, resetResume ,setValues} = useResume();
  const { setPreviewImage , setYOEUniversal} = useJobs();
  const [allresume, setallresume] = useState([]);
  const navigate = useNavigate();
 const {auth} = useAuth();
  // Move URL parameter handling into useEffect
  useEffect(() => {
    const domain = new URLSearchParams(window.location.search).get('domain');
    const sector = new URLSearchParams(window.location.search).get('sector'); 
    const YOE = new URLSearchParams(window.location.search).get('YOE');
    setSelectedExperience(YOE);
    setSelectedSector(sector);
    setSelectedDomain(domain); 
    showresumefrombackend(domain, YOE); 
    setYOEUniversal(YOE);
  }, []); // Run once on component mount

  useEffect(() => {
    let notificationTimeout;
    let intervalId;

    const showRandomNotification = () => {
      const randomUser = randomUsers[Math.floor(Math.random() * randomUsers.length)];
      const randomTemplate = Object.values(templates)[Math.floor(Math.random() * Object.values(templates).length)];
      const randomAction = randomActions[Math.floor(Math.random() * randomActions.length)];
      const randomTime = randomTimes[Math.floor(Math.random() * randomTimes.length)];

      setNotification({
        user: randomUser.name,
        location: randomUser.location,
        template: randomTemplate?.name,
        action: randomAction,
        time: randomTime
      });

      // Hide notification after 4 seconds
      notificationTimeout = setTimeout(() => {
        setNotification(null);
      }, 4000);
    };

    // Initial delay before first notification
    const initialDelay = setTimeout(() => {
      showRandomNotification();
      
      // Show notifications at random intervals between 15-25 seconds
      intervalId = setInterval(() => {
        const randomInterval = Math.floor(Math.random() * 10000) + 15000; // 15-25 seconds
        setTimeout(showRandomNotification, randomInterval);
      }, 25000);
    }, 1000);

    return () => {
      clearTimeout(initialDelay);
      clearTimeout(notificationTimeout);
      clearInterval(intervalId);
    };
  }, [templates]);

  const insertResumeToDatabase = async (image, name , templateKey) => { 
    try { 
      const domain = new URLSearchParams(window.location.search).get('domain');
      const sector = new URLSearchParams(window.location.search).get('sector'); 
      const YOE = new URLSearchParams(window.location.search).get('YOE');
      
      const freshConfig = getInitialConfigResume();


       // Update profile data with user info and domain
        freshConfig.sections.profile.data = {
          name: auth?.user?.[0]?.firstname + " " + auth?.user?.[0]?.lastname || "",
          position: selectedDomain || "",
          email: auth?.user?.[0]?.email || "",
          phone: "",
          location: "",
          linkedinUsername: "linkedin.com/" + auth?.user?.[0]?.firstname || "",
        };

      const  {data} = await privateApi.post("/api/resume/createresume", {
        resume_values: freshConfig,
        template_key: templateKey,
        template_name: name,
        template_image: image,
        domain: domain,
        sector: sector,
        YOE: YOE
      });
      const insertId = data?.resumeId;
      resetResume();
      return insertId;
    } catch (error) {
      console.error("Error inserting resume to database:", error);
      throw error;
    }
  };


  const insertResumeToDatabaseWithValues = async (image, name , templateKey , resumeValues) => { 
    try { 
      const domain = new URLSearchParams(window.location.search).get('domain');
      const sector = new URLSearchParams(window.location.search).get('sector'); 
      const YOE = new URLSearchParams(window.location.search).get('YOE');
      const  {data} = await privateApi.post("/api/resume/createresume", {
        resume_values: resumeValues,
        template_key: templateKey,
        template_name: name,
        template_image: image,
        domain: domain,
        sector: sector,
        YOE: YOE
      });
      const insertId = data?.resumeId;
      resetResume();
      return insertId;
    } catch (error) {
      console.error("Error inserting resume to database:", error);
      throw error;
    }
  };

  const loadingSteps = [
    {
      text: "AI Analyzing Template",
      subText: "Optimizing layout and structure",
      icon: <Brain className={styles.stepIcon} />,
    },
    {
      text: "Processing Resume Sections",
      subText: "Preparing professional components",
      icon: <Layers className={styles.stepIcon} />,
    },
    {
      text: "Initializing Smart Editor",
      subText: "Setting up AI-powered features",
      icon: <Cpu className={styles.stepIcon} />,
    }
  ];

  const navigateToEditor = async (templateKey, preview, name) => {
    try {
      setIsLoading(true);
      await handleTemplateChange(templateKey);
      await getInitialConfigResume();
      resetResume();
      setPreviewImage(preview);
      setYOEUniversal(selectedExperience);
      for (let i = 0; i < loadingSteps.length; i++) {
        setLoadingStep(i);
        await new Promise(resolve => setTimeout(resolve, 1000));
      } 
      
      const insertId = await insertResumeToDatabase(preview, name, templateKey); 
      
      // Build URL with proper query parameters 
      
      const queryParams = new URLSearchParams({
        insert_id: insertId,
        jobsector: selectedSector || '',
        domins: selectedDomain || '',
        YOE: selectedExperience || '',
        key: templateKey
      }).toString();

      setTimeout(() => {
        setIsLoading(false);
        navigate(`/editor?${queryParams}`);
      }, 500);

    } catch (error) {
      console.error("Error:", error);
      setIsLoading(false);
    }
  };

  

  const getTemplateLevel = (template) => {
    if (template.experience.min >= 5) return 'Advanced';
    if (template.experience.min >= 2) return 'Intermediate';
    return 'Entry Level';
  };

  const showresumefrombackend = async () => {
    try { 
      const domain = new URLSearchParams(window.location.search).get('domain');
      const YOE = new URLSearchParams(window.location.search).get('YOE');
      const sector = new URLSearchParams(window.location.search).get('sector'); 
      const { data } = await publicApi.get(`/api/admin/getResumeExamplesbydomanandexp?domain=${domain}&exp=${YOE}&sector=${sector} ` ); 
      console.log(data.data);
      if (data?.success) {
        await setallresume(data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const chekcTemplate = (type) => {
    const template = Object.values(templates).find(
      (template) => template.key === type
    );
    // console.log(template);
    return template;
  };

  const jsonConversion = (json) => {
    
    if (json == null) {
      return template1;
    } 
    let jsonData = JSON.parse(json);
    let name = auth.user[0].firstname + " " + auth.user[0].lastname;  
    let email = auth.user[0].email; 
     name = name.substring(0, 10);
    let linkedin = "linkedin.com/" + auth.user[0].firstname 
    if (jsonData.sections && jsonData.sections.profile && jsonData.sections.profile.data) {
      jsonData.sections.profile.data.name = name;
      jsonData.sections.profile.data.email = email;
      jsonData.sections.profile.data.linkedinUsername = linkedin;
    }
    return jsonData;
  };


  const navigateToEditorWithData = async (templateKey, preview, name , resumeValues) => {
    try { 
      setIsLoading(true);
      await handleTemplateChange(templateKey); 
       
      setValues(jsonConversion(resumeValues));
      await setPreviewImage(preview);
    
      for (let i = 0; i < loadingSteps.length; i++) {
        setLoadingStep(i);
        await new Promise(resolve => setTimeout(resolve, 1000));
      } 

      const insertId = await insertResumeToDatabaseWithValues(preview, name, templateKey , jsonConversion(resumeValues));  

      const queryParams = new URLSearchParams({
        insert_id: insertId,
        jobsector: selectedSector || '',
        domins: selectedDomain || '',
        YOE: selectedExperience || '',
        key: templateKey
      }).toString();

      setTimeout(() => {
        setIsLoading(false);
        navigate(`/editor?${queryParams}`);
      }, 500);


    } catch (error) {
      console.error("Error:", error);
      setIsLoading(false);
    }
  };

  const [activeTabStates, setActiveTabStates] = useState({});
  
  // Set default state for all templates when component mounts
  useEffect(() => {
    const defaultStates = allresume.reduce((acc, _, index) => {
      acc[index] = 'original';
      return acc;
    }, {});
    setActiveTabStates(defaultStates);
  }, [allresume]);

  // Function to toggle tab for a specific template
  const toggleTab = (e, index, tabType) => {
    e.stopPropagation();
    setActiveTabStates(prev => ({
      ...prev,
      [index]: tabType
    }));
  };

  // Add state for active tab
  const [activeTab, setActiveTab] = useState('templates');

  return ( 
    <div> 
        <div className={styles.navbarMobile}> <MyResponsiveNavbar/> </div> 
        <RightTop auth={auth}  activeNav={"Resume"}   />
    <motion.div 
      className={styles.container}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className={styles.backgroundDecoration}>
        <motion.div 
          className={`${styles.floatingCircle} ${styles.circle1}`}
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 0.1, scale: 1 }}
          transition={{ duration: 1 }}
        />
        <motion.div 
          className={`${styles.floatingCircle} ${styles.circle2}`}
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 0.1, scale: 1 }}
          transition={{ duration: 1, delay: 0.2 }}
        />
        <motion.div 
          className={`${styles.floatingCircle} ${styles.circle3}`}
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 0.1, scale: 1 }}
          transition={{ duration: 1, delay: 0.4 }}
        />
        <motion.div 
          className={`${styles.dots} ${styles.dots1}`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.1 }}
          transition={{ duration: 1, delay: 0.6 }}
        />
        <motion.div 
          className={`${styles.dots} ${styles.dots2}`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.1 }}
          transition={{ duration: 1, delay: 0.8 }}
        />
      </div>

      <motion.div
        className={styles.header}
        variants={headerVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div 
          className={styles.headerContent}
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          <motion.h1
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.3, duration: 0.5 }}
          >
            {activeTab === 'templates' 
              ? "Let's pick out a template for you!"
              : "Explore Example Resumes"
            }
          </motion.h1>
          <motion.p
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.4, duration: 0.5 }}
          >
            {activeTab === 'templates'
              ? "Choose from our professionally designed templates to kickstart your career journey"
              : "Get inspired by real-world resume examples tailored to your industry"
            }
          </motion.p>
          <motion.div 
            className={styles.headerDecoration}
            animate={{ 
              rotate: [0, 360],
              scale: [1, 1.1, 1]
            }}
            transition={{ 
              duration: 20,
              repeat: Infinity,
              ease: "linear"
            }}
          />
        </motion.div>
      </motion.div>

      {/* Add Tab Navigation */}
      <motion.div 
        className={styles.mainTabNavigation}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 }}
      >
        <motion.button
          className={`${styles.mainTabBtn} ${activeTab === 'templates' ? styles.mainTabActive : ''}`}
          onClick={() => setActiveTab('templates')}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
        >
          <Layout size={18} />
          <span>Templates</span>
          {activeTab === 'templates' && (
            <motion.div 
              className={styles.mainTabIndicator} 
              layoutId="mainTabIndicator"
            />
          )}
        </motion.button>

        <motion.button
          className={`${styles.mainTabBtn} ${activeTab === 'examples' ? styles.mainTabActive : ''}`}
          onClick={() => setActiveTab('examples')}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
        >
          <FileText size={18} />
          <span> Resumes</span>
          <motion.span 
            className={styles.newBadge}
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{
              type: "spring",
              stiffness: 500,
              damping: 25
            }}
          >
            NEW
          </motion.span>
          {activeTab === 'examples' && (
            <motion.div 
              className={styles.mainTabIndicator} 
              layoutId="mainTabIndicator"
            />
          )}
        </motion.button>
      </motion.div>

      <AnimatePresence>
        {notification && (
          <motion.div
            className={styles.notification}
            initial={{ x: 100, opacity: 0, scale: 0.8 }}
            animate={{ x: 0, opacity: 1, scale: 1 }}
            exit={{ x: 100, opacity: 0, scale: 0.8 }}
            transition={{ 
              type: "spring",
              stiffness: 200,
              damping: 20
            }}
          >
            <div className={styles.notificationIcon}>
              <User size={18} className={styles.userIcon} />
              <div className={styles.activeIndicator} />
            </div>
            
            <div className={styles.notificationContent}>
              <div className={styles.notificationHeader}>
                <span className={styles.userName}>{notification.user}</span>
                <span className={styles.userLocation}>
                  from {notification.location}
                </span>
              </div>
              
              <div className={styles.notificationMessage}>
                <span>{notification.action} </span>
                <span className={styles.templateNameNoti}>
                  {notification.template}
                </span>
              </div>
              
              <div className={styles.notificationFooter}>
                <span className={styles.timeAgo}>
                  <Clock size={12} />
                  {notification.time}
                </span>
                <CheckCircle size={14} className={styles.checkIcon} />
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {isLoading && (
          <motion.div 
            className={styles.loadingModal}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div 
              className={styles.loadingContent}
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ type: "spring", duration: 0.5 }}
            >
              <div className={styles.loadingIcon}>
                <div className={`${styles.loadingCircle} ${styles.outerCircle}`}></div>
                <div className={`${styles.loadingCircle} ${styles.middleCircle}`}></div>
                <div className={`${styles.loadingCircle} ${styles.innerCircle}`}></div>
                <Brain className={styles.aiIcon} size={32} />
              </div>
              
              <h3 className={styles.loadingTitle}>AI Resume Builder</h3>
              <p className={styles.loadingSteps}>
                Creating your professional resume with AI
              </p>
              
              <div className={styles.loadingProgress}>
                <motion.div 
                  className={styles.progressBar}
                  initial={{ width: "0%" }}
                  animate={{ width: `${(loadingStep / 3) * 100}%` }}
                />
              </div>
              
              <div className={styles.loadingStep}>
                {loadingStep === 0 && "Analyzing your profile..."}
                {loadingStep === 1 && "Optimizing template layout..."}
                {loadingStep === 2 && "Applying professional styling..."}
                {loadingStep === 3 && "Finalizing your resume..."}
              </div>

              <div className={styles.loadingDots}>
                <div className={styles.dot}></div>
                <div className={styles.dot}></div>
                <div className={styles.dot}></div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Conditional Content Rendering */}
      <AnimatePresence mode="wait">
        {activeTab === 'templates' ? (
          <TemplateVarient 
            templates={templates} 
            selectedDomain={selectedDomain}
            selectedSector={selectedSector}
            selectedExperience={selectedExperience}
            setHoveredTemplate={setHoveredTemplate}
            navigateToEditor={navigateToEditor}
            hoveredTemplate={hoveredTemplate}
            getTemplateLevel={getTemplateLevel}
          />
        ) : (
          <TemplateExample 
            chekcTemplate={chekcTemplate}
            allresume={allresume}
            activeTabStates={activeTabStates}
            toggleTab={toggleTab}
            selectedDomain={selectedDomain}
            selectedSector={selectedSector}
            selectedExperience={selectedExperience}
            jsonConversion={jsonConversion}
            navigateToEditorWithData={navigateToEditorWithData}
          />
        )}
      </AnimatePresence>
   
    </motion.div>
 

    </div>
  );
};


const TemplateVarient = ({templates, selectedDomain, selectedSector, selectedExperience, setHoveredTemplate, navigateToEditor, hoveredTemplate, getTemplateLevel }) => {
  return (
    <motion.div
    className={styles.templatesGrid}
    variants={containerVariants}
    initial="hidden"
    animate="visible"
  >
    {Object.values(templates)
      .map((template) => {
        // Convert experience string to numeric value
        const getExperienceYears = (expString) => {
          switch(expString) {
            case "No Experience":
              return 0;
            case "Less Than 3 Years":
              return 1;
            case "3-5 Years":
              return 3;
            case "5-10 Years":
              return 5;
            case "10+ Years":
              return 10;
            default:
              return 0;
          }
        };

        const experienceYears = getExperienceYears(selectedExperience);
        
        // Match exact sector names from Resume.jsx
        const normalizedSelectedSector = selectedSector?.trim();
        const isRecommended = template.sector.some(sector => 
          sector === normalizedSelectedSector
        ) && 
        experienceYears >= template.experience.min &&
        (!template.experience.max || experienceYears <= template.experience.max);

       

        return {
          ...template,
          isRecommended
        };
      })
      .sort((a, b) => (b.isRecommended ? 1 : 0) - (a.isRecommended ? 1 : 0))
     
      .map((template, index) => (
        <motion.div
          key={template.key}
          variants={cardVariants}
          whileHover="hover"
          whileTap="tap"
          onHoverStart={() => setHoveredTemplate(template.key)}
          onHoverEnd={() => setHoveredTemplate(null)}
          className={`${styles.templateCard} ${template.isRecommended ? styles.recommended : ''}`}
          onClick={() => navigateToEditor(template.key, template.preview, template.name)}
          style={{ cursor: 'pointer' }}
        >
          {template.isRecommended && (
            <motion.div 
              className={styles.recommendedBadge}
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ 
                type: "spring",
                stiffness: 200,
                damping: 20,
                delay: 0.3 
              }}
            >
              <div className={styles.recommendedContent}>
                <Star size={14} className={styles.starIcon} />
                <span>Recommended</span>
              </div>
              <motion.div 
                className={styles.recommendedGlow}
                animate={{ 
                  opacity: [0.5, 1, 0.5],
                  scale: [1, 1.2, 1]
                }}
                transition={{
                  duration: 2,
                  repeat: Infinity,
                  ease: "easeInOut"
                }}
              />
            </motion.div>
          )}

          <motion.div 
            className={styles.imageContainer}
            whileHover={{ scale: 1.02 }}
            transition={{ duration: 0.3 }}
          >
            <motion.img 
              src={template.preview} 
              alt={template.name}
              initial={{ scale: 1 }}
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.4 }}
            />
            <AnimatePresence>
              {hoveredTemplate === template.key && (
                <motion.div 
                  className={styles.imageOverlay}
                  initial={{ opacity: 0 }}
                  animate={{ 
                    opacity: 1,
                    transition: { duration: 0.2 }
                  }}
                  exit={{ opacity: 0 }}
                >
                  <motion.button 
                    className={styles.previewButton}
                    initial={{ y: 20, opacity: 0 }}
                    animate={{ 
                      y: 0, 
                      opacity: 1,
                      transition: { delay: 0.1 }
                    }}
                    whileHover={{ 
                      scale: 1.05,
                      y: -2,
                      transition: { type: "spring", stiffness: 400 }
                    }}
                    whileTap={{ scale: 0.95 }}
                  >
                    <Eye size={18} />
                    Use Template
                    <motion.span
                      className={styles.buttonIcon}
                      whileHover={{ rotate: -12, scale: 1.2 }}
                    >
                      <Zap size={16} />
                    </motion.span>
                  </motion.button>
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>

          <motion.div 
            className={styles.templateDetails}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
          >
            <h3 className={styles.templateName}>{template.name}</h3>
            
            <div className={styles.templateInfo}>
              <motion.div 
                className={styles.templateTag}
                whileHover={{ scale: 1.05 }}
              >
                <Sparkles size={14} />
                {getTemplateLevel(template)}
              </motion.div>
              <motion.div 
                className={styles.templateTag}
                whileHover={{ scale: 1.05 }}
              >
                <Clock size={14} />
                {template.experience.min}+ years
              </motion.div>
            </div>
          </motion.div>
        </motion.div>
      ))}
  </motion.div>
  );
}
 
const TemplateExample = ({ allresume, activeTabStates, chekcTemplate, toggleTab, selectedDomain, selectedSector, selectedExperience, jsonConversion, navigateToEditorWithData, values }) => {
  // Check if there are no resumes to display
  if (!allresume || allresume.length === 0) {
    return (
      <motion.div 
        className={styles.noTemplatesContainer}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <motion.div 
          className={styles.noTemplatesContent}
          initial={{ scale: 0.9 }}
          animate={{ scale: 1 }}
          transition={{ delay: 0.2 }}
        >
          <motion.div 
            className={styles.noTemplatesIcon}
            animate={{ 
              rotate: [0, 10, -10, 0],
              transition: { 
                duration: 2,
                repeat: Infinity,
                ease: "easeInOut"
              }
            }}
          >
            <FileText size={48} />
          </motion.div>
          
          <h3>We're Preparing Your Examples</h3>
          <p>Our team is crafting perfect resume examples for your industry. Please check back soon!</p>
          
          <motion.div 
            className={styles.loadingDots}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4 }}
          >
            <div className={styles.dot}></div>
            <div className={styles.dot}></div>
            <div className={styles.dot}></div>
          </motion.div>
        </motion.div>
      </motion.div>
    );
  }

  // Existing return statement for when there are templates
  return (
    <motion.div
    className={styles.templatesGrid}
    variants={containerVariants}
    initial="hidden"
    animate="visible"
  >
    {allresume
      .map(item => {
        // Convert experience string to numeric value
        const getExperienceYears = (expString) => {
          switch(expString) {
            case "No Experience": return 0;
            case "Less Than 3 Years": return 1;
            case "3-5 Years": return 3;
            case "5-10 Years": return 5;
            case "10+ Years": return 10;
            default: return 0;
          }
        };

        const experienceYears = getExperienceYears(selectedExperience);
        
        // Check if template matches sector and experience
        const isRecommended = item.sector === selectedSector && 
          experienceYears >= (item.min_exp || 0) &&
          (!item.max_exp || experienceYears <= item.max_exp);

        return {
          ...item,
          isRecommended
        };
      })
      .sort((a, b) => (b.isRecommended ? 1 : 0) - (a.isRecommended ? 1 : 0))
      .map((item, index) => (
        <motion.div
          key={index}
          variants={cardVariants}
          whileHover="hover"
          whileTap="tap"
          className={`${styles.templateCard} ${item.isRecommended ? styles.recommended : ''}`}
          onClick={() => navigateToEditorWithData(item.template_key, item.image, item.domins_name, item.resumeValues)}
        >
          {item.isRecommended && (
            <motion.div 
              className={styles.recommendedBadge}
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ 
                type: "spring",
                stiffness: 200,
                damping: 20,
                delay: 0.3 
              }}
            >
              <div className={styles.recommendedContent}>
                <Star size={14} className={styles.starIcon} />
                <span>Recommended</span>
              </div>
              <motion.div 
                className={styles.recommendedGlow}
                animate={{ 
                  opacity: [0.5, 1, 0.5],
                  scale: [1, 1.2, 1]
                }}
                transition={{
                  duration: 2,
                  repeat: Infinity,
                  ease: "easeInOut"
                }}
              />
            </motion.div>
          )}

          <div className={styles.tabContainer}>
            <button 
              className={`${styles.tabButton} ${activeTabStates[index] === 'preview' ? styles.activeTab : ''}`}
              onClick={(e) => toggleTab(e, index, 'preview')}
            >
              Original
            </button>
            <button 
              className={`${styles.tabButton} ${activeTabStates[index] === 'original' ? styles.activeTab : ''}`}
              onClick={(e) => toggleTab(e, index, 'original')}
            >
              Preview
            </button>
          </div>

          <motion.div 
            className={styles.imageContainer}
            whileHover={{ scale: 1.02 }}
            transition={{ duration: 0.3 }}
          >
            {activeTabStates[index] === 'preview' ? (
              <img 
                src={item.image}
                alt={`Original Resume ${index + 1}`}
                className={styles.originalImage}
              />
            ) : (
              <PreviewImage
                template={chekcTemplate(item.template_key)}
                values={jsonConversion(item.resumeValues)}
              />
            )}
            
            <AnimatePresence>
              <motion.div 
                className={styles.imageOverlay}
                initial={{ opacity: 0 }}
                whileHover={{ opacity: 1 }}
                transition={{ duration: 0.2 }}
              >
                <motion.button 
                  className={styles.previewButton}
                  whileHover={{ 
                    scale: 1.05,
                    y: -2,
                    transition: { type: "spring", stiffness: 400 }
                  }}
                  whileTap={{ scale: 0.95 }}
                >
                  <Eye size={18} />
                  Use Template
                  <motion.span className={styles.buttonIcon}>
                    <Zap size={16} />
                  </motion.span>
                </motion.button>
              </motion.div>
            </AnimatePresence>
          </motion.div>

          <motion.div className={styles.templateDetails}>
            <h3 className={styles.templateName}>{item.domins_name}</h3>
            <div className={styles.templateInfo}>
              <motion.div 
                className={styles.templateTag}
                whileHover={{ scale: 1.05 }}
              >
                <Layout size={14} />
                {item.template_key}
              </motion.div>
              {item.domins_name && (
                <motion.div 
                  className={styles.templateTag}
                  whileHover={{ scale: 1.05 }}
                >
                  <Sparkles size={14} />
                  {item.domins_name}
                </motion.div>
              )}
            </div>
          </motion.div>
        </motion.div>
      ))}
  </motion.div>
  );
}

export default ChooseTemplate;