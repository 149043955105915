import React, { useEffect, useState } from "react";
import styles from "../../assets/Design.module.css";
import { useResume } from "./components/context/Resume";
import { ChevronDown, ListFilter, Plus, Search, Lock } from "lucide-react";
import axios from "axios";
import { useJobs } from "./components/context/Jobs";
import { motion } from "framer-motion";
import { publicApi } from "../utils/aixos";

const ColorArray = [
  {
    color: "#8061DD",
    border: "#D7CAFF",
  },
  {
    color: "#FF9822",
    border: "#FFDCBE",
  },
  {
    color: "#44ABFF",
    border: "#C6E5FF",
  },
  {
    color: "#4EB471",
    border: "#A8E0BB",
  },
  {
    color: "#5860FC",
    border: "#D2D4FF",
  },
  {
    color: "#E04132",
    border: "#FCBFB9",
  },
  {
    color: "#BA3BFF",
    border: "#E8BFFF",
  },
  {
    color: "#2F8CBB",
    border: "#B0D8ED",
  },
  {
    color: "#3F4860",
    border: "#B5BCD1",
  },
  {
    color: "#FBAD41",
    border: "#FFD9A5",
  },
  // {
  //   color: "#FF95C3",
  //   border: "#FFD0E4",
  // },
];

const options = [
  "All Domains",
  "IT",
  "Finance",
  "Accounts",
  "Marketing",
  "Sales & Development",
  "Business Management ",
  "Data Science and Analytics",
  "Design And Ux",
  "Human Resource ",
];

const truncateText = (text, wordLimit) => {
  if (text.length > wordLimit) {
    return text.slice(0, wordLimit) + "...";
  }
  return text;
};

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
};

const itemVariants = {
  hidden: { 
    opacity: 0, 
    y: 20 
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5
    }
  }
};

const overlayStyles = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: 'rgba(255, 255, 255, 0.8)',
  backdropFilter: 'blur(4px)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  zIndex: 10,
};

const Design = ({setPreviewLoading}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("All Domains");
  const { selectedTemplate, templates, handleTemplateChange } = useResume();
  const { setPreviewImage } = useJobs();
  const [allresume, setallresume] = useState([]);
  const [filterdResume, setFilterResume] = useState([]);
  const [searchedWord, setSearchWord] = useState("");
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const { payment } = useJobs();

  const showresumefrombackend = async () => {
    try {
      const { data } = await publicApi.get(`/api/admin/getallresume`);
      if (data?.success) {
        await setallresume(data?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (templateKey, image) => {
    setPreviewLoading(true);
    handleTemplateChange(templateKey);
    setPreviewImage(image);
    setTimeout(() => {
      setPreviewLoading(false);
    }, 1000);
  };

  const handleNavigate = (templateKey) => {
    setPreviewLoading(true);
    handleTemplateChange(templateKey);
    setPreviewImage("");
  };
  useEffect(() => {
    showresumefrombackend();
  }, []);

  const filterByField = (resumes, field) => {
    return resumes.filter((resume) => resume.field === field);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    const serFilterResume = filterByField(allresume, option);
    setFilterResume(serFilterResume);
    setIsOpen(false);
  };

  const handleInputChangeOfSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchWord(searchTerm);

    const filteredData = allresume.filter(
      (resume) =>
        resume.name.toLowerCase().includes(searchTerm) ||
        resume.field.toLowerCase().includes(searchTerm) ||
        resume.subFiledName.toLowerCase().includes(searchTerm)
    );

    setFilterResume(filteredData);
  };

  const templateColorContainer = () => {
    return (
      <div className={styles.ColorContainer}>
        <div className={styles.ColorContainerHeading}>Template Color</div>
        <div className={styles.ColorsBox}>
          {ColorArray.map((value, index) => {
            return (
              <div
                className={styles.eachCircle}
                key={index}
                style={{
                  backgroundColor: value.color,
                  borderColor: value.border,
                }}
              ></div>
            );
          })}
          <div className={styles.eachCirclePlus}>
            <Plus size={14} />
          </div>
        </div>
      </div>
    );
  };

  const searchContainer = () => {
    return (
      <div className={styles.SearchContainer}>
        <div className={styles.textInputSecarchConatiner}>
          <Search size={14} color={"#535D79"} />
          <input
            type="text"
            placeholder="Search..."
            className={styles.textInput}
            value={searchedWord}
            onChange={(e) => handleInputChangeOfSearch(e)}
          />
        </div>
        <div className={styles.dropdownSecarchMainConatiner}>
          <div className={styles.dropdownSecarchConatiner}>
            <div className={styles.dropdownToggle} onClick={toggleDropdown}>
              <ListFilter size={14} color={"#535D79"} />{" "}
              {truncateText(selectedOption, 14)}{" "}
              <span className={styles.chevronDownIcon}>
                <ChevronDown size={14} />
              </span>
            </div>
            {isOpen && (
              <div className={styles.dropdownMenu}>
                {options.map((option, index) => (
                  <div
                    key={index}
                    className={styles.dropdownItem}
                    onClick={() => handleOptionClick(option)}
                  >
                    {option}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const handleUpgrade = () => {
    console.log("Navigate to upgrade page");
  };

  return (
    <div className={styles.main}>
      <div className={styles.MobileHeading}>
        <div className={styles.headingTitle}>Templates</div>
      </div>

      {/* <div className={styles.searchContainerOnTopWhenMobile}>
      {searchContainer()}
       </div> */}

      {/* {templateColorContainer()} */}
    {/* <div className={styles.showSeachOnBellowinDesktop}>
       {searchContainer()}
    </div> */}
      
      <motion.div 
        className={styles.ResumeContainer}
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        {filterdResume.length <= 0 && Object.values(templates).map((template, index) => (
          <motion.div
            key={index}
            variants={itemVariants}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => handleNavigate(template.key)}
            className={`${styles.dooo} ${
              template.key === selectedTemplate.key
                ? styles.selectedTemplate
                : ""
            }`}
          >
            <span className={styles.templateName}>{template?.name}</span>
            <div className={styles.resumeImageContainer}>
              <img
                className={styles.resumeImage}
                src={template.preview}
                alt={template.name}
              />
            </div>
          </motion.div>
        ))}
      </motion.div>

      {/* <motion.div 
        className={styles.ResumeContainer}
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        {(filterdResume.length !== 0 ? filterdResume : allresume).map(
          (template, index) => (
            <motion.div
              key={index}
              variants={itemVariants}
              whileHover={{ 
                scale: payment?.paymentStatus === "success" || index < 4 ? 1.05 : 1 
              }}
              whileTap={{ 
                scale: payment?.paymentStatus === "success" || index < 4 ? 0.95 : 1 
              }}
              onClick={() => 
                payment?.paymentStatus === "success" || index < 4 
                  ? handleChange(template.type, template.images) 
                  : handleUpgrade()
              }
              className={`${styles.dooo} ${
                template.key === selectedTemplate.key
                  ? styles.selectedTemplate
                  : ""
              }`}
              style={{ position: 'relative' }}
            >
              <span className={styles.templateName}>
                {truncateText(template.subFiledName, 14)}
              </span>
              <div className={styles.resumeImageContainer}>
                <img
                  className={styles.resumeImage}
                  src={template.images}
                  alt={template.name}
                  style={{
                    filter: payment?.paymentStatus !== "success" && index >= 4 
                      ? "blur(2px)" 
                      : "none"
                  }}
                />
              </div>
              
              {payment?.paymentStatus !== "success" && index >= 4 && (
                <div style={overlayStyles}>
                  <Lock size={24} className={styles.lockIcon} />
                  <p style={{ 
                    marginTop: '8px',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    textAlign: 'center'
                  }}>
                    Upgrade to unlock
                  </p>
                </div>
              )}
            </motion.div>
          )
        )}
      </motion.div> */}
    </div>
  );
};

export default Design;
