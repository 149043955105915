import React, {useEffect } from "react";
import ReactDOMServer from "react-dom/server";

 
 
import IITEResume from "../templates/IITE/IITEResume";
import MITResume from "../templates/MIT/MITResume";
import LLOResume from "../templates/LLO/LLOResume";
import LLNResume from "../templates/LLN/LLNResume";
import LLPResume from "../templates/LLP/LLPResume";
import LLMResume from "../templates/LLM/LLMResume";
import MMLResume from "../templates/MML/MMLResume";
import LLQResume from "../templates/LLQ/LLQResume";
import LLRResume from "../templates/LLR/LLRResume";
import LLSResume from "../templates/LLS/LLSResume";
 


// Preview Image 
import IITEPreviewImage from "../../../assets/images/latest.png";
import MITPreviewImage from "../../../assets/images/NEW.png";
import tr7PreviewImage from "../../../assets/images/rt7.png";
import tr8PreviewImage from "../../../assets/images/rt8.png";
import tr9PreviewImage from "../../../assets/images/rt9.png";
import tr10PreviewImage from "../../../assets/images/rt10.png";
import LLPPreviewImage from "../../../assets/images/rt11.png";
import LLQPreviewImage from "../../../assets/images/rt12.png";
import LLRPreviewImage from "../../../assets/images/rt13.png";
import LLSPreviewImage from "../../../assets/images/rt14.png";
 



//styles
import MMLStyles from "../templates/MML/MMLResume.module.css";
import IITEStyles from "../templates/IITE/IITEResume.module.css";
import MITStyles from "../templates/MIT/MITResume.module.css";
import LLOResumeStyles from "../templates/LLO/LLOResume.module.css";
import LLNResumeStyles from "../templates/LLN/LLNResume.module.css";
import LLPResumeStyles from "../templates/LLP/LLPResume.module.css";
import LLMResumeStyles from "../templates/LLM/LLMResume.module.css";
import LLQResumeStyles from "../templates/LLQ/LLQResume.module.css";
import LLRResumeStyles from "../templates/LLR/LLRResume.module.css";
import LLSResumeStyles from "../templates/LLS/LLSResume.module.css";

// Add BuildResume import
import BuildResume from "../templates/config/BuildResume";
import { getResumeConfig } from "../templates/config/ConfigSection";

const ResumeContext = React.createContext();

export const useResume = () => React.useContext(ResumeContext);


const getSharedBuild = () => {
  const config = getResumeConfig();
  return () => <BuildResume config={config} />;
};


const getInitialConfigResume = () => ({
  name: "Untitled",
  sections: {
    profile: {
      name: "Profile",
      key: "sections.profile",
      data: {},
    },
    education: {
      name: "Education",
      key: "sections.education",
      data: [],
    },
    experience: {
      name: "Experience",
      key: "sections.experience",
      data: [],
    },
    internship: {
      name: "Internship",
      key: "sections.internship",
      data: [],
    },
    projects: {
      name: "Projects",
      key: "sections.projects",
      data: [],
    },
    technicalSkills: {
      name: "Technical Skills",
      key: "sections.technicalSkills",
      data: [],
    },
    Professionalsummary: {
      name: "Professional Summary",
      key: "sections.Professionalsummary",
      data: {},
    },
    awards: {
      name: "Awards",
      key: "sections.awards",
      data: [],
    },
    awards: {
      name: "Awards",
      key: "sections.awards",
      data: [],
    },
    achivements: {
      name: "Achivements",
      key: "sections.achivements",
      data: [],
    },
    careerHighlight: {
      name: "Career Highlights",
      key: "sections.careerHighlight",
      data: [],
    },
    membership: {
      name: "Membership",
      key: "sections.membership",
      data: [],
    },
    keyStrength: {
      name: "Key Strength",
      key: "sections.keyStrength",
      data: [],
    },
    optional: {
      name: "Optional",
      key: "sections.optional",
      data: [],
    },
  },
});

const ResumeProvider = ({ children }) => {
  const templates = {
    llp: {
      name: "Executive Standard",
      key: "llp", 
      build: getSharedBuild(),
      resume: LLPResume,
      styles: LLPResumeStyles,
      page_margins: "0.0in",
      preview: LLPPreviewImage,
      sector: ["Business Management", "Finance"],
      experience: {
        min: 5,
        max: null,
      },
    },
    
    iite: {
      name: "Fresher's Edge",
      key: "iite",
      build: getSharedBuild(),
      resume: IITEResume,
      styles: IITEStyles,
      page_margins: "0.5in",
      preview: IITEPreviewImage, 
      sector: ["IT", "Data Science and Analytics"],
      experience: {
        min: 0,
        max: 1,
      },

    },
   
    lln: {
      name: "Corporate Pro",
      key: "lln",
      build: getSharedBuild(),
      resume: LLNResume,
      styles: LLNResumeStyles,
      page_margins: "0.0in",
      preview: tr9PreviewImage,
      sector: ["Accounts", "Marketing"],
      experience: {
        min: 2,
        max: 5,
      },
    },
    mit: {
      name: "Detail-Oriented",
      key: "mit",
      build: getSharedBuild(),
      resume: MITResume,
      styles: MITStyles,
      page_margins: "0.5in",
      preview: MITPreviewImage,
      sector: ["Sales and Development", "Finance"],
      experience: {
        min: 3,
        max: 5,
      },
    },
    // from here make font size 13px headding 15 px
    llo: {
      name: "Bold Approach",
      key: "llo",
      build: getSharedBuild(),
      resume: LLOResume,
      styles: LLOResumeStyles,
      page_margins: "0.0in",
      preview: tr10PreviewImage,
      sector: ["Design and UX", "Marketing"],
      experience: {
        min: 2,
        max: 5,
      },
    },
    // lll: {
    //   name: "Skills First",
    //   key: "lll",
    //   build: LLLResumeBuild,
    //   resume: LLLResume,
    //   page_margins: "0.5in",
    //   preview: LLLPreviewImage, 
    //   sector: ["IT", "Data Science and Analytics"],
    //   experience: {
    //     min: 1,
    //     max: 3,
    //   },
    // },
    llm: {  // done sizing 
      name: "Classic Professional",
      key: "llm",
      build: getSharedBuild(),
      resume: LLMResume,
      styles: LLMResumeStyles,
      page_margins: "0.5in",
      preview: tr7PreviewImage,
      sector: ["Human Resource", "Business Management"],
      experience: {
        min: 5,
        max: null,
      },
    },
    mml: {
      name: "Balanced Generalist",
      key: "mml",
      build: getSharedBuild(),
      styles: MMLStyles,
      resume: MMLResume,
      page_margins: "0.0in",
      preview: tr8PreviewImage, 
      sector: ["Human Resource", "Business Management" , "Accounts" , "Marketing" , "Sales and Development" , "Data Science and Analytics" , "Design and UX" , "IT" , "Finance" ],
      experience: {
        min: 0,
        max: 5,
      },
    },
    llq: {
      name: "Professional All-Rounder",
      key: "llq",
      build: getSharedBuild(),
      resume: LLQResume,
      styles: LLQResumeStyles,
      page_margins: "0.0in",
      preview: LLQPreviewImage,
      sector: ["Human Resource", "Business Management"],
      experience: {
        min: 2,
        max: 5,
      },
    },
    llr: {
      name: "Account Specialist",
      key: "llr",
      build: getSharedBuild(),
      resume: LLRResume,
      styles: LLRResumeStyles,
      page_margins: "0.0in",
      preview: LLRPreviewImage, 
      sector: ["Accounts"],
      experience: {
        min: 2,
        max: 4,
      },
    },
    lls: {
      name: "Marketing Maven",
      key: "lls",
      build: getSharedBuild(),
      resume: LLSResume,
      styles: LLSResumeStyles,
      page_margins: "0.0in",
      preview: LLSPreviewImage,
      sector: ["Marketing"],
      experience: {
        min: 4,
        max: 6,
      },
    }, 
   
  };

  const [selectedTemplate, setSelectedTemplate] = React.useState(templates.lll);
  const [values, setValues] = React.useState(getInitialConfigResume());
  const [loading, setLoading] = React.useState(false);
  const [index, setIndex] = React.useState("");
  const [CurentSection, SetCurentSection] = React.useState("Personal Details");
  const [sectionsArray, setSectionsArray] = React.useState([]);
  const [nextSection, setNextSection] = React.useState("");
  const [prevSection, setPrevSection] = React.useState("");
  const [currentStep, setCurrentStep] = React.useState({ current: 1, total: 0 }); 
  const printContainerRef = React.useRef(null);
  const [currentJobRole, setCurrentJobRole] = React.useState("");
  const [ai, setAi] = React.useState("");
  const [visitedSections, setVisitedSections] = React.useState(new Set());
  const [currentSector, setCurrentSector] = React.useState("");
  const [currentExperience, setCurrentExperience] = React.useState("");
  const [activeTabExperience, setActiveTabExperience] = React.useState(0);
  const [activeTabSkills, setActiveTabSkills] = React.useState(0);
  const [jobDetails, setJobDetails] = React.useState("");
  const handleResumeChange = (field, value) => {
    setLoading(true); 
    setValues((prev) => {
      let temp = prev;
      const navigate = field.split(".");
      navigate.forEach((key, index) => {
        if (index === navigate.length - 1) {
          if (Number.isInteger(parseInt(key))) {
            temp[parseInt(key)] = value;
          } else {
            temp[key] = value;
          }
        } else {
          if (Number.isInteger(parseInt(key))) {
            temp = temp[parseInt(key)];
          } else {
            temp = temp[key];
          }
        }
      });
      return { ...prev };
    });
    setLoading(false);
  };

  const get = (field) => { 
    const navigate = field.split(".");
    let temp = values;
    navigate.forEach((key) => {
      if (!temp) return null;
      if (Number.isInteger(parseInt(key))) {
        temp = temp[parseInt(key)];
      } else {
        temp = temp[key];
      }
    });
    return temp;
  };

  const handleTemplateChange = (templateKey) => {
    if (!templateKey) setSelectedTemplate(null);
    else {
      setSelectedTemplate(templates[templateKey]);
      setCurrentStep(prev => ({ ...prev, current: 1 }));
    }
  };

  const handleSectionChange = (key) => { 
    const currentIndex = sectionsArray.indexOf(CurentSection);
    
    if (key === "next" && currentIndex < sectionsArray.length - 1) {
      const nextSectionName = sectionsArray[currentIndex + 1];
      
      let sectionKey;
      if (nextSectionName === 'Skills') {
        sectionKey = 'technicalSkills';
      } else if (nextSectionName === 'Certification') {
        sectionKey = 'awards';
      } else if (nextSectionName === 'Career Highlights') {
        sectionKey = 'careerHighlight';
      } else {
        sectionKey = Object.keys(values.sections).find(
          key => values.sections[key].name === nextSectionName
        );
      }

      // Only initialize if section doesn't exist or has no data
      if (sectionKey && !visitedSections.has(sectionKey) && 
          (!values.sections[sectionKey]?.data || values.sections[sectionKey].data.length === 0)) {
        const newElement = {};
        handleResumeChange(`sections.${sectionKey}.data`, [newElement]);
      }
      setVisitedSections(prev => new Set([...prev, sectionKey]));

      SetCurentSection(nextSectionName);
      setCurrentStep({ ...currentStep, current: currentStep.current + 1 });
      
    } else if (key === "prev" && currentIndex > 0) {
      const prevSectionName = sectionsArray[currentIndex - 1];
      
      let sectionKey;
      if (prevSectionName === 'Skills') {
        sectionKey = 'technicalSkills';
      } else if (prevSectionName === 'Certification') {
        sectionKey = 'awards'; 
      } else if (prevSectionName === 'Career Highlights') {
        sectionKey = 'careerHighlight';
      } else {
        sectionKey = Object.keys(values.sections).find(
          key => values.sections[key].name === prevSectionName
        );
      }

      // Only initialize if section doesn't exist or has no data
      if (sectionKey && !visitedSections.has(sectionKey) && 
          (!values.sections[sectionKey]?.data || values.sections[sectionKey].data.length === 0)) {
        const newElement = {};
        handleResumeChange(`sections.${sectionKey}.data`, [newElement]);
      }
      setVisitedSections(prev => new Set([...prev, sectionKey]));

      SetCurentSection(prevSectionName);
      setCurrentStep({ ...currentStep, current: currentStep.current - 1 });
    }
  };

  const AddSectionToArray = async() => {
    const config = selectedTemplate?.build
      ? selectedTemplate.build()?.props?.config
      : {};
    const sections = config.sections || [];  
    const newSectionsArray = await sections.map((section) => section.name);
    setSectionsArray(newSectionsArray);
    if (newSectionsArray.length > 0) {
      SetCurentSection(newSectionsArray[0]);
    }
  };

   

  useEffect(() => {
    AddSectionToArray();
  }, [selectedTemplate]); 

  useEffect(() => {
    const currentIndex = sectionsArray.indexOf(CurentSection);
    setCurrentStep((prev) => ({ ...prev, total: sectionsArray.length }));
    setNextSection(
      currentIndex < sectionsArray.length - 1
        ? sectionsArray[currentIndex + 1]
        : ""
    );
    setPrevSection(currentIndex > 0 ? sectionsArray[currentIndex - 1] : "");
  }, [CurentSection, sectionsArray]);

  const resetResume = () => {
    const freshConfig = getInitialConfigResume();
    setValues(freshConfig);
    setVisitedSections(new Set());
  };

  const setResume = (resumeValues) => { 
    console.log(resumeValues);
    setValues(resumeValues); 
     console.log("values setd");
    setVisitedSections(new Set());
  };


  const handlePrint = async () => {
    try {
      setLoading(true);
      const resumeStyles = selectedTemplate?.styles;
      const printContent = document.getElementById("print_content");
    
      if (!printContent || !resumeStyles) {
        throw new Error("Print content or styles not found");
      }

      // Create exact clone of content
      const clonedContent = printContent.cloneNode(true);
      
      // Preserve original class names and styles
      const preserveStyles = (element) => {
        if (element.nodeType === 1) { // Element node
          // Keep original classes
          const originalClasses = element.getAttribute('class');
          if (originalClasses) {
            element.setAttribute('class', originalClasses);
          }
          
          // Keep original inline styles
          const originalStyle = element.getAttribute('style');
          if (originalStyle) {
            element.setAttribute('style', originalStyle);
          }
          
          // Process children
          Array.from(element.children).forEach(preserveStyles);
        }
      };
      preserveStyles(clonedContent);

      // Get all stylesheet rules including module styles
      const getAllStyleRules = () => {
        let cssRules = '';
        const styleSheets = Array.from(document.styleSheets);
        
        styleSheets.forEach(sheet => {
          try {
            const rules = Array.from(sheet.cssRules || sheet.rules);
            rules.forEach(rule => {
              // Preserve module-specific styles
              if (rule.selectorText && resumeStyles[rule.selectorText.substring(1)]) {
                cssRules += rule.cssText + '\n';
              }
              // Keep other relevant styles
              else if (!rule.selectorText?.includes(':hover')) {
                cssRules += rule.cssText + '\n';
              }
            });
          } catch (e) {
            console.warn('Could not access stylesheet rules', e);
          }
        });
        return cssRules;
      };

      const fullHTML = `
        <!DOCTYPE html>
        <html>
          <head>
            <meta charset="UTF-8">
            <!-- Import Merriweather font -->
            <link href="https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;500;600;700;900&display=swap" rel="stylesheet">
            <style>
              /* Import font directly in CSS as backup */
              @import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;500;600;700;900&display=swap');

              /* Set default font family for all elements */
              * {
                font-family: "Merriweather", serif !important;
              }
              
              /* Page settings with consistent margins */
              @page {
                size: A4 portrait;
                margin: ${selectedTemplate.page_margins};
              }
              
              body {
                margin: 0;
                padding: 0;
                background: white;
                -webkit-print-color-adjust: exact !important;
                print-color-adjust: exact !important;
                font-family: "Merriweather", serif;
              }
              
              /* Container styles with explicit page breaks and margins */
              .a4-container {
                width: 210mm;
                min-height: 297mm;
                padding: ${selectedTemplate.page_margins};
                margin: 0 auto;
                background: white;
                box-sizing: border-box;
                page-break-after: always;
                font-family: "Merriweather", serif;
              }

              /* Ensure content starts at top margin on new pages */
              .a4-container:not(:first-child) {
                margin-top: ${selectedTemplate.page_margins};
              }

              /* Handle last page */
              .a4-container:last-child {
                page-break-after: auto;
              }

              /* Preserve all original styles */
              ${getAllStyleRules()}
              
              /* Force exact rendering */
              * {
                -webkit-print-color-adjust: exact !important;
                print-color-adjust: exact !important;
                color-adjust: exact !important;
              }

              /* Ensure font consistency for all text elements */
              p, span, div, h1, h2, h3, h4, h5, h6, li {
                font-family: "Merriweather", serif !important;
              }
            </style>
          </head>
          <body>
            <div class="a4-container">
              ${clonedContent.outerHTML}
            </div>
          </body>
        </html>
      `;

      // Send to backend with strict rendering options
      const response = await fetch("https://api.myfuse.in/pdf/generate-pdf", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ 
          html: fullHTML,
          options: {
            printBackground: true,
            preferCSSPageSize: true,
            scale: 1,
            format: 'A4',
            margin: {
              top: selectedTemplate.page_margins,
              right: selectedTemplate.page_margins,
              bottom: selectedTemplate.page_margins,
              left: selectedTemplate.page_margins
            },
            waitForFonts: true,
            timeout: 30000,
            pageRanges: '',
            displayHeaderFooter: false,
            landscape: false
          }
        }),
      });

      const data = await response.json();
      
      if (!data.success) {
        throw new Error(data.error || 'Failed to generate PDF');
      }

      // Trigger download
      window.location.href = `https://api.myfuse.in/pdf/download/${data.filename}`;

    } catch (error) {
      console.error('Error generating PDF:', error);
      throw error;
    } finally {
      setLoading(false);
    }
  };
 

 

  const value = {
    values,
    setValues,
    handleResumeChange,
    get,
    loading,
    setLoading,
    templates,
    selectedTemplate,
    handleTemplateChange,
    printContainerRef,
    setAi,
    ai,
    index,
    setIndex,
    CurentSection,
    handleSectionChange,
    prevSection,
    nextSection,
    currentStep,
    resetResume,
    getInitialConfigResume,
    currentJobRole,
    setCurrentJobRole,
    setResume,
    currentSector,
    setCurrentSector,
    currentExperience,
    setCurrentExperience,
    activeTabExperience,
    setActiveTabExperience,
    activeTabSkills,
    setActiveTabSkills,
    handlePrint,
    jobDetails,
    setJobDetails
  };

  return (
    <ResumeContext.Provider value={value}>{children}</ResumeContext.Provider>
  );
};

export default ResumeProvider;
