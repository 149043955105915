import React from 'react'
import styles from "../../assets/Account/Right.module.css"
import { Pencil } from 'lucide-react'
const RightSide = ({auth}) => {
  return (
    <div className={styles.main}>
        <div className={styles.heading}>
            Profile
        </div>
        <div className={styles.aboutmeContainer}>  
            <div className={styles.subhedingContainer}>
                 <div className={styles.subheading}>About Me </div>  
                 <div><Pencil size={14}/></div>
            </div>
       
            <div className={styles.aboutme}>{auth?.user?.[0]?.aboutme}</div> 
        </div>
    </div>

  )
}

export default RightSide