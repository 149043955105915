import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useAuth } from "./ResumeBuilder/builder/components/context/auth";
import styles from "./assets/Login.module.css";
import { jwtDecode } from "jwt-decode";
import { GoogleLogin, useGoogleOneTapLogin } from "@react-oauth/google";
import HashLoader from "react-spinners/HashLoader";
import { motion } from "framer-motion";
import { publicApi } from './ResumeBuilder/utils/aixos';
import BottomBar from "./components/BottomBar";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setpassword] = useState("");
  const Navigate = useNavigate();
  const { auth, setAuth } = useAuth();
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);

    const handlePopState = () => {
      Navigate('/');
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [Navigate]);

  if (auth?.token !== "") {
    Navigate("/welcome");
  }
  const handleNavigateForgot = () => {
    Navigate("/forgot-password");
  };
  const handleNavigate = () => {
    Navigate("/signup");
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const res = await publicApi.post('/api/auth/login', { email, password });
      
      if (res.data.success) {
        toast.success(res.data.message);
        setAuth({
          ...auth,
          user: res.data.user,
          token: res.data.token,
        });
        localStorage.setItem("auth", JSON.stringify(res.data));
        Navigate("/welcome");
      } else {
        toast.error(res.data.message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error("Something went wrong!!!");
    }
  };

  const responseGoogleSuccess = async (credentialResponse) => {
    try {
      const decode = await jwtDecode(credentialResponse.credential);
      const response = await publicApi.post('/api/auth/loginWithGoogle', { decode });
      
      if (response.data.success) {
        const updatedAuth = {
          ...auth,
          user: response.data.user,
          token: response.data.token,
          pic: decode?.picture,
          message: response.data.message,
          success: response.data.success,
        };
        setAuth(updatedAuth);
        toast.success(response.data.message);
        localStorage.setItem("auth", JSON.stringify(updatedAuth));
        Navigate("/welcome");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const responseGoogleFailure = (error) => {
    console.error("Google Auth Error:", error);
  };

  useGoogleOneTapLogin({
    onSuccess: responseGoogleSuccess,
    onFailure: responseGoogleFailure,
    onError: () => {
      console.log("Login Failed");
    },
  });

  

  const resumePreviews = [
    {
      className: "shape1",
      lines: [
        { width: "70%", delay: 0 },
        { width: "40%", delay: 0.1 },
        { width: "90%", delay: 0.2 },
        { width: "60%", delay: 0.3 },
      ]
    },
    {
      className: "shape2",
      lines: [
        { width: "80%", delay: 0.2 },
        { width: "50%", delay: 0.3 },
        { width: "65%", delay: 0.4 },
        { width: "75%", delay: 0.5 },
      ]
    }
  ];

  const skillBadges = [
    // { icon: '💼', skill: 'Business', desc: 'Management & Finance' },
    // { icon: '🎨', skill: 'Creative', desc: 'Design & Arts' },
    // { icon: '🔬', skill: 'Science', desc: 'Research & Lab' },
    // { icon: '⚕️', skill: 'Healthcare', desc: 'Medical & Care' },
    // { icon: '📚', skill: 'Education', desc: 'Teaching & Learning' },
    // { icon: '🏗️', skill: 'Engineering', desc: 'Build & Create' }
  ];

  const shapes = [
    { type: 'circle', color: '#6366F1', size: 80 },
    { type: 'square', color: '#A855F7', size: 60 },
    { type: 'triangle', color: '#EC4899', size: 70 }
  ];

  const features = [
    { 
      icon: '✨', 
      text: 'Professional Templates',
      subText: '100+ ATS-Friendly Designs',
      color: '#6366F1'
    },
    { 
      icon: '⚡', 
      text: 'Quick & Easy',
      subText: 'Ready in 5 Minutes',
      color: '#EC4899'
    },
    { 
      icon: '🎯', 
      text: 'ATS Friendly',
      subText: '90% Success Rate',
      color: '#14B8A6'
    }
  ];

  return (
    <div className={styles.main}>
      <div className={styles.shapesContainer}>
        {shapes.map((shape, index) => (
          <motion.div
            key={`shape-${index}`}
            className={styles[shape.type]}
            style={{ background: shape.color }}
            animate={{
              y: [0, -30, 0],
              x: [0, 20, 0],
              rotate: [0, 360],
              opacity: [0.3, 0.6, 0.3]
            }}
            transition={{
              duration: 8,
              repeat: Infinity,
              delay: index * 0.5,
              ease: "easeInOut"
            }}
          />
        ))}
      </div>

      <div className={styles.floatingElements}>
        <div className={styles.leftElements}>
          {skillBadges.slice(0, 3).map((item, index) => (
            <motion.div
              key={`skill-left-${index}`}
              className={styles.skillBadge}
              initial={{ opacity: 0, x: -50 }}
              animate={{ 
                opacity: 1, 
                x: 0,
                y: [0, -10, 0]
              }}
              transition={{
                duration: 3,
                repeat: Infinity,
                delay: index * 0.2
              }}
              whileHover={{ scale: 1.1 }}
            >
              <span className={styles.skillIcon}>{item.icon}</span>
              <div className={styles.skillInfo}>
                <span className={styles.skillName}>{item.skill}</span>
                <span className={styles.skillDesc}>{item.desc}</span>
              </div>
            </motion.div>
          ))}
        </div>

        <div className={styles.rightElements}>
          {skillBadges.slice(3).map((item, index) => (
            <motion.div
              key={`skill-right-${index}`}
              className={styles.skillBadge}
              initial={{ opacity: 0, x: 50 }}
              animate={{ 
                opacity: 1, 
                x: 0,
                y: [0, -10, 0]
              }}
              transition={{
                duration: 3,
                repeat: Infinity,
                delay: index * 0.2
              }}
              whileHover={{ scale: 1.1 }}
            >
              <span className={styles.skillIcon}>{item.icon}</span>
              <div className={styles.skillInfo}>
                <span className={styles.skillName}>{item.skill}</span>
                <span className={styles.skillDesc}>{item.desc}</span>
              </div>
            </motion.div>
          ))}
        </div>

        <div className={styles.featuresContainer}>
          {features.map((feature, index) => (
            <motion.div
              key={`feature-${index}`}
              className={styles.featureCard}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.2 }}
              whileHover={{ 
                scale: 1.05,
                boxShadow: `0 8px 16px ${feature.color}15`
              }}
              style={{
                borderLeft: `4px solid ${feature.color}`
              }}
            >
              <div className={styles.featureIconWrapper}>
                <span className={styles.featureIcon}>{feature.icon}</span>
              </div>
              <div className={styles.featureContent}>
                <span className={styles.featureText}>{feature.text}</span>
                <span className={styles.featureSubText}>{feature.subText}</span>
              </div>
            </motion.div>
          ))}
        </div>
      </div>

      <div className={styles.bgAnimation}>
        {resumePreviews.map((preview, index) => (
          <motion.div
            key={index}
            className={`${styles.resumeShape} ${styles[preview.className]}`}
            animate={{
              y: [0, index % 2 === 0 ? -20 : 20, 0],
              rotate: [index % 2 === 0 ? -15 : 15, index % 2 === 0 ? -10 : 20, index % 2 === 0 ? -15 : 15],
            }}
            transition={{
              duration: 5,
              repeat: Infinity,
              ease: "easeInOut",
              delay: index * 0.5
            }}
          >
            <div className={styles.resumePreview}>
              {preview.lines.map((line, lineIndex) => (
                <motion.div
                  key={lineIndex}
                  className={`${styles.resumeLine}`}
                  style={{ width: line.width }}
                  initial={{ opacity: 0.3 }}
                  animate={{ opacity: [0.3, 0.6, 0.3] }}
                  transition={{
                    duration: 2,
                    repeat: Infinity,
                    delay: line.delay
                  }}
                />
              ))}
            </div>
          </motion.div>
        ))}
      </div>

      <motion.div
        className={styles.formContainer}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        <motion.div 
          className={styles.logoContainer}
          whileHover={{ scale: 1.05 }} 
          onClick={() => Navigate("/")} 
        >
          <h1 className={styles.logo}>
            MyFuse<span className={styles.logoDot}>.</span>In
          </h1>
          <p className={styles.tagline}>
            Create your professional resume in minutes
          </p> 
          
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
        >
          <button className={styles.googleButton}>
         
                
                  <GoogleLogin
                    clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                    buttonText="Login with Google"
                    onSuccess={responseGoogleSuccess}
                    onFailure={responseGoogleFailure}
                    cookiePolicy={"single_host_origin"}
                  />
               
    
          </button>
        </motion.div>

        <div className={styles.divider}>
          <span>or continue with email</span>
        </div>

        <form onSubmit={handleSubmit}>
          <motion.div
            className={styles.inputGroup}
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.3 }}
          >
            <input
              type="email"
              className={styles.input}
              placeholder="Email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </motion.div>

          <motion.div
            className={styles.inputGroup}
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.4 }}
          >
            <input
              type="password"
              className={styles.input}
              placeholder="Password"
              value={password}
              onChange={(e) => setpassword(e.target.value)}
              required
            />
          </motion.div>

          <motion.button
            type="submit"
            className={styles.submitButton}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5 }}
          >
            {loading ? (
              <HashLoader color="#1E1E2E" size={20} />
            ) : (
              "Sign in to create resume"
            )}
          </motion.button>
        </form>

        <div className={styles.links}>
          <motion.p
            className={styles.forgotPassword}
            onClick={handleNavigateForgot}
            whileHover={{ scale: 1.05 }}
          >
            Forgot your password?
          </motion.p>
          <p className={styles.signup}>
            Don't have an account?{" "}
            <motion.span
              whileHover={{ scale: 1.05 }}
              onClick={handleNavigate}
            >
              Create one now
            </motion.span>
          </p>
        </div>
      </motion.div> 
      <BottomBar/>
    </div>
  );
};

export default Login;
