import React, { useEffect, useState } from "react";
import styles from "../assets/Account/AccountNew.module.css";
import MyResponsiveNavbar from "./Navbar";
import LeftSide from "../components/Account.js/LeftSide";
import RightSide from "../components/Account.js/RightSide";
import Navbar from "../components/DashBoard.js/Navbar";
import { useAuth } from "../ResumeBuilder/builder/components/context/auth";
import HelmetConfig from "../components/HelmetConfig";
const AccountNew = () => {
  const [imgSrc, setImgSrc] = useState(
    "https://res.cloudinary.com/dvepwlz9j/image/upload/v1729186227/all/vzw4bjslemzuuvznzdgs.svg"
  );
  const { auth } = useAuth();
  useEffect(() => {
    if (Array.isArray(auth?.user) && auth.user.length > 0) {
      setImgSrc(
        auth.user[0]?.images ||
          "https://res.cloudinary.com/dvepwlz9j/image/upload/v1729186227/all/vzw4bjslemzuuvznzdgs.svg"
      );
    }
  }, [auth]);
  return (
    <>
     <HelmetConfig
           title="MyFuse.in-Account"
           description="Find the best job opportunities on MyFuse.in. Search jobs by industry, location, and more."
           keywords="job portal, job search, careers, employment, jobs, MyFuse.in, job opportunities"
           canonical="https://myfuse.in/account"
        />
      <div className={styles.navbarMobile}>
        <MyResponsiveNavbar />
      </div>
      <div className={styles.main}>
        <div className={styles.left}>
          <LeftSide  imgSrc={imgSrc} auth={auth} />
        </div>
        <div className={styles.right}>
          <div className={styles.navbar}>
            <Navbar auth={auth} image={imgSrc} />
          </div> 
          <div className={styles.rightside}>
             <RightSide auth ={auth} />
          </div>
          
        </div>
      </div>
    </>
  );
};

export default AccountNew;
