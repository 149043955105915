import React, { useEffect, useState } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useAuth } from '../components/ResumeBuilder/builder/components/context/auth';
import Spinner from './Spinner';
import { privateApi } from '../components/ResumeBuilder/utils/aixos';
const AdminRoute = () => {    
  const { authstatus, user, setUser  } = useAuth();
  const [loading, setLoading] = useState(true);
 
  const CheckUser = async () => { 
    try {
        const response = await privateApi.post(`/api/admin/userCheck`, {});
      const { data } = response;
      await setUser(data.user[0].user_type);
      
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  

  useEffect(() => {
    CheckUser();
  }, [setUser]);

  if (loading || authstatus === null) {
    return <Spinner />;
  }

  // Check if the user is authenticated and has admin role
  return authstatus && user === 'Blog' ? (
    <Outlet />
  ) : (
    <Navigate to="/not-authorized" />
  );
};

export default AdminRoute;
