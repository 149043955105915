import React, { useEffect, useRef, useState } from "react";
import styles from "../../assets/Dashboard/resume.module.css";
import { ChevronRight, Pen, Plus, Trash2 } from "lucide-react";
import { useResume } from "../../ResumeBuilder/builder/components/context/Resume";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../ResumeBuilder/builder/components/context/auth";
import { motion } from "framer-motion";
import { privateApi } from "../../ResumeBuilder/utils/aixos";

import PreviewImageDash from "./PreviewImageDash";
import Model from "../Model";
const Resume = ({setModalShow }) => {
  const containerRef = useRef(null); // Reference to the scrollable container
  const [open, setOpen] = useState(false);
  const [data , setData] = useState([]);
  const [modalShoww, setModalShoww] = useState(false);
  const Navigate = useNavigate(); 
  const {auth} = useAuth();
  const [loading, setLoading] = useState(true);
  const {handleTemplateChange , setValues , templates } = useResume();
  const navigate = useNavigate();
   const getResume = async () =>{ 
    
    try {
      setLoading(true);
      const {data} = await privateApi.get(`/api/resume/getresume`);
      setData(data?.result); 
    } finally {
      setLoading(false);
    }
   }

   const chekcTemplate = (type) => {
    const template = Object.values(templates).find(
      (template) => template.key === type
    );
    return template;
  };

  const jsonConversion = (json) => {
    return JSON.parse(json);
  };

   const handleDelete = async (id) => { 
    try {
      await privateApi.delete(`/api/resume/deleteresume/${id}`);
      getResume();
    } catch (error) {
      console.error('Error deleting resume:', error);
    }
  }     
  const handleScroll = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: 100, // Adjust the scroll distance as needed
        behavior: "smooth", // Smooth scrolling
      });
    }
  };

  const handleChange = async(templateKey , templateName , id , resume_values , domain , sector , yoe) => {
     const parsedData = JSON.parse(resume_values);
     setValues(parsedData);
    handleTemplateChange(templateKey); 
    Navigate(`/editor?insert_id=${id}&domins=${domain}&jobsector=${sector}&YOE=${yoe}&key=${templateKey}`);
  }; 

 
  
 
  useEffect(() => {
    getResume();
  }, []);
  return (
    <div className={styles.main}>
      <div className={styles.headingContainer}>
        <div className={styles.headingtitle}>My Resumes </div> 
        <div className={styles.tabContainer}>  
          <div className={styles.tabContainerWrapper}>
            <div className={styles.tab + " " + styles.activeTab}>Draft <span className={styles.tabNumber}>0</span></div>
            <div className={styles.tab}>Completed <span className={styles.tabNumber}>0</span></div>
          </div>
          <div className={styles.viewAllContainer}>
            <span className={styles.viewAllText}>View All</span>
            <ChevronRight size={20} />
          </div>
        </div>  
        <div className={styles.horizontalLine}></div>
      </div>
      <div className={styles.mainContainer}>
        <div className={styles.eachContainermain}>
           
           <div className={styles.downContainer} onClick={() => setModalShow(true)}>
              <div className={styles.ImageContainer}> 
                <img className={styles.Image} src="https://res.cloudinary.com/dmsbvsful/image/upload/v1741100672/Long_Clip_Up_1_q1j3qh.svg" alt="d" />
                <div className={styles.plus}>
                  <Plus size={20} color={"#ffffff"} />
                </div>
              </div>
              <div className={styles.textContainer}  >
                <div className={styles.title}>Create</div>
                <div className={styles.description}>New Resume</div>
              </div>
           </div>
         </div>


        <div className={styles.ScrollContainer} ref={containerRef}>
          {loading ? (
            // Loading skeleton cards
            Array(4).fill(0).map((_, index) => (
              <div className={`${styles.eachContainer} ${styles.skeleton}`} key={`skeleton-${index}`}>
                <div className={styles.eachcontainerUp}>
                  <div className={styles.skeletonImage} />
                </div>
                <div className={styles.eachcontainerDown}>
                  <div className={styles.eachdowninte}>
                    <div className={styles.left}>
                      <div className={styles.skeletonTitle} />
                      <div className={styles.skeletonDate} />
                    </div>
                    <div className={styles.Right}>
                      <div className={styles.skeletonPercent} />
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : data?.length === 0 ? (
            <motion.div 
              className={styles.noDataContainer}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
            >
              <motion.div 
                className={styles.noDataContent}
                initial={{ scale: 0.8 }}
                animate={{ scale: 1 }}
                transition={{ delay: 0.3, type: "spring", stiffness: 100 }}
              >
                <motion.img 
                  src="https://res.cloudinary.com/dmsbvsful/image/upload/v1741091448/Clip_Clap_yo2yok.gif"
                  alt="No resumes found"
                  whileHover={{ scale: 1.05 }}
                  transition={{ type: "spring", stiffness: 200 }}
                />
                <motion.h3
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.5 }}
                >
                  No Resumes Yet
                </motion.h3>
                <motion.p
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.7 }}
                >
                  Create your first resume and start your journey!
                </motion.p>
                <motion.button 
                  className={styles.createFirstButton}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={() => setModalShow(true)}
                >
                  <Plus size={18} />
                  Create First Resume
                </motion.button>
              </motion.div>
              
              <motion.div className={styles.backgroundElements}>
                {[...Array(6)].map((_, i) => (
                  <motion.div
                    key={i}
                    className={styles.floatingResume}
                    initial={{ 
                      x: Math.random() * window.innerWidth,
                      y: Math.random() * window.innerHeight,
                      rotate: Math.random() * 360
                    }}
                    animate={{
                      x: Math.random() * window.innerWidth,
                      y: Math.random() * window.innerHeight,
                      rotate: Math.random() * 360
                    }}
                    transition={{
                      duration: 15 + Math.random() * 10,
                      repeat: Infinity,
                      repeatType: "reverse",
                      ease: "linear"
                    }}
                  >
                    <div className={styles.resumeHeader} />
                    <div className={styles.resumeContent}>
                      {[...Array(3)].map((_, index) => (
                        <div key={index} className={styles.resumeLine} />
                      ))}
                    </div>
                  </motion.div>
                ))}
              </motion.div>
            </motion.div>
          ) : (
            // Existing card rendering code
            data?.toReversed().map((item, index) => (
              <div className={styles.eachContainer} key={index}>
                <div className={styles.overlay}></div> 
                <div className={styles.hoverButtons}>
                  <button onClick={() => handleChange(item?.template_key , item?.template_name , item?.id , item?.resume_values , item?.domain , item?.sector , item?.yoe)}>
                    <Pen size={14} color={"#806EED"} /> Edit
                  </button>
                  
                  <button onClick={() => handleDelete(item?.id)}>
                    <Trash2 size={14} color={"#806EED"} /> Delete
                  </button>
                </div>
                <div className={styles.eachcontainerUp}>
                  
                  <PreviewImageDash 
                    template={chekcTemplate(item.template_key)}
                    values={jsonConversion(item.resume_values)}
                  />
                </div>
                 
              </div>
            ))
          )}
        </div>
       
        <div className={styles.eachContainermain} style={{border:"none"}}>
           <div className={`${styles.downContainer} ${styles.proContainer}`} onClick={() => setModalShoww(true)}>
              <div className={styles.proRibbon}>PRO</div>
              <div className={styles.ImageContainer}> 
                <img 
                  className={styles.Image} 
                  src="https://res.cloudinary.com/dmsbvsful/image/upload/v1741875702/Untitled_Artwork_31_3_p2oxon.svg" 
                  alt="Premium" 
                />
                <div className={styles.sparkleEffect}>
                  <div className={styles.sparkle}></div>
                  <div className={styles.sparkle}></div>
                  <div className={styles.sparkle}></div>
                </div>
              </div>
              <div className={styles.textContainer}>
                <div className={styles.proTitle}>Upgrade to Pro</div>
                <div className={styles.proDescription}>
                  <span className={styles.proHighlight}>Unlock Premium Features</span>
                  <ul className={styles.proFeatures}>
                    <li>✨ AI Powered Resume</li>
                    <li>🎨 Custom Themes</li>
                    <li>📊 ATS Compatible</li>
                  </ul>
                </div>
                <button className={styles.upgradeButton} onClick={() =>   setModalShoww(true)}>
                  Upgrade Now
                  <ChevronRight size={16} className={styles.buttonIcon} />
                </button>
              </div>
           </div>
         </div>
       
      </div>
      <Model modalShow={modalShoww} setModalShow={setModalShoww} />
    </div>
  );
};

export default Resume;
