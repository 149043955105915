import React, { createContext, useContext, useEffect } from 'react';
import io from "socket.io-client";
import { useAuth } from './auth';
import { AnimatePresence, motion } from 'framer-motion';

const SocketContext = createContext();

let socket = null;
let connectionAttempts = 0;
const maxConnectionAttempts = 4;
const retryDelay = 2000;

export const SocketProvider = ({ children }) => {
  const { auth } = useAuth();
  const [isConnected, setIsConnected] = React.useState(false);
  const [isOnline, setIsOnline] = React.useState(navigator.onLine);
  const [networkMessage, setNetworkMessage] = React.useState(null);
  const messageTimeoutRef = React.useRef(null);

  // Handle online/offline status
  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      setNetworkMessage({
        text: "You are back online",
        type: "success"
      });
      if (auth?.token) establishSocketConnection();

      // Clear the message after 3 seconds
      if (messageTimeoutRef.current) clearTimeout(messageTimeoutRef.current);
      messageTimeoutRef.current = setTimeout(() => {
        setNetworkMessage(null);
      }, 3000);
    };

    const handleOffline = () => {
      setIsOnline(false);
      setNetworkMessage({
        text: "You are currently offline",
        type: "error"
      });
      setIsConnected(false);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
      if (messageTimeoutRef.current) clearTimeout(messageTimeoutRef.current);
    };
  }, [auth?.token]);

  const establishSocketConnection = async () => {
    try {
      if (!auth?.token) {
        console.log("No auth token found, skipping socket connection");
        return null;
      }

      if (socket?.connected) {
        console.log("Socket already connected");
        setIsConnected(true);
        return socket;
      }

      cleanupSocket();

      if (connectionAttempts < maxConnectionAttempts) {
        socket = io(`${process.env.REACT_APP_BASE_BACKEND_URL}`, {
          auth: {
            token: `Bearer ${auth.token}`
          },
          transports: ['websocket'],
          forceNew: true,
          reconnection: true,
          reconnectionAttempts: maxConnectionAttempts,
          reconnectionDelay: retryDelay,
          timeout: 10000
        });
        connectionAttempts++;

        socket.on("connect", () => {
          console.log("Socket connected successfully");
          setIsConnected(true);
          connectionAttempts = 0; // Reset attempts on successful connection
        });

        socket.on("disconnect", () => {
          console.log("Socket disconnected");
          setIsConnected(false);
        });

        socket.on("connect_error", (error) => {
          console.error("Socket connection error:", error);
          setIsConnected(false);
          if (connectionAttempts < maxConnectionAttempts) {
            console.log(`Retrying connection (attempt ${connectionAttempts + 1} of ${maxConnectionAttempts})`);
            setTimeout(establishSocketConnection, retryDelay);
          } else {
            console.error("Exceeded maximum connection attempts");
            cleanupSocket();
          }
        });

        // Add reconnect event handlers
        socket.on("reconnect", (attemptNumber) => {
          console.log("Socket reconnected after", attemptNumber, "attempts");
          setIsConnected(true);
        });

        socket.on("reconnect_attempt", (attemptNumber) => {
          console.log("Socket reconnection attempt", attemptNumber);
        });

        socket.on("reconnect_error", (error) => {
          console.error("Socket reconnection error:", error);
        });

        socket.on("reconnect_failed", () => {
          console.error("Socket reconnection failed");
          cleanupSocket();
        });
      }
    } catch (error) {
      console.error("Socket initialization error:", error);
      setIsConnected(false);
      return null;
    }
  };

  const cleanupSocket = () => {
    if (socket) {
      // Remove all listeners first
      socket.removeAllListeners();
      // Close the connection
      socket.close();
      socket = null;
      connectionAttempts = 0;
      setIsConnected(false);
    }
  };

  useEffect(() => {
    if (auth?.token) {
      establishSocketConnection();
    } else {
      cleanupSocket();
    }

    return () => cleanupSocket();
  }, [auth?.token]);

  return (
    <>
      <AnimatePresence>
        {networkMessage && (
          <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ 
              type: "spring",
              stiffness: 200,
              damping: 20
            }}
            style={{
              position: 'fixed',
              top: '32px',
              right: '32px',
              backgroundColor: 'white',
              padding: '16px',
              borderRadius: '12px',
              zIndex: 9999,
              boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
              display: 'flex',
              alignItems: 'center',
              gap: '12px',
              minWidth: '320px',
              border: `1px solid ${networkMessage.type === 'error' ? '#FEE2E2' : '#DCFCE7'}`,
            }}
          >
            <motion.div
              initial={{ scale: 0.5 }}
              animate={{ scale: 1 }}
              transition={{ 
                type: "spring",
                stiffness: 300,
                damping: 20,
                delay: 0.1 
              }}
              style={{
                backgroundColor: networkMessage.type === 'error' ? '#FEF2F2' : '#F0FDF4',
                padding: '8px',
                borderRadius: '50%',
              }}
            >
              {networkMessage.type === 'error' ? 
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M10 6V10M10 14H10.01M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z" 
                    stroke="#DC2626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg> : 
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M16.7 5.3C17.1 5.7 17.1 6.3 16.7 6.7L8.7 14.7C8.3 15.1 7.7 15.1 7.3 14.7L3.3 10.7C2.9 10.3 2.9 9.7 3.3 9.3C3.7 8.9 4.3 8.9 4.7 9.3L8 12.6L15.3 5.3C15.7 4.9 16.3 4.9 16.7 5.3Z" 
                    fill="#16A34A"/>
                </svg>
              }
            </motion.div>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '2px'
            }}>
              <span style={{
                fontSize: '14px',
                fontWeight: 600,
                color: networkMessage.type === 'error' ? '#DC2626' : '#16A34A'
              }}>
                {networkMessage.type === 'error' ? 'Connection Lost' : 'Connected'}
              </span>
              <span style={{
                fontSize: '14px',
                color: '#6B7280'
              }}>
                {networkMessage.text}
              </span>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <SocketContext.Provider value={{ socket, isConnected, isOnline }}>
        {children}
      </SocketContext.Provider>
    </>
  );
};

export const useSocket = () => {
  const context = useContext(SocketContext);
  if (!context) {
    throw new Error('useSocket must be used within a SocketProvider');
  }
  return context;
};