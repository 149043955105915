import React from "react";
import styles from "../../assets/Account/Left.module.css";
const LeftSide = ({imgSrc , auth}) => {
  return (
    <div className={styles.main}>
      <div className={styles.logocontainer}>
        <div className={styles.logo}>
          MyFuse<span className={styles.dot}>.</span>In
        </div>
      </div> 
      <div className={styles.maincontentContainer}> 
           <div className={styles.imageConatiner}>
                <img src={imgSrc} alt="Profile Image" />
                <span className={styles.name}> {auth?.user?.[0]?.firstname} {auth?.user?.[0]?.lastname}</span>
                <span className={styles.designation}> {auth?.user?.[0]?.designation}</span>
                <span className={styles.designation}> 0-1 years </span>
           </div>
           
      </div>
    </div>
  );
};

export default LeftSide;
