import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import styles from "../assets/Modeluploadscratch.module.css";
import { motion, AnimatePresence } from "framer-motion";
import { X, ArrowRight, Sparkles, Zap } from "lucide-react";

function Modeluploadscratch({ modalShow, setModalShow, setSecondModalShow }) {
  const containerVariants = {
    hidden: { opacity: 0, scale: 0.95 },
    visible: { 
      opacity: 1, 
      scale: 1,
      transition: {
        duration: 0.5,
        type: "spring",
        staggerChildren: 0.1
      }
    },
    exit: {
      opacity: 0,
      scale: 0.95,
      transition: { duration: 0.3 }
    }
  };

  const floatingAnimation = {
    y: [-5, 5],
    transition: {
      duration: 2,
      repeat: Infinity,
      repeatType: "reverse",
      ease: "easeInOut"
    }
  };

  return (
    <AnimatePresence>
      {modalShow && (
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="lg"
          centered
          className={`${styles.modalWrapper} ${styles.responsiveModal}`}
        >
          <motion.div 
            className={styles.modalBackground}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Modal.Body className={styles.bodys}>
              <motion.div 
                className={styles.container}
                variants={containerVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
              >
                {/* Animated Background Elements */}
                <div className={styles.backgroundElements}>
                  <motion.div 
                    className={styles.gradientOrb}
                    animate={{
                      scale: [1, 1.2, 1],
                      opacity: [0.5, 0.8, 0.5],
                    }}
                    transition={{
                      duration: 3,
                      repeat: Infinity,
                      ease: "easeInOut"
                    }}
                  />
                </div>

                {/* Close Button */}
                <motion.button
                  className={styles.closeButton}
                  onClick={() => setModalShow(false)}
                  whileHover={{ scale: 1.1, rotate: 90 }}
                  whileTap={{ scale: 0.9 }}
                >
                  <X className={styles.closeIcon} />
                </motion.button>

                {/* Header Section */}
                <motion.div className={styles.headerSection}>
                  <motion.div 
                    className={styles.sparkleContainer}
                    animate={floatingAnimation}
                  >
                    <Sparkles className={styles.sparkleIcon} />
                    <Zap className={styles.zapIcon} />
                  </motion.div>
                  
                  <motion.h1 
                    className={styles.headtitle}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.2 }}
                  >
                    How would you like to create your resume?
                  </motion.h1>
                  
                  <motion.p 
                    className={styles.headtitlparagraph}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.3 }}
                  >
                    Unlock AI-powered template suggestions tailored for you
                  </motion.p>
                </motion.div>

                {/* Options Container */}
                <div className={styles.twoboxContainer}>
                  {/* Create from Scratch Option */}
                  <motion.div
                    className={`${styles.eachContainersecond} ${styles.responsiveCard}`}
                    whileHover={{ 
                      scale: 1.02,
                      boxShadow: "0 20px 40px rgba(128, 97, 221, 0.2)",
                    }}
                    whileTap={{ scale: 0.98 }}
                    onClick={() => {
                      setSecondModalShow(true);
                      setModalShow(false);
                    }}
                    initial={{ opacity: 0, x: -50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: 0.4 }}
                  >
                    <div className={styles.cardInner}>
                      <motion.div 
                        className={`${styles.iconContainer} ${styles.responsiveIcon}`}
                        animate={floatingAnimation}
                      >
                        <img
                          src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1730797388/all/ricwgen0tdizsqxdnmld.svg"
                          alt="Create from scratch"
                          className={styles.responsiveImage}
                        />
                      </motion.div>
                      
                      <div className={`${styles.textContent} ${styles.responsiveText}`}>
                        <h2>Create from Scratch</h2>
                        <p>AI-Powered Template Library</p>
                      </div>

                      <motion.div 
                        className={`${styles.arrowIndicator} ${styles.responsiveArrow}`}
                        whileHover={{ x: 5 }}
                      >
                        <ArrowRight />
                      </motion.div>
                    </div>
                  </motion.div>

                  {/* Upload Resume Option */}
                  <motion.div
                    className={`${styles.eachContainerfirst} ${styles.responsiveCard}`}
                    whileHover={{ 
                      scale: 1.02,
                      boxShadow: "0 20px 40px rgba(128, 97, 221, 0.2)",
                    }}
                    whileTap={{ scale: 0.98 }}
                    onClick={() => setSecondModalShow(true)}
                    initial={{ opacity: 0, x: 50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: 0.5 }}
                  >
                    <motion.div 
                      className={styles.comingSoonBadge}
                      animate={{ 
                        background: ["#8061DD", "#9747FF", "#8061DD"],
                      }}
                      transition={{ 
                        duration: 3,
                        repeat: Infinity,
                        ease: "easeInOut"
                      }}
                    >
                      Coming Soon
                    </motion.div>

                    <div className={styles.cardInner}>
                      <motion.div 
                        className={styles.iconContainer}
                        animate={floatingAnimation}
                      >
                        <img
                          src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1730796960/all/aeoqqrzow0sxcyoqajci.svg"
                          alt="Upload resume"
                        />
                      </motion.div>
                      
                      <div className={styles.textContent}>
                        <h2>Upload Previous Resume</h2>
                        <p>Smart AI Import System</p>
                      </div>

                      <motion.div 
                        className={styles.arrowIndicator}
                        whileHover={{ x: 5 }}
                      >
                        <ArrowRight />
                      </motion.div>
                    </div>
                  </motion.div>
                </div>

                {/* Skip Step Button */}
                <motion.div
                  className={styles.skipStepContainer}
                  whileHover={{ x: 5 }}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.6 }}
                  onClick={() => setSecondModalShow(true)}
                >
                  Skip Step <ArrowRight size={16} />
                </motion.div>
              </motion.div>
            </Modal.Body>
          </motion.div>
        </Modal>
      )}
    </AnimatePresence>
  );
}

export default Modeluploadscratch;
