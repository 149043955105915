import React, { useState } from "react";
import { motion } from "framer-motion";
import { useJobs } from "./components/context/Jobs";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./components/context/auth";
import { useResume } from "./components/context/Resume";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import Model from "../../components/Model";

const Finish = () => {
  const { setActiveResume , payment } = useJobs();
  const [modalShow, setModalShow] = useState(false);
  const { auth } = useAuth();
  const { printContainerRef } = useResume();
  const buttonVariants = {
    initial: { scale: 0.9, opacity: 0, y: 20 },
    animate: { scale: 1, opacity: 1, y: 0 },
    hover: {
      scale: 1.02,
      backgroundColor: "#6c4dd1",
      transition: {
        duration: 0.2,
        ease: "easeOut",
      },
    },
    tap: {
      scale: 0.98,
      backgroundColor: "#9579e4",
    },
  };

  const floatingResumeVariants = {
    animate: {
      y: [-10, 10],
      rotate: [-5, 5],
      transition: {
        y: {
          duration: 2,
          repeat: Infinity,
          repeatType: "reverse",
          ease: "easeInOut",
        },
        rotate: {
          duration: 3,
          repeat: Infinity,
          repeatType: "reverse",
          ease: "easeInOut",
        },
      },
    },
  };

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "80vh",
    position: "relative",
    overflow: "hidden",
    padding: "20px",
  };

  const buttonStyle = {
    padding: "12px 24px",
    border: "none",
    borderRadius: "8px",
    fontSize: "15px",
    fontWeight: "500",
    cursor: "pointer",
    backgroundColor: "#8061DD",
    color: "white",
    width: "220px",
    position: "relative",
    zIndex: 2,
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
  };

  const iconContainerStyle = {
    backgroundColor: "rgba(255, 255, 255, 0.15)",
    padding: "6px",
    borderRadius: "6px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const resumeBackgroundStyle = {
    position: "absolute",
    width: "150px",
    height: "200px",
    backgroundColor: "rgba(128, 97, 221, 0.1)",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "15px",
    gap: "10px",
  };

  const handleGeneratePdf = async () => {
    const element = printContainerRef.current;

    try {
      const a4HeightPx = 1123;
      if (element.scrollHeight > a4HeightPx) {
        return;
      }

      // First convert to canvas
      const canvas = await html2canvas(element, {
        scale: 2,
        useCORS: true,
        logging: false,
        onclone: (clonedDoc) => {
          const textElements = clonedDoc.querySelectorAll("*");
          textElements.forEach((el) => {
            if (
              el.innerText &&
              window.getComputedStyle(el).display !== "none"
            ) {
              const styles = window.getComputedStyle(el);
              el.style.fontFamily = styles.fontFamily;
              el.style.fontSize = styles.fontSize;
              el.style.fontWeight = styles.fontWeight;
              el.style.color = styles.color;
              el.style.webkitUserSelect = "text";
              el.style.userSelect = "text";
            }
          });
        },
      });

      // A4 dimensions in mm
      const a4Width = 210;
      const a4Height = 297;

      // Calculate dimensions while maintaining aspect ratio
      let imgWidth = a4Width;
      let imgHeight = (canvas.height * imgWidth) / canvas.width;

      // Ensure minimum height is A4
      if (imgHeight < a4Height) {
        imgHeight = a4Height;
        imgWidth = (canvas.width * imgHeight) / canvas.height;
      }

      // Create PDF with calculated dimensions
      const pdf = new jsPDF({
        unit: "mm",
        format: [Math.max(imgWidth, a4Width), Math.max(imgHeight, a4Height)],
        orientation: "portrait",
      });

      // Add image to PDF, centered if wider than A4
      const xOffset = imgWidth > a4Width ? 0 : (a4Width - imgWidth) / 2;
      pdf.addImage(
        canvas.toDataURL("image/jpeg", 1.0),
        "JPEG",
        xOffset,
        0,
        imgWidth,
        imgHeight,
        undefined,
        "FAST"
      );

      // Add hidden text layer for searchability
      const textContent = element.innerText;
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();

      // Set font size and color for the hidden text
      pdf.setFontSize(1); // Very small font size
      pdf.setTextColor(255, 255, 255); // White color (invisible)

      // Calculate position at bottom of page
      const bottomMargin = 5; // 5mm from bottom
      const textY = pageHeight - bottomMargin;

      // Add text at the bottom
      pdf.text(textContent, 0, textY, {
        maxWidth: pageWidth,
        align: "left",
      });

      // Save the PDF
      pdf.save(
        `${auth?.user?.[0]?.firstname}_${auth?.user?.[0]?.lastname}_resume.pdf`
      );
    } catch (error) {
      console.error("PDF generation failed:", error);
    }
  };

  return (
    <div style={containerStyle}>
      {/* Floating Resume Backgrounds */}
      <motion.div
        style={{ ...resumeBackgroundStyle, top: "5%", left: "10%" }}
        variants={floatingResumeVariants}
        animate="animate"
      >
        <div
          style={{
            width: "60%",
            height: "8px",
            backgroundColor: "rgba(128, 97, 221, 0.2)",
            borderRadius: "4px",
          }}
        />
        <div
          style={{
            width: "80%",
            height: "8px",
            backgroundColor: "rgba(128, 97, 221, 0.2)",
            borderRadius: "4px",
          }}
        />
        <div
          style={{
            width: "70%",
            height: "8px",
            backgroundColor: "rgba(128, 97, 221, 0.2)",
            borderRadius: "4px",
          }}
        />
      </motion.div>

      <motion.div
        style={{ ...resumeBackgroundStyle, top: "15%", right: "10%" }}
        variants={floatingResumeVariants}
        animate="animate"
      >
        <div
          style={{
            width: "70%",
            height: "8px",
            backgroundColor: "rgba(128, 97, 221, 0.2)",
            borderRadius: "4px",
          }}
        />
        <div
          style={{
            width: "60%",
            height: "8px",
            backgroundColor: "rgba(128, 97, 221, 0.2)",
            borderRadius: "4px",
          }}
        />
        <div
          style={{
            width: "80%",
            height: "8px",
            backgroundColor: "rgba(128, 97, 221, 0.2)",
            borderRadius: "4px",
          }}
        />
      </motion.div>

      <motion.div
        style={{ ...resumeBackgroundStyle, bottom: "5%", left: "15%" }}
        variants={floatingResumeVariants}
        animate="animate"
      >
        <div
          style={{
            width: "80%",
            height: "8px",
            backgroundColor: "rgba(128, 97, 221, 0.2)",
            borderRadius: "4px",
          }}
        />
        <div
          style={{
            width: "70%",
            height: "8px",
            backgroundColor: "rgba(128, 97, 221, 0.2)",
            borderRadius: "4px",
          }}
        />
        <div
          style={{
            width: "60%",
            height: "8px",
            backgroundColor: "rgba(128, 97, 221, 0.2)",
            borderRadius: "4px",
          }}
        />
      </motion.div>

      {/* Modern Unique Buttons */}
      <motion.button
        style={buttonStyle}
        variants={buttonVariants}
        initial="initial"
        animate="animate"
        whileHover="hover"
        whileTap="tap"
        transition={{ delay: 0.1 }}
        onClick={
            payment?.paymentStatus !== "success"
              ? () => setModalShow(true)
              : handleGeneratePdf
          }
      >
        <span style={iconContainerStyle}>⬇️</span>
        <span>Download Resume</span>
      </motion.button>

      {/* <motion.button
        style={buttonStyle}
        variants={buttonVariants}
        initial="initial"
        animate="animate"
        whileHover="hover"
        whileTap="tap"
        transition={{ delay: 0.2 }}
        onClick={() => {
          setActiveResume(2);
        }}
      >
        <span style={iconContainerStyle}>📊</span>
        <span>Resume Analysis</span>
      </motion.button> */}

      <motion.button
        style={buttonStyle}
        variants={buttonVariants}
        initial="initial"
        animate="animate"
        whileHover="hover"
        whileTap="tap"
        transition={{ delay: 0.3 }}
        onClick={() => {
          setActiveResume(3);
        }}
      >
        <span style={iconContainerStyle}>🎯</span>
        <span>Job Matching</span>
      </motion.button>

      <motion.button
        style={buttonStyle}
        variants={buttonVariants}
        initial="initial"
        animate="animate"
        whileHover="hover"
        whileTap="tap"
        transition={{ delay: 0.4 }}
        onClick={() => {
          setActiveResume(5);
        }}
      >
        <span style={iconContainerStyle}>💡</span>
        <span>Job Recommendations</span>
      </motion.button>
      <Model modalShow={modalShow} setModalShow={setModalShow} />
    </div>
  );
};

export default Finish;
