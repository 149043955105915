import React, { useEffect, useState } from "react";
import styles from "../../assets/Navbar.module.css";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../ResumeBuilder/builder/components/context/auth";
import {
  Building2,
  ChevronDown,
  ChevronUp,
  MapPin,
  NotebookPen,
  SlidersHorizontal,
} from "lucide-react";
import axios from "axios";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useResume } from "../../ResumeBuilder/builder/components/context/Resume";
import { useJobs } from "../../ResumeBuilder/builder/components/context/Jobs";

const MiddleWala = () => {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const { setSectionResume } = useJobs();
  const [slectedoption, setselctedoption] = useState(18);
  const [selectedsubfield, setSubfield] = useState("0");
  const [selectedsubfieldName, setsubFieldName] = useState(
    "Full Stack Developer"
  );
  const [isJobsDropdownOpen, setIsJobsDropdownOpen] = useState(false);
  const [allresume, setallresume] = useState([]);
  const [allfiled, setField] = useState([]);
  const handleJobsMouseEnter = () => setIsJobsDropdownOpen(true);
  const handleJobsMouseLeave = () => setIsJobsDropdownOpen(false);
  const [isaiDropdownOpen, setIsaiDropdownOpen] = useState(false);
  const handleaiMouseEnter = () => setIsaiDropdownOpen(true);
  const handleaiMouseLeave = () => setIsaiDropdownOpen(false);
  const { handleTemplateChange } = useResume();
  const handleClick = async (value) => {
    navigate(`/jobs-in-india?position=${value}`)
  };
  const handleclickByLocation = (value) =>{
    navigate(`/jobs-in-${value}`)
  }

  const handleclickByIndustry = (value) =>{
    navigate(`/jobs-in-india?industry=${value}`)
  }

  const handleclickBytype = (value) =>{
    navigate(`/jobs-in-india?jobType=${value}`)
  }
  const handleSelecetedResumd = async (value) => {
    setselctedoption(value);
    setSubfield();
  };

  const handleSelecetedsubResumd = async (value, name) => {
    setSubfield(value);
    setsubFieldName(name);
  };

  const handleChange = (templateKey, section) => {
    handleTemplateChange(templateKey);
    setIsaiDropdownOpen(false);
    setSectionResume(section);
    navigate("/welcome");
  };

  // const showresumefrombackend = async () => {
  //   try {
  //     const { data } = await axios.get(
  //       `${process.env.REACT_APP_BASE_BACKEND_URL}/api/admin/getallresume`
  //     );
  //     if (data?.success) {
  //       await setallresume(data?.result);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const showfieldfrombackend = async () => {
  //   try {
  //     const { data } = await axios.get(
  //       `${process.env.REACT_APP_BASE_BACKEND_URL}/api/admin/getresumefieldAndsubField`
  //     );
  //     if (data?.success) {
  //       setField(data.data);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   if (isaiDropdownOpen ===true && allresume.length === 0) {
  //     showresumefrombackend();
  //     showfieldfrombackend();
  //   }
  // }, [isaiDropdownOpen]);

  return (
    <div className=" d-none  d-lg-flex ml-3 ml-lg-5 w-75 align-items-center  justify-content-center">
      <div className={`me-auto ms-4 list-none ${styles.laptop}`}>
        {auth.user ? (
          <>
            <span
              className={styles.name}
              onClick={() => navigate("/welcome")}
            >
              {/* <LaptopMinimal size={16} style={{ marginRight: "2px" }} />{" "} */}
              Dashboard
            </span>
          </>
        ) : null}

       

        <div>
          <div
            className={styles.wavecontainer}
            onMouseEnter={handleJobsMouseEnter}
            onMouseLeave={handleJobsMouseLeave}
          >
            <div className={styles.name} onClick={() => navigate("/jobs-in-india")}>
              {/* <BriefcaseBusiness size={16} style={{ marginRight: "2px" }} />{" "} */}
              Jobs
              {/* {!isJobsDropdownOpen ? (
                <ChevronDown size={14} />
              ) : (
                <ChevronUp size={14} />
              )} */}
              <div className={styles.forhoving}> </div>
            </div>
          </div>
          {/* <div className={styles.dropdown}>
            {isJobsDropdownOpen && (
              <div
                className={`${styles.dropdowncontentjob}`}
                onMouseEnter={handleJobsMouseEnter}
                onMouseLeave={handleJobsMouseLeave}
                style={{ backgroundColor: "white" }}
              >
                <div className={styles.jobcontent}>
                  <div className={styles.jobsdiv}>
                    <div className={styles.jobsheading}>
                      {" "}
                      <MapPin size={15} style={{ marginRight: "5px" }} /> Jobs
                      By Location{" "}
                    </div>
                    <div className={styles.jobsitemss}>
                      <div
                        className={styles.jobsSingleItem}
                        data-value="Bangalore"
                        onClick={(e) =>
                          handleclickByLocation(
                            e.currentTarget.getAttribute("data-value")
                          )
                        }
                      >
                        {" "}
                        Jobs in Bangalore{" "}
                      </div>
                      <div
                        className={styles.jobsSingleItem}
                        data-value="Pune"
                        onClick={(e) =>
                          handleclickByLocation(
                            e.currentTarget.getAttribute("data-value")
                          )
                        }
                      >
                        {" "}
                        Jobs in Pune{" "}
                      </div>
                      <div
                        className={styles.jobsSingleItem}
                        data-value="hyderabad"
                        onClick={(e) =>
                          handleclickByLocation(
                            e.currentTarget.getAttribute("data-value")
                          )
                        }
                      >
                        {" "}
                        Jobs in hyderabad{" "}
                      </div>
                      <div
                        className={styles.jobsSingleItem}
                        data-value="Mumbai"
                        onClick={(e) =>
                          handleclickByLocation(
                            e.currentTarget.getAttribute("data-value")
                          )
                        }
                      >
                        {" "}
                        Jobs in Mumbai{" "}
                      </div>
                      <div
                        className={styles.jobsSingleItem}
                        data-value="Patna"
                        onClick={(e) =>
                          handleclickByLocation(
                            e.currentTarget.getAttribute("data-value")
                          )
                        }
                      >
                        {" "}
                        Jobs in Patna{" "}
                      </div>
                      <div
                        className={styles.jobsSingleItem}
                        data-value="Lucknow"
                        onClick={(e) =>
                          handleclickByLocation(
                            e.currentTarget.getAttribute("data-value")
                          )
                        }
                      >
                        {" "}
                        Jobs in Lucknow{" "}
                      </div>
                      <div
                        className={styles.jobsSingleItem}
                        data-value="Gurugram"
                        onClick={(e) =>
                          handleclickByLocation(
                            e.currentTarget.getAttribute("data-value")
                          )
                        }
                      >
                        {" "}
                        Jobs in Gurugram{" "}
                      </div>
                      <div
                        className={styles.jobsSingleItem}
                        data-value="Delhi"
                        onClick={(e) =>
                          handleclickByLocation(
                            e.currentTarget.getAttribute("data-value")
                          )
                        }
                      >
                        {" "}
                        Jobs in Delhi{" "}
                      </div>
                      <div
                        className={styles.jobsSingleItem}
                        data-value="Jaipur"
                        onClick={(e) =>
                          handleclickByLocation(
                            e.currentTarget.getAttribute("data-value")
                          )
                        }
                      >
                        Jobs in Jaipur
                      </div>
                    </div>
                  </div>
                  <div className={styles.horizantalBar}></div>
                  <div className={styles.jobsdiv}>
                    <div className={styles.jobsheading}>
                      {" "}
                      <Building2
                        size={15}
                        style={{ marginRight: "5px" }}
                      />{" "}
                      Jobs By Industry
                    </div>
                    <div className={styles.jobsitemss}>
                      <div
                        className={styles.jobsSingleItem}
                        data-value="Technology"
                        onClick={(e) =>
                          handleclickByIndustry(
                            e.currentTarget.getAttribute("data-value")
                          )
                        }
                      >
                        {" "}
                        Technology and IT{" "}
                      </div>
                      <div
                        className={styles.jobsSingleItem}
                        data-value="Education"
                        onClick={(e) =>
                          handleclickByIndustry(
                            e.currentTarget.getAttribute("data-value")
                          )
                        }
                      >
                        {" "}
                        Education{" "}
                      </div>
                      <div
                        className={styles.jobsSingleItem}
                        data-value="TransPortation"
                        onClick={(e) =>
                          handleclickByIndustry(
                            e.currentTarget.getAttribute("data-value")
                          )
                        }
                      >
                        {" "}
                        Transportation{" "}
                      </div>
                      <div
                        className={styles.jobsSingleItem}
                        data-value="Travel"
                        onClick={(e) =>
                          handleclickByIndustry(
                            e.currentTarget.getAttribute("data-value")
                          )
                        }
                      >
                        {" "}
                        Travel And Tourism
                      </div>
                      <div
                        className={styles.jobsSingleItem}
                        data-value="Health"
                        onClick={(e) =>
                          handleclickByIndustry(
                            e.currentTarget.getAttribute("data-value")
                          )
                        }
                      >
                        {" "}
                        Health Care{" "}
                      </div>
                      <div
                        className={styles.jobsSingleItem}
                        data-value="Reatail"
                        onClick={(e) =>
                          handleclickByIndustry(
                            e.currentTarget.getAttribute("data-value")
                          )
                        }
                      >
                        {" "}
                        Retail and Consumer{" "}
                      </div>
                    </div>
                  </div>
                  <div className={styles.horizantalBar}></div>
                  <div className={styles.jobsdiv}>
                    <div className={styles.jobsheading}>
                      {" "}
                      <NotebookPen
                        size={15}
                        style={{ marginRight: "5px" }}
                      />{" "}
                      Jobs By Position
                    </div>
                    <div className={styles.jobsitemss}>
                      <div
                        className={styles.jobsSingleItem}
                        data-value="Software"
                        onClick={(e) =>
                          handleClick(
                            e.currentTarget.getAttribute("data-value")
                          )
                        }
                      >
                        {" "}
                        Software Developer{" "}
                      </div>
                      <div
                        className={styles.jobsSingleItem}
                        data-value="Java"
                        onClick={(e) =>
                          handleClick(
                            e.currentTarget.getAttribute("data-value")
                          )
                        }
                      >
                        {" "}
                        Java Developer{" "}
                      </div>
                      <div
                        className={styles.jobsSingleItem}
                        data-value="Associate"
                        onClick={(e) =>
                          handleClick(
                            e.currentTarget.getAttribute("data-value")
                          )
                        }
                      >
                        {" "}
                        Associate{" "}
                      </div>
                      <div
                        className={styles.jobsSingleItem}
                        data-value="Consultant"
                        onClick={(e) =>
                          handleClick(
                            e.currentTarget.getAttribute("data-value")
                          )
                        }
                      >
                        {" "}
                        Consultant{" "}
                      </div>
                      <div
                        className={styles.jobsSingleItem}
                        data-value=" Data Analyst "
                        onClick={(e) =>
                          handleClick(
                            e.currentTarget.getAttribute("data-value")
                          )
                        }
                      >
                        {" "}
                        Data Analyst{" "}
                      </div>
                      <div
                        className={styles.jobsSingleItem}
                        data-value="Travel"
                        onClick={(e) =>
                          handleClick(
                            e.currentTarget.getAttribute("data-value")
                          )
                        }
                      >
                        {" "}
                        Travel Agent{" "}
                      </div>
                      <div
                        className={styles.jobsSingleItem}
                        data-value="full stack developer"
                        onClick={(e) =>
                          handleClick(
                            e.currentTarget.getAttribute("data-value")
                          )
                        }
                      >
                        {" "}
                        Fullstack dev{" "}
                      </div>
                      <div
                        className={styles.jobsSingleItem}
                        data-value="Django"
                        onClick={(e) =>
                          handleClick(
                            e.currentTarget.getAttribute("data-value")
                          )
                        }
                      >
                        {" "}
                        Django dev{" "}
                      </div>
                      <div
                        className={styles.jobsSingleItem}
                        data-value="App"
                        onClick={(e) =>
                          handleClick(
                            e.currentTarget.getAttribute("data-value")
                          )
                        }
                      >
                        {" "}
                        App developer{" "}
                      </div>
                    </div>
                  </div>
                  <div className={styles.horizantalBar}></div>
                  <div className={styles.jobsdiv}>
                    <div className={styles.jobsheading}>
                      {" "}
                      <SlidersHorizontal
                        size={15}
                        style={{ marginRight: "5px" }}
                      />{" "}
                      Jobs By Type
                    </div>
                    <div className={styles.jobsitemss}>
                      <div
                        className={styles.jobsSingleItem}
                        data-value="Freshers"
                        onClick={(e) =>
                          handleclickBytype(
                            e.currentTarget.getAttribute("data-value")
                          )
                        }
                      >
                        {" "}
                        Freshers{" "}
                      </div>
                      <div
                        className={styles.jobsSingleItem}
                        data-value="Experience"
                        onClick={(e) =>
                          handleclickBytype(
                            e.currentTarget.getAttribute("data-value")
                          )
                        }
                      >
                        {" "}
                        Experience{" "}
                      </div>
                       
                      
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div> */}
        </div>

        <div>
          <div
            className={styles.wavecontainer}
            onMouseEnter={handleaiMouseEnter}
            onMouseLeave={handleaiMouseLeave}
          >
            <span
              className={`${styles.name}`}
              onClick={() => navigate("/airesume")}
            >
              <span className={styles.aiResumeButton} onClick={()=>navigate('/airesume')}>
                {/* <NotepadText
                  color={"rgb(102, 108, 255)"}
                  size={16}
                  style={{ marginRight: "2px" }}
                />{" "} */}
                AI Resume{" "}
                {/* {!isaiDropdownOpen ? (
                  <ChevronDown size={14} color="black" />
                ) : (
                  <ChevronUp size={14} color="black" />
                )} */}
                <div className={styles.forhoving}> </div>
              </span>
            </span>
          </div>
          {/* <div className={styles.dropdown}>
            {isaiDropdownOpen && (
              <div
                className={`${styles.dropdowncontentreume}`}
                onMouseEnter={handleaiMouseEnter}
                onMouseLeave={handleaiMouseLeave}
              >
                <div className={styles.resumecontent}>
                     <div className={styles.hto} onClick={()=>navigate('/airesume')}> Resumes </div>
                      <div className={styles.hto} onClick={()=>navigate('/resume-examples')}> Resume Examples </div>
                </div>
              </div>
            )}
          </div> */}
        </div>
        <span className={styles.name}  onClick={() => navigate("/blogs")}>
           Blogs
        </span>
        <span className={styles.name} onClick={() => navigate("/Pricing")}>
            Pricing
        </span>
      </div>
    </div>
  );
};

export default MiddleWala;
