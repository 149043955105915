// import React from 'react'

import React, { useEffect, useRef, useState } from "react";
import styles from "../../assets/Dashboard/JobRecon.module.css";
import { Clock, Plus } from "lucide-react";
import { useResume } from "../../ResumeBuilder/builder/components/context/Resume";
import { useNavigate } from "react-router-dom";
import { privateApi } from "../../ResumeBuilder/utils/aixos";
import slugify from "slugify";
const JobRecon = ({
  modalShow,
  setModalShow,
  SecondmodalShow,
  setSecondModalShow,
}) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const containerRef = useRef(null); // Reference to the scrollable container
  const [skillfromdbapi, setskillfromdibapi] = useState(""); // Initialize as empty string
  const { values } = useResume();
  const [loading, setLoadingJobs] = useState(false);
  const datalocal = JSON.parse(localStorage.getItem("auth"));
  const [jobs, setJobs] = useState([]);
  const resume = values;
  const role = resume?.sections?.technicalSkills?.data?.[0]?.skills || "react";
  const skill_from_resume = Array.isArray(role) ? role.join(",") : ["react"];
  const mergedskill = `${skill_from_resume},${skillfromdbapi}`;
  const getUserSkill = async () => {
    try {
      const { data } = await privateApi.get(`/api/auth/skillsbyid`);
      if (data?.skillsfromdb?.length > 0) {
        setskillfromdibapi(data.skillsfromdb[0].skills);
      } else {
        console.warn("No skills found in the response");
      }
    } catch (error) {
      console.error("Error fetching user skills:", error);
    }
  };
  const getRelativeDate = (date) => {
    const now = new Date();
    const diffTime = Math.abs(now - date);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  
    if (diffDays === 0) {
      return "today";
    } else if (diffDays === 1) {
      return "yesterday";
    } else if (diffDays <= 6) {
      return `${diffDays} days ago`;
    } else if (diffDays <= 13) {
      return "1 week ago";
    } else if (diffDays <= 20) {
      return "2 weeks ago";
    } else {
      return date.toLocaleDateString("en-US", {
        weekday: "short",
        month: "short",
        day: "numeric",
      });
    }
  };
  // Fetch all jobs based on merged skills
  const getAllJobs = async () => {
    try {
      setLoadingJobs(true);
      const { data } = await privateApi.get(
        `/api/auth/filterjob?skills=${mergedskill}`
      );
      console.log(data);
      if (data?.success) {
        const sortedJobs = [...data.jobs].sort(
          (a, b) => b.percentageMatched - a.percentageMatched
        );
        setJobs(sortedJobs);
      }
      setLoadingJobs(false);
    } catch (error) {
      console.error("Error fetching jobs:", error);
      setLoadingJobs(false);
    }
  };

  // Navigate to job details
  const handleNavigate = async (value, heading) => {
    const slug = `${slugify(heading)}-${value}`;
    window.open(`/jobs/details/${slug}`, "_blank");
  };

  // Fetch user skills once on component mount
  useEffect(() => {
    if (!datalocal?.token) {
      console.error("No authentication token found in local storage");
      return;
    }
    getUserSkill();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetch jobs when skills from DB are updated
  useEffect(() => {
    if (skillfromdbapi) {
      getAllJobs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skillfromdbapi]); // Only trigger when skills from DB are updated

  return (
    <div className={styles.main}>
      <div className={styles.headingContainer}>
        <div className={styles.headingtitle}>Recommended Jobs </div>
      </div>
      <div className={styles.mainContainer}>
        

        <div className={styles.ScrollContainer} ref={containerRef}>
          {loading
            ? // Loading skeleton cards
              Array(4)
                .fill(0)
                .map((_, index) => (
                  <div
                    className={`${styles.eachContainer} ${styles.skeleton}`}
                    key={`skeleton-${index}`}
                  >
                    <div className={styles.eachcontainerUp}>
                      <div className={styles.skeletonImage} />
                    </div>
                    <div className={styles.eachcontainerDown}>
                      <div className={styles.eachdowninte}>
                        <div className={styles.left}>
                          <div className={styles.skeletonTitle} />
                          <div className={styles.skeletonDate} />
                        </div>
                        <div className={styles.Right}>
                          <div className={styles.skeletonPercent} />
                        </div>
                      </div>
                    </div>
                  </div>
                ))
            : // Existing card rendering code
              jobs?.map((item, index) => (
                <div className={styles.eachContainer} key={index}>
                  <div className={styles.eachcontainerUp}>
                    <div className={styles.eachcontainerUpImage}>
                      <img
                        src={`https://old.myfuse.in/uploads/logo/${item.companyLogo}`}
                        alt="company logo"
                      />
                    </div>
                    <div className={styles.eachcontainerUpText}>
                      <div className={styles.jobTitle}>
                        {item.jobtitle.slice(0, 25)}...
                      </div>
                      <div className={styles.companyName}>
                        {item.companyName.slice(0, 15)}
                      </div>
                    </div>
                  </div>
                  <div className={styles.eachcontainerDown}>
                    <div className={styles.eachdowninte}>
                      <div className={styles.eachdownLocation}>
                        {item.location.slice(0, 25)}
                      </div>
                      <div className={styles.eachdownPercent}>
                        {item.percentageMatched}%{" "}
                      </div>
                    </div> 
                    <div className={styles.eachdownDate}>
                          <div> <Clock size={15} /> {getRelativeDate(new Date(item.createdat))}</div>
                    </div>
                    <div className={styles.eachdownButtonContainer}>
                      <span className={styles.eachdownButton} onClick={() => handleNavigate(item.id_jobpost, item.jobtitle)}>
                        VIEW DETAILS{" "}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
        </div>

        <div className={styles.eachContainermain}>
          <div className={styles.downContainer}>
            <div className={styles.ImageContainer}>
              <img
                className={styles.Image}
                src="https://res.cloudinary.com/dmsbvsful/image/upload/v1741875702/Untitled_Artwork_31_3_p2oxon.svg"
                alt="d"
              />
            </div>
            <div className={styles.textContainer}>
              <div className={styles.title}>Find Recommended</div>
              <div className={styles.description}>Jobs</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobRecon;
