import React, { useEffect, useState } from "react";
import { useResume } from "../ResumeBuilder/builder/components/context/Resume";
import previewStyles from "../ResumeBuilder/builder/components/Preview/Preview.module.css";
import { template1 } from "../ResumeBuilder/utils/DifferentJson";
import PreviewTempalte from "../ResumeBuilder/builder/components/Preview/PreviewTempalte";
import { publicApi } from "../ResumeBuilder/utils/aixos";
const TemplatePreview = () => {
  const { templates } = useResume();
  const [allresume, setallresume] = useState([]);

  const showresumefrombackend = async () => {
    try {
      const { data } = await publicApi.get(`/api/admin/getResumeExamples`); 
      console.log(data.data);
      if (data?.success) {
        await setallresume(data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const chekcTemplate = (type) => {
    const template = Object.values(templates).find(
      (template) => template.key === type
    );
    console.log(template);
    return template;
  };

  const jsonConversion = (json) => {
    console.log(json);
    if (json == null) {
      return template1;
    }
    return JSON.parse(json);
  };

  useEffect(() => {
    showresumefrombackend();
  }, []);

  return (
    <div>
      <div>
        <div style={{ padding: "20px" }}>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(4, 1fr)",
              gap: "20px",
              padding: "20px",
            }}
          >
            {/* {Object.values(templates).map((template, index) =>  
              <>
                <div
                  key={index}
                  style={{
                    cursor: "pointer",
                    border: "1px solid #e0e0e0",
                    borderRadius: "8px",
                    overflow: "hidden",
                    transition: "transform 0.2s, box-shadow 0.2s",
                    ":hover": {
                      transform: "scale(1.02)",
                      boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                    },
                  }}
                >
                  <div>
                   
                    <div >
                      <PreviewTempalte
                        template={template}
                        values={template1}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}  */}

            {allresume.map((item, index) => {
              return (
                <>
                  <div
                    key={index}
                    style={{
                      cursor: "pointer",
                      border: "1px solid #e0e0e0",
                      borderRadius: "8px",
                      overflow: "hidden",
                      transition: "transform 0.2s, box-shadow 0.2s",
                      ":hover": {
                        transform: "scale(1.02)",
                        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                      },
                    }}
                  >
                    <PreviewTempalte
                      template={chekcTemplate(item.template_key)}
                      values={jsonConversion(item.resumeValues)}
                    />
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TemplatePreview;
