import React, { useState } from "react";
import styles from "./SideNavbar.module.css";
import { useNavigate } from "react-router-dom";
import { BarChart2, BriefcaseBusiness, ChevronRight, FilePen, FileSearch, Pen, Pencil, PenTool, Settings, SquareGanttChart, Sparkles } from "lucide-react";
import { useJobs } from "./components/context/Jobs";
import Model from "../../components/Model";

const SideNavbar = ({image,auth}) => {
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const { setActiveResume, activeResume } = useJobs();
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        {/* <span className={styles.logo} onClick={()=>navigate("/welcome")}>
          MyFuse<span className={styles.dorcolor}>.</span>In
        </span> */}
        <div className={styles.subcontainer}>
          {/* <div className={styles.profilecontainer}>
            <div className={styles.imageContainer}>
              <img src={image} alt="profile" className={styles.profileimage} />
              <span onClick={() => navigate("/welcome/account")}>
                <img  src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1729186563/all/bqrjzzlw6qfepvwn0o3g.svg" alt="pen" className={styles.penimage} />
              </span>
            </div>
          </div> */}
          {/* <div className={styles.detailsContainer}>
            <span className={styles.name}>{auth?.user?.[0]?.firstname} {auth?.user?.[0]?.lastname} </span>
          </div> */}
          <div className={styles.navigationContainer}>
            <div className={`${styles.ee} ${activeResume === 1 ? styles.active : ""}`} onClick={() => setActiveResume(1)}>
               <Pencil size={24} />
              <span className={styles.iconLabel}>Fill In </span>
              <div className={`${styles.bar} ${activeResume === 1 ? styles.baractive : ""}`}></div>
            </div>
            <div className={`${styles.ee} ${activeResume === 4 ? styles.active : ""}`} onClick={() => setActiveResume(4)}>
              {/* <img src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1729191042/all/lzu9h5j3quekpekqlxm2.svg" alt="Examples" /> */}
              <PenTool size={24} />
              <span className={styles.iconLabel}>Designs </span>
              <div className={`${styles.bar} ${activeResume === 4 ? styles.baractive : ""}`}></div>
            </div>
            <div className={`${styles.ee} ${activeResume === 2 ? styles.active : ""}` } onClick={() => setActiveResume(2)}>
                <SquareGanttChart size={24} />
              <span className={styles.iconLabel}>Analysis</span>
              <div className={`${styles.bar} ${activeResume === 2 ? styles.baractive : ""}`}></div>
            </div>
            <div className={`${styles.ee} ${activeResume === 3 ? styles.active : ""}`} onClick={() => setActiveResume(3)}   >
                <FileSearch size={24} />
              <span className={styles.iconLabel}>ATS</span>
              <div className={`${styles.bar} ${activeResume === 3 ? styles.baractive : ""}`}></div>
            </div>
            <div className={`${styles.ee} ${activeResume === 5 ? styles.active : ""}`}  onClick={() => setActiveResume(5)}>
                <BriefcaseBusiness size={24} /> 
              <span className={styles.iconLabel} > Job Picks</span>
              <div className={`${styles.bar} ${activeResume === 5 ? styles.baractive : ""}`}></div>
            </div>

            {/* Pro upgrade section */}
            <div className={styles.proUpgradeContainer} onClick={() => setModalShow(true)}>
              <div className={styles.proRibbon}>PRO</div>
              <div className={styles.sparkleEffect}>
                <div className={styles.sparkle}></div>
                <div className={styles.sparkle}></div>
                <div className={styles.sparkle}></div>
              </div>
              <div className={styles.proContent}>
                <div className={styles.proIcon}>
                  <img 
                    src="https://res.cloudinary.com/dmsbvsful/image/upload/v1741875702/Untitled_Artwork_31_3_p2oxon.svg" 
                    alt="Premium" 
                    className={styles.proImage}
                  />
                </div>
                <div className={styles.proTextContent}>
                  <span className={styles.proTitle}>Upgrade to Pro</span>
                  <span className={styles.proDescription}>Unlock premium features</span>
                </div>
                <ChevronRight size={16} className={styles.proArrow} color="#ffd700" />
              </div>
            </div>

            {/* <div className={`${styles.ee} ${activeResume === 6 ? styles.active : ""}`}  onClick={() => setActiveResume(6)}>
              <Settings size={20} />
              <span className={styles.iconLabel} >Settings</span>
              <div className={`${styles.bar} ${activeResume === 6 ? styles.baractive : ""}`}></div>
            </div> */}
          </div>
        </div>
      </div>
      <Model modalShow={modalShow} setModalShow={setModalShow} />
    </div>
  );
};

export default SideNavbar;
