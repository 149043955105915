import React from 'react'
import styles from "../../assets/Dashboard/ResumePreviewImage.module.css"
import { useResume } from '../../ResumeBuilder/builder/components/context/Resume';
const PreviewImageDash = ({template , values}) => {

    const { templates } = useResume();
  
    const getTemplateComponent = (key) => {
      if (!key || !templates[key]) {
        console.log('Template not found for key:', key);
        return null;
      }
      return templates[key].resume;
    };
  
    const ResumeTemplate = getTemplateComponent(template?.key);
  

  return (
    <div> <div className={styles.gridContainer}>
    <div className={styles.gridWrapper}>
      <div className={styles.gridScaler}>
        <div 
          id="print_content"
          className={styles.gridContent}
        >
          <ResumeTemplate sections={values.sections} />
        </div>
      </div>
    </div>
  </div>  </div>
  )
}

export default PreviewImageDash