import React from 'react'
import styles from '../../assets/Dashboard/Upgrade.module.css'
const Upgrade = () => {
  return (
    <div className={styles.container}>
        <div className={styles.upgrade}> 
            <div className={styles.left}>
                <span className={styles.title}>Get the most out of MyFuse.In</span>
                 
            </div>
            <div className={styles.center}>    
                <span className={styles.description}>Upgrade your subscription to get unlimited features and
                unlock premium templates and create more resumes.</span>
            </div>
            <div className={styles.right}>
                <span className={styles.button}>UPGRADE TO PRO</span>
            </div>
             
        </div>
    </div>
  )
}
 
export default Upgrade