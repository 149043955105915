"use client";
import React, { useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import styles from "./FindJobs.module.css";
import { useNavigate } from "react-router-dom";
import { publicApi } from "../../../ResumeBuilder/utils/aixos";
import slugify from "slugify";
export default function FindJobs() { 
  const navigate = useNavigate();
  const [jobs, setJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [autoScrollEnabled, setAutoScrollEnabled] = useState(true);
  const [autoScrollPaused, setAutoScrollPaused] = useState(false);
  
  // Fetch jobs from API
  useEffect(() => {
    const fetchJobs = async () => {
      try {
        setIsLoading(true);
        const response = await publicApi.post('/api/auth/filterJobPost?page=1', {});
        if (response.data?.success) {
          setJobs(response.data.users); 
          console.log(response.data.users);
        }
      } catch (error) {
        console.error("Error fetching jobs:", error);
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchJobs();
  }, []);
  
  const slideWidth = 324;
  const maxIndex = Math.max(jobs.length - 1, 0);

  const [currentIndex, setCurrentIndex] = useState(0);
  const controls = useAnimation();

  // Auto-scroll interval
  useEffect(() => {
    let autoScrollInterval;
    
    if (autoScrollEnabled && !autoScrollPaused && jobs.length > 0) {
      autoScrollInterval = setInterval(() => {
        setCurrentIndex((prevIndex) => {
          // For infinite scrolling, go back to the beginning when reaching the end
          if (prevIndex >= maxIndex) {
            return 0;
          }
          return prevIndex + 1;
        });
      }, 3000); // Change slide every 3 seconds
    }
    
    return () => {
      if (autoScrollInterval) clearInterval(autoScrollInterval);
    };
  }, [autoScrollEnabled, autoScrollPaused, jobs.length, maxIndex]);

  // Animate to the current index
  const animateToIndex = (newIndex) => {
    controls.start({
      x: `-${newIndex * slideWidth}px`,
      transition: { duration: 0.6, ease: "easeInOut" },
    });
  };

  // Handle Swipe for Mobile
  const handleSwipe = (direction) => {
    // Pause auto-scroll when user interacts
    setAutoScrollPaused(true);
    
    if (direction === "left" && currentIndex < maxIndex) {
      setCurrentIndex(currentIndex + 1);
    } else if (direction === "right" && currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
    
    // Resume auto-scroll after 5 seconds of inactivity
    setTimeout(() => {
      setAutoScrollPaused(false);
    }, 5000);
  };

  const getRelativeDate = (date) => {
    const now = new Date();
    const diffTime = Math.abs(now - date);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  
    if (diffDays === 0) {
      return "today";
    } else if (diffDays === 1) {
      return "yesterday";
    } else if (diffDays <= 6) {
      return `${diffDays} days ago`;
    } else if (diffDays <= 13) {
      return "1 week ago";
    } else if (diffDays <= 20) {
      return "2 weeks ago";
    } else {
      return date.toLocaleDateString("en-US", {
        weekday: "short",
        month: "short",
        day: "numeric",
      });
    }
  };

  const handleNavigate = async (value, heading) => {
    const slug = `${slugify(heading)}-${value}`;
    window.open(`/jobs/details/${slug}`, "_blank");
  };

  useEffect(() => {
    animateToIndex(currentIndex);
  }, [currentIndex]);

  // Handle Previous and Next Buttons (Desktop Only)
  const handlePrev = () => {
    // Pause auto-scroll when user interacts
    setAutoScrollPaused(true);
    
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    } else {
      // For infinite scrolling, go to the end when at the beginning
      setCurrentIndex(maxIndex);
    }
    
    // Resume auto-scroll after 5 seconds of inactivity
    setTimeout(() => {
      setAutoScrollPaused(false);
    }, 5000);
  };

  const handleNext = () => {
    // Pause auto-scroll when user interacts
    setAutoScrollPaused(true);
    
    if (currentIndex < maxIndex) {
      setCurrentIndex(currentIndex + 1);
    } else {
      // For infinite scrolling, go back to the beginning when at the end
      setCurrentIndex(0);
    }
    
    // Resume auto-scroll after 5 seconds of inactivity
    setTimeout(() => {
      setAutoScrollPaused(false);
    }, 5000);
  };

  // Format the posted date
  const formatPostedDate = (dateString) => {
    const postedDate = new Date(dateString);
    const currentDate = new Date();
    const diffTime = Math.abs(currentDate - postedDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    
    if (diffDays === 0) return "Today";
    if (diffDays === 1) return "Yesterday";
    if (diffDays < 7) return `${diffDays} days ago`;
    if (diffDays < 30) return `${Math.floor(diffDays / 7)} weeks ago`;
    return `${Math.floor(diffDays / 30)} months ago`;
  };

  return (
    <section className={styles.findJobsSection}>
      {/* Heading */}
      <h2 className={styles.heading}>Find Matching Jobs</h2>
      <p className={styles.subheading}>
        Create or upload your resume to match relevant job opportunities
      </p>

      {/* Carousel Container */}
      <div className={styles.carouselContainer}
           onMouseEnter={() => setAutoScrollPaused(true)}
           onMouseLeave={() => setAutoScrollPaused(false)}
           onTouchStart={() => setAutoScrollPaused(true)}>
        {isLoading ? (
          <div className={styles.loadingContainer}>
            <div className={styles.loadingSpinner}></div>
            <p>Loading jobs...</p>
          </div>
        ) : jobs.length === 0 ? (
          <div className={styles.noJobsContainer}>
            <p>No jobs found. Please try again later.</p>
          </div>
        ) : (
          <div className={styles.carouselWrapper}>
            <motion.div
              className={styles.carouselTrack}
              style={{ touchAction: "pan-y" }}
              animate={controls}
              drag="x"
              dragConstraints={{ left: -slideWidth * maxIndex, right: 0 }}
              dragDirectionLock={true}
              dragPropagation={true}
              dragElastic={0.2}
              dragMomentum={false}
              onDragEnd={(event, info) => {
                if (info.offset.x < -50) {
                  handleSwipe("left");
                } else if (info.offset.x > 50) {
                  handleSwipe("right");
                }
              }}
            >
              {jobs.map((job) => (
                <div key={job._id} className={styles.jobCard}>
                  <div className={styles.cardTop}>
                    <div className={styles.logoContainer}>
                      <img
                        src={`https://old.myfuse.in/uploads/logo/${job.companyLogo}`}
                        alt={`${job.companyName || 'Company'} Logo`}
                        className="w-12 h-12"
                        style={{ width: "3rem", height: "3rem" }}
                        onError={(e) => {
                          e.target.src = "/images/companylogos/default.svg";
                        }}
                      />
                    </div>
                    <div className={styles.jobTitleSection}>
                      <h3 className={styles.jobTitle}>{job.jobtitle.length > 14 ? job.jobtitle.substring(0, 15) + "..." : job.jobtitle || "Job Title"}</h3>
                      <p className={styles.jobCategory}>{job.companyName || "Industry"}</p>
                    </div>
                  </div>
                  <div className={styles.jobDetails}>
                    <p>{job.location.split(",")[0] || "Location"}</p>
                    <p>{job.job_nature || "Job Type"}</p>
                  </div>
                  <p className={styles.postedInfo}>
                    ⏳ Posted {getRelativeDate(new Date(job.createdat))}
                  </p>
                  <hr className={styles.divider} />
                  <button 
                    className={styles.viewDetailsButton}
                    onClick={() => handleNavigate(job.id_jobpost, job.jobtitle)}
                  >
                    VIEW DETAILS
                  </button>
                </div>
              ))}
            </motion.div>
          </div>
        )}
      </div>

      {/* Dot Indicators (Hidden on Desktop) */}
      <div className={styles.dotIndicators}>
        {jobs.slice(0, 5).map((_, index) => (
          <span
            key={index}
            className={`${styles.dot} ${
              index === currentIndex ? styles.activeDot : styles.inactiveDot
            }`}
          />
        ))}
      </div>

      {/* Navigation Buttons (Desktop Only) */}
      <div className={styles.navButtonsContainer}>
        <div className={styles.emptyDiv} />
        <div className={styles.findMoreCenter}>
          <button onClick={() => navigate("/jobs-in-india")}>Find more</button>
        </div>
        <div className={styles.navButtons}>
          <button
            onClick={handlePrev}
            className={styles.navButton}
          >
            <FaArrowLeft />
          </button>
          <button
            onClick={handleNext}
            className={styles.navButton}
          >
            <FaArrowRight />
          </button>
        </div>
      </div>
    </section>
  );
}
