import React, { useEffect, useRef, useState } from "react";
import styles from "./Preview.module.css";
import { useResume } from "../context/Resume";
import { htmlToCanvasImage } from "../../../utils/html_to_canvas_image";

import { ChevronDown, ChevronUp, Upload, Maximize2 } from "lucide-react";
import { useJobs } from "../context/Jobs";
import html2canvas from "html2canvas";
import Model from "../../../../components/Model";
import { motion, AnimatePresence } from "framer-motion";
import { jsPDF } from "jspdf";
import { useAuth } from "../context/auth";

const PreviewLoader = () => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    className="d-flex justify-content-center align-items-center flex-column"
    style={{
      minHeight: "842px",
      width: "100%",
      background: "#ffffff",
      padding: "2rem",
      borderRadius: "12px",
      boxShadow: "0 8px 24px rgba(0, 0, 0, 0.06)",
      position: "relative",
    }}
  >
    <motion.div
      className="text-center"
      style={{
        maxWidth: "400px",
        padding: "2rem",
        background: "#f8fafc",
        borderRadius: "12px",
        boxShadow: "0 4px 16px rgba(0, 0, 0, 0.03)",
      }}
    >
      {/* Logo */}
      <motion.div
        style={{
          width: "48px",
          height: "48px",
          borderRadius: "12px",
          background: "#4F46E5",
          margin: "0 auto 1.5rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#ffffff"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5" />
        </svg>
      </motion.div>

      {/* Text Content */}
      <h2
        style={{
          fontSize: "20px",
          fontWeight: "600",
          color: "#1f2937",
          marginBottom: "0.75rem",
        }}
      >
        Generating Preview
      </h2>
      <p
        style={{
          fontSize: "14px",
          color: "#6b7280",
          marginBottom: "2rem",
        }}
      >
        Please wait while we prepare your resume
      </p>

      {/* Loading Indicator */}
      <div className="d-flex gap-2 justify-content-center">
        {[...Array(3)].map((_, i) => (
          <motion.div
            key={i}
            style={{
              width: "8px",
              height: "8px",
              borderRadius: "50%",
              background: "#4F46E5",
            }}
            animate={{
              scale: [1, 1.2, 1],
              opacity: [0.3, 1, 0.3],
            }}
            transition={{
              duration: 1,
              repeat: Infinity,
              delay: i * 0.2,
            }}
          />
        ))}
      </div>
    </motion.div>
  </motion.div>
);

const PreviewImage = ({
  image,
  orginal,
  previewResume,
  templates,
  selectedTemplate,
  handleImageLoad,
  isImageLoaded,
  handleDownload
}) => {
  const [isMaximized, setIsMaximized] = useState(false);

  const handleMaximize = () => {
    setIsMaximized(true);
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        style={{ position: "relative", display: "inline-block" }}
      >
        <div className={styles.buttonContainer}>
          <div className={styles.uploadContainer} onClick={handleMaximize}>
            <Maximize2 size={20} />
            <div className={styles.horizontalLine}></div>
          </div>
          <div onClick={handleDownload}>
            <Upload size={20} />
          </div>
        </div>
        <motion.img
          src={orginal ? image : previewResume || templates[selectedTemplate?.key].preview}
          alt="Resume"
          onLoad={handleImageLoad}
          className={isImageLoaded ? styles.imageLoaded : ""}
          transition={{ type: "spring", stiffness: 300 }}
        />
      </motion.div>

      {/* A4 Maximized Image Modal */}
      {isMaximized && (
        <div className={styles.maximizedOverlay}>
          <div className={styles.maximizedA4Container}>
            <button 
              className={styles.closeButton}
              onClick={() => setIsMaximized(false)}
            >
              ✕
            </button>
            <div className={styles.a4Paper}>
              <img
                src={orginal ? image : previewResume || templates[selectedTemplate?.key].preview}
                alt="Resume"
                className={styles.maximizedImage}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};


const TopTab = ({isOpen, setIsOpen, activeTab, setActiveTab , tabdisabled  }) => {
  const {
    handleTemplateChange,
    templates,
    selectedTemplate,
    activeTabExperience,
    setActiveTabExperience,
    CurentSection,
    activeTabSkills,
    setActiveTabSkills,
  } = useResume();
  
  
  return (
    <div className={styles.templateContainer}>
      <div className={styles.templateRowItemHeader}>
        <div className={styles.tabContainer}>
          <span
            className={`${styles.templateName} ${
              activeTab === "1" ? styles.activeTab : ""
            }`}
            onClick={() => setActiveTab("1")}
          >
            {/* <strong> Template : {selectedTemplate?.name}</strong> */}
            Templates
          </span>
          <span
            className={`${styles.templateName} ${
              activeTab === "2" ? styles.activeTab : ""
            } ${tabdisabled ? styles.disabled : ""}`}
            onClick={() => !tabdisabled && setActiveTab("2")}
            style={{
              cursor: tabdisabled ? "not-allowed" : "pointer",
              opacity: tabdisabled ? "0.5" : "1",
            }}
          >
            Layouts and Formats
          </span>
        </div>
        <div
          className={styles.chevronUpDownContainer}
          onClick={() => setIsOpen(!isOpen)}
        >
          {!isOpen ? (
            <ChevronDown size={20} color="#898989" />
          ) : (
            <ChevronUp size={20} color="#898989" />
          )}
        </div>
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className={styles.templateRowItem}
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{
              type: "spring",
              duration: 0.5,
              bounce: 0.2,
            }}
          >
            {activeTab === "1" ? (
              <motion.div
                className={styles.templatesRow}
                initial={{ y: -20 }}
                animate={{ y: 0 }}
                exit={{ y: -20 }}
                transition={{ duration: 0.3 }}
              >
                {Object.entries(templates).map(([key, template]) => (
                  <div
                    key={key}
                    className={`${styles.templateItem} ${
                      selectedTemplate?.key === key
                        ? styles.selectedTemplate
                        : ""
                    }`}
                    onClick={() => handleTemplateChange(key)}
                  >
                    <div className={styles.templateImage}>
                      <img
                        src={template.preview}
                        alt={`Template ${key}`}
                        loading="lazy"
                        width={595} // A4 width in pixels at 72 DPI
                        height={842} // A4 height in pixels at 72 DPI
                        style={{
                          maxWidth: "595px",
                          height: "auto",
                          objectFit: "cover",
                          imageRendering: "crisp-edges",
                          WebkitImageRendering: "crisp-edges",
                          transform: "translateZ(0)",
                          willChange: "transform",
                          filter: "contrast(1.02)", // Slightly increase contrast
                          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                        }}
                        onLoad={(e) => {
                          // Ensure image is rendered at highest quality
                          e.target.style.imageRendering = "auto";
                          setTimeout(() => {
                            e.target.style.imageRendering = "crisp-edges";
                          }, 0);
                        }}
                      />
                    </div>
                  </div>
                ))}
              </motion.div>
            ) : (
              <>
                {CurentSection === "Experience" ? (
                  <div className={styles.layoutAndFormatContainer}>
                    <div className={styles.eachItemContainer}>
                      <div className={styles.eachItemWrapper}>
                        <span className={styles.eachItemTitle}>
                          Simple List
                        </span>
                        <div
                          className={`${styles.eachItem} ${
                            activeTabExperience === 0
                              ? styles.eachItemActive
                              : ""
                          }`}
                          onClick={() => setActiveTabExperience(0)}
                        >
                          <div className={styles.innerItem}>
                            <div className={styles.innerItemTitle}>Role</div>
                            <ul>
                              <li>
                                {" "}
                                <span className={styles.innerItemKeyPoints}>
                                  Key Points
                                </span>
                              </li>
                              <li>
                                {" "}
                                <span className={styles.innerItemKeyPoints}>
                                  Key Points
                                </span>
                              </li>
                              <li>
                                {" "}
                                <span className={styles.innerItemKeyPoints}>
                                  Key Points
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className={styles.eachItemWrapper}>
                        <span className={styles.eachItemTitle}>
                          Problem solution Impact
                        </span>
                        <div
                          className={`${styles.eachItem} ${
                            activeTabExperience === 2
                              ? styles.eachItemActive
                              : ""
                          }`}
                          onClick={() => setActiveTabExperience(2)}
                        >
                          <div className={styles.innerItem}>
                            <div className={styles.innerItemTitle}>Role</div>
                            <ul>
                              <li>
                                {" "}
                                <strong>Problem :</strong>{" "}
                                <span className={styles.innerItemKeyPoints}>
                                  Key Points
                                </span>
                              </li>
                              <li>
                                {" "}
                                <strong>Solution : </strong>{" "}
                                <span className={styles.innerItemKeyPoints}>
                                  Key Points
                                </span>
                              </li>
                              <li>
                                {" "}
                                <strong>Impact : </strong>{" "}
                                <span className={styles.innerItemKeyPoints}>
                                  Key Points
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className={styles.eachItemWrapper}>
                        <span className={styles.eachItemTitle}>
                          Responsiblity and Accomplishments
                        </span>
                        <div
                          className={`${styles.eachItem} ${
                            activeTabExperience === 1
                              ? styles.eachItemActive
                              : ""
                          }`}
                          onClick={() => setActiveTabExperience(1)}
                        >
                          <div className={styles.innerItem}>
                            <div className={styles.innerItemTitle}>Role</div>
                            <ul>
                              <li>
                                {" "}
                                <strong>Responsiblity :</strong>{" "}
                                <span className={styles.innerItemKeyPoints}>
                                  Key Points
                                </span>
                              </li>
                              <li>
                                {" "}
                                <strong>Accomplishments :</strong>{" "}
                                <span className={styles.innerItemKeyPoints}>
                                  Key Points
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className={styles.eachItemWrapper}>
                        <span className={styles.eachItemTitle}>
                          Detailed Groups
                        </span>
                        <div
                          className={`${styles.eachItem} ${
                            activeTabExperience === 3
                              ? styles.eachItemActive
                              : ""
                          }`}
                          onClick={() => setActiveTabExperience(3)}
                        >
                          <div className={styles.innerItem}>
                            <div className={styles.innerItemTitle}>Role</div>
                            <ul>
                              <li>
                                {" "}
                                <strong>Performance :</strong>{" "}
                                <span className={styles.innerItemKeyPoints}>
                                  Key Points
                                </span>
                              </li>
                              <li>
                                {" "}
                                <strong>Cost Efficiency :</strong>{" "}
                                <span className={styles.innerItemKeyPoints}>
                                  Key Points
                                </span>
                              </li>
                              <li>
                                {" "}
                                <strong>Compliance :</strong>{" "}
                                <span className={styles.innerItemKeyPoints}>
                                  Key Points
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={styles.layoutAndFormatContainer}>
                    <div className={styles.eachItemContainer}>
                      <div className={styles.eachItemWrapper}>
                        <span className={styles.eachItemTitle}>
                          Simple List
                        </span>
                        <div
                          className={`${styles.eachItem} ${
                            activeTabSkills === 0 ? styles.eachItemActive : ""
                          }`}
                          onClick={() => setActiveTabSkills(0)}
                        >
                          <div className={styles.innerItem}>
                            <ul>
                              <li>
                                {" "}
                                <span className={styles.innerItemKeyPoints}>
                                  Key Skills
                                </span>
                              </li>
                              <li>
                                {" "}
                                <span className={styles.innerItemKeyPoints}>
                                  Key Skills
                                </span>
                              </li>
                              <li>
                                {" "}
                                <span className={styles.innerItemKeyPoints}>
                                  Key Skills
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className={styles.eachItemWrapper}>
                        <span className={styles.eachItemTitle}>
                          Two Columns
                        </span>
                        <div
                          className={`${styles.eachItem} ${
                            activeTabSkills === 1 ? styles.eachItemActive : ""
                          }`}
                          onClick={() => setActiveTabSkills(1)}
                        >
                          <div className={styles.innerItem}>
                            <ul>
                              <li>
                                {" "}
                                <strong>Soft Skills : </strong>{" "}
                                <span className={styles.innerItemKeyPoints}>
                                  Key Skills
                                </span>
                              </li>

                              <li>
                                {" "}
                                <strong>Technical Skills :</strong>{" "}
                                <span className={styles.innerItemKeyPoints}>
                                  Key Skills
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className={styles.eachItemWrapper}>
                        <span className={styles.eachItemTitle}>
                          Detailed Groups
                        </span>
                        <div
                          className={`${styles.eachItem} ${
                            activeTabSkills === 2 ? styles.eachItemActive : ""
                          }`}
                          onClick={() => setActiveTabSkills(2)}
                        >
                          <div className={styles.innerItem}>
                            <ul>
                              <li>
                                {" "}
                                <strong>Tools :</strong>{" "}
                                <span className={styles.innerItemKeyPoints}>
                                  Key Points
                                </span>
                              </li>
                              <li>
                                {" "}
                                <strong>Soft Skills :</strong>{" "}
                                <span className={styles.innerItemKeyPoints}>
                                  Key Skills
                                </span>
                              </li>
                              <li>
                                {" "}
                                <strong>Custom Skills :</strong>{" "}
                                <span className={styles.innerItemKeyPoints}>
                                  Key Skills
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

 const TopTabAts = ({isOpen, setIsOpen, activeTab, setActiveTab , tabdisabled , templates , selectedTemplate , handleTemplateChange , activeTabExperience , setActiveTabExperience , activeTabSkills , setActiveTabSkills , setCurentSection }) => {
  const {jobDetails} = useResume();
  return (
    <div className={styles.templateContainer}>
      <div className={styles.templateRowItemHeader}>
        <div className={styles.tabContainer}>
          <span
            className={`${styles.templateName} ${
              activeTab === "1" ? styles.activeTab : ""
            }`}
            onClick={() => setActiveTab("1")}
          >
            {/* <strong> Template : {selectedTemplate?.name}</strong> */}
            Job Description Content
          </span>
          <span
            className={`${styles.templateName}`}
            onClick={() => setActiveTab("2")}
            style={{
               
              opacity: tabdisabled ? "0.5" : "1",
            }}
          >
            Recruiter Lens
          </span>
        </div>
        <div
          className={styles.chevronUpDownContainer}
          onClick={() => setIsOpen(!isOpen)}
        >
          {!isOpen ? (
            <ChevronDown size={20} color="#898989" />
          ) : (
            <ChevronUp size={20} color="#898989" />
          )}
        </div>
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className={styles.templateRowItem}
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{
              type: "spring",
              duration: 0.5,
              bounce: 0.2,
            }}
          >
            {activeTab === "1" ? (
              <motion.div
                className={styles.templatesRow}
                initial={{ y: -20 }}
                animate={{ y: 0 }}
                exit={{ y: -20 }}
                transition={{ duration: 0.3 }} 
                style={{
                  height: "10rem",
                  whiteSpace: "pre-wrap",
                  color: "black",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "20px",
                  letterSpacing: "0.25px",
                  textAlign: "left",
                }}
              >
                 {jobDetails}
              </motion.div>
            ) : (
              <>
              
                  <div className={styles.layoutAndFormatContainer}>
                      asasdsfsafasa
                  </div>
                
              </>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
 }

const Preview = ({ setPreviewLoading, previewLoading }) => {
  const {
    values,
    printContainerRef,
    handleTemplateChange,
    templates,
    selectedTemplate,
    currentStep,
    activeTabExperience,
    setActiveTabExperience,
    CurentSection,
    activeTabSkills,
    setActiveTabSkills,
  } = useResume();

  const { payment, previewResume ,activeResume } = useJobs();
  const previewRef = useRef(null);
  const [modalShow, setModalShow] = useState(false);
  const [image, setImage] = useState(null);
  const [imageTransformsY, setImageTransformsY] = useState([]);
  const [selectedImageTransformY, setSelectedImageTransformY] = useState(0);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [orginal, setOriginal] = useState(true);
  const [hasShownWarning, setHasShownWarning] = useState(false);
  const [isExceedingA4, setIsExceedingA4] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [tabdisabled, settabdisabaled] = useState(true);
  const [isOpen, setIsOpen] = useState(true);
  const { auth } = useAuth();
  const getA4Images = () => {
    const newImage = new Image();
    newImage.src = image;

    newImage.onload = () => {
      const width = newImage.width;
      const height = newImage.height;
      const a4aspectRatio = 210 / 297;
      const a4Height = width / a4aspectRatio;
      let h = height;
      const transformsY = [];
      let transformY = 0;
      // Calculate the transformations for A4 pages
      while (h > 0) {
        transformsY.push(transformY);
        transformY += a4Height;
        h -= a4Height;
      }
      setImageTransformsY(transformsY);
    };
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (values) {
        if (printContainerRef.current) {
          htmlToCanvasImage(
            printContainerRef.current,
            payment,
            values,
            currentStep
          ).then((img) => {
            setImage(img);
          });
        }
      }
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [values, handleTemplateChange]);

  const handleImageLoad = () => {
    setIsImageLoaded(true);
    setPreviewLoading(false);
  };

  const handleDownladImage = async () => {
    const canvas = await html2canvas(printContainerRef.current);
    const imgData = canvas.toDataURL("image/png");
    const response = await fetch(imgData);
    const blob = await response.blob();
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${values.name}.png`;
    link.click();
  };

  const handlePrintPDF = () => {
    const pageMargins = selectedTemplate.page_margins;
    const style = document.createElement("style");
    style.innerHTML = `@page { margin: ${pageMargins} !important; size: A4 !important; }`;
    const printContent = document.getElementById("print_content");
    printContent.style.width = `calc(210mm - 2 * ${pageMargins})`;
    const originalPadding = printContainerRef.current.style.padding;
    printContainerRef.current.style.padding = "0";
    document.head.appendChild(style);
    window.print();
    style.remove();
    printContent.style.width = "100%";
    printContainerRef.current.style.padding = originalPadding;
  };

  const checkA4Size = () => {
    if (!printContainerRef.current) return;

    const element = printContainerRef.current;
    const a4HeightPx = 1123; // Approximately 297mm in pixels at 96 DPI
    const currentHeight = element.scrollHeight;

    if (currentHeight > a4HeightPx && !hasShownWarning) {
      setIsExceedingA4(true);
      setHasShownWarning(true);
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      checkA4Size();
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [values]);

  const handleGeneratePdf = async () => {
    const element = printContainerRef.current;

    try {
      const canvas = await html2canvas(element, {
        scale: 2,
        useCORS: true,
        logging: false,
        onclone: (clonedDoc) => {
          const textElements = clonedDoc.querySelectorAll("*");
          textElements.forEach((el) => {
            if (
              el.innerText &&
              window.getComputedStyle(el).display !== "none"
            ) {
              const styles = window.getComputedStyle(el);
              el.style.fontFamily = styles.fontFamily;
              el.style.fontSize = styles.fontSize;
              el.style.fontWeight = styles.fontWeight;
              el.style.color = styles.color;
            }
          });
        },
      });

      // A4 dimensions
      const a4Width = 210; // mm
      const a4Height = 297; // mm
      const footerHeight = 15; // mm
      const effectiveHeight = a4Height - footerHeight;

      // Create PDF
      const pdf = new jsPDF({
        unit: "mm",
        format: "a4",
        orientation: "portrait",
        putOnlyUsedFonts: true,
        compress: true,
      });

      // Calculate dimensions
      const imgWidth = a4Width;
      const imgHeight = (canvas.height * a4Width) / canvas.width;
      console.log("imgHeight", imgHeight);
      // Calculate number of pages needed
      const pageCount = Math.ceil(imgHeight / (effectiveHeight + 16));
      console.log("effectiveHeight", effectiveHeight);
      console.log("pageCount", pageCount);

      // Footer function
      const spaceBottom = () => {
        pdf.setFillColor(255, 255, 255); // white background
        pdf.rect(0, a4Height - footerHeight, a4Width, footerHeight, "F");
        pdf.setFontSize(8);
        pdf.setTextColor(107, 114, 128); // Gray text
      };

      const spaceTop = () => {
        pdf.setFillColor(255, 255, 255); // white background
        pdf.rect(0, 0, a4Width, footerHeight, "F");
        pdf.setFontSize(8);
        pdf.setTextColor(107, 114, 128); // Gray text
      };

      // Get text content for searchability
      const textContent = element.innerText;

      // For each page
      for (let page = 0; page < pageCount; page++) {
        if (page > 0) {
          pdf.addPage();
        }

        // Add image with offset for current page
        pdf.addImage(
          canvas.toDataURL("image/jpeg", 1.0),
          "JPEG",
          0, // x offset
          -page * (effectiveHeight - (page > 0 ? footerHeight : 0)), // Adjust offset for top space on subsequent pages
          imgWidth, // image width
          imgHeight, // image height
          undefined, // alias
          "FAST" // compression
        );

        // Add spacing
        spaceBottom();
        if (page > 0) {
          spaceTop();
        }

        // Add hidden searchable text layer
        pdf.setFontSize(1); // Very small font size
        pdf.setTextColor(255, 255, 255); // White color (invisible)

        // Add text at the bottom of each page
        const bottomMargin = 5; // 5mm from bottom
        const textY = a4Height - bottomMargin;

        pdf.text(textContent, 0, textY, {
          maxWidth: a4Width,
          align: "left",
        });
      }

      // Save the PDF
      pdf.save(
        `${auth?.user?.[0]?.firstname}_${auth?.user?.[0]?.lastname}_resume.pdf`
      );
    } catch (error) {
      console.error("PDF generation failed:", error);
    }
  };

  useEffect(() => {
    if (CurentSection === "Experience" || CurentSection === "Skills") {
      setActiveTab("2");
      settabdisabaled(false);
      setIsOpen(true);
    } else {
      setActiveTab("1");
      settabdisabaled(true);
    }
  }, [CurentSection]);


 
  
  return (
    <div className={styles.preview}>
      {/* <div className={styles.sfs}>
        <div className={styles.containerButton}>
          <div
            className={`${styles.innerbutton} ${
              !orginal ? styles.buttonActive : ""
            }`}
            onClick={() => setOriginal(false)}
          >
            Resume Example
          </div>
          <div
            className={`${styles.innerbutton} ${
              orginal ? styles.buttonActive : ""
            }`}
            onClick={() => setOriginal(true)}
          >
            Your Resume
          </div>
        </div>
      </div>  */}

       {activeResume === 1 ? <TopTab isOpen={isOpen} setIsOpen={setIsOpen} activeTab={activeTab} setActiveTab={setActiveTab} tabdisabled={tabdisabled} settabdisabaled={settabdisabaled} templates={templates} activeTabExperience={activeTabExperience} setActiveTabExperience={setActiveTabExperience} activeTabSkills={activeTabSkills} setActiveTabSkills={setActiveTabSkills}  CurentSection={CurentSection}  handleTemplateChange={handleTemplateChange} /> : null}
       {activeResume === 3 ? <TopTabAts isOpen={isOpen} setIsOpen={setIsOpen} activeTab={activeTab} setActiveTab={setActiveTab} tabdisabled={tabdisabled} settabdisabaled={settabdisabaled} templates={templates} activeTabExperience={activeTabExperience} setActiveTabExperience={setActiveTabExperience} activeTabSkills={activeTabSkills} setActiveTabSkills={setActiveTabSkills} /> : null}
      <div
        className={styles.previewContainer}
        style={isOpen && (activeResume === 1 || activeResume === 3) ? { paddingTop: "260px" } : { paddingTop: "80px" }}
      >
        <div ref={previewRef} className={styles.preview_image}>
          {previewLoading ? <PreviewLoader /> : null}
          {image && (
            <PreviewImage
              image={image}
              orginal={orginal}
              previewResume={previewResume}
              templates={templates}
              selectedTemplate={selectedTemplate}
              handleImageLoad={handleImageLoad}
              isImageLoaded={isImageLoaded}
              imageTransformsY={imageTransformsY}
              selectedImageTransformY={selectedImageTransformY}
              previewRef={previewRef} 
              handleDownload={handleGeneratePdf}
            />
          )}
        </div>
      </div>

      <div className={styles.downloadContainer}>
        <div
          className={styles.downloadButton}
          onClick={
            payment?.paymentStatus !== "success"
              ? () => setModalShow(true)
              : handleGeneratePdf
          }
        >
          Download
        </div>
      </div>
      <Model modalShow={modalShow} setModalShow={setModalShow} />
    </div>
  );
};

export default Preview;
