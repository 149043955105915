import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { GoogleOAuthProvider } from "@react-oauth/google";
import AuthProvider from "./components/ResumeBuilder/builder/components/context/auth";
import ResumeProvider from "./components/ResumeBuilder/builder/components/context/Resume";
import JobProvider from "./components/ResumeBuilder/builder/components/context/Jobs";
import { CreditsProvider } from "./components/ResumeBuilder/builder/components/context/CreditsContext";
import mixpanel from "mixpanel-browser";
import { SocketProvider } from "./components/ResumeBuilder/builder/components/context/SocketContext";
// import ScrollProgressBar from "./components/ResumeBuilder/utils/scrollProgressbar";
import { HelmetProvider } from 'react-helmet-async';
import { reportWebVitals } from "./utils/webVitals";
const root = ReactDOM.createRoot(document.getElementById("root"));
mixpanel.init("c53feb9d5c57a68dfb5cb87594fa977e", {
  debug: false,
  track_pageview: true,
  persistence: "localStorage",
});
root.render(
    <HelmetProvider>
      <BrowserRouter>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <Toaster />
          <CreditsProvider>
            <JobProvider>
              <AuthProvider> 
                <SocketProvider>
                  <ResumeProvider> 
                    <App />
                  </ResumeProvider>
                </SocketProvider>
              </AuthProvider>
            </JobProvider>
          </CreditsProvider>
        </GoogleOAuthProvider>
      </BrowserRouter>
    </HelmetProvider>
);

reportWebVitals();
