import React from "react";
 

const baseConfig = {
  name: "",
  language: "en",
  direction: "ltr",
  company: "",
};

export const sections = {
  personalDetails: {
    name: "Personal Details",
    key: "sections.profile.data",
    description:
      "Please fill the fields below with your contact and personal details.",
    type: "static",
    displayKey: "sections.profile.data.name",
    fields: [
      {
        name: "name",
        key: "sections.profile.data.name",
        label: "Name",
        placeholder: "",
        type: "text",
      },
      {
        name: "email",
        key: "sections.profile.data.email",
        label: "Email",
        placeholder: "",
        type: "text",
      },
      {
        name: "phone",
        key: "sections.profile.data.phone",
        label: "Mobile Number",
        placeholder: "",
        type: "text",
      },

      {
        name: "address",
        key: "sections.profile.data.address",
        label: "City",
        placeholder: "",
        type: "text",
      },
      {
        name: "Position",
        key: "sections.profile.data.position",
        label: "Job Domain",
        placeholder: "",
        type: "text",
      },
     
      {
        name: "linkedinUsername",
        key: "sections.profile.data.linkedinUsername",
        label: "LinkedIn Username",
        placeholder: "",
        type: "text",
      },
      {
        name: "githubUsername",
        key: "sections.profile.data.githubUsername",
        label: "Github Username",
        placeholder: "",
        type: "text",
      },
      {
        name: "tags",
        key: "sections.profile.data.tags",
        label: "Tags",
        placeholder: "",
        type: "text",
      },
    ],
  },
  education: {
    name: "Education",
    key: "sections.education.data",
    description: "Your academic achievements",
    type: "dynamic",
    displayKey: "degree",
    fields: [
      {
        name: "degree",
        key: "degree",
        label: "Degree",
        placeholder: "e.g. B.Tech, Class 12th, etc.",
        type: "text",
      },
      {
        name: "branch",
        key: "branch",
        label: "Branch",
        placeholder: "e.g. Computer Science, Science (PCM), etc.",
        type: "text",
      },
      {
        name: "college",
        key: "college",
        label: "College",
        placeholder: "",
        type: "text",
      },
      {
        name: "year",
        key: "year",
        label: "Year",
        placeholder: "",
        type: "text",
      },
      //   {
      //     name: "cgpa",
      //     key: "cgpa",
      //     label: "CGPA or Percentage",
      //     placeholder: "",
      //     type: "text",
      //   },
    ],
  },
  experience: {
    name: "Experience",
    key: "sections.experience",
    description: "Experience always counts! Add your experience here.",
    type: "dynamic",
    displayKey: "company",
    fields: [
      {
        name: "company",
        key: "company",
        label: "Company Name",
        placeholder: "",
        type: "text",
      },
      {
        name: "role",
        key: "role",
        label: "Role",
        placeholder: "",
        type: "text",
      },
      {
        name: "timePeriod",
        key: "timePeriod",
        label: "Time Period",
        placeholder: "e.g. (Jan 2020 - Feb 2021)",
        type: "date",
      },
      {
        name: "location",
        key: "location",
        label: "Location",
        placeholder: "e.g. Banglore",
        type: "text",
      },
      {
        name: "points",
        key: "points",
        label: "Key Points",
        placeholder: "",
        type: "list-string",
      },
    ],
    data: { 
        // format1
       simpleList: {
        name: "simpleList",
        key: "sections.experience.data.simpleList",
        type: "simpleList",
        categories: [
            {
                name: "company",
                key: "company",
                label: "Company Name",
                placeholder: "",
                type: "text",
              },
              {
                name: "role",
                key: "role",
                label: "Role",
                placeholder: "",
                type: "text",
              },
              {
                name: "timePeriod",
                key: "timePeriod",
                label: "Time Period",
                placeholder: "e.g. (Jan 2020 - Feb 2021)",
                type: "date",
              },
              {
                name: "location",
                key: "location",
                label: "Location",
                placeholder: "e.g. Banglore",
                type: "text",
              },
              {
                name: "points",
                key: "points",
                label: "Key Points",
                placeholder: "",
                type: "list-string",
              },
        ],
       } ,
       // format 2
       responsibilitiesAndAccomplishments: {
        name: "responsibilitiesAndAccomplishments",
        key: "sections.experience.data.responsibilitiesAndAccomplishments",
        type: "responsibilitiesAndAccomplishments",
        categories: [
            {
                name: "company",
                key: "company",
                label: "Company Name",
                placeholder: "",
                type: "text",
              },
              {
                name: "role",
                key: "role",
                label: "Role",
                placeholder: "",
                type: "text",
              },
              {
                name: "timePeriod",
                key: "timePeriod",
                label: "Time Period",
                placeholder: "e.g. (Jan 2020 - Feb 2021)",
                type: "date",
              },
              {
                name: "location",
                key: "location",
                label: "Location",
                placeholder: "e.g. Banglore",
                type: "text",
              },
              {
                name: "responsibilities",
                key: "responsibilities",
                label: "Responsibilities",
                placeholder: "Add your key responsibilities",
                type: "list-string",
            },
            {
                name: "accomplishments",
                key: "accomplishments",
                label: "Accomplishments",
                placeholder: "Add your key accomplishments",
                type: "list-string",
            }
        ],
       } ,
       // format 3
       problemSolutionImapact: {
        name: "problemSolutionImapact",
        key: "sections.experience.data.problemSolutionImapact",
        type: "problemSolutionImapact",
        categories: [
            {
                name: "company",
                key: "company",
                label: "Company Name",
                placeholder: "",
                type: "text",
              },
              {
                name: "role",
                key: "role",
                label: "Role",
                placeholder: "",
                type: "text",
              },
              {
                name: "timePeriod",
                key: "timePeriod",
                label: "Time Period",
                placeholder: "e.g. (Jan 2020 - Feb 2021)",
                type: "date",
              },
              {
                name: "location",
                key: "location",
                label: "Location",
                placeholder: "e.g. Banglore",
                type: "text",
              },
              {
                name: "problem",
                key: "problem",
                label: "Problem",
                placeholder: "Add your key problem",
                type: "text",
            },
            {
                name: "solution",
                key: "solution",
                label: "Solution",
                placeholder: "Add your key solution",
                type: "text",
            },
            {
                name: "impact",
                key: "impact",
                label: "Impact",
                placeholder: "Add your key impact",
                type: "text",
            }
        ],
       },
       // format 4 
       detailedGroups: {
        name: "detailedGroups",
        key: "sections.experience.data.detailedGroups",
        type: "detailedGroups",
        categories: [
            {
                name: "company",
                key: "company",
                label: "Company Name",
                placeholder: "",
                type: "text",
            },
            {
                name: "role",
                key: "role",
                label: "Role",
                placeholder: "",
                type: "text",
            },
            {
                name: "timePeriod",
                key: "timePeriod",
                label: "Time Period",
                placeholder: "e.g. (Jan 2020 - Feb 2021)",
                type: "date",
            },
            {
                name: "location",
                key: "location",
                label: "Location",
                placeholder: "e.g. Banglore",
                type: "text",
            },
            {
                name: "groups",
                key: "groups",
                label: "Groups",
                type: "groups",
                fields: [
                    {
                        name: "groupName",
                        key: "groupName",
                        label: "Group Name",
                        placeholder: "Enter group name",
                        type: "text"
                    },
                    {
                        name: "text",
                        key: "text",
                        label: "Text",
                        placeholder: "Enter text",
                        type: "text"
                    }
                ]
            }
        ],
       }
    },
    activeTab: 0,
  },
  internship: {
    name: "Internship",
    key: "sections.internship.data",
    description: "Experience always counts! Add your internship here.",
    type: "dynamic",
    displayKey: "company",
    fields: [
      {
        name: "company",
        key: "company",
        label: "Internship Name",
        placeholder: "",
        type: "text",
      },
      {
        name: "role",
        key: "role",
        label: "Role",
        placeholder: "",
        type: "text",
      },
      {
        name: "timePeriod",
        key: "timePeriod",
        label: "Time Period",
        placeholder: "e.g. (Jan 2020 - Feb 2021)",
        type: "date",
      },
      {
        name: "location",
        key: "location",
        label: "Location",
        placeholder: "e.g. Banglore",
        type: "text",
      },
      {
        name: "points",
        key: "points",
        label: "Key Points",
        placeholder: "",
        type: "list-string",
      },
    ],
  },
  projects: {
    name: "Projects",
    key: "sections.projects.data",
    description: "Good and relevant projects are always a plus!",
    type: "dynamic",
    displayKey: "title",
    fields: [
      {
        name: "title",
        key: "title",
        label: "Title",
        placeholder: "",
        type: "text",
      },
      {
        name: "organisation",
        key: "organisation",
        label: "Organisation",
        placeholder: "e.g. Institute technical Summer Project, etc.",
        type: "text",
      },
      {
        name: "year",
        key: "year",
        label: "Time Period",
        placeholder: "",
        type: "date",
      },
      {
        name: "description",
        key: "description",
        label: "Description",
        placeholder: "",
        type: "textarea",
      },
      {
        name: "points",
        key: "points",
        label: "Feature Points",
        placeholder: "",
        type: "list-string",
      },
    ],
  },
  achivements: {
    name: "Achivements",
    key: "sections.achivements.data",
    description: "Your achivements",
    type: "dynamic",
    displayKey: "name",
    fields: [
      {
        name: "points",
        key: "points",
        label: "Achivement Points",
        placeholder: "",
        type: "list-string",
      },
    ],
  },
  skills: {
    name: "Skills",
    key: "sections.technicalSkills",
    description: "Your skills",
    type: "dynamic",
    displayKey: "name",
    data : {
      // Format 1: Simple List
      simpleList: {
        name: "simpleList",
        key: "sections.technicalSkills.data.simpleList",
        label: "Skills List",
        placeholder: "Add your skills",
        type: "list-string",
      },
      // Format 2: Two Column Layout
      twoColumn: {
        name: "twoColumn",
        key: "sections.technicalSkills.data.twoColumn",
        type: "twoColumn",
        categories: [
          {
            name: "name",
            key: "name",
            label: "Category Name",
            placeholder: "E.g Programming Languages, Tools, etc.",
            type: "text",
          },
          {
            name: "skills",
            key: "skills",
            label: "Skills",
            placeholder: "Add skills for this category",
            type: "list-string",
          },
        ],
      },
      // Format 3: Detailed Groups
      detailed: {
        name: "detailed",
        key: "sections.technicalSkills.data.detailed",
        type: "skill-groups",
        categories: [
          {
            name: "name",
            key: "name",
            label: "Group Name",
            placeholder: "E.g Frontend, Backend, DevOps etc",
            type: "text",
          },
          {
            name: "skills",
            key: "skills",
            label: "Skills",
            placeholder: "Add skills for this group",
            type: "list-string",
          },
        ],
      }
    },
    activeTab: 0,
  },
  certification: {
    name: "Certification",
    key: "sections.awards.data",
    description: "Your awards",
    type: "dynamic",
    displayKey: "",
    fields: [
      {
        name: "name",
        key: "name",
        label: "Name",
        placeholder: "Example: Fullstack Developer Bootcamp",
        type: "text",
      },
      {
        name: "issuedOn",
        key: "issuedOn",
        label: "Issued On",
        placeholder: "Example: May 2024",
        type: "singleDate",
      },
      {
        name: "Issued By",
        key: "IssuedBy",
        label: "Issued By",
        placeholder: "Example: Coursera",
        type: "text",
      },
    ],
  },
  membership: {
    name: "Membership",
    key: "sections.membership.data",
    description: "Your membership",
    type: "dynamic",
    displayKey: "name",
    fields: [
      {
        name: "points",
        key: "points",
        label: "Points",
        placeholder: "",
        type: "list-string",
      },
    ],
  },
  keyStrength: {
    name: "Key Strength",
    key: "sections.keyStrength.data",
    description: "Your key strength",
    type: "dynamic",
    displayKey: "name",
    fields: [
      {
        name: "points",
        key: "points",
        label: "Points",
        placeholder: "",
        type: "list-string",
      },
    ],
  },
  careerHighlight: {
    name: "Career Highlights",
    key: "sections.careerHighlight.data",
    description: "Your career highlights",
    type: "dynamic",
    displayKey: "name",
    fields: [
      {
        name: "points",
        key: "points",
        label: "Points",
        placeholder: "",
        type: "list-string",
      },
    ],
  },
  professionalSummary: {
    name: "Professional Summary",
    key: "sections.Professionalsummary.data",
    description: "Please fill the fields below with your Summary details.",
    type: "static",
    displayKey: "",
    fields: [
      {
        name: "Professionalsummary",
        key: "sections.Professionalsummary.data.summary",
        label: "Professional Summary",
        placeholder: "",
        type: "textarea",
      },
    ],
  },
  optional: {
    name: "Optional",
    key: "sections.optional.data",
    description: "Tell a little more about yourself",
    type: "dynamic",
    displayKey: "name",
    fields: [
      {
        name: "name",
        key: "name",
        label: "Heading",
        placeholder: "Example: Achievements, Extra Curricular",
        type: "text",
      },

      {
        name: "points",
        key: "points",
        label: "Points",
        placeholder: "",
        type: "list-string",
      },
    ],
  },
};

export const getResumeConfig = () => {
  const getExperienceLevelFromURL = () => {
    const YOE = new URLSearchParams(window.location.search).get("YOE");

    switch (YOE) {
      case "No Experience":
        return "noExperience";
      case "Less Than 3 Years":
        return "1-2years";
      case "3-5 Years":
        return "3-5years";
      case "5-10 Years":
        return "5-10years";
      case "10  Years":
        return "10plusYears";
      default:
        return "noExperience";
    }
  };

  const getSectorFromURL = () => {
    const sector = new URLSearchParams(window.location.search).get("jobsector");
    return sector;
  }; 

  const experienceLevel = getExperienceLevelFromURL();
  const sector = getSectorFromURL();
  
  const configSections = [];
  
  // Create a copy of personal details section and modify fields based on sector
  const personalDetailsSection = { ...sections.personalDetails };
  
  if (sector?.toLowerCase() !== "it") {
    personalDetailsSection.fields = personalDetailsSection.fields.filter(
      field => field.name !== "githubUsername"
    );
  }
  
  configSections.push(personalDetailsSection);

  switch (experienceLevel) {
    case "noExperience":
      configSections.push(
        sections.education,
        sections.experience,
        sections.projects,
        sections.skills,
        sections.certification,
        sections.professionalSummary,
      );
      break;

    case "1-2years":
      configSections.push(
        sections.education,
        sections.experience,
        sections.projects,
        sections.skills,
        sections.certification,
        sections.achivements,
        sections.professionalSummary,
      );
      break;

    case "3-5years":
      configSections.push(
        sections.education,
        sections.experience,
        sections.projects,
        sections.skills,
        sections.certification,
        sections.careerHighlight,
        sections.achivements,
        sections.professionalSummary,
      );
      break;

    case "5-10years":
      configSections.push(
        sections.education,
        sections.experience,
        sections.projects,
        sections.skills,
        sections.careerHighlight,
        sections.achivements,
        sections.certification,
        sections.professionalSummary
      );
      break;

    case "10plusYears":
      configSections.push(
        sections.education,
        sections.experience,
        sections.skills,
        sections.achivements,
        sections.careerHighlight,
        sections.membership,
        sections.certification,
        sections.keyStrength,
        sections.professionalSummary
      );
      break;

    default:
      configSections.push(
        sections.experience,
        sections.education,
        sections.projects,
        sections.skills,
        sections.achivements,
        sections.careerHighlight,
        sections.certification,
        sections.membership,
        sections.keyStrength,
        sections.professionalSummary
      );
  }

  configSections.push(sections.optional);

  return {
    ...baseConfig,
    sections: configSections,
  };
};

// Experience level constants for easier reference
export const EXPERIENCE_LEVELS = {
  NO_EXPERIENCE: "noExperience",
  ONE_TO_TWO_YEARS: "1-2years",
  THREE_TO_FIVE_YEARS: "3-5years",
  FIVE_TO_TEN_YEARS: "5-10years",
  TEN_PLUS_YEARS: "10plusYears",
};

const ConfigSection = () => {
  return <div>ConfigSection</div>;
};

export default ConfigSection;
