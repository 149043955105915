import React from 'react'
import HelmetConfig from '../components/HelmetConfig'
import ResponsiveNavbar from '../components/HomePage/Navbar/Navbar' 
import HeroSection from "../components/HomePage/HeroSection/HeroSection"; 
import CompaniesWorking from "../components/HomePage/CompaniesWorking/CompaniesWorking";
import StorySection from "../components/HomePage/StorySection/StorySection";
import ResponsiveAnimationSection from "../components/HomePage/ResponsiveAnimationSection/ResponsiveAnimationSection";
import CenterOverlapCarousel from "../components/HomePage/CarouselComponent/CarouselComponent";
import CommentSection from "../components/HomePage/CommentSection/CommentSection";
import FindJobs from "../components/HomePage/FindJobs/FindJobs";
import CustomerJourney from "../components/HomePage/CustomerJoueney/CustomerJourney";
import FAQSection from "../components/HomePage/FAQSection/FAQSection";
import GetStartedSection from "../components/HomePage/GetStartedSection/GetStartedSection";
import FooterSection from "../components/HomePage/FooterSection/FooterSection";
import styles from '../assets/HomePage/Home.module.css'
import "../../assets/styles/global.module.css"; 

const Home = () => {
  return (
    <div className={styles.container}> 
      <HelmetConfig 
        title="MyFuse: AI-Powered Job Portal & Resume Builder | Find Your Dream Job Today"
        description="Welcome to MyFuse, your AI-powered job portal and resume builder. Discover job opportunities, create a standout resume, and take your career to the next level with our advanced tools."
        keywords="Job Search India, Professional Networking, Job Portal India, Job Opportunities in India, Career Networking, 
        Best Job Search Platforms in India 2024, Top Job Portals in India for Freshers, How to Find Jobs in India Online, 
        Best Sites to Find Jobs in India, Networking for Job Seekers in India, 
        Job Search, Job Network, Employment India, Job Listings, Careers India, 
        Career Opportunities, Employment India, Find Jobs Online, Job Networking Sites, Job Search Engines, 
        Online Job Search India, Indian Job Market, Job Vacancies India, Best Job Boards in India, Indian Job Search Engines, 
        How to Network for Jobs in India, Indian Employment Portals, Job Search Tips for Indians, How to Get Hired in India, 
        Best Job Sites for Experienced Professionals in India, 
        Job Portal for Freshers in India, Indian Professional Network, Career Growth in India, 
        Online Job Applications India, Job Search Tools India"
        canonical="https://myfuse.in/"
      />
      <ResponsiveNavbar />
      <HeroSection />
      <CompaniesWorking />
      <StorySection />
      <ResponsiveAnimationSection />
      <CenterOverlapCarousel />
      <CommentSection />
      <FindJobs />
      <CustomerJourney />
      <FAQSection />
      <GetStartedSection />
      <FooterSection />
    </div>
  )
}

export default Home