"use client";
import React, { useState } from "react";
import { motion } from "framer-motion";
import { journeySection } from "../../../constants/journeySection";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import styles from "./CustomerJourney.module.css";
import { buttonData } from "../../../constants/buttons";
import MyFuseButton from "../MyFuseButton/MyFuseButton";
import { useNavigate } from "react-router-dom";
// Motion variants for content (front) and overlay (back)
const contentVariants = {
  initial: { opacity: 1 },
  hover: { opacity: 0, transition: { duration: 0.3, ease: "easeInOut" } },
};

const overlayVariants = {
  initial: { opacity: 0 },
  hover: { opacity: 1, transition: { duration: 0.3, ease: "easeInOut" } },
};

export default function CustomerJourney() {
  const [hoveredCard, setHoveredCard] = useState(null);
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      {/* Heading */}
      <div className={styles.heading}>Your Journey with Us 
      <div className={styles.mobileCardIcon}>
        <img src="https://res.cloudinary.com/dmsbvsful/image/upload/v1741100672/Long_Clip_Up_1_q1j3qh.svg" alt="Desktop Card Icon" />
       </div>
         </div>
      <div className={styles.subheading}>
        Land your dream job in just 3 simple steps!
      </div>
       <div className={styles.desktopCardIcon}>
        <img src="https://res.cloudinary.com/dmsbvsful/image/upload/v1741100672/Long_Clip_Up_1_q1j3qh.svg" alt="Desktop Card Icon" />
       </div>
      {/* Desktop Cards */}
      <div className={styles.desktopCards}>
        {journeySection.map((item) => (
          <div
            key={item.id}
            onMouseEnter={() => setHoveredCard(item.id)}
            onMouseLeave={() => setHoveredCard(null)}
            className={styles.desktopCard}
          >
            {/* Front Content */}
            <motion.div
              className={styles.frontContent}
              variants={contentVariants}
              animate={hoveredCard === item.id ? "hover" : "initial"}
            >
              <div className={styles.frontId}>{item.id}</div>
              <img
                src={item.icon}
                width={52}
                height={52}
                alt={`Icon ${item.id}`}
                style={{ margin: "0.5rem 0" , transform: "scale(1.5)" }}
              />
              <div className={styles.frontTitle}>{item.title}</div>
            </motion.div>

            {/* Back Content (Revealed on Hover) */}
            <motion.div
              className={styles.backContent}
              variants={overlayVariants}
              animate={hoveredCard === item.id ? "hover" : "initial"}
            >
              <p className={styles.backText}>
                {item.revealText || "Learn More"}
              </p>
              <button className={styles.backButton}>
                <FaArrowRight className={styles.iconStyle} />
              </button>
            </motion.div>
          </div>
        ))} 

        {/* <div className={styles.desktopCardIcon}> 

        </div> */}
      </div>

      {/* Mobile Cards */}
      <div className={styles.mobileCards}>
        {journeySection.map((item) => (
          <div key={item.id} className={styles.mobileCard}>
            <div className={styles.mobileCardTop}>
              <img src={item.icon} alt="Icon" className={styles.mobileIcon} />
              <span className={styles.mobileTitle}>{item.title}</span>
            </div>
            <div className={styles.mobileCardBottom}>
              <p className={styles.mobileText}>
                {item.revealText || "Learn More"}
              </p>
            </div>
          </div>
        ))}
      </div>

      {/* Start Now Button */}

      <MyFuseButton
        title={buttonData.CustomerJourney[0].title}
        onClick={() => navigate("/welcome")}
        variant={buttonData.CustomerJourney[0].variant}
        className={styles.startButton}
      />
    </div>
  );
}
