import React, { useEffect, useState } from "react";
import styles from "../assets/ModelAskExp.module.css";
import { motion, AnimatePresence } from "framer-motion";
import {
  Info,
  X,
  BriefcaseIcon,
  BuildingIcon,
  CodeIcon,
  ArrowRight,
} from "lucide-react";
import occupationalData from "../ResumeBuilder/utils/occupationalData";
import { publicApi } from "../ResumeBuilder/utils/aixos";
import { useResume } from "../ResumeBuilder/builder/components/context/Resume";
import { useNavigate } from "react-router-dom";

const ModelAskExp = ({
  modalShow,
  setModalShow,
  setSecondModalShow,
  setSelectedExperience,
  setSelectedSector,
  setSelectedDomain,
  selectedExperience,
  selectedSector,
  selectedDomain,
}) => {
  const [allresume, setallresume] = useState([]);
  const navigate = useNavigate();
  const { setCurrentJobRole } = useResume();
  const sectorOptions = Object.keys(occupationalData.getAllData());
  const getJobDomains = (sector) => {
    if (!sector) return [];
    const categoryData = occupationalData.getCategoryData(sector);
    return categoryData ? Object.keys(categoryData.roles) : [];
  };
  const [jobDomainOptions, setJobDomainOptions] = useState([]);

  useEffect(() => {
    if (selectedSector) {
      setJobDomainOptions(getJobDomains(selectedSector));
      setSelectedDomain(null);
    }
  }, [selectedSector]);

  // Prevent body scroll when modal is open
  useEffect(() => {
    if (modalShow) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [modalShow]);

  const showresumefrombackend = async () => {
    try {
      const { data } = await publicApi.get(`/api/admin/getallresume`);
      if (data?.success) {
        await setallresume(data?.result);
        // console.log(data?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    showresumefrombackend();
  }, []);

  const backdropVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  const modalVariants = {
    hidden: {
      opacity: 0,
      scale: 0.8,
      y: 50,
    },
    visible: {
      opacity: 1,
      scale: 1,
      y: 0,
      transition: {
        type: "spring",
        duration: 0.5,
        bounce: 0.3,
      },
    },
    exit: {
      opacity: 0,
      scale: 0.8,
      y: 50,
      transition: {
        duration: 0.3,
      },
    },
  };

  const floatingAnimation = {
    y: [-5, 5],
    transition: {
      duration: 2,
      repeat: Infinity,
      repeatType: "reverse",
      ease: "easeInOut",
    },
  };

  const experienceOptions = [
    "No Experience",
    "Less Than 3 Years",
    "3-5 Years",
    "5-10 Years",
    "10+ Years",
  ];

  const JobDomainButton = ({ option, isSelected, onClick }) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const domainResumes = allresume.filter(
      (resume) => resume.subFiledName === option
    );

    useEffect(() => {
      if (domainResumes.length <= 1) return;

      const interval = setInterval(() => {
        setCurrentImageIndex((prev) =>
          prev === domainResumes.length - 1 ? 0 : prev + 1
        );
      }, 3000); // Change image every 3 seconds

      return () => clearInterval(interval);
    }, [domainResumes.length]);

    return (
      <motion.button
        className={`${styles.resumeOptionButton} ${
          isSelected ? styles.selected : ""
        }`}
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
        onClick={onClick}
      >
        {domainResumes.length > 0 && (
          <div className={styles.imageCarousel}>
            {domainResumes.map((resume, index) => (
              <img
                key={resume.id}
                src={resume.images}
                alt={resume.name}
                className={`${styles.carouselImage} ${
                  index === currentImageIndex ? styles.active : ""
                }`}
              />
            ))}
          </div>
        )}
        {option}
      </motion.button>
    );
  };

  const skip = () => {
    navigate(`/choose-template/?domain=${selectedDomain}&sector=${selectedSector}&YOE=${selectedExperience}`);
  }

  if (!modalShow) return null;

  return (
    <AnimatePresence>
      <motion.div
        className={styles.modalBackdrop}
        variants={backdropVariants}
        initial="hidden"
        animate="visible"
        exit="hidden"
        onClick={() => setModalShow(false)}
      >
        <motion.div
          className={styles.modalContent}
          variants={modalVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
          onClick={(e) => e.stopPropagation()}
        >
          {/* Background Elements */}
          <div className={styles.backgroundElements}>
            <motion.div
              className={styles.gradientOrb}
              animate={{
                scale: [1, 1.2, 1],
                opacity: [0.5, 0.8, 0.5],
              }}
              transition={{
                duration: 3,
                repeat: Infinity,
                ease: "easeInOut",
              }}
            />
            <motion.div
              className={styles.gradientOrbSecond}
              animate={{
                scale: [1.2, 1, 1.2],
                opacity: [0.3, 0.6, 0.3],
              }}
              transition={{
                duration: 4,
                repeat: Infinity,
                ease: "easeInOut",
              }}
            />
          </div>

          {/* Close Button */}
          <motion.button
            className={styles.closeButton}
            onClick={() => setModalShow(false)}
            whileHover={{ scale: 1.1, rotate: 90 }}
            whileTap={{ scale: 0.9 }}
          >
            <X className={styles.closeIcon} />
          </motion.button>

          {/* Experience Section */}
          <motion.div
            className={styles.questionSection}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
          >
            <div className={styles.sectionHeader}>
              <motion.div
                animate={floatingAnimation}
                className={styles.iconWrapper}
              >
                <BriefcaseIcon className={styles.sectionIcon} />
              </motion.div>
              <h1>
                How long have you been working? <Info size={16} />
              </h1>
            </div>
            {/* <p>We'll find the best templates for your experience level.</p> */}

            <div className={styles.optionsGrid}>
              {experienceOptions.map((option) => (
                <motion.button
                  key={option}
                  className={`${styles.optionButton} ${
                    selectedExperience === option ? styles.selected : ""
                  }`}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={() => setSelectedExperience(option)}
                >
                  {option}
                </motion.button>
              ))}
            </div>
          </motion.div>

          {/* Sector Section */}
          <motion.div
            className={styles.questionSection}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
          >
            <div className={styles.sectionHeader}>
              <motion.div
                animate={floatingAnimation}
                className={styles.iconWrapper}
              >
                <BuildingIcon className={styles.sectionIcon} />
              </motion.div>
              <h1>What sector do you work in?</h1>
            </div>
            <div className={styles.optionsGrid}>
              {sectorOptions.map((option) => (
                <motion.button
                  key={option}
                  className={`${styles.optionButton} ${
                    selectedSector === option ? styles.selected : ""
                  }`}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={() => setSelectedSector(option)}
                >
                  {option}
                </motion.button>
              ))}
            </div>
          </motion.div>

          {/* Job Domain Section - Only show if sector is selected */}
          {selectedSector && (
            <motion.div
              className={styles.questionSection}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
            >
              <div className={styles.sectionHeader}>
                <motion.div
                  animate={floatingAnimation}
                  className={styles.iconWrapper}
                >
                  <CodeIcon className={styles.sectionIcon} />
                </motion.div>
                <h1>What is your job domain?</h1>
              </div>
              <div className={styles.optionsGrid}>
                {jobDomainOptions.map((option) => (
                  <motion.button
                    key={option}
                    className={`${styles.optionButton} ${selectedDomain === option ? styles.selected : ''}`}
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    onClick={() => {
                      setSelectedDomain(option);
                      setCurrentJobRole(option);
                      setModalShow(false);
                      navigate(`/choose-template/?domain=${option}&sector=${selectedSector}&YOE=${selectedExperience}`);
                      // setSecondModalShow(true);
                    }}
                  >
                    {option}
                  </motion.button>
                ))}
              </div>
            </motion.div>
          )}

          <div className={styles.bottomButtons}>
            <button
              className={styles.doneButton}
              onClick={()=> skip()}
            >
              Skip
            </button>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default ModelAskExp;
