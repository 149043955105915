import React, { useEffect, useState } from "react";
import MyResponsiveNavbar from "../Navbar";
import axios from "axios";
import styles from "../../assets/DataSharing/DataSharing.module.css";
import { GoogleLogin, useGoogleOneTapLogin } from "@react-oauth/google";
import { motion } from "framer-motion";
import { jwtDecode } from "jwt-decode";
import { publicApi } from '../../ResumeBuilder/utils/aixos';

import {
  FaFilePdf,
  FaDownload,
  FaPlayCircle,
  FaBriefcase,
  FaRobot,
  FaAward,
  FaChartLine,
  FaUsers,
  FaMapMarkerAlt,
  FaBuilding,
  FaClock,
  FaRegClock,
  FaArrowRight,
  FaChevronRight,
} from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../ResumeBuilder/builder/components/context/auth";
import HelmetConfig from "../../components/HelmetConfig";
import { toast } from "react-hot-toast";

const DataSharing = () => {
  const { slug } = useParams();
  const Navigate = useNavigate();
  const parts = slug.split("-");
  const id = parts.pop();
  const TitleUrl = parts.join("-");
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false); // State for modal visibility
  let [DataSharing, setDataSharing] = useState();
  let [jobs, setJobs] = useState([]);
  let [video_link, setVideo_link] = useState("");
  const {
    auth,
    setIsCheatSheetRdirect,
    setPreviousUrlCheatSheet,
    flagdownloadCheatSheet,
    setFlagdownloadCheatSheet,
    setAuth
  } = useAuth();

  const getDataSharing = async () => {
    try {
      const { data } = await publicApi.get(`/api/public/DataSharingById?id=${id}`);
      if (data.data && data.data[0]) {
        setDataSharing(data.data[0]);
        if (data.data[0]?.video_link) {
          setVideo_link(data.data[0].video_link);
        }
        return data.data[0]; // Return the data
      }
      return null;
    } catch (error) {
      console.log("Error fetching data:", error);
      throw error; // Propagate the error
    }
  };
  const getJobs = async () => {
    const { data } = await publicApi.post(`/api/auth/filterJobPost?page=1`);
    setJobs(data.users);
  };
  useEffect(() => {
    getDataSharing();
    getJobs();
  }, [
    flagdownloadCheatSheet,
    setFlagdownloadCheatSheet,
  ]);

  const responseGoogleSuccess = async (credentialResponse) => {
    try {
      const decode = await jwtDecode(credentialResponse.credential);
      const response = await publicApi.post('/api/auth/loginWithGoogle', { decode });
      
      if (response.data.success) {
        const updatedAuth = {
          ...auth,
          user: response.data.user,
          token: response.data.token,
          pic: decode?.picture,
          message: response.data.message,
          success: response.data.success,
        };
        setAuth(updatedAuth);
        toast.success(response.data.message);
        localStorage.setItem("auth", JSON.stringify(updatedAuth));
        setIsCheatSheetRdirect(true);
        setPreviousUrlCheatSheet(window.location.pathname);
        Navigate("/welcome");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const responseGoogleFailure = (error) => {
    console.error("Google Auth Error:", error);
  };

  useGoogleOneTapLogin({
    onSuccess: responseGoogleSuccess,
    onFailure: responseGoogleFailure,
    onError: () => {
      console.log("Login Failed");
    },
    disable: !!auth?.token, // Disable the prompt if user is logged in
  });

  const handleVideoPlay = () => {
    const video = document.querySelector("video");
    if (video) {
      if (video.paused) {
        video.play();
        setIsPlaying(true);
      } else {
        video.pause();
        setIsPlaying(false);
      }
    }
  };

  const JobCard = ({ job }) => {
    const postDate = job.createdat;
    const currentDate = new Date();
    const diffTime = Math.abs(currentDate - postDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const postedDate = diffDays > 1 ? `${diffDays} days ago` : "Today";
    return (
      <motion.div
        className={styles["job-card"]}
        whileHover={{ scale: 1.02 }}
        transition={{ duration: 0.2 }}
        id={job.id_jobpost}
      >
        <div className={styles["job-card-header"]}>
          <div className={styles["company-info"]}>
            <div className={styles["company-logo-wrapper"]}>
              <img
                src={`https://old.myfuse.in/uploads/logo/${job.companyLogo}`}
                alt={`${job.company} logo`}
                className={styles["company-logo"]}
                onError={(e) => (e.target.src = "/default-company-logo.png")}
                loading="lazy"
              />
            </div>
            <div className={styles["company-details"]}>
              <h3>{job.jobtitle}</h3>
              <span className={styles["company-name"]}>
                <FaBuilding className={styles["company-icon"]} />
                {job.companyName}
              </span>
            </div>
          </div>
        </div>

        <div className={styles["job-info"]}>
          <div className={styles["job-meta"]}>
            <span className={styles["job-type"]}>
              <FaClock /> {job.job_nature}
            </span>
            <span className={styles["job-location"]}>
              <FaMapMarkerAlt /> {job.location}
            </span>
            {/* <span className={styles['experience']}>
            <FaBriefcase /> {job.experience}
          </span> */}
          </div>

          {/* <div className={styles['job-tags']}>
          {job.tags.map((tag, index) => (
            <motion.span 
              key={index} 
              className={styles['tag']}
              whileHover={{ scale: 1.1 }}
            >
              {tag}
            </motion.span>
          ))}
        </div> */}

          <div className={styles["job-footer"]}>
            <div className={styles["posted-date"]}>
              <FaRegClock /> {postedDate}
            </div>
            <motion.button
              className={styles["apply-btn"]}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              // link is there
              onClick={() => {
                window.open(job.link, "_blank");
              }}
            >
              Apply Now
              <FaArrowRight className={styles["arrow-icon"]} />
            </motion.button>
          </div>
        </div>
      </motion.div>
    );
  };
  const handleDownloadCheatsheet = async () => {
    try {
      setIsLoading(true);

      // 1. Early return for unauthenticated users
      if (!auth?.token) {
        setIsCheatSheetRdirect(true);
        setPreviousUrlCheatSheet(window.location.pathname);
        Navigate("/login");
        return;
      }

      // 2. Ensure we have the latest data
      let currentData = DataSharing;
      if (!currentData?.cheat_sheet_link) {
        console.log("Fetching fresh data...");
        currentData = await getDataSharing();
      }

      // 3. Check if we have a valid cheatsheet link
      if (!currentData?.cheat_sheet_link) {
        console.error("No cheatsheet URL found");
        toast.error("Cheatsheet not available at the moment");
        return;
      }

      // 4. Download handling
      try {
        // 5. Validate URL
        const response = await fetch(currentData.cheat_sheet_link);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = "resume-cheatsheet.pdf";
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);

        // 8. Success feedback
        toast.success("Download started successfully!");

        // Show modal after successful download
        setTimeout(() => setShowModal(true), 1000);
      } catch (downloadError) {
        console.error("Download failed:", downloadError);
        toast.error("Failed to download cheatsheet. Please try again.");
      }
    } catch (error) {
      console.error("Error in handleDownloadCheatsheet:", error);
      toast.error("Something went wrong. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  const CustomModal = ({ isOpen, setShowModal }) => {
    if (!isOpen) return null;
  
    return (
      <div
        className={styles["custom-modal-overlay"]}
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-title"
      >
        <div className={styles["custom-modal"]}>
          <button
            className={styles["close-button"]}
            onClick={() => setShowModal(false)}
            aria-label="Close modal"
          >
            &times;
          </button>
  
          <div className={styles["modal-content-wrapper"]}>
            {/* Left side content */}
            <div className={styles["modal-content"]}>
              <h2 id="modal-title" className={styles["modal-title"]}>
                Discover Our AI Resume Builder
              </h2>
              <p className={styles["modal-description"]}>
                Our AI Resume Builder uses advanced technology to help you create
                a professional and ATS-friendly resume in minutes. With smart
                formatting suggestions and industry-specific keywords, you can
                ensure your resume stands out to employers.
              </p>
              <div className={styles["features-section"]}>
                <p className={styles["modal-key-features"]}>Key Features:</p>
                <ul className={styles["modal-features-list"]}>
                  <li>Smart formatting & layout suggestions</li>
                  <li>Industry-specific keywords</li>
                  <li>Real-time ATS compatibility check</li>
                </ul>
              </div>
              <button
                className={styles["modal-close-button"]}
                onClick={() => Navigate("/airesume")}
              >
                Get Started Now
              </button>
            </div>
  
            {/* Right side video */}
            <div className={styles["modal-video"]}>
              <video
                
                loop
                playsInline
                className={styles["features-video"]}
              >
                <source
                  src="https://res.cloudinary.com/dvepwlz9j/video/upload/v1733159868/video/i87lzqx37oqdfiwhwzui.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (flagdownloadCheatSheet) {
      setFlagdownloadCheatSheet(false);
      handleDownloadCheatsheet();
    }
  }, [
    flagdownloadCheatSheet,
    handleDownloadCheatsheet,
    setFlagdownloadCheatSheet,
  ]);

  return (
    <div>
      <HelmetConfig
        title={`Cheatsheet - ${TitleUrl}`}
        description={`Download our comprehensive Resume Cheatsheet - your ultimate guide to creating a powerful, ATS-friendly resume. Learn expert tips on resume formatting, keyword optimization, and professional presentation. Get insider strategies for highlighting your skills, crafting compelling work experiences, and standing out to employers. This free guide includes proven templates, action words, and industry-specific recommendations to help you land your dream job. Join over 150,000 professionals who've transformed their job search with our AI-powered resume tools and expert guidance.`}
        keywords="
         Resume Cheatsheet, Professional Resume Tips, Resume Writing Guide, Career Development Tips,
  Resume Building Tips, Job Application Guide, Resume Templates, Resume Format Guide,
  ATS-Friendly Resume Tips, Resume Keywords Guide, Resume Writing Best Practices,
  Professional Resume Writing, Resume Optimization Tips, Career Advancement Guide,
  Resume Skills Section Guide, Resume Achievement Examples, Resume Action Words,
  Resume Formatting Tips, Job Search Strategies, Resume Do's and Don'ts,
  Resume Section Guidelines, Work Experience Writing Tips, Resume Summary Guide,
  Resume Header Tips, Resume Education Section Guide, Resume Skills Optimization,
  Resume Bullet Points Guide, Resume Length Guidelines, Resume Design Tips,
  Professional Resume Examples, Entry-Level Resume Tips, Executive Resume Guide,
  Resume Update Guidelines, Modern Resume Tips, Resume Trends, Resume Mistakes to Avoid,
  Resume Writing Checklist, Resume Success Strategies, Career Growth Tips
        "
        canonical="https://myfuse.in/about"
      />
      <MyResponsiveNavbar />
      <div className={styles["data-sharing-container"]}>
        <div className={styles["content-wrapper"]}>
          <motion.div
            className={styles["hero-section"]}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8 }}
          >
            <div className={styles["hero-content"]}>
              <motion.div
                className={styles["resume-warning"]}
                initial={{ scale: 0.95 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.5 }}
              >
                <div className={styles["warning-header"]}>
                  <motion.div
                    className={styles["title-wrapper"]}
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    <motion.span
                      className={styles["floating-emoji"]}
                      animate={{ y: [-10, 10, -10] }}
                      transition={{ duration: 3, repeat: Infinity }}
                    >
                      ✨
                    </motion.span>
                    <h2 className={styles["warning-title"]}>
                      <span className={styles["gradient-text"]}>
                        Elevate Your Career
                      </span>
                      <span className={styles["highlight-text"]}>
                        With AI Excellence
                      </span>
                    </h2>
                    <div className={styles["title-badges"]}>
                      <motion.span
                        className={styles["badge"]}
                        whileHover={{ scale: 1.05 }}
                      >
                        95% Success Rate
                      </motion.span>
                      <motion.span
                        className={styles["badge"]}
                        whileHover={{ scale: 1.05 }}
                      >
                        🚀 3x Faster Results
                      </motion.span>
                    </div>
                  </motion.div>

                  <motion.h3
                    className={styles["warning-subheader"]}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.2 }}
                  >
                    Join <span className={styles["highlight"]}>150,000+</span>{" "}
                    Professionals Who've
                    <span className={styles["gradient-text"]}>
                      {" "}
                      Transformed Their Career Path
                    </span>
                  </motion.h3>
                </div>

                <motion.div className={styles["download-button-wrapper"]}>
                  <div className={styles["download-description"]}>
                    <h3 className={styles["download-title"]}>
                      Get Your Resume Cheatsheet
                    </h3>
                    <p className={styles["download-text"]}>
                      Download our comprehensive guide to enhance your resume
                      and stand out in the job market.
                    </p>
                  </div>
                  <motion.button
                    className={`${styles["upload-button"]} ${styles["download-button"]}`}
                    aria-label="Download Resume Cheatsheet"
                    title="Download Resume Cheatsheet"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={handleDownloadCheatsheet}
                  >
                    {isLoading ? (
                      <>
                        <FaDownload className={styles["loading-icon"]} />
                        <span style={{ marginLeft: "10px" }}>Loading...</span>
                      </>
                    ) : (
                      <>
                        <FaDownload /> Download Cheatsheet
                      </>
                    )}
                  </motion.button>
                </motion.div>
              </motion.div>
            </div>

            <div className={styles["video-section"]}>
              <div className={styles["video-wrapper"]}>
                {video_link ? (
                  <video
                    key={video_link}
                    controls
                    preload="auto"
                    playsInline
                    controlsList="nodownload"
                    onError={(e) =>
                      console.error("Video Error:", e.target.error)
                    }
                  >
                    <source type="video/mp4" src={video_link} />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <div>Loading video...</div>
                )}
                {!isPlaying && (
                  <div
                    className={styles["video-overlay"]}
                    onClick={handleVideoPlay}
                    style={{ cursor: "pointer" }}
                  >
                    <FaPlayCircle className={styles["play-icon"]} />
                  </div>
                )}
              </div>
            </div>
          </motion.div>

          <motion.div
            className={styles["features-section"]}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
          >
            <div className={styles["features-grid"]}>
              <div className={styles["feature-card"]}>
                <FaRobot />
                <h3>AI Analysis</h3>
                <p>
                  Smart resume scanning with industry-specific recommendations
                </p>
              </div>
              <div className={styles["feature-card"]}>
                <FaBriefcase />
                <h3>Job Matching</h3>
                <p>Intelligent job recommendations based on your profile</p>
              </div>
              <div className={styles["feature-card"]}>
                <FaChartLine />
                <h3>Skills Gap Analysis</h3>
                <p>Identify and improve key skills for your target role</p>
              </div>
            </div>
          </motion.div>

          {/* <motion.div 
          className={styles['upload-section']}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
        >
          <h2 className={styles['section-title']}>Get Your Free Resume Cheatsheet</h2>
          <p className={styles['upload-description']}>
            Download our comprehensive resume guide with expert tips and templates.
          </p>
          
          <motion.button 
            className={`${styles['upload-button']} ${styles['download-button']}`}
             
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <FaDownload /> Download Resume Cheatsheet
          </motion.button>
        </motion.div> */}

          <motion.div
            className={styles["ai-builder-section"]}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5 }}
          >
            <div className={styles["ai-builder-content"]}>
              <div className={styles["ai-builder-text"]}>
                <h2 className={styles["section-title"]}>
                  <FaRobot className={styles["title-icon"]} />
                  AI-Powered Resume Builder
                </h2>
                <p className={styles["section-description"]}>
                  Create an ATS-friendly resume in minutes using our advanced AI
                  technology
                </p>

                <div className={styles["builder-features"]}>
                  <div className={styles["feature-item"]}>
                    <FaChartLine className={styles["feature-icon"]} />
                    <span>Smart formatting & layout suggestions</span>
                  </div>
                  <div className={styles["feature-item"]}>
                    <FaBriefcase className={styles["feature-icon"]} />
                    <span>Industry-specific keywords</span>
                  </div>
                  <div className={styles["feature-item"]}>
                    <FaUsers className={styles["feature-icon"]} />
                    <span>Real-time ATS compatibility check</span>
                  </div>
                </div>

                <div className={styles["builder-actions"]}>
                  <motion.button
                    className={styles["primary-button"]}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={() => {
                      window.location.href = "/airesume";
                    }}
                  >
                    Build Your Resume Now
                    <FaArrowRight className={styles["arrow-icon"]} />
                  </motion.button>
                  <span className={styles["time-estimate"]}>
                    <FaClock className={styles["clock-icon"]} />
                    Takes only 10 minutes
                  </span>
                </div>
              </div>

              <div className={styles["ai-builder-preview"]}>
                <div className={styles["preview-card"]}>
                  <div className={styles["preview-header"]}>
                    <span className={styles["live-badge"]}>Live Preview</span>
                  </div>
                  <div className={styles["preview-content"]}>
                    <img
                      src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1732288142/resumeEample/hempols36pnuxqu5ee2j.png"
                      alt="Resume Builder Preview"
                      className={styles["preview-image"]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </div>

        <div className={styles["sidebar"]}>
          <div className={styles["sidebar-section"]}>
            <div className={styles["section-header"]}>
              <h2 className={styles["sidebar-title"]}>
                <motion.span
                  className={styles["ai-title-wrapper"]}
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <motion.span
                    animate={{
                      scale: [1, 1.1, 1],
                      rotate: [0, 5, -5, 0],
                    }}
                    transition={{
                      duration: 2,
                      repeat: Infinity,
                      repeatType: "reverse",
                    }}
                  >
                    <FaRobot
                      className={`${styles["section-icon"]} ${styles["ai-icon"]}`}
                    />
                  </motion.span>
                  <span className={styles["ai-text"]}>AI</span> Resume Analysis
                </motion.span>
              </h2>
              <div className={styles["badges-wrapper"]}>
                <span className={styles["feature-badge"]}>New</span>
                <span
                  className={`${styles["feature-badge"]} ${styles["trending-badge"]}`}
                >
                  🔥 Trending
                </span>
              </div>
            </div>

            <div className={styles["video-container"]}>
              <img
                src="https://myfuse.in/static/media/low%20score.7da13386a3f345f8f04d.gif"
                alt="AI Resume Analysis"
                className={styles["feature-video"]}
              />
            </div>

            <div className={styles["ai-features"]}>
              <div className={styles["feature-item"]}>
                <div className={styles["feature-icon-wrapper"]}>
                  <FaChartLine className={styles["feature-icon"]} />
                </div>
                <div className={styles["feature-content"]}>
                  <h3>Smart Score</h3>
                  <p>Get instant feedback on your resume's effectiveness</p>
                </div>
              </div>

              <div className={styles["feature-item"]}>
                <div className={styles["feature-icon-wrapper"]}>
                  <FaUsers className={styles["feature-icon"]} />
                </div>
                <div className={styles["feature-content"]}>
                  <h3>ATS Optimization</h3>
                  <p>Ensure your resume passes applicant tracking systems</p>
                </div>
              </div>

              <div className={styles["feature-item"]}>
                <div className={styles["feature-icon-wrapper"]}>
                  <FaBriefcase className={styles["feature-icon"]} />
                </div>
                <div className={styles["feature-content"]}>
                  <h3>Job Match</h3>
                  <p>Find roles that match your skills and experience</p>
                </div>
              </div>
            </div>

            <button
              className={styles["try-ai-btn"]}
              onClick={() => {
                window.location.href = "/resume-score";
              }}
            >
              Try AI Analysis
              <FaArrowRight className={styles["arrow-icon"]} />
            </button>
          </div>

          <div className={styles["sidebar-section"]}>
            <div className={styles["section-header"]}>
              <h2 className={styles["sidebar-title"]}>
                <FaBriefcase className={styles["section-icon"]} />
                Recent Jobs
              </h2>
              <motion.span
                className={styles["jobs-count"]}
                whileHover={{ scale: 1.1 }}
              ></motion.span>
            </div>

            <div className={styles["job-listings"]}>
              {jobs?.slice(0, 3).map((job, index) => (
                <JobCard key={index} job={job} />
              ))}

              <motion.button
                className={styles["view-more-btn"]}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => {
                  Navigate("/jobs-in-india");
                }}
              >
                View All Opportunities
                <FaChevronRight className={styles["chevron-icon"]} />
              </motion.button>
            </div>
          </div>
        </div>
      </div>

      <CustomModal
        isOpen={showModal}
        setShowModal={setShowModal}
        onClose={() => setShowModal(false)}
      />
    </div>
  );
};

export default DataSharing;
