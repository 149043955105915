import { useState, useEffect, useContext, createContext } from "react";
import { privateApi } from "../../../utils/aixos";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(() => {
    const data = localStorage.getItem("auth");
    if (data) {
      const parseData = JSON.parse(data);
      return {
        user: parseData.user,
        token: parseData.token,
      };
    }
    return { user: null, token: "" };
  });
  const [authstatus, setAuthStatus] = useState(null);
  const [user, setUser] = useState(null);
  const [isCheatSheetRdirect, setIsCheatSheetRdirect] = useState(false);
  const [previousUrlCheatSheet, setPreviousUrlCheatSheet] = useState(null);
  const [flagdownloadCheatSheet, setFlagdownloadCheatSheet] = useState(false);

  useEffect(() => {
    let isSubscribed = true;
    
    const checkAuthStatus = async () => {
      if (!auth?.token) {
        setAuthStatus(false);
        return;
      }
       
    
      try {
        const tokenData = JSON.parse(atob(auth.token.split('.')[1]));
        const expirationTime = tokenData.exp * 1000;  
        if (Date.now() >= expirationTime) {
          if (isSubscribed) {
            setAuthStatus(false);
            localStorage.removeItem("auth");
            setAuth({ user: null, token: "" });
            setUser(null);
            return;
          }
        }

        // Token is valid, verify with server
        const res = await privateApi.get("/api/auth/userauth");
        if (isSubscribed) {
          setAuthStatus(res.data.ok);
          if (res.data.user) {
            setUser(res.data.user);
          }
        }
      } catch (error) {
        if (isSubscribed) {
          console.error("Error while checking authentication status:", error);
          setAuthStatus(false);
          localStorage.removeItem("auth");
          setAuth({ user: null, token: "" });
          setUser(null);
        }
      }
    };

    checkAuthStatus();

    return () => {
      isSubscribed = false;
    };
  }, [auth?.token]);

  const value = {
    auth,
    setAuth,
    authstatus,
    setUser,
    user,
    isCheatSheetRdirect,
    setIsCheatSheetRdirect,
    previousUrlCheatSheet,
    setPreviousUrlCheatSheet,
    flagdownloadCheatSheet,
    setFlagdownloadCheatSheet,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);

export default AuthProvider;
