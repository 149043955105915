import React from 'react';
import styles from "../assets/BottomBar.module.css";
import { LayoutDashboard, FileSearch, BarChart } from "lucide-react";
import { useNavigate } from 'react-router-dom';
import { useJobs } from '../ResumeBuilder/builder/components/context/Jobs';
import { HiOutlineBriefcase } from "react-icons/hi2";
import { PiReadCvLogoBold } from "react-icons/pi";
import { motion, AnimatePresence, LayoutGroup } from "framer-motion";
import { useResume } from '../ResumeBuilder/builder/components/context/Resume';
import { useAuth } from '../ResumeBuilder/builder/components/context/auth';

const BottomBar = () => {
  const navigate = useNavigate();
  const {selected, setSelected, setActiveResume} = useJobs();
  const { selectedTemplate, handleTemplateChange } = useResume();
  const { auth } = useAuth();
  const handleNavigateAtsAnalysis = (name) => {
    setActiveResume(name)
    if (!selectedTemplate || selectedTemplate == null) {
      handleTemplateChange("iitg");
    }
    if(name === 'ATS'){
      setActiveResume(3)
    } else if(name === 'Analysis') {
      setActiveResume(2)
    } else if(name === 'Resume') {
      setActiveResume(1)
    }
    navigate('/editor')
  }
   
 

  const navigationItems = [
    { 
      id: '1', 
      icon: <LayoutDashboard size={24} />, 
      label: auth.token ? 'Dashboard' : 'Home', 
      route: auth.token ? '/welcome' : '/',
      color: '#3b82f6',
      gradient: 'linear-gradient(135deg, #3b82f6, #2563eb)'
    },
    { 
      id: '2', 
      icon: <HiOutlineBriefcase size={24} />, 
      label: 'Jobs', 
      route: '/jobs-in-india',
      color: '#10b981',
      gradient: 'linear-gradient(135deg, #10b981, #059669)'
    },
    { 
      id: '3', 
      icon: <PiReadCvLogoBold size={24} />, 
      label: 'Resume', 
      route: 'Resume',
      color: '#6366f1',
      gradient: 'linear-gradient(135deg, #6366f1, #4f46e5)'
    },
    { 
      id: '4', 
      icon: <FileSearch size={24} />, 
      label: 'ATS', 
      route: 'ATS',
      color: '#f59e0b',
      gradient: 'linear-gradient(135deg, #f59e0b, #d97706)'
    },
    { 
      id: '5', 
      icon: <BarChart size={24} />, 
      label: 'Analysis', 
      route: 'Analysis',
      color: '#ec4899',
      gradient: 'linear-gradient(135deg, #ec4899, #db2777)'
    }
  ];

  return (
    <motion.div 
      initial={{ y: 100, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ 
        type: "spring",
        stiffness: 260,
        damping: 20,
        mass: 1
      }}
      className={styles.main}
    >
      <LayoutGroup>
        <motion.div className={styles.containerall}>
          {navigationItems.map((item) => (
            <motion.div
              key={item.id}
              className={`${styles.iconWrapper} ${selected === item.id ? styles.selected : ''}`}
              onClick={() => {
                setSelected(item.id);
                if (item.label === 'ATS' || item.label === 'Analysis' || item.label === 'Resume') {
                  handleNavigateAtsAnalysis(item.route);
                } else {
                  navigate(item.route);
                }
              }}
              whileHover={{ 
                y: -4,
                backgroundColor: "rgba(0, 0, 0, 0.03)",
                transition: { duration: 0.2 }
              }}
              whileTap={{ scale: 0.95 }}
            >
              <motion.div 
                className={styles.iconBackground}
                animate={{
                  background: selected === item.id ? item.gradient : 'transparent',
                  scale: selected === item.id ? 1 : 0.8,
                }}
                transition={{ type: "spring", stiffness: 300, damping: 20 }}
              >
                <motion.div
                  className={styles.iconContainer}
                  animate={{ 
                    color: selected === item.id ? '#ffffff' : '#64748b',
                    scale: selected === item.id ? 1 : 0.9
                  }}
                  transition={{ type: "spring", stiffness: 300 }}
                >
                  {item.icon}
                </motion.div>
              </motion.div>

              <motion.span 
                className={styles.label}
                animate={{ 
                  color: selected === item.id ? item.color : '#64748b',
                  fontWeight: selected === item.id ? 600 : 500
                }}
              >
                {item.label}
              </motion.span>

              <AnimatePresence>
                {selected === item.id && (
                  <motion.div 
                    className={styles.glow}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 0.15 }}
                    exit={{ opacity: 0 }}
                    style={{ background: item.gradient }}
                  />
                )}
              </AnimatePresence>
            </motion.div>
          ))}
        </motion.div>
      </LayoutGroup>
    </motion.div>
  );
};

export default BottomBar;
