import React, { useEffect, useState } from "react";
import styles from "../../assets/Matching.module.css";
import {
  AlertCircle,
  AlertTriangle,
  CheckCircle,
  ChevronDown,
  ChevronUp,
  Plus,
  PlusCircle,
  PlusIcon,
  RotateCw,
  Search,
  FileText,
  GitCompare,
  Scan,
  Edit2,
  Trash2,
} from "lucide-react";
import { useResume } from "./components/context/Resume";
import { useJobs } from "./components/context/Jobs";
import Model from "../../components/Model";
import OpenAI from "openai";
import "react-circular-progressbar/dist/styles.css";
import { privateApi } from "../utils/aixos";
import { toast } from "react-hot-toast";
import { motion } from "framer-motion";

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true,
});

const escapeRegExp = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};

const Matching = () => {
  const [value, setValue] = useState("");
  const [response, setResponse] = useState("");
  let [misskingSkills, setMissingSkills] = useState([]);
  let [skillss, setSkills] = useState([]);
  const [loading, setLoading] = useState(false);
  const { values, handleResumeChange, setValues, setJobDetails } = useResume();
  const [point, setPoint] = useState([]);
  const [pointProject, setPointProject] = useState([]);
  const { payment } = useJobs();
  const [modalShow, setModalShow] = useState(false);
  const [visibleCountKey, setVisibleCountkey] = useState(15);
  const [ProffestionalSummary, setProffestionalSummary] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [openSkill, setOpenSkill] = useState(true);
  const [openKeywords, setOpenKeywords] = useState(true);
  const [openSummary, setOpenSummary] = useState(true);
  const [openPoints, setOpenPoints] = useState(true);
  const [structuredJD, setStructuredJD] = useState(null);
  const [isAfterSummary, setIsAfterSummary] = useState(false);
  const [isAfterExperience, setIsAfterExperience] = useState(true);
  const [savedJobs, setSavedJobs] = useState(true);
  const [selectedJob, setSelectedJob] = useState(null);
  const [showAtsScore, setShowAtsScore] = useState(false);
  const [loadingStep, setLoadingStep] = useState(0);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [activeCompanyIndex, setActiveCompanyIndex] = useState(0);
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [editingPoints, setEditingPoints] = useState({});
  const [editingExistingPoints, setEditingExistingPoints] = useState({});
  const [addedPoints, setAddedPoints] = useState({});
  const [openProjectPoints, setOpenProjectPoints] = useState(true);
  const [isAfterProject, setIsAfterProject] = useState(true);
  const [activeProjectIndex, setActiveProjectIndex] = useState(0);
  const [savedJobsData, setSavedJobsData] = useState([]);
  const [TotalSkills, setTotalSkills] = useState([]); 
  const [savedId, setSavedId] = useState(null);
  const handleSubmit = () => {
    if (!value.trim()) {
      toast.error("Please enter a job description");
      return;
    }
    analyzeJobDescription();
  };

  const analyzeJobDescription = async () => {
    try {
      setLoading(true);
      // First pass: Basic keyword extraction
      const keywordExtraction = {
        skills: extractSkills(value),
      };

      // Second pass: AI analysis with enhanced keyword extraction
      const completion = await openai.chat.completions.create({
        messages: [
          {
            role: "system",
            content: `You are an expert ATS optimization specialist. Extract the most relevant skills and keywords from job descriptions to maximize ATS matching scores when included in a resume.

              Your output MUST follow this exact JSON structure:
              {
                "skills": {
                  "technical": [],
                  "soft": [],
                  "topKeywords": []
                },
                "JobRole": "",
                "location": ""
              }

              Guidelines for extraction:
              1. For "technical" skills: Include specific technologies, programming languages, tools, platforms, and methodologies.
              2. For "soft" skills: Include interpersonal abilities, management skills, and work style traits.
              3. For "topKeywords": Extract 20-25 most critical terms that appear to be essential for ATS matching, prioritizing terms that appear multiple times.
              4. For "JobRole": Extract the exact job title from the description, or infer the most likely title if not explicitly stated.
              5. For "location": Extract the job location if mentioned in the description. This could be a city, state, country. If no location is specified, leave it as an empty string.

              Prioritize keywords that:
              - Appear multiple times in the job description (these are critical)
              - Are mentioned in requirements or qualifications sections
              - Are industry-specific terms or domain expertise indicators
              - Represent certifications, frameworks, or specialized knowledge

              For location extraction:
              - Look for city/state/country names that appear in context of where the job is located
              - Check for phrases like "located in", "based in", "position in", etc.
              - If multiple locations are mentioned, include all of them separated by commas
              - If no location is found, return an empty string

              Ensure all extracted terms are:
              - Cleaned of punctuation and properly formatted
              - Exactly as they appear in the job description (preserve capitalization of technical terms)
              - Ranked by importance (frequency and prominence in the job description)
              - Not duplicated across categories

              Be comprehensive - extract as many relevant keywords as possible to maximize ATS matching potential.`,
          },
          {
            role: "user",
            content: `Extract the most ATS-relevant skills and keywords from this job description. Focus on terms that would significantly improve resume matching scores when included.

              Initial extraction: ${JSON.stringify(keywordExtraction.skills)}

              Job Description:
              ${value}`,
          },
        ],
        model: "gpt-3.5-turbo",
        temperature: 0.1,
        response_format: { type: "json_object" },
      });

      // Parse and validate AI response
      let aiAnalysis;
      try {
        aiAnalysis = JSON.parse(completion.choices[0].message.content);
        console.log("aiAnalysis", aiAnalysis);
        // Ensure topKeywords are properly formatted and deduplicated
        if (aiAnalysis.skills && aiAnalysis.skills.topKeywords) {
          // Create a Set for deduplication while preserving order
          const uniqueKeywords = new Set();

          // Process and clean keywords
          aiAnalysis.skills.topKeywords = aiAnalysis.skills.topKeywords
            .filter(
              (keyword) =>
                keyword && typeof keyword === "string" && keyword.trim()
            ) // Remove empty entries
            .map((keyword) => keyword.trim()) // Clean up whitespace
            .filter((keyword) => {
              // Check if it's a duplicate (case-insensitive)
              const lowerKeyword = keyword.toLowerCase();
              if (uniqueKeywords.has(lowerKeyword)) {
                return false;
              }
              uniqueKeywords.add(lowerKeyword);
              return true;
            })
            .slice(0, 20); // Allow up to 20 top keywords
        }

        // Deduplicate technical skills
        if (aiAnalysis.skills && aiAnalysis.skills.technical) {
          const uniqueTechnical = new Set();
          aiAnalysis.skills.technical = aiAnalysis.skills.technical
            .filter(
              (skill) => skill && typeof skill === "string" && skill.trim()
            )
            .map((skill) => skill.trim())
            .filter((skill) => {
              const lowerSkill = skill.toLowerCase();
              if (uniqueTechnical.has(lowerSkill)) {
                return false;
              }
              uniqueTechnical.add(lowerSkill);
              return true;
            });
        }

        // Deduplicate soft skills
        if (aiAnalysis.skills && aiAnalysis.skills.soft) {
          const uniqueSoft = new Set();
          aiAnalysis.skills.soft = aiAnalysis.skills.soft
            .filter(
              (skill) => skill && typeof skill === "string" && skill.trim()
            )
            .map((skill) => skill.trim())
            .filter((skill) => {
              const lowerSkill = skill.toLowerCase();
              if (uniqueSoft.has(lowerSkill)) {
                return false;
              }
              uniqueSoft.add(lowerSkill);
              return true;
            });
        }
      } catch (error) {
        console.error("Error parsing AI response:", error);
        aiAnalysis = { skills: { technical: [], soft: [], topKeywords: [] } };
      }

      // Merge the AI analysis with our initial extraction
      const finalAnalysis = {
        skills: {
          technical: [
            ...new Set([
              ...(keywordExtraction.skills?.technical || []),
              ...(aiAnalysis.skills?.technical || []),
            ]),
          ],
          soft: [
            ...new Set([
              ...(keywordExtraction.skills?.soft || []),
              ...(aiAnalysis.skills?.soft || []),
            ]),
          ],
          topKeywords: aiAnalysis.skills?.topKeywords || [], // Prefer AI's keyword analysis
        },
        JobRole: aiAnalysis.JobRole || "",
        location: aiAnalysis.location || "",
      };

      // Final cleanup to ensure no duplicates between categories
      const allTechnical = new Set(
        finalAnalysis.skills.technical.map((s) => s.toLowerCase())
      );
      const allSoft = new Set(
        finalAnalysis.skills.soft.map((s) => s.toLowerCase())
      );

      // Make sure topKeywords doesn't duplicate skills that are already in technical or soft
      finalAnalysis.skills.topKeywords =
        finalAnalysis.skills.topKeywords.filter((keyword) => {
          const lowerKeyword = keyword.toLowerCase();
          return !allTechnical.has(lowerKeyword) && !allSoft.has(lowerKeyword);
        });

      setStructuredJD(finalAnalysis);
      console.log("this is the final analysis", finalAnalysis);
      await handleATSScan({ finalAnalysis });
    } catch (error) {
      console.error("Error in analyzeJobDescription:", error);
      toast.error(error.message || "Error analyzing job description");
      setLoading(false);
    }
  };

  // Improve the extractSkills function for better initial extraction
  const extractSkills = (text) => {
    const technicalSkills = [
      // Programming Languages
      "JavaScript",
      "Python",
      "Java",
      "C++",
      "C#",
      "Ruby",
      "PHP",
      "Swift",
      "Kotlin",
      "Go",
      "Rust",
      "TypeScript",
      "Scala",
      "R",
      "MATLAB",
      "Perl",
      "Shell scripting",
      "Bash",

      // Web Technologies
      "HTML",
      "CSS",
      "SASS",
      "LESS",
      "React",
      "Angular",
      "Vue.js",
      "Node.js",
      "Express.js",
      "Django",
      "Flask",
      "Spring",
      "ASP.NET",
      "Laravel",
      "Ruby on Rails",
      "jQuery",
      "Redux",
      "GraphQL",
      "REST API",
      "SOAP",
      "WebSocket",
      "PWA",
      "Web Components",

      // Databases
      "SQL",
      "MySQL",
      "PostgreSQL",
      "MongoDB",
      "Redis",
      "Cassandra",
      "Oracle",
      "Microsoft SQL Server",
      "SQLite",
      "NoSQL",
      "Firebase",
      "DynamoDB",

      // Cloud & DevOps
      "AWS",
      "Azure",
      "Google Cloud",
      "Docker",
      "Kubernetes",
      "Jenkins",
      "Git",
      "CI/CD",
      "Terraform",
      "Ansible",
      "Chef",
      "Puppet",
      "ELK Stack",
      "Prometheus",
      "Grafana",
      "New Relic",
      "CloudFormation",
      "Serverless",

      // Mobile Development
      "iOS",
      "Android",
      "React Native",
      "Flutter",
      "Xamarin",
      "Mobile SDK",
      "SwiftUI",
      "Kotlin Multiplatform",
      "App development",

      // AI/ML & Data Science
      "Machine Learning",
      "Deep Learning",
      "TensorFlow",
      "PyTorch",
      "Scikit-learn",
      "Natural Language Processing",
      "Computer Vision",
      "Data Mining",
      "Big Data",
      "Hadoop",
      "Spark",
      "Neural Networks",
      "AI",
      "Data Analysis",
      "Statistics",

      // Testing & QA
      "Unit Testing",
      "Integration Testing",
      "Jest",
      "Mocha",
      "Selenium",
      "Cypress",
      "TestNG",
      "JUnit",
      "QA Automation",
      "Manual Testing",

      // Security
      "Cybersecurity",
      "Encryption",
      "OAuth",
      "JWT",
      "SSL/TLS",
      "OWASP",
      "Penetration Testing",
      "Security Protocols",
      "Authentication",
      "Authorization",

      // Design & UI/UX
      "UI/UX",
      "Figma",
      "Adobe XD",
      "Sketch",
      "InVision",
      "Photoshop",
      "Illustrator",
      "Responsive Design",
      "Material Design",
      "Bootstrap",

      // Project Management & Tools
      "Agile",
      "Scrum",
      "Kanban",
      "JIRA",
      "Confluence",
      "Trello",
      "MS Project",
      "Version Control",
      "GitHub",
      "BitBucket",
      "GitLab",

      // Build Tools & Package Managers
      "NPM",
      "Yarn",
      "Webpack",
      "Vite",
      "Babel",
      "Grunt",
      "Gulp",
      "Rollup",
      "Parcel",
      "Lerna",
      "pnpm",
      "Maven",
      "Gradle",

      // Frontend Development
      "GraphQL",
      "REST",
      "Apollo",
      "Redux",
      "Vuex",
      "MobX",
      "RxJS",
      "WebSocket",
      "Frontend",
      "Front-end",
      "Front end",
      "Client-side",
    ];

    const softSkills = [
      // Communication
      "communication",
      "presentation",
      "public speaking",
      "writing",
      "documentation",
      "interpersonal",
      "collaboration",
      "team player",
      "articulate",
      "listening",

      // Leadership
      "leadership",
      "mentoring",
      "coaching",
      "team management",
      "delegation",
      "decision making",
      "strategic thinking",
      "conflict resolution",
      "motivation",

      // Problem Solving
      "problem solving",
      "analytical",
      "critical thinking",
      "troubleshooting",
      "debugging",
      "root cause analysis",
      "innovation",
      "creativity",

      // Project Management
      "project management",
      "time management",
      "organization",
      "planning",
      "prioritization",
      "multitasking",
      "deadline-oriented",
      "resource management",

      // Adaptability
      "adaptability",
      "flexibility",
      "learning agility",
      "quick learner",
      "resilience",
      "change management",
      "initiative",
      "self-motivated",

      // Work Ethic
      "attention to detail",
      "reliability",
      "responsibility",
      "accountability",
      "professionalism",
      "work ethic",
      "integrity",
      "dedication",

      // Team Skills
      "teamwork",
      "collaboration",
      "cross-functional",
      "team building",
      "relationship building",
      "networking",
      "stakeholder management",

      // Business Skills
      "business acumen",
      "customer service",
      "client relations",
      "negotiation",
      "requirements gathering",
      "strategic planning",
      "analytical thinking",
    ];

    // Helper to clean text content
    const cleanText = (text) => {
      return text
        .replace(/company description:?.*/gi, "") // Remove company description sections
        .replace(/our mission.*/gi, "") // Remove mission statements
        .replace(/etc\./g, "") // Remove etc.
        .replace(/such as/g, ",") // Convert "such as" to comma
        .replace(/[,\s]+/g, " ") // Normalize spaces and commas
        .trim();
    };

    // Helper to extract skills from requirement phrases
    const extractSkillsFromPhrase = (phrase) => {
      const skillsFound = {
        technical: [],
        soft: [],
        other: [],
      };

      // Split by common delimiters
      const parts = phrase
        .split(/[,;]|\sand\s|\sor\s|\such as\s|\setc\.?/g)
        .map((part) => part.trim())
        .filter(Boolean);

      parts.forEach((part) => {
        // Clean up the part
        const cleanPart = part
          .replace(/experience\s+(in|with)\s+/i, "")
          .replace(/knowledge\s+of\s+/i, "")
          .replace(/proficiency\s+in\s+/i, "")
          .trim();

        // Check if it's a technical skill
        const matchedTechnicalSkill = technicalSkills.find((skill) =>
          new RegExp(`\\b${escapeRegExp(skill.toLowerCase())}\\b`).test(
            cleanPart.toLowerCase()
          )
        );

        if (matchedTechnicalSkill) {
          skillsFound.technical.push(matchedTechnicalSkill);
        } else {
          // Check if it's a soft skill
          const matchedSoftSkill = softSkills.find((skill) =>
            new RegExp(`\\b${escapeRegExp(skill.toLowerCase())}\\b`).test(
              cleanPart.toLowerCase()
            )
          );

          if (matchedSoftSkill) {
            skillsFound.soft.push(matchedSoftSkill);
          } else if (cleanPart.length > 2 && !/^\d+$/.test(cleanPart)) {
            // Add to other skills if it meets minimum criteria
            skillsFound.other.push(cleanPart);
          }
        }
      });

      return skillsFound;
    };

    const skills = {
      technical: [],
      soft: [],
      other: [],
    };

    // Clean the input text
    const cleanedText = cleanText(text);

    // Extract skills from requirement phrases
    const requirementPatterns = [
      /experience\s+(?:in|with)\s+([^.!?]+)/gi,
      /knowledge\s+of\s+([^.!?]+)/gi,
      /proficiency\s+in\s+([^.!?]+)/gi,
      /familiarity\s+with\s+([^.!?]+)/gi,
      /skills?\s+(?:in|with)?\s*:?\s*([^.!?]+)/gi,
      /tools?\s+(?:such\s+as\s+)?([^.!?]+)/gi,
    ];

    requirementPatterns.forEach((pattern) => {
      const matches = cleanedText.matchAll(pattern);
      for (const match of matches) {
        const phrase = match[1];
        const extractedSkills = extractSkillsFromPhrase(phrase);

        skills.technical.push(...extractedSkills.technical);
        skills.soft.push(...extractedSkills.soft);
        skills.other.push(...extractedSkills.other);
      }
    });

    // Add additional skill extraction patterns
    const skillSectionPatterns = [
      /required skills[:\s]*([\s\S]*?)(?=\n\s*\n|\n\s*[A-Z]|$)/i,
      /technical skills[:\s]*([\s\S]*?)(?=\n\s*\n|\n\s*[A-Z]|$)/i,
      /qualifications[:\s]*([\s\S]*?)(?=\n\s*\n|\n\s*[A-Z]|$)/i,
      /requirements[:\s]*([\s\S]*?)(?=\n\s*\n|\n\s*[A-Z]|$)/i,
    ];

    // Extract skills from specific sections
    skillSectionPatterns.forEach((pattern) => {
      const match = text.match(pattern);
      if (match && match[1]) {
        const sectionText = match[1];
        const sectionSkills = extractSkillsFromPhrase(sectionText);

        skills.technical.push(...sectionSkills.technical);
        skills.soft.push(...sectionSkills.soft);
        skills.other.push(...sectionSkills.other);
      }
    });

    // Remove duplicates and sort
    return {
      technical: [...new Set(skills.technical)].sort(),
      soft: [...new Set(skills.soft)].sort(),
      other: [...new Set(skills.other)]
        .filter(
          (skill) =>
            !skills.technical.includes(skill) &&
            !skills.soft.includes(skill) &&
            skill.length > 2 && // Filter out short words
            !/^\d+$/.test(skill) // Filter out numbers
        )
        .sort(),
    };
  };

  const getResponse = async () => {
    try {
      setLoading(true);
      const completion = await openai.chat.completions.create({
        messages: [
          {
            role: "system",
            content: `You are a professional resume writer specializing in creating impactful professional summaries. 
            Focus on incorporating keywords naturally while maintaining a compelling narrative.`,
          },
          {
            role: "user",
            content: `Create a professional summary incorporating these missing keywords: ${response.suggestions.missing_words.join(
              ", "
            )}.
            
            Guidelines:
            1. Keep it between 3-4 sentences
            2. Do not start with years of experience
            3. Highlight technical skills and domain knowledge
            4. Focus on quantifiable achievements
            5. Naturally weave in the missing keywords
            6. Use active voice and professional tone
            7. Avoid generic phrases and clichés
            
            Current Job Role: ${values.sections.experience?.data[0]?.role || ""}
            Industry: ${values.sections.experience?.data[0]?.company || ""}
            `,
          },
        ],
        model: "gpt-3.5-turbo",
        temperature: 0.7,
        max_tokens: 250,
      });

      const summary = completion.choices[0].message.content;
      setProffestionalSummary(summary);
      setLoading(false);
      setOpenSummary(true); // Open the summary section automatically
    } catch (error) {
      console.error("Error generating professional summary:", error);
      setLoading(false);
      toast.error("Failed to generate professional summary. Please try again.");
    }
  };

  const handleATSScan = async ({ finalAnalysis }) => {
    try {
      const { data } = await privateApi.post(`/pdf/ats`, {
        descrption: value,
        resumeContent: values,
        relevantSkills: finalAnalysis.skills, // Pass the extracted skills
        jobDescription: value,
        jobTitle: finalAnalysis?.JobRole || "",
        jobLocation: finalAnalysis?.location || "",
      });

     
       setSavedId(data.savedId);
      setJobDetails(value);
      setLoading(false);
      setResponse(data.score);
      setTotalSkills(data.score);
      setTotalSkills(data.score.totalSkillsJD);
      setSelectedKeywords(data.score.topKeywordsJD);
      setValue(data.Jd);
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error("Error processing job description");
    }
  };

  const handleImproveResume = async (id) => {
    try {
      const { data } = await privateApi.post(`/pdf/ats-check-saved`, {
        resumeContent: values,
        id: id,
      }); 
       setSavedId(data.savedId);
      setTotalSkills(data.score.totalSkillsJD);
      setSelectedKeywords(data.score.topKeywordsJD);
      setLoading(false);
      setResponse(data.score);
      setJobDetails(data.Jd);
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error("Error processing job description");
    }
  };

  const generatePoint = async (expIndex) => {
    try {
      // Get existing points for the selected experience
      const existingPoints =
        values.sections.experience?.simpleList[expIndex]?.points || [];

      const completion = await openai.chat.completions.create({
        messages: [
          {
            role: "system",
            content:
              "You're a resume enhancement expert. Your task is to improve existing resume points by incorporating missing skills. Return only a JSON array of enhanced points, maintaining the same number of points as provided.",
          },
          {
            role: "user",
            content: `
                Job role: ${
                  values.sections.experience?.simpleList[expIndex]?.role || ""
                }
                Company: ${
                  values.sections.experience?.simpleList[expIndex]?.company ||
                  ""
                }
                Missing Skills: ${response.suggestions.missing_skills.join(
                  ", "
                )}

                Existing points to enhance:
                ${JSON.stringify(existingPoints)}

                Guidelines:
                1. Maintain the same number of points as the existing list
                2. Preserve the core achievements and responsibilities in each point
                3. Naturally incorporate the missing skills where relevant
                4. Include percentages or metrics where appropriate
                5. Keep each point under 150 characters
                6. Return only a JSON array of strings with the enhanced points
                          `,
          },
        ],
        model: "gpt-3.5-turbo",
        temperature: 0.7,
        max_tokens: 1000,
      });

      const jsonString = completion.choices[0].message.content;

      // Handle both direct JSON and JSON within markdown code blocks
      let suggestions;
      try {
        // Try parsing directly first
        suggestions = JSON.parse(jsonString);
      } catch (e) {
        // If direct parsing fails, try extracting from markdown code block
        const jsonMatch = jsonString.match(/```(?:json)?\s*([\s\S]*?)\s*```/);
        if (jsonMatch && jsonMatch[1]) {
          suggestions = JSON.parse(jsonMatch[1].trim());
        } else {
          throw new Error("Failed to parse response as JSON");
        }
      }

      // If we got fewer points than existing, pad with existing points
      if (suggestions.length < existingPoints.length) {
        suggestions = [
          ...suggestions,
          ...existingPoints.slice(suggestions.length),
        ];
      }
      // If we got more points than existing, truncate
      else if (
        suggestions.length > existingPoints.length &&
        existingPoints.length > 0
      ) {
        suggestions = suggestions.slice(0, existingPoints.length);
      }

      setPoint(suggestions);
    } catch (error) {
      console.error("Error generating enhanced points:", error);
      toast.error("Failed to enhance experience points. Please try again.");
    }
  };

  const generatePointProject = async (projectIndex = 0) => {
    try {
      setLoading(true);

      // Get the current project
      const currentProject = values.sections.projects.data[projectIndex];

      if (!currentProject) {
        toast.error("No project found at this index");
        setLoading(false);
        return;
      }

      // Create prompt for generating project points
      const prompt = `Generate 5 strong bullet points for a resume project section based on the following project:
      
      Project Title: ${currentProject.title || ""}
      Organization: ${currentProject.organisation || ""}
      Description: ${currentProject.description || ""}
      
      Job Description I'm applying for: ${value}
      
      Create impactful bullet points that highlight technical skills, achievements, and results. Focus on technologies used, problems solved, and quantifiable outcomes. Make each point start with a strong action verb.`;

      // Call API or use OpenAI
      const completion = await openai.chat.completions.create({
        messages: [
          { role: "system", content: "You are a professional resume writer." },
          { role: "user", content: prompt },
        ],
        model: "gpt-3.5-turbo",
      });

      // Parse the response to get bullet points
      const response = completion.choices[0].message.content;
      const bulletPoints = response
        .split(/\d+\.|\n-|\*/)
        .map((point) => point.trim())
        .filter((point) => point.length > 0);

      // Set the points
      setPointProject(bulletPoints);
      setLoading(false);
    } catch (error) {
      console.error("Error generating project points:", error);
      setLoading(false);
      toast.error("Failed to generate project points");
    }
  };

  const addSummary = async () => {
    handleResumeChange(
      `sections.Professionalsummary.data.summary`,
      ProffestionalSummary
    );
  };
  const addSkills = (singleSkill) => {
    // Filter out the selected skill from missingSkills
    setMissingSkills((prevMissingSkills) =>
      prevMissingSkills.filter((skill) => skill !== singleSkill)
    );

    // Add the selected skill to the skills array
    setSkills((prevSkills) => {
      // Retrieve existing content from the `values` state
      setValues((prevState) => {
        let temp = { ...prevState }; // Copy the previous state

        // Safely navigate to `sections.technicalSkills.simpleList`
        if (!temp.sections) {
          temp.sections = {};
        }
        if (!temp.sections.technicalSkills) {
          temp.sections.technicalSkills = {};
        }
        if (!temp.sections.technicalSkills.simpleList) {
          temp.sections.technicalSkills.simpleList = [];
        }

        // Get the current skills array
        const currentSkills = temp.sections.technicalSkills.simpleList;

        // Check if skill already exists
        if (!currentSkills.includes(singleSkill)) {
          // Add the new skill directly to the array
          const updatedSkills = [...currentSkills, singleSkill];

          // Update the simpleList in the state
          temp.sections.technicalSkills.simpleList = updatedSkills;

          // Call handleResumeChange with the updated skills array
          handleResumeChange(
            "sections.technicalSkills.simpleList",
            updatedSkills
          );
        } else {
          console.log("Skill already exists in the array, not adding again.");
        }

        return temp;
      });

      // Add the skill to local skills array if needed for another purpose
      return [...prevSkills, singleSkill];
    });
  };

  const addExper = (data, expIndex) => {
    setValues((prevState) => {
      let temp = { ...prevState };

      // Safely navigate to the specific experience entry
      if (!temp.sections?.experience?.simpleList[expIndex]) {
        temp.sections.experience.simpleList[expIndex] = { points: [] };
      }

      // Get the current experience at specified index
      const currentExperienceAtIndex =
        temp.sections.experience.simpleList[expIndex];

      // Ensure points array exists
      if (!currentExperienceAtIndex.points) {
        currentExperienceAtIndex.points = [];
      }

      // Replace the point at the same index or add if it doesn't exist
      const pointIndex = point.indexOf(data);
      if (
        pointIndex >= 0 &&
        pointIndex < currentExperienceAtIndex.points.length
      ) {
        // Replace existing point at the same index
        const updatedPoints = [...currentExperienceAtIndex.points];
        updatedPoints[pointIndex] = data;
        temp.sections.experience.simpleList[expIndex].points = updatedPoints;
      } else {
        // Add as new point if index doesn't exist
        const updatedPoints = [...currentExperienceAtIndex.points, data];
        temp.sections.experience.simpleList[expIndex].points = updatedPoints;
      }

      // Define sectionKey for the specific experience
      let sectionKey = `sections.experience.simpleList.${expIndex}.points`;

      // Call handleResumeChange with the updated points array
      handleResumeChange(
        sectionKey,
        temp.sections.experience.simpleList[expIndex].points
      );

      // Mark this point as added
      setAddedPoints((prev) => ({
        ...prev,
        [`${expIndex}-${data}`]: true,
      }));

      return temp;
    });
  };

  const addProject = (data, projectIndex) => {
    setValues((prevState) => {
      let temp = { ...prevState };

      // Safely navigate to `sections.projects.data`
      if (!temp.sections) {
        temp.sections = {};
      }
      if (!temp.sections.projects) {
        temp.sections.projects = {};
      }
      if (!temp.sections.projects.data) {
        temp.sections.projects.data = [];
      }

      // Ensure there is an object at the specified project index
      if (!temp.sections.projects.data[projectIndex]) {
        temp.sections.projects.data[projectIndex] = { points: [] };
      }

      // Get the current project at the specified index
      const currentProjectAtIndex = temp.sections.projects.data[projectIndex];

      // Ensure there is a `points` field in the current project
      if (!currentProjectAtIndex.points) {
        currentProjectAtIndex.points = [];
      }

      // Find the index of this point in the generated points
      const pointIndex = pointProject.indexOf(data);

      // Replace the point at the same index or add if it doesn't exist
      if (pointIndex >= 0 && pointIndex < currentProjectAtIndex.points.length) {
        // Replace existing point at the same index
        const updatedPoints = [...currentProjectAtIndex.points];
        updatedPoints[pointIndex] = data;
        temp.sections.projects.data[projectIndex].points = updatedPoints;
      } else {
        // Add as new point if index doesn't exist
        const updatedPoints = [...currentProjectAtIndex.points, data];
        temp.sections.projects.data[projectIndex].points = updatedPoints;
      }

      // Define sectionKey dynamically for handleResumeChange
      let sectionKey = `sections.projects.data.${projectIndex}.points`;

      // Call handleResumeChange with the updated points array
      handleResumeChange(
        sectionKey,
        temp.sections.projects.data[projectIndex].points
      );

      // Mark this point as added
      setAddedPoints((prev) => ({
        ...prev,
        [`project-${projectIndex}-${data}`]: true,
      }));

      // Return the updated state
      return temp;
    });
  };

  const handleAtsCheck = (jobId) => {
    setSelectedJob(jobId);
    setShowAtsScore(true);
  };

  const JobDescriptionContainer = () => {
    return (
      <div className={styles.container}>
        {/* <label htmlFor="jobDescription" className={styles.label}>
          Job Description
        </label> */}
        <div className={styles.textBoxContainer}>
          {!isFocused && (
            <div className={styles.placeholderText}>
              Example
              <br />
              - Company-wide committee facilitation and participation
              <br />
              - Employee safety, welfare, wellness and health reporting
              <br />
              - Provide direct support to employees during implementation of HR
              services, policies and programs
              <br />
              What skills will the intern learn:
              <br />
              - Active participation in strategic planning process, including
              developing goals, objectives and processes
              <br />
              - How to engage professionally in HR meetings and seminars with
              other HR professionals in the region
              <br />
              - Gain experience with Human Resources Information system (HRIS)
              database management and record keeping
              <br />
              - Application of HR law and compliance with governmental
              regulations
              <br />
              Qualifications:
              <br />
              - Proficient with Microsoft Word and Excel
              <br />
              - General knowledge of employment law and practices
              <br />
              - Able to maintain a high level of confidentiality
              <br />- Effective oral and written management communication skills
            </div>
          )}
          <textarea
            id="jobDescription"
            className={styles.textBox}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onFocus={() => setIsFocused(true)}
            onBlur={(e) =>
              setIsFocused(e.target.value.length > 0 ? true : false)
            }
          />
        </div>

        <div className={styles.buttoncontainwer}>
          <div
            className={styles.buttons}
            onClick={
              payment?.paymentStatus !== "success"
                ? () => setModalShow(true)
                : handleSubmit
            }
          >
            {loading ? (
              <div className={styles.loadingContainer}>
                <div className={styles.loadingProgress}>
                  <div
                    className={styles.progressBar}
                    style={{ width: `${loadingProgress}%` }}
                  />
                  <div className={styles.loadingIcon}>
                    {loadingStep === 0 && (
                      <Search className={styles.icon} size={18} />
                    )}
                    {loadingStep === 1 && (
                      <FileText className={styles.icon} size={18} />
                    )}
                    {loadingStep === 2 && (
                      <GitCompare className={styles.icon} size={18} />
                    )}
                    {loadingStep === 3 && (
                      <CheckCircle className={styles.icon} size={18} />
                    )}
                  </div>
                  <div className={styles.loadingText}>
                    <span className={styles.stepText}>
                      {loadingStep === 0 && "Analyzing Resume"}
                      {loadingStep === 1 && "Extracting Skills"}
                      {loadingStep === 2 && "Matching Requirements"}
                      {loadingStep === 3 && "Finalizing Results"}
                    </span>
                    <span className={styles.progressText}>
                      {loadingProgress}%
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <Scan className={styles.scanIcon} size={18} />
                <span>Scan ATS</span>
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  const SavedJobsContainer = () => {
    return (
      <div className={styles.savedJobsWrapper}>
        {savedJobsData.length > 0 ? (
          // ... existing code for when jobs exist ...
          <>
            <div className={styles.headerContainer}>
              <div className={styles.jobCount}>
                {savedJobsData.length} jobs
              </div>
            </div>

            <div className={styles.jobsList}>
              {savedJobsData.map((job) => (
                <div key={job.id} className={styles.jobCard}>
                  <div className={styles.jobHeader}>
                    <div className={styles.companyLogo}>
                      <img
                        src="https://png.pngtree.com/template/20191014/ourmid/pngtree-real-estate-logo-design-template-building-logo-image_317801.jpg"
                        alt={`${job.JobRole} logo`}
                      />
                    </div>
                    <div className={styles.jobInfo}>
                      <h3 className={styles.jobTitle}>{job.Job_title}</h3>
                      <p className={styles.companyName}>{job.company}</p>
                    </div>
                    <div className={styles.scoreContainer}>
                      {showAtsScore && selectedJob === job.id && (
                        <div className={styles.matchScore}>
                          <div className={styles.atsScore}>
                            <div
                              className={styles.scoreCircle}
                              style={{
                                background: `conic-gradient(#10B981 ${job.Score}%, #e5e7eb ${job.Score}%)`,
                              }}
                            >
                              <span>{job.Score}%</span>
                            </div>
                            <p>ATS</p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className={styles.jobDetails}>
                    <div className={styles.jobDescription}>
                      {JSON.parse(job.JD).slice(0, 200)}
                    </div>
                  </div>

                  <div className={styles.jobFooter}>
                    <div className={styles.leftActions}>
                      <button
                        className={styles.atsButton}
                        onClick={() => handleAtsCheck(job.id)}
                      >
                        Check ATS Score
                      </button>
                      <button
                        className={styles.improveButton}
                        onClick={() => handleImproveResume(job.id)}
                      >
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                        >
                          <path d="M12 20h9M16.5 3.5a2.12 2.12 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
                        </svg>
                        Improve Resume
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <div className={styles.emptyStateContainer}>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className={styles.emptyStateContent}
            >
              <motion.img
                src="https://res.cloudinary.com/dmsbvsful/image/upload/v1741092080/MyFuseMascot_tpzjwr.png"
                alt="Empty jobs illustration"
                className={styles.emptyStateImage}
                animate={{ 
                  y: [0, -10, 0],
                  rotate: [-2, 2, -2]
                }}
                transition={{ 
                  duration: 3,
                  repeat: Infinity,
                  ease: "easeInOut"
                }}
              />
              <motion.h3
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.3 }}
                className={styles.emptyStateTitle}
              >
                No Saved Jobs Yet
              </motion.h3>
              <motion.p
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.5 }}
                className={styles.emptyStateDescription}
              >
                Add your first job description to get personalized ATS feedback and improve your resume
              </motion.p>
              <motion.button
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ delay: 0.7 }}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className={styles.addJobButton}
                onClick={() => setSavedJobs(false)}
              >
                <Plus size={18} />
                Add Job Description
              </motion.button>
            </motion.div>
          </div>
        )}
      </div>
    );
  };

  const TabContainer = () => {
    return (
      <div className={styles.sfsTab}>
        <div className={styles.containerButtonTab}>
          <div
            className={`${styles.innerbutton} ${
              !savedJobs ? styles.buttonActive : ""
            }`}
            onClick={() => setSavedJobs(false)}
          >
            Job Description
          </div>
          <div
            className={`${styles.innerbutton} ${
              savedJobs ? styles.buttonActive : ""
            }`}
            onClick={() => setSavedJobs(true)}
          >
            Saved Jobs
          </div>
        </div>
      </div>
    );
  };

  const ScoreContainer = () => {
    const percentage = response?.overallScore || 20;
    const isComplete = percentage === 100; 
    const perccentageCalculator = ({fullScore,score}) => {
      return Math.round((score/fullScore)*100);
    }

    const handleRecheck = async () => {
      setLoading(true);
      try {
        await handleImproveResume(savedId);
      } catch (error) {
        console.error("Error in recheck:", error);
        toast.error("Failed to recheck. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    const getScoreCategory = (score) => {
      if (score >= 90) return "excellent";
      if (score >= 70) return "good";
      if (score >= 40) return "average";
      return "poor";
    };

    return (
      <div className={styles.scoreContainerMain}> 
        <div className={styles.resultsContainer}>
          {/* Left side - ATS Results */}
          <div className={styles.atsResults}>
            <div className={styles.sectionHeader}>
              <h2 className={styles.sectionTitle}>ATS Results</h2>
             
            </div>

            {/* Score Circle */}
            <div className={styles.scoreCircleWrapper}>
            <div className={styles.progressRing}>
              <svg width="92" height="102" viewBox="0 0 92 102">
                <circle
                  cx="46"
                  cy="51"
                  r="40"
                  fill="none"
                  strokeWidth="7"
                  className={styles.progressBg}
                />
                <motion.circle
                  cx="46"
                  cy="51"
                  r="40"
                  fill="none"
                  strokeWidth="7"
                  className={`${styles.progressFill} ${
                    isComplete
                      ? styles.completeProgress
                      : styles[
                          `${
                            percentage < 40
                              ? "low"
                              : percentage < 70
                              ? "medium"
                              : "high"
                          }Progress`
                        ]
                  }`}
                  style={{
                    transformOrigin: "center",
                    transform: "rotate(-90deg)",
                    strokeDasharray: "251",
                  }}
                  initial={{ strokeDashoffset: 251 }}
                  animate={{
                    strokeDashoffset: 251 - (251 * percentage) / 100,
                  }}
                  transition={{
                    duration: 1,
                    ease: "easeInOut",
                    delay: 0.2,
                  }}
                />
              </svg>

              <motion.div
                className={styles.progressValue}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.5 }}
              >
                {/* {Math.round(percentage)}%  */}
                <img
                  src="https://res.cloudinary.com/dmsbvsful/image/upload/v1741092080/MyFuseMascot_tpzjwr.png"
                  alt=""
                  className={styles.progressMascot}
                />
              </motion.div>
            </div>
               <span className={styles.scoreText}> {response?.overallScore}%</span> 
               <button 
                onClick={handleRecheck}
                className={`${styles.recheckButton} ${loading ? styles.loading : ''}`}
                disabled={loading}
              >
                <RotateCw size={16} className={loading ? styles.spinning : ''} />
                <span>{loading ? 'Analyzing...' : 'Re-analyze Resume'}</span>
                {loading && <div className={styles.loadingDots}><span>.</span><span>.</span><span>.</span></div>}
              </button>
              </div>

            {/* Score Metrics */}
            <div className={styles.metrics}>
              {/* Keywords Match */}
              <div 
                className={styles.metricItemContainer}
                data-score={getScoreCategory(perccentageCalculator({fullScore:20,score:response?.keywordWordsMatch?.score}))}
              >
                <div className={styles.metricItem}>
                  <span className={styles.metricLabel}>Keywords Match</span>
                  <span 
                    className={styles.metricValue}
                    data-score={getScoreCategory(perccentageCalculator({fullScore:20,score:response?.keywordWordsMatch?.score}))}
                  >
                    {perccentageCalculator({fullScore:20,score:response?.keywordWordsMatch?.score})}%
                  </span>
                </div>
                <div className={styles.metricBar}>
                  <div 
                    className={styles.metricFill} 
                    style={{ width: perccentageCalculator({fullScore:20,score:response?.keywordWordsMatch?.score}) + "%" }}
                    data-score={getScoreCategory(perccentageCalculator({fullScore:20,score:response?.keywordWordsMatch?.score}))}
                  />
                </div>
              </div>

              {/* Skills Match */}
              <div 
                className={styles.metricItemContainer}
                data-score={getScoreCategory(perccentageCalculator({fullScore:20,score:response?.skillsMatch?.score}))}
              >
                <div className={styles.metricItem}>
                  <span className={styles.metricLabel}>Skills Match</span>
                  <span 
                    className={styles.metricValue}
                    data-score={getScoreCategory(perccentageCalculator({fullScore:20,score:response?.skillsMatch?.score}))}
                  >
                    {perccentageCalculator({fullScore:20,score:response?.skillsMatch?.score})}%
                  </span>
                </div>
                <div className={styles.metricBar}>
                  <div 
                    className={styles.metricFill} 
                    style={{ width: perccentageCalculator({fullScore:20,score:response?.skillsMatch?.score}) + "%" }}
                    data-score={getScoreCategory(perccentageCalculator({fullScore:20,score:response?.skillsMatch?.score}))}
                  />
                </div>
              </div>

              {/* Experience Relevance */}
              <div 
                className={styles.metricItemContainer}
                data-score={getScoreCategory(perccentageCalculator({fullScore:15,score:response?.experienceRelivance?.score}))}
              >
                <div className={styles.metricItem}>
                  <span className={styles.metricLabel}>Experience Relevance</span>
                  <span 
                    className={styles.metricValue}
                    data-score={getScoreCategory(perccentageCalculator({fullScore:15,score:response?.experienceRelivance?.score}))}
                  >
                    {perccentageCalculator({fullScore:15,score:response?.experienceRelivance?.score})}%
                  </span>
                </div>
                <div className={styles.metricBar}>
                  <div 
                    className={styles.metricFill} 
                    style={{ width: perccentageCalculator({fullScore:15,score:response?.experienceRelivance?.score}) + "%" }}
                    data-score={getScoreCategory(perccentageCalculator({fullScore:15,score:response?.experienceRelivance?.score}))}
                  />
                </div>
              </div>

              {/* Education And Certification */}
              <div 
                className={styles.metricItemContainer}
                data-score={getScoreCategory(perccentageCalculator({fullScore:10,score:response?.educationaAndCertification?.score}))}
              >
                <div className={styles.metricItem}>
                  <span className={styles.metricLabel}>Education And Certification</span>
                  <span 
                    className={styles.metricValue}
                    data-score={getScoreCategory(perccentageCalculator({fullScore:10,score:response?.educationaAndCertification?.score}))}
                  >
                    {perccentageCalculator({fullScore:10,score:response?.educationaAndCertification?.score})}%
                  </span>
                </div>
                <div className={styles.metricBar}>
                  <div 
                    className={styles.metricFill} 
                    style={{ width: perccentageCalculator({fullScore:10,score:response?.educationaAndCertification?.score}) + "%" }}
                    data-score={getScoreCategory(perccentageCalculator({fullScore:10,score:response?.educationaAndCertification?.score}))}
                  />
                </div>
              </div>

              {/* Resume Structure */}
              <div 
                className={styles.metricItemContainer}
                data-score={getScoreCategory(perccentageCalculator({fullScore:5,score:response?.resumeStructure?.score}))}
              >
                <div className={styles.metricItem}>
                  <span className={styles.metricLabel}>Resume Structure</span>
                  <span 
                    className={styles.metricValue}
                    data-score={getScoreCategory(perccentageCalculator({fullScore:5,score:response?.resumeStructure?.score}))}
                  >
                    {perccentageCalculator({fullScore:5,score:response?.resumeStructure?.score})}%
                  </span>
                </div>
                <div className={styles.metricBar}>
                  <div 
                    className={styles.metricFill} 
                    style={{ width: perccentageCalculator({fullScore:5,score:response?.resumeStructure?.score}) + "%" }}
                    data-score={getScoreCategory(perccentageCalculator({fullScore:5,score:response?.resumeStructure?.score}))}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Right side - Suggestions */}
          <div className={styles.suggestions}>
          <div className={styles.sectionHeader}>
              <h2 className={styles.sectionTitle}> Suggestions</h2>
             
            </div>
             

            <div className={styles.suggestionSummary}>
              <div className={styles.summaryCard}>
                <span>Priority Actions to Improve Your Score</span>
                <p>Focus on these suggestions to enhance your ATS compatibility</p>
              </div>
            </div>

            {/* Existing suggestion list */}
            <div className={styles.suggestionList}>
              <div className={`${styles.suggestionItem} ${styles.jobTitle}`}>
                <h3>
                  <FileText size={16} />
                  Job Title Match
                </h3>
                <p>{response?.jobTitleMatch?.feedback}</p>
              </div>

              <div className={`${styles.suggestionItem} ${styles.location}`}>
                <h3>
                  <GitCompare size={16} />
                  Location Preference
                </h3>
                <p>{response?.locationMatch?.feedback}</p>
              </div>

              <div className={`${styles.suggestionItem} ${styles.experience}`}>
                <h3>
                  <Search size={16} />
                  Experience Relevance
                </h3>
                <p>
                  {response?.experienceRelivance?.feedback.actionVerbs}
                  <br />
                  {response?.experienceRelivance?.feedback.measurables}
                </p>
              </div>

              <div className={`${styles.suggestionItem} ${styles.language}`}>
                <h3>
                  <Scan size={16} />
                  Language Accuracy
                </h3>
                <p>No spelling errors in the resume.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const getSkillStatus = (skill) => {
    // Count occurrences of the skill in the job description
    const skillRegex = new RegExp(`\\b${escapeRegExp(skill)}\\b`, "gi");
    const occurrences = (value?.match(skillRegex) || []).length;

    // Determine priority based on occurrences
    if (occurrences > 2) {
      return <span className={styles.high}>High Priority</span>;
    } else if (occurrences === 2) {
      return <span className={styles.medium}>Medium Priority</span>;
    } else {
      return <span className={styles.low}>Low Priority</span>;
    }
  };

  const MissingSkillsContainer = () => {
    // Helper function to check if a skill is already in the resume
    const isSkillAdded = (skill) => {
      return values.sections?.technicalSkills?.simpleList?.includes(skill);
    };

    // Helper function to get resume skills
    const getResumeSkills = () => {
      return values.sections?.technicalSkills?.simpleList || [];
    };

    // Helper function to get JD skills
    const getJDSkills = () => {
      // const technicalSkills = structuredJD?.skills?.technical || [];
      // const softSkills = structuredJD?.skills?.soft || [];
      // Combine all skills from JD without removing duplicates
      // return [...technicalSkills, ...softSkills];

      return TotalSkills;
    };

    // Function to get all skills comparison
    const getAllSkillsComparison = () => {
      const resumeSkills = getResumeSkills();
      const jdSkills = getJDSkills();
      // Create array to store all skill comparisons
      const comparisons = [];

      // Process JD skills
      jdSkills.forEach((jdSkill) => {
        if (jdSkill && typeof jdSkill === "string") {
          const matchingResumeSkill = resumeSkills.find(
            (resumeSkill) => resumeSkill.toLowerCase() === jdSkill.toLowerCase()
          );

          comparisons.push({
            jdSkill: jdSkill,
            resumeSkill: matchingResumeSkill || null,
            isMatch: !!matchingResumeSkill,
          });
        }
      });

      // Add remaining resume skills that aren't in JD
      resumeSkills.forEach((resumeSkill) => {
        if (resumeSkill && typeof resumeSkill === "string") {
          const isAlreadyCompared = comparisons.some(
            (comp) =>
              comp.resumeSkill?.toLowerCase() === resumeSkill.toLowerCase()
          );

          if (!isAlreadyCompared) {
            comparisons.push({
              jdSkill: null,
              resumeSkill: resumeSkill,
              isMatch: false,
            });
          }
        }
      });

      // console.log("Comparison Result:", comparisons);
      return comparisons;
    };

    // Get the comparison data
    const skillComparisons = getAllSkillsComparison();

    return (
      <div className={`${styles.flfo} ${!openSkill ? styles.opneskil : ""}`}>
        <div className={styles.ddepal} onClick={() => setOpenSkill(!openSkill)}>
          <div className={styles.skillComparison}>Skill Comparison</div>
          <div className={styles.ecahScoreMian}>
            <div>
              {openSkill ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
            </div>
          </div>
        </div>

        {openSkill && (
          <div className={styles.skillsList}>
            <div className={styles.horizontalline}></div>
            <div className={styles.skillRowTop}>
              <span className={styles.skillName}>JD Skills</span>
              <span>Resume Skills</span>
              <span>Status</span>
              <span>Action</span>
            </div>
            <div style={{ padding: "16px" }}>
              {skillComparisons.length === 0 ? (
                <div className={styles.skillRow}>
                  <span>No skills found</span>
                </div>
              ) : (
                skillComparisons.map((skillComparison, index) => (
                  <div key={index} className={styles.skillRow}>
                    <span
                      className={`${styles.skillName} ${
                        !skillComparison.jdSkill ? styles.emptySkill : ""
                      }`}
                    >
                      {skillComparison.jdSkill || "—"}
                    </span>
                    <span
                      className={`${styles.resumeSkill} ${
                        !skillComparison.resumeSkill ? styles.emptySkill : ""
                      }`}
                    >
                      {skillComparison.resumeSkill || "—"}
                    </span>
                    <span className={styles.skillStatus}>
                      {skillComparison.isMatch ? (
                        <CheckCircle className={styles.success} size={20} />
                      ) : skillComparison.jdSkill &&
                        !skillComparison.resumeSkill ? (
                        <AlertCircle className={styles.error} size={20} />
                      ) : (
                        <AlertTriangle className={styles.warning} size={20} />
                      )}
                    </span>
                    <div className={styles.toggleSwitch}>
                      {(skillComparison.jdSkill ||
                        skillComparison.resumeSkill) && (
                        <>
                          <input
                            type="checkbox"
                            id={`skill-${index}`}
                            checked={
                              skillComparison.isMatch ||
                              isSkillAdded(
                                skillComparison.jdSkill ||
                                  skillComparison.resumeSkill
                              )
                            }
                            onChange={() => {
                              const skill =
                                skillComparison.jdSkill ||
                                skillComparison.resumeSkill;
                              if (isSkillAdded(skill)) {
                                // Remove skill from resume
                                const updatedSkills =
                                  values.sections.technicalSkills.simpleList.filter(
                                    (s) => s !== skill
                                  );
                                handleResumeChange(
                                  "sections.technicalSkills.simpleList",
                                  updatedSkills
                                );
                              } else {
                                // Add skill to resume
                                addSkills(skill);
                              }
                            }}
                          />
                          <label htmlFor={`skill-${index}`}></label>
                        </>
                      )}
                    </div>
                  </div>
                ))
              )}
            </div>
            <div className={styles.addedSkills}>
              <div className={styles.addedSkillsList}>
                {values.sections?.technicalSkills?.simpleList?.map(
                  (skill, index) => (
                    <span className={styles.addedSkillsItem} key={index}>
                      {skill}
                    </span>
                  )
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const MissingKeywordsContainer = () => {
    // Helper function to check if a keyword is selected
    const isKeywordSelected = (keyword) => {
      return selectedKeywords.includes(keyword);
    };

    // Helper function to get keyword priority level (returns a number for sorting)
    const getKeywordPriorityLevel = (keyword) => {
      // Count occurrences of the keyword in the job description
      const keywordRegex = new RegExp(`\\b${escapeRegExp(keyword)}\\b`, "gi");
      const occurrences = (value?.match(keywordRegex) || []).length;

      // Return priority level as number (3 = high, 2 = medium, 1 = low)
      if (occurrences > 2) return 3; // High priority
      if (occurrences === 2) return 2; // Medium priority
      return 1; // Low priority
    };

    // Sort keywords by priority
    const sortedKeywords = [
      ...(response?.suggestions?.missing_words || []),
    ].sort((a, b) => {
      return getKeywordPriorityLevel(b) - getKeywordPriorityLevel(a);
    });

    // Handle keyword selection and auto-generate content
    const handleKeywordToggle = async (keyword) => {
      let newSelectedKeywords;

      if (selectedKeywords.includes(keyword)) {
        // Remove keyword if already selected
        newSelectedKeywords = selectedKeywords.filter((k) => k !== keyword);
      } else {
        // Add keyword if not selected
        newSelectedKeywords = [...selectedKeywords, keyword];
      }

      setSelectedKeywords(newSelectedKeywords);

      // Only generate content if we have keywords selected
      if (newSelectedKeywords.length > 0) {
        try {
          setLoading(true);

          // Generate professional summary
          const summaryCompletion = await openai.chat.completions.create({
            messages: [
              {
                role: "system",
                content: `You are a professional resume writer specializing in creating impactful professional summaries. 
                Focus on incorporating keywords naturally while maintaining a compelling narrative.`,
              },
              {
                role: "user",
                content: `Create a professional summary incorporating these selected keywords: ${newSelectedKeywords.join(
                  ", "
                )}.
                
                Guidelines:
                1. Keep it between 50 to 60 words
                2. Highlight technical skills and domain knowledge
                3. Focus on quantifiable achievements
                4. Naturally weave in the selected keywords
                5. Use active voice and professional tone
                6. Avoid generic phrases and clichés
                
                Current Job Role: ${
                  values.sections.experience?.data[0]?.role || ""
                }
                Industry: ${values.sections.experience?.data[0]?.company || ""}
                `,
              },
            ],
            model: "gpt-3.5-turbo",
            temperature: 0.7,
            max_tokens: 250,
          });

          const summary = summaryCompletion.choices[0].message.content;
          setProffestionalSummary(summary);
          setOpenSummary(true);

          // Generate experience points for current role
          const experienceCompletion = await openai.chat.completions.create({
            messages: [
              {
                role: "system",
                content: `You are a professional resume writer. Generate only bullet points for work experience, without any headers or introductions. Each point should start directly with an action verb.`,
              },
              {
                role: "user",
                content: `Create 4-5 impactful bullet points incorporating these keywords: ${newSelectedKeywords.join(
                  ", "
                )}.
                
                Context (for reference only, don't include in output):
                Role: ${
                  values.sections.experience?.data[activeCompanyIndex]?.role ||
                  ""
                }
                Company: ${
                  values.sections.experience?.data[activeCompanyIndex]
                    ?.company || ""
                }
                
                Guidelines:
                - Start each point with a strong action verb
                - Include quantifiable achievements
                - Show impact and results
                - Naturally incorporate the keywords
                - Use specific technical details
                - Focus on contributions and innovations
                
                Return only the bullet points, without any headers or role information.`,
              },
            ],
            model: "gpt-3.5-turbo",
            temperature: 0.7,
            max_tokens: 350,
          });

          const points = experienceCompletion.choices[0].message.content
            .split("\n")
            .filter((point) => point.trim())
            .map((point) => point.replace(/^[•\-]\s*/, "")); // Remove bullet points or dashes

          setPoint(points);
          setOpenPoints(true);

          // Generate project points if available
          if (values.sections?.projects?.data[0]?.title) {
            const projectCompletion = await openai.chat.completions.create({
              messages: [
                {
                  role: "system",
                  content: `You are a professional resume writer. Generate only bullet points for project descriptions, without any headers or introductions. Each point should start directly with an action verb.`,
                },
                {
                  role: "user",
                  content: `Create 3-4 impactful bullet points incorporating these keywords: ${newSelectedKeywords.join(
                    ", "
                  )}.
                  
                  Context (for reference only, don't include in output):
                  Project: ${values.sections.projects.data[0].title}
                  Organization: ${
                    values.sections.projects.data[0].organisation || ""
                  }
                  
                  Guidelines:
                  - Start each point with a strong technical action verb
                  - Include specific technologies used
                  - Show impact and results
                  - Naturally incorporate the keywords
                  - Highlight technical challenges overcome
                  - Focus on specific contributions
                  
                  Return only the bullet points, without any headers or project information.`,
                },
              ],
              model: "gpt-3.5-turbo",
              temperature: 0.7,
              max_tokens: 300,
            });

            const projectPoints = projectCompletion.choices[0].message.content
              .split("\n")
              .filter((point) => point.trim())
              .map((point) => point.replace(/^[•\-]\s*/, "")); // Remove bullet points or dashes

            setPointProject(projectPoints);
          }

          setLoading(false);
        } catch (error) {
          console.error("Error generating content:", error);
          setLoading(false);
          toast.error("Failed to generate content. Please try again.");
        }
      }
    };

    return (
      <div
        className={`${styles.flfo}  ${!openKeywords ? styles.opneskil : ""}`}
      >
        <div
          className={styles.ddepal}
          onClick={() => setOpenKeywords(!openKeywords)}
        >
          <div className={styles.skillComparison}>Keywords Comparison</div>
          <div className={styles.ecahScoreMian}>
            <div>
              {openKeywords ? (
                <ChevronUp size={20} />
              ) : (
                <ChevronDown size={20} />
              )}
            </div>
          </div>
        </div>

        <div>
          {openKeywords && (
            <div className={styles.skillsList}>
              <div className={styles.horizontalline}></div>
              <div className={styles.skillRowTop}>
                <span className={styles.skillName}>Keyword</span>
                <span>Status</span>
                <span>Select</span>
              </div>
              <div style={{ padding: "16px" }}>
                {sortedKeywords.length === 0 ? (
                  <div className={styles.skillRow}>
                    <span>No missing keywords found</span>
                  </div>
                ) : (
                  sortedKeywords
                    .slice(0, visibleCountKey)
                    .map((item, index) => (
                      <div key={index} className={styles.skillRow}>
                        <span className={styles.skillName}>{item}</span>
                        <span className={styles.skillStatus}>
                          {getSkillStatus(item)}
                        </span>
                        <div className={styles.toggleSwitch}>
                          <input
                            type="checkbox"
                            id={`keyword-${index}`}
                            checked={isKeywordSelected(item)}
                            onChange={() => handleKeywordToggle(item)}
                            disabled={loading}
                          />
                          <label htmlFor={`keyword-${index}`}></label>
                        </div>
                      </div>
                    ))
                )}
              </div>

              {/* Display selected keywords */}
              {selectedKeywords.length > 0 && (
                <div className={styles.selectedKeywords}>
                  {selectedKeywords
                    .slice(0, visibleCountKey)
                    .map((keyword, index) => (
                      <span key={index} className={styles.keywordTag}>
                        {keyword}
                        <button
                          onClick={() => handleKeywordToggle(keyword)}
                          className={styles.removeKeyword}
                          disabled={loading}
                        >
                          ×
                        </button>
                      </span>
                    ))}
                </div>
              )}

              {/* Loading indicator */}
              {loading && (
                <div className={styles.loadingIndicator}>
                  <RotateCw className={styles.loadingIcon} size={16} />
                  <span>Generating content...</span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  const proffestionalSummaryContainer = () => {
    return (
      <div
        className={`${styles.flfo}   ${!openSummary ? styles.opneskil : ""}`}
      >
        <div className={styles.ddepal}>
          <div
            className={styles.skillComparison}
            onClick={() => setOpenSummary(!openSummary)}
          >
            Suggested Professional Summary
          </div>
          <div className={styles.ecahScoreMian}>
            {" "}
            <div>
              <div className={styles.sfs}>
                <div className={styles.containerButton}>
                  <div
                    className={`${styles.innerbutton} ${
                      !isAfterSummary ? styles.buttonActive : ""
                    }`}
                    onClick={() => setIsAfterSummary(false)}
                  >
                    After
                  </div>
                  <div
                    className={`${styles.innerbutton} ${
                      isAfterSummary ? styles.buttonActive : ""
                    }`}
                    onClick={() => setIsAfterSummary(true)}
                  >
                    Before
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          {openSummary && (
            <div className={styles.dd}>
              <div className={styles.horizontalline}></div>
              <div className={styles.proffestionald}>
                <div
                  className={styles.mainsfr}
                  dangerouslySetInnerHTML={{
                    __html: !isAfterSummary
                      ? ProffestionalSummary
                      : values.sections.Professionalsummary.data.summary,
                  }}
                />
              </div>
              <div className={styles.dfroore}>
                <span
                  className={styles.aibuttonf}
                  onClick={() => getResponse()}
                >
                  <RotateCw size={14} />
                  IMPROVISE
                </span>
                <span
                  className={styles.boroerr}
                  onClick={() => {
                    addSummary();
                  }}
                >
                  {" "}
                  <PlusIcon size={14} /> ADD TO RESUME
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const AIgeneratedPointsContainer = () => {
    return (
      <div className={`${styles.flfo} ${!openPoints ? styles.opneskil : ""}`}>
        <div className={styles.ddepal}>
          <div
            className={styles.skillComparison}
            onClick={() => setOpenPoints(!openPoints)}
          >
            Suggested Work Experience Points
          </div>
          <div className={styles.ecahScoreMian}>
            <div>
              <div className={styles.sfs}>
                <div className={styles.containerButton}>
                  <div
                    className={`${styles.innerbutton} ${
                      !isAfterExperience ? styles.buttonActive : ""
                    }`}
                    onClick={() => setIsAfterExperience(false)}
                  >
                    Before
                  </div>
                  <div
                    className={`${styles.innerbutton} ${
                      isAfterExperience ? styles.buttonActive : ""
                    }`}
                    onClick={() => setIsAfterExperience(true)}
                  >
                    After
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {openPoints && (
          <div>
            <div className={styles.horizontalline}></div>

            {/* Company Tabs */}
            <div className={styles.companyTabs}>
              {values?.sections?.experience?.simpleList?.map(
                (experience, index) => (
                  <div
                    key={index}
                    className={`${styles.companyTab} ${
                      activeCompanyIndex === index ? styles.activeTab : ""
                    }`}
                    onClick={() => setActiveCompanyIndex(index)}
                  >
                    <span className={styles.companyName}>
                      {experience?.company}
                    </span>
                  </div>
                )
              )}
            </div>

            {/* Display points for active company */}
            {values?.sections?.experience?.simpleList?.[activeCompanyIndex] && (
              <div className={styles.experienceList}>
                {/* <div className={styles.headingiro}>
                  {values.sections.experience.simpleList[activeCompanyIndex]?.role} at{" "}
                  {values.sections.experience.simpleList[activeCompanyIndex]?.company}
                </div> */}

                {isAfterExperience ? (
                  <div>
                    {point.map((data, index) => (
                      <div key={index}>
                        <div className={styles.editableListItem}>
                          {editingPoints[index] ? (
                            <div className={styles.textareaWrapper}>
                              <textarea
                                value={data}
                                onChange={(e) =>
                                  handlePointEdit(index, e.target.value)
                                }
                                className={styles.editableTextarea}
                                rows={3}
                                autoFocus
                              />
                              <div className={styles.editActions}>
                                <button
                                  onClick={() => togglePointEdit(index)}
                                  className={styles.saveButton}
                                >
                                  Save
                                </button>
                              </div>
                            </div>
                          ) : (
                            <div className={styles.pointDisplay}>
                              <span className={styles.pointText}>{data}</span>
                              <div className={styles.itemActions}>
                                <span onClick={() => togglePointEdit(index)}>
                                  <Edit2 color="#4B5563" size={14} />
                                </span>
                                <span
                                  onClick={() =>
                                    addExper(data, activeCompanyIndex)
                                  }
                                >
                                  {addedPoints[
                                    `${activeCompanyIndex}-${data}`
                                  ] ? (
                                    <CheckCircle color="green" size={14} />
                                  ) : (
                                    <PlusCircle color="blue" size={14} />
                                  )}
                                </span>
                                <span
                                  onClick={() => {
                                    const newPoints = point.filter(
                                      (_, i) => i !== index
                                    );
                                    setPoint(newPoints);
                                  }}
                                >
                                  <Trash2 color="red" size={14} />
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div>
                    {values.sections.experience.simpleList[
                      activeCompanyIndex
                    ]?.points?.map((data, index) => (
                      <div key={index}>
                        <div className={styles.editableListItem}>
                          {editingExistingPoints[index] ? (
                            <div className={styles.textareaWrapper}>
                              <textarea
                                value={data}
                                onChange={(e) =>
                                  handleExistingPointEdit(index, e.target.value)
                                }
                                className={styles.editableTextarea}
                                rows={3}
                                autoFocus
                              />
                              <div className={styles.editActions}>
                                <button
                                  onClick={() => togglePointEdit(index, true)}
                                  className={styles.saveButton}
                                >
                                  Save
                                </button>
                              </div>
                            </div>
                          ) : (
                            <div className={styles.pointDisplay}>
                              <span className={styles.pointText}>{data}</span>
                              <div className={styles.itemActions}>
                                <span
                                  onClick={() => togglePointEdit(index, true)}
                                >
                                  <Edit2 color="#4B5563" size={14} />
                                </span>
                                <span
                                  onClick={() =>
                                    addExper(data, activeCompanyIndex)
                                  }
                                >
                                  {addedPoints[
                                    `${activeCompanyIndex}-${data}`
                                  ] ? (
                                    <CheckCircle color="green" size={14} />
                                  ) : (
                                    <PlusCircle color="blue" size={14} />
                                  )}
                                </span>
                                <span
                                  onClick={() => {
                                    const newPoints =
                                      values.sections.experience.simpleList[
                                        activeCompanyIndex
                                      ].points.filter((_, i) => i !== index);
                                    handleResumeChange(
                                      `sections.experience.simpleList.${activeCompanyIndex}.points`,
                                      newPoints
                                    );
                                  }}
                                >
                                  <Trash2 color="red" size={14} />
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                <div className={styles.dfroore}>
                  <span
                    className={styles.aibuttonf}
                    onClick={() => generatePoint(activeCompanyIndex)}
                  >
                    <img
                      src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1731091848/all/sm89cm7yyer7emxfaoje.svg"
                      alt="re-genererate"
                    />
                    Re-Generate Points
                  </span>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  const ProjectPointsContainer = () => {
    if (
      !values?.sections?.projects?.data ||
      values.sections.projects.data.length === 0
    ) {
      return null;
    }

    return (
      <div
        className={`${styles.flfo} ${
          !openProjectPoints ? styles.opneskil : ""
        }`}
      >
        <div className={styles.ddepal}>
          <div
            className={styles.skillComparison}
            onClick={() => setOpenProjectPoints(!openProjectPoints)}
          >
            Suggested Project Points
          </div>
          <div className={styles.ecahScoreMian}>
            <div>
              <div className={styles.sfs}>
                <div className={styles.containerButton}>
                  <div
                    className={`${styles.innerbutton} ${
                      !isAfterProject ? styles.buttonActive : ""
                    }`}
                    onClick={() => setIsAfterProject(false)}
                  >
                    Before
                  </div>
                  <div
                    className={`${styles.innerbutton} ${
                      isAfterProject ? styles.buttonActive : ""
                    }`}
                    onClick={() => setIsAfterProject(true)}
                  >
                    After
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {openProjectPoints && (
          <div>
            <div className={styles.horizontalline}></div>

            {/* Project Tabs */}
            <div className={styles.companyTabs}>
              {values?.sections?.projects?.data?.map((project, index) => (
                <div
                  key={index}
                  className={`${styles.companyTab} ${
                    activeProjectIndex === index ? styles.activeTab : ""
                  }`}
                  onClick={() => setActiveProjectIndex(index)}
                >
                  <span className={styles.companyName}>
                    {project?.title || `Project ${index + 1}`}
                  </span>
                </div>
              ))}
            </div>

            {/* Display points for active project */}
            {values?.sections?.projects?.data?.[activeProjectIndex] && (
              <div className={styles.experienceList}>
                <div className={styles.headingiro}>
                  {values?.sections?.projects?.data[activeProjectIndex]?.title}
                  {values.sections.projects?.data[activeProjectIndex]
                    ?.organisation && (
                    <>
                      {" "}
                      at{" "}
                      {
                        values.sections.projects?.data[activeProjectIndex]
                          ?.organisation
                      }
                    </>
                  )}
                </div>

                {isAfterProject ? (
                  <div>
                    {pointProject.map((data, index) => (
                      <div key={index}>
                        <div className={styles.editableListItem}>
                          {editingPoints[
                            `project-${activeProjectIndex}-${index}`
                          ] ? (
                            <div className={styles.textareaWrapper}>
                              <textarea
                                value={data}
                                onChange={(e) => {
                                  const newPoints = [...pointProject];
                                  newPoints[index] = e.target.value;
                                  setPointProject(newPoints);
                                }}
                                className={styles.editableTextarea}
                                rows={3}
                                autoFocus
                              />
                              <div className={styles.editActions}>
                                <button
                                  onClick={() =>
                                    toggleProjectPointEdit(
                                      activeProjectIndex,
                                      index
                                    )
                                  }
                                  className={styles.saveButton}
                                >
                                  Save
                                </button>
                              </div>
                            </div>
                          ) : (
                            <div className={styles.pointDisplay}>
                              <span className={styles.pointText}>{data}</span>
                              <div className={styles.itemActions}>
                                <span
                                  onClick={() =>
                                    toggleProjectPointEdit(
                                      activeProjectIndex,
                                      index
                                    )
                                  }
                                >
                                  <Edit2 color="#4B5563" size={14} />
                                </span>
                                <span
                                  onClick={() =>
                                    addProject(data, activeProjectIndex)
                                  }
                                >
                                  {addedPoints[
                                    `project-${activeProjectIndex}-${data}`
                                  ] ? (
                                    <CheckCircle color="green" size={14} />
                                  ) : (
                                    <PlusCircle color="blue" size={14} />
                                  )}
                                </span>
                                <span
                                  onClick={() => {
                                    const newPoints = pointProject.filter(
                                      (_, i) => i !== index
                                    );
                                    setPointProject(newPoints);
                                  }}
                                >
                                  <Trash2 color="red" size={14} />
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div>
                    {values.sections.projects.data[
                      activeProjectIndex
                    ]?.points?.map((data, index) => (
                      <ul key={index}>
                        <li className={styles.editableListItem}>
                          {editingPoints[
                            `existing-project-${activeProjectIndex}-${index}`
                          ] ? (
                            <div className={styles.textareaWrapper}>
                              <textarea
                                value={data}
                                onChange={(e) =>
                                  handleExistingProjectPointEdit(
                                    activeProjectIndex,
                                    index,
                                    e.target.value
                                  )
                                }
                                className={styles.editableTextarea}
                                rows={3}
                                autoFocus
                              />
                              <div className={styles.editActions}>
                                <button
                                  onClick={() =>
                                    toggleExistingProjectPointEdit(
                                      activeProjectIndex,
                                      index
                                    )
                                  }
                                  className={styles.saveButton}
                                >
                                  Save
                                </button>
                              </div>
                            </div>
                          ) : (
                            <div className={styles.pointDisplay}>
                              <span className={styles.pointText}>{data}</span>
                              <div className={styles.itemActions}>
                                <span
                                  onClick={() =>
                                    toggleExistingProjectPointEdit(
                                      activeProjectIndex,
                                      index
                                    )
                                  }
                                >
                                  <Edit2 color="#4B5563" size={14} />
                                </span>
                                <span
                                  onClick={() => {
                                    const newPoints =
                                      values.sections.projects.data[
                                        activeProjectIndex
                                      ].points.filter((_, i) => i !== index);
                                    handleResumeChange(
                                      `sections.projects.data.${activeProjectIndex}.points`,
                                      newPoints
                                    );
                                  }}
                                >
                                  <Trash2 color="red" size={14} />
                                </span>
                              </div>
                            </div>
                          )}
                        </li>
                      </ul>
                    ))}
                  </div>
                )}

                <div className={styles.dfroore}>
                  <span
                    className={styles.aibuttonf}
                    onClick={() => generatePointProject(activeProjectIndex)}
                  >
                    <img
                      src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1731091848/all/sm89cm7yyer7emxfaoje.svg"
                      alt="re-generate"
                    />
                    Re-Generate Points
                  </span>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  const handleExistingProjectPointEdit = (projectIndex, pointIndex, value) => {
    const newPoints = [...values.sections.projects.data[projectIndex].points];
    newPoints[pointIndex] = value;
    handleResumeChange(
      `sections.projects.data.${projectIndex}.points`,
      newPoints
    );
  };

  const toggleExistingProjectPointEdit = (projectIndex, pointIndex) => {
    setEditingPoints((prev) => ({
      ...prev,
      [`existing-project-${projectIndex}-${pointIndex}`]:
        !prev[`existing-project-${projectIndex}-${pointIndex}`],
    }));
  };

  const handlePointEdit = (index, value) => {
    const newPoints = [...point];
    newPoints[index] = value;
    setPoint(newPoints);
  };

  const handleExistingPointEdit = (index, value) => {
    const newPoints = [
      ...values.sections.experience.simpleList[activeCompanyIndex].points,
    ];
    newPoints[index] = value;
    handleResumeChange(
      `sections.experience.simpleList.${activeCompanyIndex}.points`,
      newPoints
    );
  };

  const togglePointEdit = (index, isExisting = false) => {
    if (isExisting) {
      setEditingExistingPoints((prev) => ({
        ...prev,
        [index]: !prev[index],
      }));
    } else {
      setEditingPoints((prev) => ({
        ...prev,
        [index]: !prev[index],
      }));
    }
  };

  const toggleProjectPointEdit = (projectIndex, pointIndex) => {
    setEditingPoints((prev) => ({
      ...prev,
      [`project-${projectIndex}-${pointIndex}`]:
        !prev[`project-${projectIndex}-${pointIndex}`],
    }));
  };

  useEffect(() => {
    if (response) {
      generatePointProject();
      generatePoint();
      getResponse();
    }
  }, [response]);

  useEffect(() => {
    let progressInterval;
    if (loading) {
      progressInterval = setInterval(() => {
        setLoadingProgress((prev) => {
          if (prev >= 100) {
            clearInterval(progressInterval);
            return 100;
          }
          return prev + 1;
        });

        setLoadingStep((prev) => {
          if (loadingProgress < 25) return 0;
          if (loadingProgress < 50) return 1;
          if (loadingProgress < 75) return 2;
          return 3;
        });
      }, 30); // Smoother animation
    } else {
      setLoadingProgress(0);
      setLoadingStep(0);
    }
    return () => clearInterval(progressInterval);
  }, [loading, loadingProgress]);

  useEffect(() => {
    const fetchSavedJobs = async () => {
      try {
        if (savedJobs) {
          // Only fetch when viewing saved jobs tab
          const { data } = await privateApi.get("/pdf/ats-saved-jobs");
          setSavedJobsData(data.jobs);
        }
      } catch (error) {
        console.error("Error fetching saved jobs:", error);
        toast.error("Failed to load saved jobs");
      }
    };

    fetchSavedJobs();
  }, [savedJobs]); // Depend on savedJobs state

  return (
    <div className={styles.main}>
      {response && ScoreContainer()}
      {!response && TabContainer()}
      {!response &&
        (savedJobs ? SavedJobsContainer() : JobDescriptionContainer())}
      {response && (
        <div className={styles.alllor}>
          {MissingSkillsContainer()}
          {MissingKeywordsContainer()}
          {proffestionalSummaryContainer()}
          {AIgeneratedPointsContainer()}
          <ProjectPointsContainer />
        </div>
      )}
      {/* <BottomBar/> */}
      <Model modalShow={modalShow} setModalShow={setModalShow} />
    </div>
  );
};

export default Matching;
