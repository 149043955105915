"use client";

import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { imageLinks } from "../../../constants/featureImages";
import styles from "./StorySection.module.css";
import MyFuseButton from "../MyFuseButton/MyFuseButton";
function StorySection() {
  // Add auto-scroll interval (in milliseconds)
  const AUTO_SCROLL_INTERVAL = 6000; // 3 seconds

  // Initialize with window check for React
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [activeImages, setActiveImages] = useState(
    window.innerWidth < 768 ? imageLinks.small : imageLinks.big
  );

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsMobile(true);
        setActiveImages(imageLinks.small);
      } else {
        setIsMobile(false);
        setActiveImages(imageLinks.big);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Carousel images array
  const images = activeImages;

  // Current slide index
  const [currentIndex, setCurrentIndex] = useState(0);

  // Define separate slide widths and gaps for mobile and desktop
  const mobileSlidePx = 260;
  const desktopSlidePx = 800;
  const mobileGap = 16;
  const desktopGap = 24;

  // Choose slidePx and gap based on isMobile flag
  const slidePx = isMobile ? mobileSlidePx : desktopSlidePx;
  const gap = isMobile ? mobileGap : desktopGap;

  // Reference to carousel container and measure its width
  const carouselRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);

  useEffect(() => {
    function updateContainerWidth() {
      if (carouselRef.current) {
        setContainerWidth(carouselRef.current.offsetWidth);
      }
    }
    updateContainerWidth();
    window.addEventListener("resize", updateContainerWidth);
    return () => window.removeEventListener("resize", updateContainerWidth);
  }, []);

  // Effective width accounts for the horizontal padding (16px on each side => 32px total)
  const effectiveWidth = containerWidth - 32;

  // Calculate offset to center the carousel
  const mobileOffset =
    effectiveWidth > 0
      ? (effectiveWidth - mobileSlidePx) / 2 -
        currentIndex * (mobileSlidePx + mobileGap)
      : 0;
  const desktopOffset =
    effectiveWidth > 0
      ? (effectiveWidth - desktopSlidePx) / 2 -
        currentIndex * (desktopSlidePx + desktopGap)
      : 0;

  // Use the appropriate offset based on screen size
  const computedOffset = isMobile ? mobileOffset : desktopOffset;

  // Add state to track if auto-scroll should be active
  const [isAutoScrolling, setIsAutoScrolling] = useState(true);

  // Modified auto-scroll effect
  useEffect(() => {
    let autoScroll;
    if (isAutoScrolling) {
      autoScroll = setInterval(() => {
        setCurrentIndex((prev) => 
          prev === images.length - 1 ? 0 : prev + 1
        );
      }, AUTO_SCROLL_INTERVAL);
    }
    return () => clearInterval(autoScroll);
  }, [images.length, isAutoScrolling]);

  // Add drag start handler to stop auto-scroll
  const handleDragStart = () => {
    setIsAutoScrolling(false);
  };

  const handleDragEnd = (event, info) => {
    if (!carouselRef.current) return;

    if (info.offset.x < -mobileSlidePx / 6) {
      if (currentIndex === images.length - 1) {
        setCurrentIndex(0);
      } else {
        setCurrentIndex((prev) => prev + 1);
      }
    } else if (info.offset.x > mobileSlidePx / 6) {
      if (currentIndex === 0) {
        setCurrentIndex(images.length - 1);
      } else {
        setCurrentIndex((prev) => prev - 1);
      }
    }
  };

  const handleNext = () => {
    setIsAutoScrolling(false);
    setCurrentIndex((prev) => 
      prev === images.length - 1 ? 0 : prev + 1
    );
  };

  const handlePrev = () => {
    setIsAutoScrolling(false);
    setCurrentIndex((prev) => 
      prev === 0 ? images.length - 1 : prev - 1
    );
  };

  // Simplified dot pagination logic (mobile only)
  const maxVisibleDots = 7;
  const totalDots = images.length;
  
  // Calculate the range of dots to show
  let startDot = 0;
  let endDot = totalDots;

  if (totalDots > maxVisibleDots) {
    const halfWindow = Math.floor(maxVisibleDots / 2);
    
    if (currentIndex <= halfWindow) {
      // At the start
      startDot = 0;
      endDot = maxVisibleDots;
    } else if (currentIndex >= totalDots - halfWindow) {
      // At the end
      startDot = totalDots - maxVisibleDots;
      endDot = totalDots;
    } else {
      // In the middle
      startDot = currentIndex - halfWindow;
      endDot = currentIndex + halfWindow + 1;
    }
  }

  return (
    <div
      className={styles.container}
      style={{
        backgroundImage: isMobile
          ? "url('/images/backgrounds/featureSectionPhone.svg')"
          : "url('/images/backgrounds/featureSection.svg')",
      }}
    >
      {/* Heading */}
      <div className={styles.headingWrapper}>
        <div className={styles.headingText}>
          Dear Job Seeker,
          <br />
          <span className={styles.myFuseBlue}></span>
        </div>

        {/* Carousel Container */}
        <div className={styles.carouselContainer} ref={carouselRef}>
          <motion.div
            className={styles.carouselInner}
            animate={{ x: computedOffset }}
            transition={{ duration: 0.6, ease: "easeInOut" }}
            drag={isMobile ? "x" : false}
            onDragStart={isMobile ? handleDragStart : undefined}
            onDragEnd={isMobile ? handleDragEnd : undefined}
            style={{ display: "flex", gap: `${gap}px` }}
          >
            {images.map((link, index) => (
              <div key={index} className={styles.slideWrapper}>
                {link ? (
                  <img
                    src={link}
                    alt={`Slide ${index}`}
                    width={isMobile ? 260 : 800}
                    height={400}
                    className={styles.slideImage}
                  />
                ) : (
                  <div className={styles.placeholder} /> 
                
                )}
              </div>
            ))}
          </motion.div>
        </div>
   
        {/* Arrow Buttons (desktop only) */}
        {!isMobile && (
          <div className={styles.arrowButtonsContainer}>
            <button
              onClick={handlePrev}
              className={styles.arrowButton}
              style={{ border: "none" }}
            >
              <img
                width={24}
                height={24}
                src="/images/icons/Arrow_right.svg"
                alt="Previous Slide"
              />
            </button>
            <button
              onClick={handleNext}
              className={styles.arrowButton}
              style={{ border: "none" }}
            >
              <img
                width={24}
                height={24}
                src="/images/icons/Arrow_right.svg"
                className={styles.rotate180}
                alt="Next Slide"
              />
            </button>
          </div>
        )}

        {/* Dot Pagination (mobile only) */}
        {isMobile && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className={styles.paginationDots}>
              {Array.from({ length: totalDots }).map((_, i) => (
                <div
                  key={i}
                  className={`${styles.dot} ${
                    i === currentIndex ? styles.activeDot : styles.inactiveDot
                  }`}
                  onClick={() => setCurrentIndex(i)}
                />
              ))}
            </div>
           
          </div>
        )}
         <div style={{ display: "flex", justifyContent: "center", alignItems: "center",  paddingRight:"16px" , paddingLeft:"16px" }}>
            <a href={"/welcome"} style={{ textDecoration: "none" }}>
              <MyFuseButton
                title={"CREATE YOUR JOB-WINNING RESUME NOW"}
                variant="filled"
              />
            </a>

            </div>
            <div
              style={
                 {
                      fontFamily: "Figtree",
                      fontSize: "24px",
                      color: "black",
                      textAlign: "center",
                    
                    }
              }
            >
              This time, let's make them notice
              <br />
              <span style={{ color: "var(--my-fuse-blue)" }}>
                This time, let's make it count.
              </span>
            </div>
      </div>
    </div>
  );
}

export default StorySection;
