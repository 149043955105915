import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import styles from "./assets/Signup.module.css";
import { jwtDecode } from "jwt-decode";
import { GoogleLogin } from "@react-oauth/google";
import { useGoogleOneTapLogin } from "@react-oauth/google";
import Form from "react-bootstrap/Form";
import HashLoader from "react-spinners/HashLoader";
import HelmetConfig from "./components/HelmetConfig";
import { motion } from "framer-motion";
import { useAuth } from "./ResumeBuilder/builder/components/context/auth";
import { publicApi } from './ResumeBuilder/utils/aixos';
const Signup = () => {
  const [email, setEmail] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setlastName] = useState();
  const [phone, setPhone] = useState("");
  const [password, setpassword] = useState();
  const [cpassword, setcpassword] = useState();
  let [loading, setLoading] = useState(false);
  const { auth, setAuth } = useAuth();
  const Navigate = useNavigate();
  const handleNavigate = () => {
    Navigate("/login");
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const res = await publicApi.post(`/api/auth/register`, { email, password, firstName, lastName, phone });
      setLoading(false);
      if (res.data.success) {
        toast.success(res.data.message);
        Navigate(`/verify/email=${email}`);
      } else {
        setLoading(false);
        toast.success(
          "🚀 Easy there, multitasker! You’re already logged in. No need to set a world record! 😄🔐"
        );
        Navigate(`/Login`);
      }
    } catch (error) {
      console.log(error);
      toast.error("Please Enter Correct User Id and password");
    }
  };

  const responseGoogleSuccess = async (credentialResponse) => {
    try {
      const decode = await jwtDecode(credentialResponse.credential);
      const response = await publicApi.post(`/api/auth/loginWithGoogle`, { decode });
      
      if (response.data.success) {
        const updatedAuth = {
          ...auth,
          user: response.data.user,
          token: response.data.token,
          pic: decode?.picture,
          message: response.data.message,
          success: response.data.success,
        };
        setAuth(updatedAuth);
        toast.success(response.data.message);
        localStorage.setItem("auth", JSON.stringify(updatedAuth));
        Navigate("/welcome");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const responseGoogleFailure = (error) => {
    console.error("Google Auth Error:", error);
  };

  useGoogleOneTapLogin({
    onSuccess: responseGoogleSuccess,
    onFailure: responseGoogleFailure,
    onError: () => {
      console.log("Login Failed");
    },
  });

  useGoogleOneTapLogin({
    onSuccess: (credentialResponse) => {
      console.log(credentialResponse);
      const decode = jwtDecode(credentialResponse.credential);
      console.log(decode);
    },
    onError: () => {
      console.log("Login Failed");
    },
  });

  const handleClick = async (value) => {
    Navigate(`/`);
  };

  // Add sector badges for visual appeal
  const sectorBadges = [
    { icon: '💼', sector: 'Business', desc: 'Management & Finance' },
    { icon: '🎨', sector: 'Creative', desc: 'Design & Arts' },
    { icon: '🔬', sector: 'Science', desc: 'Research & Lab' },
    { icon: '⚕️', sector: 'Healthcare', desc: 'Medical & Care' },
    { icon: '📚', sector: 'Education', desc: 'Teaching & Learning' },
    { icon: '🏗️', sector: 'Engineering', desc: 'Build & Create' }
  ];

 

  return (
    <div className={styles.main}>
      {/* Animated Background */}
      <div className={styles.gradientBg}>
        <div className={styles.gradientOverlay}></div>
      </div>

      <div className={styles.container}>
        {/* Left Section with Features */}
        <motion.div 
          className={styles.leftSection}
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6 }}
        >
          <h1 className={styles.mainTitle}>
            Create Your <span className={styles.highlight}>Professional</span> Profile
          </h1>
          <p className={styles.subtitle}>Join thousands of professionals building their careers</p>
          
          {/* Added trust indicators */}
          <div className={styles.trustIndicators}>
            <div className={styles.trustStat}>
              <span className={styles.statNumber}>50K+</span>
              <span className={styles.statLabel}>Active Users</span>
            </div>
            <div className={styles.trustStat}>
              <span className={styles.statNumber}>1000+</span>
              <span className={styles.statLabel}>Companies Hiring</span>
            </div>
            <div className={styles.trustStat}>
              <span className={styles.statNumber}>95%</span>
              <span className={styles.statLabel}>Success Rate</span>
            </div>
          </div>

         
          
          <div className={styles.featureGrid}>
            <motion.div 
              className={styles.featureCard}
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <span className={styles.featureIcon}>🎯</span>
              <h3>Smart Resume Builder</h3>
              <p>Create ATS-friendly resumes in minutes</p>
            </motion.div>

            <motion.div 
              className={styles.featureCard}
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <span className={styles.featureIcon}>⚡</span>
              <h3>Quick Apply</h3>
              <p>One-click job applications</p>
            </motion.div>

            <motion.div 
              className={styles.featureCard}
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <span className={styles.featureIcon}>🎨</span>
              <h3>Professional Templates</h3>
              <p>Stand out with modern designs</p>
            </motion.div>
          </div>
        </motion.div>

        {/* Right Section with Form */}
        <motion.div 
          className={styles.rightSection}
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6 }}
        >
          <div className={styles.formWrapper}>
            <div className={styles.formHeader}>
              <h1 className={styles.logoText} onClick={() => Navigate("/")}>MyFuse<span className={styles.logoTextSpan}>.</span>In</h1>
              {/* <h2>Create Account</h2> */}
              <p>Start your professional journey today</p>
            </div>

            <form onSubmit={handleSubmit} className={styles.signupForm}>
              <div className={styles.socialLogin}>
                <GoogleLogin
                  clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                  buttonText="Login with Google"
                  onSuccess={responseGoogleSuccess}
                  onFailure={responseGoogleFailure}
                  cookiePolicy={"single_host_origin"}
                />
              </div>

              <div className={styles.divider}>
                <span>or register with email</span>
              </div>

              <div className={styles.inputGroup}>
                <div className={styles.nameFields}>
                  <div className={styles.inputWrapper}>
                    <label>First Name</label>
                    <input
                      type="text"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                      placeholder="Ashutosh"
                    />
                  </div>
                  <div className={styles.inputWrapper}>
                    <label>Last Name</label>
                    <input
                      type="text"
                      value={lastName}
                      onChange={(e) => setlastName(e.target.value)}
                      required
                      placeholder="Kumar"
                    />
                  </div>
                </div>

                <div className={styles.inputWrapper}>
                  <label>Email</label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    placeholder="ashutosh@myfuse.in"
                  />
                </div>

                {/* <div className={styles.inputWrapper}>
                  <label>Phone Number</label>
                  <input
                    type="tel"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    required
                    placeholder="+91 98XXXXXX785"
                  />
                </div> */}

                <div className={styles.inputWrapper}>
                  <label>Password</label>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setpassword(e.target.value)}
                    required
                    placeholder="••••••••"
                  />
                </div>

                <div className={styles.inputWrapper}>
                  <label>Confirm Password</label>
                  <input
                    type="password"
                    value={cpassword}
                    onChange={(e) => setcpassword(e.target.value)}
                    required
                    placeholder="••••••••"
                  />
                </div>
              </div>

              <div className={styles.termsWrapper} style={{display: "flex",}}>
                <Form.Check required />
                <span>I accept the <a href="https://myfuse.in/term-and-conditions" target="_blank" rel="noopener noreferrer">terms and conditions</a></span>
              </div>

              <button type="submit" className={styles.submitBtn}>
                {loading ? <HashLoader color="#ffffff" size={25} /> : "Create Account"}
              </button>

              <p className={styles.loginLink}>
                Already have an account? 
                <span onClick={handleNavigate}>Sign In</span>
              </p>
            </form>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Signup;
