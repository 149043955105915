import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaLinkedin,
  FaYoutube,
} from "react-icons/fa";

export const footerLinks = {
  navLinks: [
    {id:8 , name: "Jobs", redirectURL: "/jobs-in-india"},
    {id:7 , name: "Blogs", redirectURL: "/ai/blogs"},
    {id:6 , name: "Pricing", redirectURL: "/pricing"},
    { id: 3, name: "Privacy Policy", redirectURL: "/privacy-policy" },
    { id: 2, name: "Terms & conditions", redirectURL: "/term-and-conditions" },
   

  ],
  legalLinks: [
    { id: 1, name: "All rights reserved © 2025", redirectURL: "#" },
    { id: 2, name: "Terms & conditions", redirectURL: "term-and-conditions" },
    { id: 4, name: "Refund and Return", redirectURL: "/cancellationandrefundpolicy" },
    { id: 3, name: "Privacy Policy", redirectURL: "/privacy-policy" },
    { id: 4, name: "Sitemap", redirectURL: "/sitemap.xml" },
  ],
  socialLinks: [
    { id: 1, name: "facebook", icon: <FaFacebook />, redirectURL: "https://www.facebook.com/myfuse.in/" },
    { id: 2, name: "twitter", icon: <FaTwitter />, redirectURL: "https://twitter.com/myfuse_in" },
    { id: 3, name: "instagram", icon: <FaInstagram />, redirectURL: "https://www.instagram.com/myfuse.in/" },
    { id: 4, name: "linkedin", icon: <FaLinkedin />, redirectURL: "https://www.linkedin.com/company/myfuse-india/posts/?feedView=all" },
    { id: 5, name: "youtube", icon: <FaYoutube />, redirectURL: "https://www.youtube.com/@myfuseindia" },
  ],
};
