import React, { useEffect, useState } from "react";
import styles from "./ChooseTemplate.module.css";
import { useResume } from "../../context/Resume";
import { useNavigate } from "react-router-dom";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { publicApi } from "../../../../utils/aixos";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Top from "../../../../../components/ResumeExamples/Top";
import { SingleFrame } from "../../../../../components/ResumeExamples/SingleFrame";

const ChooseTemplate = () => {
  const { templates, handleTemplateChange } = useResume();
  const [allresume, setallresume] = useState([]);
  const [allfiled, setField] = useState([]);

  const Navigate = useNavigate();
  const handleChange = (templateKey) => {
    handleTemplateChange(templateKey);
    Navigate("/welcome");
  };  

  const showresumefrombackend = async () => {
    try {
      const { data } = await publicApi.get(`/api/admin/getallresume`);
      if (data?.success) {
        await setallresume(data?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

   const showfieldfrombackend = async () => {
    try {
      const { data } = await publicApi.get(`/api/admin/getresumefieldAndsubField`);
      if (data?.success) {
        setField(data.data); 
       
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(()=>{
    showresumefrombackend(); 
    showfieldfrombackend (); 
   
  },[])

  let resumeLines = [
    "We know you’re great at creating impactful resumes. Use our samples to design one that tells your story.",
    "Your skills deserve to shine. Let our resume samples guide you in crafting a design that leaves a mark.",
    "Stand out with a resume that reflects your unique experience. Our design samples make it simple.",
    "Let your resume do the talking. Use our samples to create one that showcases your design expertise.",
    "Make an impression with a stunning resume. Our samples are here to help you highlight your skills.",
    "Design a resume that not only looks great but also communicates your value. Our samples will guide you.",
    "Showcase your expertise with a resume that’s as polished as your skills. Get inspired by our samples.",
    "Your experience is impressive—your resume should be too. Use our design samples to stand out.",
    "Focus on what you do best, and let us help you design a resume that captures your achievements."
  ];
 
  return (
    <div className={`${styles.container}`}>
      <Top />
      <div className={styles.templatesGrid}>
        <div className={styles.innercontainer}>
          <div className={styles.beedh}>
            <h2 className={styles.Job_sfas__0AKy9}> <img src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1725637293/all/c5g4jsurhdpdq986yy9z.png" /> Top Resumes </h2>
          </div>

          <Swiper
            spaceBetween={10}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
            slidesPerView={3.5}
            breakpoints={{
              640: {
                slidesPerView: 4,
              },

              0: {
                slidesPerView: 1,
              },
            }}
          >
            <>
              {Object.values(templates).map((template, index) => {
                return (
                  <SwiperSlide
                    key={index}
                    className={styles.showuseresume}
                    onClick={() => handleChange(template.key)}
                  >
                    <div className={styles.template}>
                      <div className={styles.preview}>
                        <img src={template.preview} alt={template.name} />
                        {/* <div
                          className={styles.show}
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <span className={styles.but}>Use Template</span>
                        </div> */}
                      </div>
                      <div className={styles.name}>{template.name}</div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </>
          </Swiper>
        </div>
      </div> 
      {allfiled?.map((value,index)=>{ 
        return(
      <SingleFrame allresume={allresume}   name={value.field} line={resumeLines[index]} isbackgroundColor={index % 2 === 0 ? true : false }/>
        )  
    })}
    </div>
  );
};

export default ChooseTemplate;
