import axios from 'axios';

// Instance for authenticated requests
export const privateApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_BACKEND_URL
});

// Instance for public requests
export const publicApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_BACKEND_URL
});

// Add token only to private requests
privateApi.interceptors.request.use(
  (config) => {
    const auth = JSON.parse(localStorage.getItem('auth'));
    if (auth?.token) {
      config.headers.Authorization = `Bearer ${auth.token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Optional: Add response interceptors for error handling
const handleError = (error) => {
  if (error.response?.status === 401) {
    // Handle unauthorized access (e.g., redirect to login)
    localStorage.removeItem('auth');
    window.location.href = '/login';
  }
  return Promise.reject(error);
};

privateApi.interceptors.response.use(
  response => response,
  handleError
);

publicApi.interceptors.response.use(
  response => response,
  handleError
);