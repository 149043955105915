import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import styles from "../assets/ModelChoseTemplate.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { ChevronDown} from "lucide-react";
import axios from "axios";
import { useResume } from "../ResumeBuilder/builder/components/context/Resume";
import { useNavigate } from "react-router-dom";
import { useJobs } from "../ResumeBuilder/builder/components/context/Jobs";
import { publicApi } from "../ResumeBuilder/utils/aixos";
const options = [
  "Choose your domain",
  "IT",
  "Finance",
  "Accounts",
  "Marketing",
  "Sales & Development",
  "Business Management ",
  "Data Science and Analytics",
  "Design And Ux",
  "Human Resource ",
];


function ModelChoseTemplate({ modalShow, setModalShow , selectedExperience , selectedSector , selectedDomain }) {
  const [isOpen, setIsOpen] = useState(false);
  const [allresume, setallresume] = useState([]);
  const [filterdResume, setFilterResume] = useState([]);
  const [selectedOption, setSelectedOption] = useState("Choose your domain");
  const { selectedTemplate, templates, handleTemplateChange ,getInitialConfigResume , resetResume } = useResume();
  const { setPreviewImage } = useJobs();
  
  const navigate = useNavigate();
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const showresumefrombackend = async () => {
    try {
      const { data } = await publicApi.get(`/api/admin/getallresume`);
      if (data?.success) {
        await setallresume(data?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const filterByField = (resumes, field) => { 
    console.log(resumes)
    return resumes.filter((resume) => resume.field === field);
  };

  const searchContainer = () => {
    return (
      <div className={styles.SearchContainer}>
        <div className={styles.dropdownSecarchMainConatiner}>
          <div className={styles.dropdownSecarchConatiner}>
            <div className={styles.dropdownToggle} onClick={toggleDropdown}>
              {selectedOption}
              <span className={styles.chevronDownIcon}>
                <ChevronDown size={14} />
              </span>
            </div>
            {isOpen && (
              <div className={styles.dropdownMenu}>
                {options.map((option, index) => (
                  <div
                    key={index}
                    className={styles.dropdownItem}
                    onClick={() => handleOptionClick(option)}
                  >
                    {option}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const handleOptionClick = async (option) => { 

    setSelectedOption(option);
    const serFilterResume = filterByField(allresume, option);
    setFilterResume(serFilterResume);
    setIsOpen(false);
  };

  const insertResumeToDatabase = async (image, name) => {
    try {
      const freshConfig = getInitialConfigResume(); // Get fresh copy
      const { data } = await axios.post(`${process.env.REACT_APP_BASE_BACKEND_URL}/api/resume/createresume`, {
        resume_values: freshConfig,
        template_key: selectedTemplate?.key,
        template_name: name,
        template_image: image,
      });
      const insertId = data?.resumeId;
      resetResume();
      return insertId;
    } catch (error) {
      console.error("Error inserting resume to database:", error);
      throw error;
    }
  };

  const navigateToEditor = async (templateKey, image, name) => {
    try {
      const insertId = await insertResumeToDatabase(image, name);
      handleTemplateChange(templateKey);
      setPreviewImage(image || "");
      navigate(`/editor/${name}-${insertId}`);
    } catch (error) {
      console.error("Error navigating to editor:", error);
    }
  };

  const handleNavigate = (templateKey, name) => {
    const image = templates[templateKey]?.preview;
    navigateToEditor(templateKey, image, name);
  };

  const handleChange = (templateKey, image, name) => {
    navigateToEditor(templateKey, image, name);
  };

  const truncateText = (text, wordLimit) => {
    if (text.length > wordLimit) {
      return text.slice(0, wordLimit) + "...";
    }
    return text;
  };

  useEffect(()=>{
      showresumefrombackend();
  },[])

  return (
    <Modal
      show={modalShow}
      onHide={() => setModalShow(false)}
      size="lg"
      centered
    >
      <Modal.Body className={styles.bodys}>
        <div className="container" style={{ position: "relative" }}>
          <div className={styles.close}>
            <span
              className={`${styles.closeButton} close`}
              onClick={() => setModalShow(false)}
            >
              <FontAwesomeIcon icon={faClose} className={styles.iconcut} />
            </span>
          </div>
          <div className="row">
            <div className={`col-12 col-md-12 ${styles.second}`}>
              <div className={styles.container}>
                <div className={styles.topHeadingContainer}>
                  <div className={styles.headtitle}>
                    Let's pick out a template for you!
                  </div>
                  <div className={styles.headtitlparagraph}>
                    Narrow down your domain for personalised template
                    suggestions
                  </div>
                </div>

                <div className={styles.ResumeContainer}>
                  {Object.values(templates)
                    .map((template) => {
                      // Convert experience string to numeric value
                      const getExperienceYears = (expString) => {
                        switch(expString) {
                          case "No Experience":
                            return 0;
                          case "Less Than 3 Years":
                            return 1;
                          case "3-5 Years":
                            return 3;
                          case "5-10 Years":
                            return 5;
                          case "10+ Years":
                            return 10;
                          default:
                            return 0;
                        }
                      };

                      const experienceYears = getExperienceYears(selectedExperience);
                      
                      const isRecommended = 
                        template.sector.includes(selectedSector) && 
                        experienceYears >= template.experience.min &&
                        (!template.experience.max || experienceYears <= template.experience.max);

                      // Add isRecommended property to template object
                      return { ...template, isRecommended };
                    })
                    // Sort templates - recommended ones first
                    .sort((a, b) => (b.isRecommended ? 1 : 0) - (a.isRecommended ? 1 : 0))
                    .map((template, index) => (
                      <div
                        key={index}
                        onClick={() => handleNavigate(template?.key, template?.name)}
                        className={`${styles.dooo} ${template.isRecommended ? styles.recommendedTemplate : ''}`}
                      >
                        {template.isRecommended && (
                          <div className={styles.recommendedBadge}>
                            <div className={styles.recommendedIcon}>★</div>
                            <span>Recommended</span>
                          </div>
                        )}
                        <div className={styles.resumeImageContainer}>
                          <img
                            className={styles.resumeImage}
                            src={template.preview}
                            alt={template.name}
                          />
                        </div>
                        <span className={styles.templateName}>
                          {template?.name}
                        </span>
                      </div>
                    ))}
                </div>

                {filterdResume.length != 0 && (
                  <div className={styles.ResumeContainer}>
                    {filterdResume.map((template, index) => (
                      <div
                        key={index}
                        onClick={() =>
                          handleChange(template.type, template.images , template.name)
                        }
                        className={`${styles.dooo}`}
                      >
                       
                        <div className={styles.resumeImageContainer}>
                          <img
                            className={styles.resumeImage}
                            src={template.images}
                            alt={template.name}
                          />
                        </div>
                        <span className={styles.templateName}>
                          {truncateText(template.subFiledName, 14)}
                        </span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </Modal.Body>
    </Modal>
  );
}

export default ModelChoseTemplate;
