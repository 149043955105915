import React, { useEffect, useState } from "react";
import styles from "./IITEResume.module.css";
import { getSafeMarkdownString } from "../../../../utils/markdown";
import { EXPERIENCE_LEVELS } from "../config/ConfigSection";
import { Check } from "lucide-react";

const IITEResume = ({ sections }) => {
  const [experienceLevel, setExperienceLevel] = useState(
    EXPERIENCE_LEVELS.NO_EXPERIENCE
  );

  useEffect(() => {
    // Get experience level from URL
    const getExperienceLevelFromURL = () => {
      const YOE = new URLSearchParams(window.location.search).get("YOE");
      switch (YOE) {
        case "No Experience":
          return EXPERIENCE_LEVELS.NO_EXPERIENCE;
        case "Less Than 3 Years":
          return EXPERIENCE_LEVELS.ONE_TO_TWO_YEARS;
        case "3-5 Years":
          return EXPERIENCE_LEVELS.THREE_TO_FIVE_YEARS;
        case "5-10 Years":
          return EXPERIENCE_LEVELS.FIVE_TO_TEN_YEARS;
        case "10  Years":
          return EXPERIENCE_LEVELS.TEN_PLUS_YEARS;
        default:
          return EXPERIENCE_LEVELS.NO_EXPERIENCE;
      }
    };

    setExperienceLevel(getExperienceLevelFromURL());
  }, [window.location.search]);

  const getSectionOrder = () => {
    switch (experienceLevel) {
      case EXPERIENCE_LEVELS.NO_EXPERIENCE:
        return (
          <>
            <About Professionalsummary={sections.Professionalsummary?.data} />
            <TechnicalSkills
              technicalSkills={sections.technicalSkills}
              name="Skills"
            />
            <Education education={sections.education?.data} />
            <Experience
              experience={sections.experience}
              name="Internship Experience"
            />
            <Projects projects={sections.projects?.data} />
            <Awards awards={sections.awards?.data} />
            
            <Optional optional={sections.optional?.data} />
          </>
        );

      case EXPERIENCE_LEVELS.ONE_TO_TWO_YEARS:
        return (
          <>
            <About Professionalsummary={sections.Professionalsummary?.data} />
            <TechnicalSkills
              technicalSkills={sections.technicalSkills}
              name="Core Skills"
            />
            <Experience experience={sections.experience} />
            <Projects projects={sections.projects?.data} />
            <Achivements achivements={sections.achivements?.data} />
            <Education education={sections.education?.data} />
            <Awards awards={sections.awards?.data} />
            <Optional optional={sections.optional?.data} />
          </>
        );

      case EXPERIENCE_LEVELS.THREE_TO_FIVE_YEARS:
        return (
          <>
            <About Professionalsummary={sections.Professionalsummary?.data} />
            <Achivements achivements={sections.achivements?.data} />
            <Experience experience={sections.experience} />
            <Projects projects={sections.projects?.data} />
            <CareerHighlight careerHighlight={sections.careerHighlight?.data} name="Career Highlights" />
            <TechnicalSkills
              technicalSkills={sections.technicalSkills}
              name="Core Skills & Tools"
            />
            <Achivements achivements={sections.achivements?.data} />
            <Education education={sections.education?.data} />
            <Awards awards={sections.awards?.data} />
            <Optional optional={sections.optional?.data} />
          </>
        );
      case EXPERIENCE_LEVELS.FIVE_TO_TEN_YEARS:
        return (
          <>
            <About Professionalsummary={sections.Professionalsummary?.data} />
           
            <Experience
              experience={sections.experience}
              name="Work Experience"
            />
            <Projects projects={sections.projects?.data} name="Key Projects" />
            <TechnicalSkills
              technicalSkills={sections.technicalSkills}
              name="Core Competencies"
            />
            <CareerHighlight careerHighlight={sections.careerHighlight?.data} name="Career Highlights" />
            <Education
              education={sections.education?.data}
              name="Education & Executive Training"
            />
            <Awards awards={sections.awards?.data} name="Certifications" />
             <Achivements
              achivements={sections.achivements?.data}
              name="Key Achievements"
            />
            <Optional optional={sections.optional?.data} />
          </>
        );

      case EXPERIENCE_LEVELS.TEN_PLUS_YEARS:
        return (
          <>
            <About
              Professionalsummary={sections.Professionalsummary?.data}
              name="Executive Summary"
            />
            <Experience
              experience={sections.experience}
              name="Leadership Experience"
            />
            <TechnicalSkills
              technicalSkills={sections.technicalSkills}
              name="Core Competencies"
            />
            <Awards awards={sections.awards?.data} />
            <Achivements
              achivements={sections.achivements?.data}
               name="Key Achievements"
            />
            <Membership membership={sections.membership?.data}  name="Board Memberships & Public Speaking" />
            <KeyStrength keyStrength={sections.keyStrength?.data} name="Key Strengths" />
            <CareerHighlight careerHighlight={sections.careerHighlight?.data} name="Career Highlights" />
            <Education
              education={sections.education?.data}
              name="Education & Executive Training"
            />
            <Optional optional={sections.optional?.data} />
          </>
        );

      default:
        return (
          <>
            <About Professionalsummary={sections.Professionalsummary?.data} />
            <Experience experience={sections.experience} />
            <TechnicalSkills technicalSkills={sections.technicalSkills} />
            <Awards awards={sections.awards?.data} />
            <Projects projects={sections.projects?.data} />
            <Education education={sections.education?.data} />
            <Achivements
              achivements={sections.achivements?.data}
              name="Achivements"
            />
            <Optional optional={sections.optional?.data} />
          </>
        );
    }
  };

  return (
    <div className={styles.resume}>
      <Header profile={sections.profile.data} />
      {getSectionOrder()}
    </div>
  );
};

const Header = ({ profile }) => {
  if (!profile) return null;
  return (
    <div className={styles.header}>
      <div className={styles.user_details}>
        <div>
          <h1 className={styles.header_name}>{profile.name}</h1>
          <h2 className={styles.header_name_position}>
            {profile.position} {profile.tags ? `| ${profile.tags}` : ""}
          </h2>
        </div>
        <div className={styles.header_bewlowname}>
          <div className={styles.header_text}>
            {" "}
            <a href="tel:"> {profile.address} </a>{" "}
          </div>
          <div className={styles.header_text}>
            {" "}
            {profile.address ? "|" : ""} <a href="tel:"> {profile.phone} </a>{" "}
          </div>

          <div className={styles.header_text}>
            {" "}
            {profile.email ? "|" : ""}{" "}
            <a href={`mailto:${profile.email}`}> {profile.email} </a>{" "}
          </div>
          <div className={styles.header_text}>
            {" "}
            {profile.linkedinUsername ? "|" : ""}{" "}
            <a href={`https://www.linkedin.com/in/${profile.linkedinUsername}`}>
              {" "}
              {profile.linkedinUsername
                ? ` linkedin.com/in/${profile.linkedinUsername}`
                : ""}{" "}
            </a>
          </div>
          <div className={styles.header_text}>
            {" "}
            {profile.githubUsername ? "|" : ""}
            <a href={`https://github.com/${profile.githubUsername}`}>
              {" "}
              {profile.githubUsername
                ? `github.com/${profile.githubUsername} `
                : ""}{" "}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const Section = ({ title, children }) => {
  if (!children) return null;
  return (
    <div className={styles.section}>
      <div>
        <h2 className={styles.format1}>{title}</h2>
        <div className={styles.hr}> </div>
      </div>

      <div className={styles.section_container}>{children}</div>
    </div>
  );
};

const Points = ({ points, years, markdown = true, secondary }) => {
  if (!points) return null;
  return (
    <ul className={styles.points + " " + (secondary ? styles.secondary : null)}>
      {points?.map((point, index) => (
        <li key={index} className={styles.point}>
          {markdown ? (
            <>
              <div
                key={index}
                dangerouslySetInnerHTML={{
                  __html: getSafeMarkdownString(point),
                }}
              />
              {years && <i>{years[index]}</i>}
            </>
          ) : (
            <>
              <div>{point}</div>
              {years && <i>{years[index]}</i>}
            </>
          )}
        </li>
      ))}
    </ul>
  );
};

const Experience = ({ experience, name }) => {
  if (!experience || experience.length === 0) return null;

  if (
    experience.activeTab === 0 ||
    experience.activeTab === undefined ||
    experience.activeTab === null
  ) {
    return (
      <Section title={name || "Experience"}>
        <div>
          {experience.simpleList?.map((exp, index) => (
            <div key={index} className={styles.experienceContainer}>
              <div className={styles.her}>
                <div className={styles.resumesampleh2}>
                  {exp.role}
                  <span className={styles.removefontwidth}>
                    {" "}
                    {exp.company ? `, ${exp.company}` : ""}
                    {exp.location ? `, ${exp.location}` : ""}
                    {exp.timePeriod ? `| ${exp.timePeriod}` : ""}{" "}
                  </span>{" "}
                </div>
              </div>
              <div className={styles.pointsContainer}>
                <Points points={exp.points} markdown={true} secondary={true} />
              </div>
            </div>
          ))}
        </div>
      </Section>
    );
  }
  if (experience.activeTab === 1) {
    return (
      <Section title={name || "Experience"}>
        <div>
          {experience.responsibilitiesAndAccomplishments?.map((exp, index) => (
            <div key={index}>
              <div className={styles.her}>
                <div className={styles.resumesampleh2}>
                  {exp.role}
                  <span className={styles.removefontwidth}>
                    {" "}
                    {exp.company ? `, ${exp.company}` : ""}
                    {exp.location ? `, ${exp.location}` : ""}
                    {exp.timePeriod ? `| ${exp.timePeriod}` : ""}{" "}
                  </span>{" "}
                </div>
              </div>
              {/* for responsibilities */}
              <div>
                <h1 className={styles.responsibilities}>Responsibilities</h1>
                <Points
                  points={exp.responsibilities}
                  markdown={true}
                  secondary={true}
                />
              </div>
              {/* for accomplishments */}
              <div>
                <h1 className={styles.accomplishments}>Accomplishments</h1>
                <Points
                  points={exp.accomplishments}
                  markdown={true}
                  secondary={true}
                />
              </div>
            </div>
          ))}
        </div>
      </Section>
    );
  }
  if (experience.activeTab === 2) {
    return (
      <Section title={name || "Experience"}>
        <div>
          {experience.problemSolutionImapact?.map((exp, index) => (
            <div key={index} className={styles.problemSolutionImapactContainer}>
              <div className={styles.her}>
                <div className={styles.resumesampleh2}>
                  {exp.role}
                  <span className={styles.removefontwidth}>
                    {" "}
                    {exp.company ? `, ${exp.company}` : ""}
                    {exp.location ? `, ${exp.location}` : ""}
                    {exp.timePeriod ? `| ${exp.timePeriod}` : ""}{" "}
                  </span>{" "}
                </div>
              </div>
              {/* for problem */}
              <div>
                <span className={styles.problem}>Problem:</span>
                {exp.problem}
                <br />
                <span className={styles.solution}>Solution:</span>
                {exp.solution}
                <br />
                <span className={styles.impact}>Impact:</span>
                {exp.impact}
              </div>
            </div>
          ))}
        </div>
      </Section>
    );
  }
  if (experience.activeTab === 3) {
    return (
      <Section title={name || "Experience"}>
        <div>
          {experience.detailedGroups?.map((exp, index) => (
            <div className={styles.problemSolutionImapactContainer} key={index}>
              <div className={styles.her}>
                <div className={styles.resumesampleh2}>
                  {exp.role}
                  <span className={styles.removefontwidth}>
                    {" "}
                    {exp.company ? `, ${exp.company}` : ""}
                    {exp.location ? `, ${exp.location}` : ""}
                    {exp.timePeriod ? `| ${exp.timePeriod}` : ""}{" "}
                  </span>{" "}
                </div>
              </div>
              {/* for p roblem */}
              {exp.groups.map((group, index) => (
                <div key={index}>
                  <span className={styles.problem}> {group.groupName}:</span>
                  {group.description}
                </div>
              ))}
            </div>
          ))}
        </div>
      </Section>
    );
  }
  
};

const About = ({ Professionalsummary, name }) => {
  if (!Professionalsummary) return null;
  return (
    <Section title={name || "Professional Summary"}>
      <div className={styles.secondline}>
        <span
          className={styles.resumesampletextabout}
          dangerouslySetInnerHTML={{ __html: Professionalsummary?.summary }}
        />
      </div>
    </Section>
  );
};

const Projects = ({ projects, name }) => {
  if (!projects || projects.length === 0) return null;
  return (
    <Section title={name || "Projects"}>
      {projects?.map((project, index) => (
        <div className={styles.project} key={index}>
          <div className={styles.firstline}>
            <h3 className={styles.title}> {project.title} </h3>
            <i>{project.year}</i>
          </div>

          <div className={styles.secondline}>
            <i>{project.techstack}</i>
            <i>{project.organisation}</i>
          </div>

          <div
            style={{
              marginLeft: "1rem",
            }}
          >
            <Points points={project.points} markdown={true} secondary={true} />
          </div>
        </div>
      ))}
    </Section>
  );
};

const Education = ({ education, name }) => {
  if (!education || education.length === 0) return null;
  return (
    <Section title={name || "Education"}>
      <ul className={styles.border}>
        {education.map((point, index) => (
          <>
            <li key={index}>
              <span style={{ fontWeight: "600" }}> {point.college} </span>
              <i>{point.year}</i>
            </li>
            <div
              key={index}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <i>
                {point.degree} {point.branch ? `in ${point.branch}` : ""}
              </i>
              {/* <i>{`${point?.cgpa ? point?.cgpa :  point?.cgpa}`}</i> */}
            </div>
          </>
        ))}
      </ul>
    </Section>
  );
};

const TechnicalSkills = ({ technicalSkills, name }) => {
  if (!technicalSkills || technicalSkills.length === 0) return null;
  return (
    <Section title={name || "Technical Skills"}>
      {technicalSkills.activeTab === 0 && (
        <div className={styles.skillsGrid}>
          {technicalSkills.simpleList.map((skill, index) => (
            <div key={index} className={styles.skillItem}>
              <Check size={16} className={styles.tickIcon} />
              <span>{skill}</span>
            </div>
          ))}
        </div>
      )}

      {technicalSkills.activeTab === 1 &&
        technicalSkills.twoColumn.map((tech, index) => (
          <div key={index}>
            <strong>{tech.name}</strong> {tech.name ? ":" : ""}
            <span>{tech.skills?.join(",")}</span>
          </div>
        ))}

      {technicalSkills.activeTab === 2 &&
        technicalSkills.detailed.map((tech, index) => (
          <div key={index}>
            <strong>{tech.name}</strong> {tech.name ? ":" : ""}
            <span>{tech.skills?.join(",")}</span>
          </div>
        ))}
      <div style={{ height: "0.7rem" }}></div>
    </Section>
  );
};

const Awards = ({ awards, name }) => {
  if (!awards || awards.length === 0) return null;
  return (
    <Section title={name || "Certifications"}>
      {awards.map((award, index) => (
           <div key={index}>
              <strong>{award.name}</strong>
              {award.IssuedBy ? `, ${award.IssuedBy}` : ""}
              {award.issuedOn ? `, Issued ${award.issuedOn}` : ""}
           </div>
        ))}
    </Section>
  );
};

const Achivements = ({ achivements, name }) => {
  if (!achivements || achivements.length === 0) return null;
  return (
    <Section title={name || "Achivements"}>
      {achivements?.map((achivement, index) => (
        <div className={index > 0 ? styles.bordertop : ""} key={index}>
          <div className={styles.her}>
            <div className={styles.resumesampleh2}>{achivement.title}</div>
          </div>
          <div>
            <Points
              points={achivement.points}
              markdown={true}
              secondary={true}
            />
          </div>
        </div>
      ))}
    </Section>
  );
};

const CareerHighlight = ({ careerHighlight, name }) => {
  if (!careerHighlight || careerHighlight.length === 0) return null;
  return (
    <Section title={name || "Career Highlights"}>
      {careerHighlight?.map((careerHighlight, index) => (
        <div className={index > 0 ? styles.bordertop : ""} key={index}>
          <div className={styles.her}>
            {/* <div className={styles.resumesampleh2}>{careerHighlight.title}</div> */}
          </div>
          <div>
            <Points
              points={careerHighlight.points}
              markdown={true}
              secondary={true}
            />
          </div>
        </div>
      ))}
    </Section>
  );
};

const KeyStrength = ({ keyStrength, name }) => {
  if (!keyStrength || keyStrength.length === 0) return null;
  return (
    <Section title={name || "Key Strengths"}>
      {keyStrength?.map((keyStrength, index) => (
        <div className={index > 0 ? styles.bordertop : ""} key={index}>
          <div className={styles.her}>
            {/* <div className={styles.resumesampleh2}>{keyStrength.title}</div> */}
          </div>
          <div>
            <Points
              points={keyStrength.points}
              markdown={true}
              secondary={true}
            />
          </div>
        </div>
      ))}
    </Section>
  );
};

const Membership = ({ membership, name }) => {
  if (!membership || membership.length === 0) return null;
  return (
    <Section title={name || "Board Memberships & Public Speaking"}>
      {membership?.map((membership, index) => (
        <div className={index > 0 ? styles.bordertop : ""} key={index}>
          <div className={styles.her}>
            {/* <div className={styles.resumesampleh2}>{membership.title}</div> */}
          </div>
          <div>
            <Points
              points={membership.points}
              markdown={true}
              secondary={true}
            />
          </div>
        </div>
      ))}
    </Section>
  );
};

const Optional = ({ optional }) => {
  if (!optional || optional.length === 0) return null;
  return (
    <div>
      {optional.map((item, index) => (
        <Section title={item.name}>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: item.description }}
          ></div>
        </Section>
      ))}
    </div>
  );
};

export default IITEResume;
