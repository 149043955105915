import React from 'react'
import styles from "../../assets/Dashboard/Status.module.css"
import { ChevronRight } from 'lucide-react'

const StatusCard = ({ icon, value, label1, label2 }) => {
  return (
    <div className={styles.statusCard}>
      <div className={styles.statusCardLeft}>
        <div className={styles.leftcardCircleContainer}>
          <div className={styles.textContainer}>
            <span className={styles.text}>{icon || value}</span>
          </div>
        </div>
      </div>
      <div className={styles.statusCardRight}>
        <div className={styles.rightCardTextContainer}>
          <span className={styles.rightCardText}>{label1} </span>
          <span className={styles.rightCardText}>{label2}</span>
        </div>    
      </div>
    </div>
  )
}

const Status = () => {
  const statusData = [
    { value: "07", label1: "Jobs", label2: "Matched" },
    { value: "60%", label1: "Profile", label2: "Completed" },
    { value: "07", label1: "Resumes", label2: "Score" },
    { icon: <ChevronRight size={30} color={"#ffffff"} />, label1: "Check", label2: "ATS Score" }
  ]

  return (
    <div className={styles.statusContainer}>
      {statusData.map((item, index) => (
        <StatusCard 
          key={index}
          icon={item.icon}
          value={item.value}
          label1={item.label1}
          label2={item.label2}
        />
      ))}
    </div>
  )
}

export default Status