import React, { useEffect, useState } from "react";
import styles from "./ResumeBuilder.module.css";
import Preview from "./components/Preview/Preview";
import previewStyles from "./components/Preview/Preview.module.css";
import { useResume } from "./components/context/Resume";
import MyResponsiveNavbar from "../../pages/Navbar";
import { useAuth } from "./components/context/auth";
import { useJobs } from "./components/context/Jobs";
import RightTop from "./RightTop";
import Analysis from "./Analysis";
import Matching from "./Matching";
import Design from "./Design";
import Jobs from "./Jobs";
import { useNavigate, useParams } from "react-router-dom";
import SideNavbar from "./SideNavbar";
import { ArrowLeft, ArrowRight, Settings, X } from "lucide-react";
import { useSocket } from "./components/context/SocketContext";
import { debounce } from "lodash";
import toast from "react-hot-toast";
import Finish from "./Finish";
import { privateApi } from "../utils/aixos";

const ResumeBuilder = () => {
  const {
    selectedTemplate,
    printContainerRef,
    values,
    setValues,
    handleSectionChange,
    nextSection,
    prevSection,
    currentStep,
    handleTemplateChange,
    setCurrentExperience,
    setCurrentSector,
  } = useResume();
  const [previewLoading, setPreviewLoading] = useState(true);
  const { activeResume, setActiveResume } = useJobs();
  const { auth } = useAuth();
  const { socket, isConnected } = useSocket();
  const navigate = useNavigate();
  const Build = selectedTemplate?.build;
  const Resume = selectedTemplate?.resume;
  const [showPreview, setShowPreview] = useState(false);
  const [imgSrc, setImgSrc] = useState(
    "https://res.cloudinary.com/dvepwlz9j/image/upload/v1729186227/all/vzw4bjslemzuuvznzdgs.svg"
  );
  const [showAdminModal, setShowAdminModal] = useState(false);
  const [selectedDomain, setSelectedDomain] = useState("");
  const [selectedSector, setSelectedSector] = useState("");
  const [selectedKey, setSelectedKey] = useState("");
  const [selectedInsertId, setSelectedInsertId] = useState("");
  const [resumeExamples, setResumeExamples] = useState([]);
  const [selectedYOE, setSelectedYOE] = useState("");
  const [adminFormData, setAdminFormData] = useState({
    resumeValues: "",
    image: "",
    template_key: "",
    YOE: "",
    domins_name: "",
    sector_name: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);

  const template_key = selectedTemplate?.key;
  useEffect(() => {
    if (Array.isArray(auth?.user) && auth.user.length > 0) {
      setImgSrc(
        auth.user[0]?.images ||
          "https://res.cloudinary.com/dvepwlz9j/image/upload/v1729186227/all/vzw4bjslemzuuvznzdgs.svg"
      );
    }
  }, [auth]);
  useEffect(() => {
    if (showAdminModal) {
      setAdminFormData({
        template_key: template_key,
        resumeValues: JSON.stringify(values),
        YOE: selectedYOE,
        domins_name: selectedDomain,
        sector_name: selectedSector,
      });
    }
  }, [showAdminModal]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const insertId = urlParams.get("insert_id");
    const jobsector = urlParams.get("jobsector");
    const domins = urlParams.get("domins");
    const YOE = urlParams.get("YOE");
    const key = urlParams.get("key");
    setSelectedDomain(domins);
    setSelectedSector(jobsector);
    setSelectedKey(key);
    setSelectedInsertId(insertId);
    setSelectedYOE(YOE);
    handleTemplateChange(key);
    setCurrentSector(jobsector);
    setCurrentExperience(YOE);
  }, []); // Run once on component mount

  useEffect(() => {
    const debouncedEmit = debounce((resumeId, values) => {
      const idToUse = resumeId || selectedInsertId;

      if (!isConnected || !socket) {
        console.log("Waiting for socket connection...");
        return;
      }

      if (!idToUse || !values) {
        console.log("Missing required data:", {
          resumeId: idToUse,
          hasValues: !!values,
        });
        return;
      }

      try {
        socket.emit("edit", {
          resumeId: idToUse,
          values,
        });
      } catch (error) {
        console.error("Error saving changes:", error);
        toast.error("Failed to save changes");
      }
    }, 1000);

    debouncedEmit(selectedInsertId, values);

    return () => {
      debouncedEmit.cancel();
    };
  }, [values, socket, isConnected, selectedInsertId]);

  useEffect(() => {
    if (socket && isConnected && selectedInsertId) {
      socket.emit("getInitialData", { resumeId: selectedInsertId });
      socket.on("initialData", (data) => {
        if (data.resumeId === selectedInsertId && data.values) {
          setValues(data.values);
        }
      });

      return () => {
        socket.off("initialData");
      };
    }
  }, [socket, isConnected, selectedInsertId]);

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
  };

  const NavigationContainer = () => {
    return (
      <div className={styles.navigationContainer}>
        {prevSection && (
          <div
            className={styles.navigationPrev}
            onClick={() => handleSectionChange("prev")}
          >
            <ArrowLeft size={15} /> Previous
          </div>
        )}

        {nextSection ? (
          <div
            className={styles.navigationNext}
            onClick={() => handleSectionChange("next")}
          >
            Next 
            <ArrowRight style={{ marginLeft: "3px" }} size={15} />
          </div>
        ) : (
          <div
            className={styles.navigationNext}
            onClick={() => setActiveResume(6)}
          >
            Finish
            <ArrowRight style={{ marginLeft: "3px" }} size={15} />
          </div>
        )}
      </div>
    );
  };

  const NavigationContainerMobile = () => {
    return (
      <>
        <div
          className={styles.floatingPreviewBtn}
          onClick={() => setShowPreview(true)}
          title="Preview Resume"
        >
          <div className={styles.previewBtnContent}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.92 11.6C19.9 6.91 16.1 4 12 4S4.1 6.91 2.08 11.6a1 1 0 0 0 0 .8C4.1 17.09 7.9 20 12 20s7.9-2.91 9.92-7.6a1 1 0 0 0 0-.8zM12 18c-3.17 0-6.17-2.29-7.9-6C5.83 8.29 8.83 6 12 6s6.17 2.29 7.9 6c-1.73 3.71-4.73 6-7.9 6zm0-10a4 4 0 1 0 0 8 4 4 0 0 0 0-8zm0 6a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
                fill="currentColor"
              />
            </svg>
            <span>Preview</span>
          </div>
        </div>

        <div className={styles.navigationContainerMobile}>
          {prevSection ? (
            <div
              className={styles.navigationPreviewMobile}
              onClick={() => handleSectionChange("prev")}
            >
              <ArrowLeft style={{ marginRight: "3px" }} size={15} />
              Previous
            </div>
          ) : (
            <div
              className={styles.navigationPreviewMobile}
              onClick={() => navigate("/dashboard")}
            >
              <ArrowLeft style={{ marginRight: "3px" }} size={15} />
              Dashboard
            </div>
          )}

          {nextSection ? (
            <div
              className={styles.navigationNextMobile}
              onClick={() => handleSectionChange("next")}
            >
              Next: {truncateText(nextSection, 10)}{" "}
              <ArrowRight style={{ marginLeft: "3px" }} size={15} />
            </div>
          ) : (
            <div
              className={styles.navigationNextMobile}
              onClick={() => setActiveResume(6)}
            >
              Finish
              <ArrowRight style={{ marginLeft: "3px" }} size={15} />
            </div>
          )}
        </div>
      </>
    );
  };

  const StepContainer = () => {
    return (
      <div className={styles.stepContainer}>
        <div className={styles.progressLine}>
          {Array.from({ length: currentStep.total }).map((_, index) => (
            <React.Fragment key={index}>
              <div className={styles.stepItem}>
                <div 
                  className={`
                    ${styles.stepCircle} 
                    ${index + 1 <= currentStep.current ? styles.active : ''} 
                    ${index + 1 === currentStep.current + 1 ? styles.nextStep : ''}
                  `}
                >
                  {index + 1}
                </div>
              </div>
              {index < currentStep.total - 1 && (
                <div 
                  className={`
                    ${styles.connector} 
                    ${index + 1 < currentStep.current ? styles.active : ''} 
                    ${index + 1 === currentStep.current ? styles.nextConnector : ''}
                  `} 
                />
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    );
  };

  const MobileNavbar = () => {
    return (
      <div className={styles.mobilenavMain}>
        <div className={styles.backButton}>
          {" "}
          <ArrowLeft size={15} /> Back
        </div>

        <div>
          <img
            className={styles.profileImage}
            src={
              "https://res.cloudinary.com/dvepwlz9j/image/upload/v1729186227/all/vzw4bjslemzuuvznzdgs.svg"
            }
            alt="profile"
          />
        </div>
      </div>
    );
  };

  const handleAdminInputChange = (e) => {
    const { name, type } = e.target;

    if (type === "file") {
      const file = e.target.files[0];
      setAdminFormData((prev) => ({
        ...prev,
        [name]: file,
      }));

      // Create and set image preview
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result);
        };
        reader.readAsDataURL(file);
      } else {
        setImagePreview(null);
      }
    } else {
      // Handle other inputs
      setAdminFormData((prev) => ({
        ...prev,
        [name]: e.target.value,
      }));
    }
  };

  const handleAdminSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formDataToSend = new FormData();

    if (adminFormData.image) {
      formDataToSend.append("image", adminFormData.image);
    }

    formDataToSend.append(
      "formData",
      JSON.stringify({
        resumeValues: adminFormData.resumeValues,
        template_key: adminFormData.template_key,
        YOE: adminFormData.YOE,
        domins_name: adminFormData.domins_name,
        sector_name: adminFormData.sector_name,
      })
    );

    try {
      const { data } = await privateApi.post(
        "/api/admin/addResumeExamples",
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      toast.success("Resume example added successfully");
      setShowAdminModal(false);
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to add resume example");
    } finally {
      setIsSubmitting(false);
    }
  };

  const getResumeExamples = async () => {
    const { data } = await privateApi.get("/api/admin/getResumeExamples");
    setResumeExamples(data.data);
    console.log(data.data);
  };

  const adminModal = () => {
    return (
      <>
        <button
          className={styles.adminButton}
          onClick={() => {
            getResumeExamples();
            setShowAdminModal(true);
          }}
        >
          <Settings size={16} />
          Resume Management System
        </button>

        {showAdminModal && (
          <div className={styles.fullScreenModal}>
            <div className={styles.modalSidebar}>
              <div className={styles.sidebarHeader}>
                <div className={styles.sidebarHeaderLeft}>
                  <h3>Resume Management System</h3>
                  <span className={styles.templateCount}>
                    <i className="far fa-file-alt" /> {resumeExamples.length}{" "}
                    Templates Available
                  </span>
                </div>
                <button
                  className={styles.closeButton}
                  onClick={() => setShowAdminModal(false)}
                >
                  <X size={20} />
                </button>
              </div>

              <div className={styles.sidebarSearch}>
                <div className={styles.searchWrapper}>
                  <i className="far fa-search" />
                  <input
                    type="text"
                    placeholder="Search templates..."
                    className={styles.searchInput}
                  />
                </div>
                {/* <div className={styles.filterButtons}>
                  <button className={`${styles.filterButton} ${styles.active}`}>All</button>
                  <button className={styles.filterButton}>Recent</button>
                  <button className={styles.filterButton}>Favorites</button>
                </div> */}
              </div>

              <div className={styles.historyList}>
                {resumeExamples && resumeExamples.length > 0 ? (
                  resumeExamples.map((item) => (
                    <div key={item.id} className={styles.historyItem}>
                      <div className={styles.historyItemContent}>
                        <div className={styles.historyItemPreview}>
                          <img src={item.image} alt="Resume preview" />
                          <div className={styles.templateType}>
                            <span className={styles.templateBadge}>
                              Professional
                            </span>
                          </div>
                        </div>
                        <div className={styles.historyItemDetails}>
                          <div className={styles.itemHeader}>
                            <h4>{item.name}</h4>
                            <button className={styles.favoriteButton}>
                              <i className="far fa-star" />
                            </button>
                          </div>
                          <div className={styles.templateMeta}>
                            <span>
                              <i className="far fa-folder" /> {item.domins_name}
                            </span>
                            <span>
                              <i className="far fa-clock" /> {item.sector_name}
                            </span>
                            <span>
                              <i className="far fa-download" /> {item.YOE}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className={styles.historyItemActions}>
                        <button
                          className={styles.actionButton}
                          title="Edit Template"
                        >
                          <i className="far fa-edit" />
                          <span>Edit</span>
                        </button>
                        {/* <button className={styles.actionButton} title="Duplicate Template">
                          <i className="far fa-copy" />
                          <span>Duplicate</span>
                        </button> */}
                        <button
                          className={styles.deleteButton}
                          title="Delete Template"
                        >
                          <i className="far fa-trash-alt" />
                          <span>Delete</span>
                        </button>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className={styles.emptyState}>
                    <div className={styles.emptyStateIcon}>
                      <i className="far fa-folder-open" />
                    </div>
                    <h3>No Templates Yet</h3>
                    <p>Start by adding your first resume template</p>
                  </div>
                )}
              </div>
            </div>

            <div className={styles.modalMain}>
              <div className={styles.modalHeader}>
                <div className={styles.headerContent}>
                  <div className={styles.headerTitle}>
                    <h2>Upload New Resume Template</h2>
                  </div>
                  <p className={styles.headerDescription}>
                    Create and manage professional resume templates with preview
                    and details
                  </p>
                </div>
              </div>

              <div className={styles.modalBody}>
                <form
                  onSubmit={handleAdminSubmit}
                  className={styles.uploadForm}
                >
                  <div className={styles.formGrid}>
                    <div className={styles.formLeft}>
                      <div className={styles.formSection}>
                        <div className={styles.sectionHeader}>
                          <h3>Template Information</h3>
                          <p>Basic details about the resume template</p>
                        </div>

                        <div className={styles.inputGroup}>
                          <label htmlFor="resumeValues">
                            Resume JSON
                            <span className={styles.requiredStar}>*</span>
                          </label>
                          <textarea
                            id="resumeValues"
                            name="resumeValues"
                            value={adminFormData.resumeValues}
                            onChange={handleAdminInputChange}
                            disabled
                            required
                            className={styles.textarea}
                            placeholder="Enter resume structure..."
                          />
                        </div>

                        <div className={styles.inputRow}>
                          <div className={styles.inputGroup}>
                            <label htmlFor="template_key">
                              Template Key
                              <span className={styles.requiredStar}>*</span>
                            </label>
                            <input
                              type="text"
                              id="template_key"
                              name="template_key"
                              value={adminFormData.template_key}
                              onChange={handleAdminInputChange}
                              disabled
                              required
                              className={styles.input}
                            />
                          </div>

                          <div className={styles.inputGroup}>
                            <label htmlFor="YOE">
                              Years of Experience
                              <span className={styles.requiredStar}>*</span>
                            </label>
                            <input
                              type="text"
                              id="YOE"
                              name="YOE"
                              value={adminFormData.YOE}
                              onChange={handleAdminInputChange}
                              disabled
                              required
                              className={styles.input}
                            />
                          </div>
                        </div>

                        <div className={styles.inputRow}>
                          <div className={styles.inputGroup}>
                            <label htmlFor="sector_name">
                              Sector
                              <span className={styles.requiredStar}>*</span>
                            </label>
                            <input
                              type="text"
                              id="sector_name"
                              name="sector_name"
                              value={adminFormData.sector_name}
                              onChange={handleAdminInputChange}
                              disabled
                              required
                              className={styles.input}
                            />
                          </div>

                          <div className={styles.inputGroup}>
                            <label htmlFor="domins_name">
                              Domain
                              <span className={styles.requiredStar}>*</span>
                            </label>
                            <input
                              type="text"
                              id="domins_name"
                              name="domins_name"
                              value={adminFormData.domins_name}
                              onChange={handleAdminInputChange}
                              disabled
                              required
                              className={styles.input}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={styles.formRight}>
                      <div className={styles.formSection}>
                        <div className={styles.sectionHeader}>
                          <h3>Template Preview</h3>
                          <p>Upload a preview image for the template</p>
                        </div>

                        <div className={styles.uploadPreviewSection}>
                          <div className={styles.uploadArea}>
                            <label
                              htmlFor="image"
                              className={styles.uploadLabel}
                            >
                              {!imagePreview ? (
                                <div className={styles.uploadPlaceholder}>
                                  <div className={styles.uploadIconWrapper}>
                                    <i className="far fa-cloud-upload-alt" />
                                  </div>
                                  <h4>Upload Preview Image</h4>
                                  <p>
                                    Drag & drop your image here or click to
                                    browse
                                  </p>
                                  <span className={styles.supportedFormats}>
                                    Supported formats: JPG, PNG (Max 5MB)
                                  </span>
                                </div>
                              ) : (
                                <div className={styles.previewContainer}>
                                  <img
                                    src={imagePreview}
                                    alt="Preview"
                                    className={styles.previewImage}
                                  />
                                  <div className={styles.previewOverlay}>
                                    <button
                                      type="button"
                                      className={styles.changeImageBtn}
                                    >
                                      <i className="far fa-camera" />
                                      Change Image
                                    </button>
                                  </div>
                                </div>
                              )}
                              <input
                                type="file"
                                id="image"
                                name="image"
                                onChange={handleAdminInputChange}
                                accept="image/*"
                                required
                                className={styles.hiddenInput}
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={styles.formActions}>
                    <button
                      type="button"
                      className={styles.cancelButton}
                      onClick={() => setShowAdminModal(false)}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className={styles.submitButton}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <>
                          <span className={styles.spinner}></span>
                          Publishing Template...
                        </>
                      ) : (
                        <>
                          <i className="far fa-check-circle" />
                          Publish Template
                        </>
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const previewContent = () => {
    return (
      <div className={styles.previewContent}>
        <div
          className={styles.previewClose}
          onClick={() => setShowPreview(false)}
        >
          <X size={24} />
        </div>
        <div className={styles.previewContentInner}>
          <Preview template={selectedTemplate} />
        </div>
      </div>
    );
  };

  return (
    <div className={styles.maintop}>
      <div className={styles.righttop}>
        <div className={styles.mobilenav}>
          {/* {MobileNavbar()}  */}
          <MyResponsiveNavbar />
        </div>
        <RightTop auth={auth} image={imgSrc} activeNav={"Resume"} />
        <div
            className={previewStyles.print_only_resume_page}
            id="print_content"
          >
            <div
              ref={printContainerRef}
              className={previewStyles.container}
              style={{
                width: "100%",
                padding: selectedTemplate?.page_margins,
              }}
            >
              {Resume && <Resume sections={values.sections} />}
            </div>
          </div>
        <div className={styles.OverallContainer}>
          <div className={styles.lefttop}>
            <SideNavbar auth={auth} image={imgSrc} />
          </div>
          <div className={styles.container}>
            <div className={styles.rightt}>
              <Preview
                setPreviewLoading={setPreviewLoading}
                previewLoading={previewLoading}
                template={selectedTemplate}
              />
            </div>

            <div className={styles.left}>
              {activeResume === 1 && (
                <>
                  {StepContainer()}
                  {Build && <Build />}
                  {NavigationContainer()}
                  {NavigationContainerMobile()}
                  {showPreview && previewContent()}
                  {auth?.user[0]?.id_user === 1374 && adminModal()}
                </>
              )}

              {activeResume === 2 && <Analysis />}
              {activeResume === 3 && <Matching />}
              {activeResume === 4 && (
                <Design
                  setPreviewLoading={setPreviewLoading}
                  previewLoading={previewLoading}
                />
              )}
              {activeResume === 5 && <Jobs id={selectedInsertId} />}
              {activeResume === 6 && <Finish />}
            </div>
          </div>
        </div>
      </div>

      {/* <BottomBar /> */}
    </div>
  );
};

export default ResumeBuilder;
