import React from "react";
import styles from "../../assets/Dashboard/Header.module.css";
import { Plus } from "lucide-react";
const Header = ({modalShow , setModalShow , auth}) => { 
  return (
    <div className={styles.headerContainer}>
      <div className={styles.leftHeader}> 
        <div className={styles.imageContainer}>
            <img src={"https://res.cloudinary.com/dmsbvsful/image/upload/v1741863999/Untitled_Artwork_25_1_ifxa9j.svg"} alt="MyFuse Logo" />
        </div> 
        <div className={styles.textContainer}> 
            <h1 className={styles.welcomeText}>Welcome Back, <span className={styles.name}>{auth?.user?.[0]?.firstname} {auth?.user?.[0]?.lastname} !</span></h1>
            <p className={styles.textParagraph}>Create your custom resume using our AI
            and start applying for jobs.</p>
        </div>
      </div>
      <div className={styles.rightHeader}>
        {/* two buttons is here ai resume and upload resume */}
        <div className={styles.buttonContainer}> 
            <div className={styles.button}>
                <span className={styles.NewResumeButton} onClick={() => setModalShow(true)}> <Plus size={18} />   New Resume</span>
            </div>
            <div className={styles.button2}>
                <span className={styles.UploadResumeButton} onClick={() => setModalShow(true)}>Upload Resume</span>
            </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
