export const comments = [
  {
    id: 1,
    category: "recent graduate",
    text: "I struggled to get interview calls until I optimized my resume with MyFuse. The ATS score feature helped me tailor my resume, and within weeks, I landed multiple interviews. Highly recommend it for freshers!",
    name: "Akshay R., B.Tech Graduate",
    profile: "/images/profile/rajeshpfp.jpg",
  },
  {
    id: 2,
    category: "recent graduate",
    text: "As a recent MBA graduate, I wasn’t sure how to stand out in a competitive job market. MyFuse’s AI-powered resume builder made my resume professional and ATS-friendly. I got hired within a month!",
    name: "Priya S., MBA Graduate",
    profile: "/images/profile/rajeshpfp.jpg",
  },
  {
    id: 3,
    category: "recent graduate",
    text: "I was applying to jobs with no response, but after using MyFuse, I saw a huge difference. The job-matching feature helped me apply to the right roles, and I finally secured a job at a top company!",
    name: "Rohan M., B.Com Graduate",
    profile: "/images/profile/rajeshpfp.jpg",
  },
  {
    id: 4,
    category: "young professional",
    text: "As a fresher, I had no idea how to make my resume stand out. MyFuse helped me optimize it for ATS, and I started getting responses from top companies. Now, I’m working at a leading marketing firm!",
    name: "Neha K., Marketing Associate",
    profile: "/images/profile/rajeshpfp.jpg",
  },
  {
    id: 5,
    category: "young professional",
    text: "I was applying to dozens of jobs but barely got interview calls. MyFuse’s AI resume analysis showed me what I was missing. Within weeks of improving my resume, I landed a job as a software engineer!",
    name: "Rahul T., Software Engineer",
    profile: "/images/profile/rajeshpfp.jpg",
  },
  {
    id: 6,
    category: "young professional",
    text: "Breaking into the corporate world as a fresher felt overwhelming. MyFuse not only helped me craft a professional resume but also matched me with the right job openings. I’m now working as a business analyst at a great company!",
    name: "Ananya P., Business Analyst",
    profile: "/images/profile/rajeshpfp.jpg",
  },
  {
    id: 7,
    category: "experienced professional",
    text: "Even with years of experience, my resume wasn’t getting noticed. MyFuse helped me refine it, improve my ATS score, and align it with the job descriptions. Within weeks, I landed a senior role at a top company!",
    name: "Sameer R., Sales Manager (5+ years experience)",
    profile: "/images/profile/rajeshpfp.jpg",
  },
  {
    id: 8,
    category: "experienced professional",
    text: "I was looking for a job switch but wasn’t getting responses. MyFuse’s AI-driven resume builder highlighted the gaps in my resume. After optimizing it, I started getting more interview calls, and now I’m in my dream job!",
    name: "Megha S., Senior Software Developer (7+ years experience)",
    profile: "/images/profile/rajeshpfp.jpg",
  },
  {
    id: 9,
    category: "experienced professional",
    text: "Recruiters often overlook resumes that don’t match ATS requirements. MyFuse helped me structure my resume correctly, and I saw an immediate boost in responses from hiring managers. Highly recommended for professionals!",
    name: "Arjun P., HR Specialist (6+ years’ experience)",
    profile: "/images/profile/rajeshpfp.jpg",
  },
];
