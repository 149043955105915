import React from 'react'
import { useResume } from '../ResumeBuilder/builder/components/context/Resume';


const Test3 = () => {
    const { templates } = useResume();
  return (
    <div>

        {/* this i had made for preview of resume  */} 

    </div>
  )
}

export default Test3