import { useState, useEffect } from "react";
import axios from "axios";
import { load } from "@cashfreepayments/cashfree-js";
import { useAuth } from "../ResumeBuilder/builder/components/context/auth";
import toast from "react-hot-toast";
import { privateApi } from "../ResumeBuilder/utils/aixos";

const usePayment = () => {
  const [loading, setLoading] = useState(false);
  const [cashfree, setCashfree] = useState(null);
  const { auth } = useAuth();

  useEffect(() => {
    const initializeSDK = async () => {
      const sdk = await load({
        mode: "production",
      });
      setCashfree(sdk);
    };
    initializeSDK();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createOrder = async (amount) => {
    const datalocal = JSON.parse(localStorage.getItem("auth"));
    setLoading(true);
    try {
        const response =  await privateApi.post(`/api/createOrder`,
        {
          customer_name: auth?.user[0]?.firstname,
          customer_email: auth?.user[0]?.email,
          customer_phone: "9999999999",
          order_amount: amount,
        }
      );

      if (response.data && response.data.success) {
        return response.data.data.payment_session_id;
      } else {
        console.error("Error creating order:", response.data.message);
      }
    } catch (error) {
      console.error("API Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const initiatePayment = async (amount) => {
    const datalocal = JSON.parse(localStorage.getItem("auth"));
    !datalocal
      ? toast.error("Please Login First for Payment")
      : toast.success("Navigating To Payment Page");
    try {
      if (!cashfree) {
        console.error("Cashfree SDK is not initialized");
        return;
      }

      let paymentSessionId = await createOrder(amount);
      if (!paymentSessionId) {
        console.error("Payment session ID is missing");
        return;
      }

      let checkoutOptions = {
        paymentSessionId: paymentSessionId,
        redirectTarget: "_self",
      };

      cashfree
        .checkout(checkoutOptions)
        .then((res) => {
          console.log("Payment initiated:", res);
        })
        .catch((error) => {
          console.error("Error during checkout:", error);
        });
    } catch (error) {
      console.error("Error initiating payment:", error);
    }
  };

  return { initiatePayment };
};

export { usePayment };
