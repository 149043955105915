import React from 'react';
import { Helmet } from 'react-helmet-async';

const HelmetConfig = ({ title, description, keywords, canonical}) => {
  const domain = window.location.origin;
  const imageUrl = `${domain}/myfuse_india_logo.jpeg`; // Replace with your actual image name

    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />
            <link rel="canonical" href={canonical} />

        {/* Favicon and App Icons */}
        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="/mstile-144x144.png" />

        {/* Essential WhatsApp meta tags - optimized order */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={canonical} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:site_name" content="MyFuse" />
        
        {/* Image optimization for WhatsApp */}
        <meta property="og:image" content={imageUrl} /> 
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:alt" content={title} />

            {/* Force WhatsApp to refresh cache */}
            <meta property="og:updated_time" content={new Date().toISOString()} />

        {/* Twitter Card tags for broader compatibility */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={imageUrl} />
    </Helmet>
)

}
export default HelmetConfig;
