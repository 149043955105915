export const EXPERIENCE_TYPES = {
  SIMPLE: 'simple',
  PSI: 'psi',
  RESPONSIBILITIES: 'responsibilities',
  DETAILED: 'detailed'
};

export const INITIAL_STATES = {
  [EXPERIENCE_TYPES.SIMPLE]: {
    company: "",
    role: "",
    timePeriod: "",
    location: "",
    points: ["", "", "", ""]
  },
  [EXPERIENCE_TYPES.PSI]: {
    company: "",
    role: "",
    timePeriod: "",
    location: "",
    problem: "",
    solution: "",
    impact: ""
  },
  [EXPERIENCE_TYPES.RESPONSIBILITIES]: {
    company: "",
    role: "",
    timePeriod: "",
    location: "",
    responsibilities: ["", ""],
    accomplishments: ["", ""]
  },
  [EXPERIENCE_TYPES.DETAILED]: {
    company: "",
    role: "",
    timePeriod: "",
    location: "",
    groups: [{
      groupName: "",
      description: ""
    }]
  }
};

export const TAB_CONFIG = {
  SIMPLE: {
    id: 0,
    label: "Simple List",
    description: "Simple list of experience points"
  },
  PSI: {
    id: 2,
    label: "Problem-Solution-Impact",
    description: "Structured format focusing on challenges and outcomes"
  },
  RESPONSIBILITIES: {
    id: 1,
    label: "Responsibilities & Accomplishments",
    description: "Separate your daily duties from key achievements"
  },
  DETAILED: {
    id: 3,
    label: "Detailed Groups",
    description: "Organize experience into custom categories"
  }
};

export const INPUT_PLACEHOLDERS = {
  COMPANY: "Ex: Google, Amazon, Microsoft",
  ROLE: "Ex: Software Engineer, Product Manager",
  LOCATION: "Ex: San Francisco, CA",
  GROUP_NAME: "Ex: Technical Skills, Leadership Experience",
  PROBLEM: "Describe the problem or challenge you encountered...",
  SOLUTION: "Explain your approach and implementation...",
  IMPACT: "Describe the quantifiable outcomes...",
  DESCRIPTION: "Describe the details and achievements related to this group..."
};

export const SECTION_LABELS = {
  RESPONSIBILITIES: {
    title: "Responsibilities",
    description: "List your key job responsibilities and duties"
  },
  ACCOMPLISHMENTS: {
    title: "Accomplishments",
    description: "Highlight your achievements and successes"
  },
  PROBLEM: {
    title: "Problem",
    description: "What challenge did you face?"
  },
  SOLUTION: {
    title: "Solution",
    description: "How did you solve it?"
  },
  IMPACT: {
    title: "Impact",
    description: "What were the results?"
  }
};

export const MAX_ITEMS = {
  POINTS: 4,
  RESPONSIBILITIES: 2,
  ACCOMPLISHMENTS: 2,
  GROUPS: 5
};

export const ANIMATION_SETTINGS = {
  INITIAL: { opacity: 0, y: 20 },
  ANIMATE: { opacity: 1, y: 0 },
  TRANSITION: { duration: 0.3 }
};

export const DEBOUNCE_DELAY = 300;

export const ERROR_MESSAGES = {
  GENERIC: "Something went wrong. Please try again.",
  EMPTY_FIELD: "This field cannot be empty",
  MAX_LENGTH: "Maximum length exceeded",
  INVALID_DATE: "Please enter a valid date"
};

export const BUTTON_LABELS = {
  ADD_EXPERIENCE: "Add Experience",
  ADD_GROUP: "Add Group",
  ADD_NEW_COMPANY: "Add New Company",
  ADD_SKILL: "Add Skill"
}; 