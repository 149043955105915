import React, { useEffect, useRef, useState } from "react";
import styles from "../builder/components/templates/config/Config.module.css";
import { Plus, Trash2, FilePlus2, ChevronDown } from "lucide-react";
import { useResume } from "../builder/components/context/Resume";
import {
  AIImprovisePoints,
  AIPoints,
  AISkillsPoints,
  SegregateExperienceDetailed,
  SegregateTechnicalandSoftSkills,
} from "../builder/components/templates/config/AI";
import DatePicker from "react-datepicker";
import { RiMagicLine } from "react-icons/ri";
import { motion } from "framer-motion"; 
import occupationalData from "./occupationalData";
import { 
  EXPERIENCE_TYPES, 
  INITIAL_STATES, 
  TAB_CONFIG,
  INPUT_PLACEHOLDERS,
  SECTION_LABELS,
  BUTTON_LABELS
} from './constants';

import { SegregateExperiencePoints , SegregateExperiencePSI } from '../builder/components/templates/config/AI';

const SkillsSuggestions = ({sectionKey}) => {
  const { currentJobRole, values, handleResumeChange } = useResume();
  const [showAll, setShowAll] = useState(false);
  const defaultSkills = [
    "Communication",
    "Teamwork",
    "Problem Solving",
    "Adaptability",
    "Time Management",
    "Leadership",
    "Creativity",
  ];

  const [skills, setSkills] = useState(defaultSkills);
  const [tools, setTools] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState(new Set());
  const maxInitialDisplay = 8; // Number of items to show initially

  const handleSkillClick = (skill) => {
    if (!selectedSkills.has(skill)) {
      setSelectedSkills(new Set([...selectedSkills, skill]));
      
      const pathParts = sectionKey.split('.');
      let currentSkills = values;
      for (const part of pathParts) {
        currentSkills = currentSkills?.[part] || [];
      }
      
      const nonEmptySkills = Array.isArray(currentSkills) 
        ? currentSkills.filter(s => s !== "" && s.trim() !== "")
        : [];
      const updatedSkills = [...nonEmptySkills, skill];
      
      handleResumeChange(sectionKey, updatedSkills);
    }
  };

  useEffect(() => {
    try {
      if (!occupationalData?.getSkillsByRole) {
        console.warn("Occupational data or method not available");
        setSkills(defaultSkills);
        setTools([]);
        return;
      } 

      const resumePosition = values?.sections?.profile?.data?.position;
      const jobRole = resumePosition?.trim() || currentJobRole?.trim();
  
      if (!jobRole) {
        console.warn("No position or job role specified");
        setSkills(defaultSkills);
        setTools([]);
        return;
      }

      const roleSkills = occupationalData.getSkillsByRole(jobRole); 
      const roleTools = occupationalData.getToolsByRole(jobRole);

      if (Array.isArray(roleSkills) && roleSkills.length > 0) {
        setSkills(roleSkills);
      } else {
        setSkills(defaultSkills);
      }

      if (Array.isArray(roleTools) && roleTools.length > 0) {
        setTools(roleTools);
      } else {
        setTools([]);
      }
    } catch (error) {
      console.warn("Error loading role-specific data:", error);
      setSkills(defaultSkills);
      setTools([]);
    }
  }, [currentJobRole, values?.sections?.profile?.data?.position]);

  const allItems = [ ...tools,...skills];
  const displayedItems = showAll ? allItems : allItems.slice(0, maxInitialDisplay);

  return (
    <div className={styles.CertificationSuggestion}>
      <div className={styles.CertificationSuggestionHeading}>
        Suggested Skills & Tools
      </div>
      <div className={styles.skillsGrid2}>
        {displayedItems.map((item, index) => (
          <div 
            key={index} 
            className={`${styles.skillCard2} ${tools.includes(item) ? styles.toolCard : ''}`}
          >
            {item}
            {!selectedSkills.has(item) ? (
              <button 
                className={styles.addSkillButton}
                onClick={() => handleSkillClick(item)}
              >
                <Plus size={14} />
              </button>
            ) : (
              <span className={styles.tickMark}>✓</span>
            )}
            {tools.includes(item) && (
              <span className={styles.toolBadge}>Tool</span>
            )}
          </div>
        ))}
      </div>
      {allItems.length > maxInitialDisplay && (
        <button 
          className={styles.showMoreButton2}
          onClick={() => setShowAll(!showAll)}
        >
          {showAll ? 'Show Less' : `Show More (${allItems.length - maxInitialDisplay})`}
        </button>
      )}
    </div>
  );
};

const SkillsDifferntStyle = ({ fields, section, sectionKey }) => {
  const [activeTab, setActiveTab] = useState(section?.activeTab || 0);
  const { handleResumeChange, currentSector, currentExperience, activeTabSkills, setActiveTabSkills } = useResume();
  
  // Handle tab change and skill segregation
  const handleTabChange = async (tabIndex) => {
    setActiveTabSkills(tabIndex);
    handleResumeChange(`${sectionKey}.activeTab`, tabIndex);

    // When switching to two-column view, segregate skills if needed
    if (tabIndex === 1 && section?.simpleList?.some(skill => skill.trim())) {
      try {
        const segregatedSkills = await SegregateTechnicalandSoftSkills(section.simpleList);
        const twoColumnData = [
          {
            name: "Technical Skills",
            skills: segregatedSkills.technical,
          },
          {
            name: "Soft Skills",
            skills: segregatedSkills.soft,
          },
        ];
        handleResumeChange(`${sectionKey}.twoColumn`, twoColumnData);
      } catch (error) {
        console.error("Error segregating skills:", error);
      }
    }
  };

  // Initialize activeTab field if it doesn't exist
  useEffect(() => {
    if (section?.activeTab === undefined) {
      handleResumeChange(`${sectionKey}.activeTab`, 0);
    }
  }, []);

  useEffect(() => {
    handleTabChange(activeTabSkills)
  }, [activeTabSkills]);

  // Simplified initialization
  useEffect(() => {
    const defaultTwoColumn = [
      {
        name: "Technical Skills",
        skills: ["", "", ""],
      },
      {
        name: "Soft Skills",
        skills: ["", "", ""],
      },
    ];

    // Initialize all required structures if they don't exist
    if (!section?.simpleList) {
      handleResumeChange(`${sectionKey}.simpleList`, ["", "", ""]);
    }
    if (!section?.twoColumn) {
      handleResumeChange(`${sectionKey}.twoColumn`, defaultTwoColumn);
    }
    
    if(section?.activeTab) {
      setActiveTabSkills(section?.activeTab || 0)
    }

    if (!section?.detailed) {
      handleResumeChange(`${sectionKey}.detailed`, occupationalData.getSectorSkillGroups(currentSector, currentExperience));
    }
  }, []);

  const handleDetailedNameChange = (index, value) => {
    const newDetailed = [...(section?.detailed || [])];
    newDetailed[index] = {
      ...newDetailed[index],
      name: value,
    };
    handleResumeChange(`${sectionKey}.detailed`, newDetailed);
  };

  const handleAddDetailedGroup = () => {
    const newDetailed = [...(section?.detailed || [])];
    newDetailed.push({
      name: "",
      skills: ["", "", ""],
    });
    handleResumeChange(`${sectionKey}.detailed`, newDetailed);
  };

  const handleTwoColumnSkillsChange = (columnIndex, skillsArray) => {
    const newTwoColumn = [...(section?.twoColumn || [])];
    newTwoColumn[columnIndex] = {
      ...newTwoColumn[columnIndex],
      skills: skillsArray,
    };
    handleResumeChange(`${sectionKey}.twoColumn`, newTwoColumn);
  };

  return (
    <div className={styles.skillsTabContainer}>
       
     
      <div className={styles.tabContent}>
        {/* Simple List */}
        {activeTabSkills === 0 && (
          <div className={styles.checkmarkList}>
             <SkillsSuggestions  sectionKey={`${sectionKey}.simpleList`}/>
            <DynamicListString
              name="Skills List"
              desc="Add your skills"
              sectionKey={`${sectionKey}.simpleList`}
              section={section?.simpleList || []}
              type="list-string"
              fields={fields?.data?.simpleList || {}}
            />
          </div>
        )}

        {/* Two Column */}
        {activeTabSkills === 1 && (
          <div className={styles.checkmarkList}>
            {(section?.twoColumn || []).map((column, index) => (
              <div
                key={index}
                className={styles.section_fields}
                style={{ gridTemplateColumns: "none" }}
              >
                <div
                  className={styles.section_fields_each}
                  style={{ display: "inline-block" }}
                >
                  <div className={styles.input_text}>
                    <label>{column?.name || `Column ${index + 1}`}</label>
                    <DynamicListString
                      name={column?.name || `Column ${index + 1}`}
                      sectionKey={`${sectionKey}.twoColumn.${index}.skills`}
                      section={column?.skills || []}
                      type="twoColumn"
                      fields={fields}
                      onSkillsChange={(skills) =>
                        handleTwoColumnSkillsChange(index, skills)
                      }
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        {/* Detailed Groups */}
        {activeTabSkills === 2 && (
          <div className={styles.checkmarkList}>
            {(section?.detailed || []).map((group, index) => (
              <div key={index} className={styles.skillGroupContainer}>
                <div className={styles.skillGroupInputWrapper}>
                  <input
                    type="text"
                    className={styles.skillGroupNameInput}
                    placeholder="Group Name"
                    value={group.name || ""}
                    onChange={(e) =>
                      handleDetailedNameChange(index, e.target.value)
                    }
                  />
                  <DynamicListString
                    name="Skills"
                    sectionKey={`${sectionKey}.detailed.${index}.skills`}
                    section={group.skills || []}
                    type="detailed"
                    fields={fields}
                    partiCularName="Skills" 
                    groupName={group.name || ""}
                  />
                </div>
                <button
                  className={styles.skillGroupDeleteBtn}
                  onClick={() => {
                    const newDetailed = [...section.detailed];
                    newDetailed.splice(index, 1);
                    handleResumeChange(`${sectionKey}.detailed`, newDetailed);
                  }}
                >
                  <Trash2 size={15} />
                </button>
              </div>
            ))}
            <div className={styles.skillGroupActions}>
              <span
                className={styles.addSkillGroupBtn}
                onClick={handleAddDetailedGroup}
              >
                <Plus color="#6B4DC4" size={14} /> Add Skill Group
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const SkillsSectionConfig = ({
  name,
  fields,
  sectionKey,
  section,
  desc,
  progesscardrender,
  sectionRefs,
  handleElementDelete,
  handleElementAdd,
  TipsContainer,
  currentStep,
}) => {
  const { handleResumeChange } = useResume(); 

  const calculateProgress = () => {
    if (!section || !fields) return { 
      percentage: 0, 
      completed: [], 
      remaining: [],
      totalFields: 0
    };
    
    let completed = [];
    let remaining = [];
    let weightedScore = 0;
    // const totalWeight = fields.reduce((sum, field) => sum + (field.weight || 1), 0) * Math.max(1, section.length);
    
    // section.forEach(element => {
    //   fields.forEach(field => {
    //     const value = element[field.name];
    //     const fieldWeight = field.weight || 1;
        
    //     const isValidValue = (value) => {
    //       if (!value) return false;
    //       const trimmedValue = value.toString().trim();
          
    //       switch (field.type) {
    //         case 'textarea':
    //           return trimmedValue.length >= 50;
    //         case 'text':
    //           return trimmedValue.length >= 3;
    //         case 'date':
    //           return true;
    //         case 'list-string':
    //           return Array.isArray(value) && value.some(item => item.trim().length > 0);
    //         default:
    //           return trimmedValue.length > 0;
    //       }
    //     };

    //     if (isValidValue(value)) {
    //       completed.push({
    //         label: field.label,
    //         weight: fieldWeight
    //       });
    //       weightedScore += fieldWeight;
    //     } else {
    //       remaining.push({
    //         label: field.label,
    //         weight: fieldWeight,
    //         type: field.type
    //       });
    //     }
    //   });
    // });
    
    return {
      percentage: 100,
      completed : [],
      remaining : [],
      totalFields: fields.length * section.length,
      weightedScore : 100,
      totalWeight : 100
    };
  };

  return (
    <div className={styles.section}>
      <div className={styles.headingContainer}>
        <div className={styles.headerTop}>
          <h2 className={styles.sectionTitle}> {currentStep?.current}. {name}</h2>
          <div className={styles.bottomLine} ></div>
        </div>
      </div>

      <TipsContainer  calculateProgress={calculateProgress} />
     
      <div className={styles.section_elements} style={{ gap: "0px" }}>

     
        <SkillsDifferntStyle
          section={section}
          sectionKey={sectionKey}
          handleResumeChange={handleResumeChange}
          fields={fields}
          name={name}
          sectionRefs={sectionRefs}
          handleElementDelete={handleElementDelete}
          handleElementAdd={handleElementAdd}
          SkillsSuggestions={SkillsSuggestions}
        />
      </div>

      {/* <div className={styles.hr}></div> */}
    </div>
  );
};

export const ExperiencesSectionConfig = ({
  name,
  fields,
  sectionKey,
  section,
  TipsContainer,
  currentStep,
  handleElementAdd
}) => {
  const { handleResumeChange, activeTabExperience, setActiveTabExperience } = useResume();
  const [activeTab, setActiveTab] = useState(section?.activeTab || 0);
  // Initialize activeTab when component mounts
  useEffect(() => {
    setActiveTabExperience(section?.activeTab || 0);
  }, []);

  // // Add this useEffect to handle tab changes
  useEffect(() => {
    if (activeTabExperience !== undefined) {
      setActiveTab(activeTabExperience);
      handleTabChange(activeTabExperience);
    }
  }, [activeTabExperience]);

  const calculateProgress = () => {
    if (!section || !fields) return { 
      percentage: 0, 
      completed: [], 
      remaining: [],
      totalFields: 0
    };
    
    
    
    return {
      percentage: 0,
      completed : [],
      remaining : [],
      totalFields: fields.length * section.length,
      weightedScore : 100,
      totalWeight : 100
    };
  };

  // Initialize section data structure
  useEffect(() => {
    if (!section || typeof section !== "object") {
      handleResumeChange(sectionKey, {
        activeTab: 0,
        data: {
          [EXPERIENCE_TYPES.SIMPLE]: [ {
            company: '',
            role: '',
            timePeriod: '',
            location: '',
            points: ['', '', '', '']
          }],
          [EXPERIENCE_TYPES.RESPONSIBILITIES]: [],
          [EXPERIENCE_TYPES.PSI]: [],
          [EXPERIENCE_TYPES.DETAILED]: [],
        },
      });
    }
  }, []);

  // Handle tab changes
  const handleTabChange = async(tabIndex) => {
    // Always use simpleList as source data
    const sourceData = section?.simpleList || [];

    // Get target array based on new tab
    let targetKey;
    switch (tabIndex) {
      case 0:
        targetKey = 'simpleList';
        break;
      case 1:
        targetKey = 'responsibilitiesAndAccomplishments';
        break;
      case 2:
        targetKey = 'problemSolutionImapact';
        break;
      case 3:
        targetKey = 'detailedGroups';
        break;
    }

    // Copy company details to the new tab format
    const targetData = section?.[targetKey] || [];
    const updatedTargetData = await Promise.all(sourceData.map(async (sourceItem, index) => {
      const existingTarget = targetData[index] || {};
      const baseDetails = {
        company: sourceItem.company || '',
        role: sourceItem.role || '',
        timePeriod: sourceItem.timePeriod || '',
        location: sourceItem.location || ''
      };

      // Always get points from simpleList format
      const points = sourceItem.points || [];

      switch (tabIndex) {
        case 0: // Simple List
          return {
            ...baseDetails,
            points: existingTarget.points || points || ['', '', '', '']
          };

        case 1: // Responsibilities & Accomplishments
          const segregated = await SegregateExperiencePoints(points);
          return {
            ...baseDetails,
            responsibilities: segregated.responsibilities,
            accomplishments: segregated.accomplishments
          };

        case 2: // Problem-Solution-Impact
          const psi = await SegregateExperiencePSI(points);
          return {
            ...baseDetails,
            problem: psi.problem || '',
            solution: psi.solution || '',
            impact: psi.impact || ''
          };

        case 3: // Detailed Groups
          return {
            ...baseDetails,
            groups: await SegregateExperienceDetailed(points) || [{
              groupName: '',
              description: ''
            }]
          };

        default:
          return baseDetails;
      }
    }));

    // Update the target section with copied data
    handleResumeChange(`${sectionKey}.${targetKey}`, updatedTargetData);
    
    // Update active tab
    setActiveTab(tabIndex);
    handleResumeChange(`${sectionKey}.activeTab`, tabIndex);
  };

  return (
    <div className={styles.section}>
      <div className={styles.headingContainer}>
        <div className={styles.headerTop}>
          <h2 className={styles.sectionTitle}> {currentStep?.current}. {name}</h2>
          <div className={styles.bottomLine} ></div>
        </div>
     
      </div>

      {/* <TipsContainer /> */}
      <TipsContainer  calculateProgress={calculateProgress} />
      <div className={styles.skillsTabContainer}>
      {/* <div className={styles.tabList}>
          {Object.values(TAB_CONFIG).map((tab) => (
            <button
              key={tab.id}
              className={`${styles.tabButton} ${
                activeTab === tab.id ? styles.active : ""
              }`}
              onClick={() => handleTabChange(tab.id)}
            >
              {tab.label}
            </button>
          ))}
        </div> */}

        <div className={styles.tabContent}>
          {activeTab === TAB_CONFIG.SIMPLE.id && (
            <ExperienceSimpleList
              section={section?.simpleList}
              sectionKey={`${sectionKey}.simpleList`}
              fields={fields} 
              handleElementAdd={handleElementAdd}
            />
          )}
          {activeTab === TAB_CONFIG.RESPONSIBILITIES.id && (
            <ExperienceResponsibilities
              section={section?.responsibilitiesAndAccomplishments}
              sectionKey={`${sectionKey}.responsibilitiesAndAccomplishments`}
              fields={fields}
            />
          )}
          {activeTab === TAB_CONFIG.PSI.id && (
            <ExperiencePSI
              section={section?.problemSolutionImapact}
              sectionKey={`${sectionKey}.problemSolutionImapact`}
              fields={fields}
            />
          )}
          {activeTab === TAB_CONFIG.DETAILED.id && (
            <ExperienceDetailedGroups
              section={section?.detailedGroups}
              sectionKey={`${sectionKey}.detailedGroups`}
              fields={fields} 
              tabIndex={activeTab}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const DynamicListString = ({
  name,
  sectionKey,
  section = [],
  desc,
  type,
  fields,
  onSkillsChange, 
  partiCularName,
  groupName
}) => {
  const { handleResumeChange, values } = useResume();
  const itemRefs = useRef([]);

  const handleAdd = (e) => {
    e.preventDefault();
    const newSection = [...section, ""];
    handleResumeChange(sectionKey, newSection);
    if (onSkillsChange) onSkillsChange(newSection);
  };

  const handleDelete = (e, index) => {
    e.preventDefault();
    const newSection = [...section];
    newSection.splice(index, 1);
    handleResumeChange(sectionKey, newSection);
    if (onSkillsChange) onSkillsChange(newSection);
  };

  const handleInputChange = (e, index) => {
    const newSection = [...section];
    newSection[index] = e.target.value;
    handleResumeChange(sectionKey, newSection);
    if (onSkillsChange) onSkillsChange(newSection);
  };

  return (
    <div className={styles.input_list}>
      <div className={styles.input_list_elements}>
        {section.map((skill, index) => (
          <div
            key={index}
            ref={(el) => (itemRefs.current[index] = el)}
            className={styles.input_list_String_element}
          >
            <input
              type="text"
              placeholder={fields?.placeholder || "Add skill"}
              value={skill}
              onChange={(e) => handleInputChange(e, index)}
            />
            <button onClick={(e) => handleDelete(e, index)}>
              <Trash2 size={15} />
            </button>
          </div>
        ))}
      </div>
      {(type === "list-string" ||
        type === "detailed" ||
        type === "twoColumn") && (
        <div className={styles.section_actions}>
          <span className={styles.addButtons} onClick={handleAdd}>
            <FilePlus2 style={{ height: "15px" }} /> Add Skill
          </span>
          {type == "list-string" && (
            <AISkillsPoints
              sectionKey={sectionKey}
              name={name}
              section={section}
              partiCularName={partiCularName}
              skillType={type}
            skillCategoryName={
              type === "twoColumn" 
                ? name?.toLowerCase()?.includes("technical") 
                  ? "technical" 
                  : "soft"
                : type === "detailed"
                ? groupName || name
                : "general"
            }
          />
          )}
        </div>
      )}
    </div>
  );
};

const DynamicDate = ({ name, inputKey, value, label }) => {
  const { handleResumeChange } = useResume();
  const parseDateValue = (dateStr) => {
    if (!dateStr) return { start: null, end: null, present: false };

    const parts = dateStr.split("-").map((part) => part.trim());
    return {
      start: parts[0] ? new Date(parts[0]) : null,
      end: parts[1] === "Present" ? null : parts[1] ? new Date(parts[1]) : null,
      present: parts[1] === "Present",
    };
  };

  const dateValue = typeof value === "string" ? parseDateValue(value) : value;

  const formatDate = (date) => {
    if (!date) return "";
    return date.toLocaleDateString("en-US", {
      month: "short",
      year: "numeric",
    });
  };

  return (
    <div className={styles.inputGroup}>
      <label>{label}</label>
      <div className={styles.date_container}>
        <div className={styles.date_input_wrapper}>
          <DatePicker
            selected={dateValue?.start}
            onChange={(date) => {
              const endDate = dateValue?.present
                ? "Present"
                : formatDate(dateValue?.end);
              handleResumeChange(inputKey, `${formatDate(date)} - ${endDate}`);
            }}
            dateFormat="MMM yyyy"
            showMonthYearPicker
            placeholderText="Start Date"
            className={styles.datepicker}
          />
        </div>
        <span className={styles.date_separator}>-</span>
        <div className={styles.end_date_section}>
          <div className={styles.date_input_wrapper}>
            <DatePicker
              selected={dateValue?.end}
              onChange={(date) => {
                handleResumeChange(
                  inputKey,
                  `${formatDate(dateValue?.start)} - ${formatDate(date)}`
                );
              }}
              dateFormat="MMM yyyy"
              showMonthYearPicker
              placeholderText="End Date"
              disabled={dateValue?.present}
              className={styles.datepicker}
              maxDate={new Date()}
              minDate={
                dateValue?.start
                  ? dateValue.start
                  : new Date(
                      new Date().setFullYear(new Date().getFullYear() - 10)
                    )
              }
            />
          </div>
        </div>
      </div>
      <div className={styles.present_checkbox}>
        <input
          type="checkbox"
          id={`${name}_present`}
          checked={dateValue?.present}
          onChange={(e) => {
            const isPresent = e.target.checked;
            handleResumeChange(
              inputKey,
              `${formatDate(dateValue?.start)} - ${isPresent ? "Present" : ""}`
            );
          }}
        />
        <label htmlFor={`${name}_present`}>Present</label>
      </div>
    </div>
  );
};

const ProgressBar = ({ value, index, onUpdateValue }) => {
  const [showModal, setShowModal] = useState(false);

  // New function to analyze the content quality
  const analyzeContent = (text) => {
    let score = 5;
    // Give base score of 20 if user has typed something
    score = text.trim().length > 0 ? 10 : 3;
    const criteria = {
      // Comprehensive metrics patterns
      hasMetrics:
        /((\d{1,3}(,\d{3})*(\.\d+)?)|(\d+))(\s*\+?)(\s*[kKmMbBtT])?(%|\s*(thousand|million|billion|users|customers|clients|people|employees|teams|projects|products|services|hours|days|weeks|months|years|dollars|EUR|USD|£|€|¥|rupees|INR|CAD|AUD|points|percent|sessions|clicks|views|impressions|downloads|subscriptions|transactions|sales|leads|ROI|growth|increase|decrease|reduction|improvement|efficiency|accuracy|score|rating|rank|position|place|tier|level|phase|stage|generation|version|release|sprint|cycle|quarter|annual|monthly|weekly|daily|hourly))?|\$\s*\d+|\d+\s*%|(increased|decreased|reduced|improved|grew|shrunk|expanded|minimized|maximized|optimized|accelerated|decelerated|scaled|doubled|tripled|quadrupled)\s+by\s+\d+/gi.test(
          text
        ),

      // Comprehensive action verbs at start
      hasActionVerb:
        /^(Led|Developed|Implemented|Created|Managed|Increased|Reduced|Improved|Achieved|Designed|Spearheaded|Orchestrated|Launched|Executed|Established|Streamlined|Pioneered|Transformed|Revolutionized|Generated|Delivered|Accelerated|Optimized|Engineered|Architected|Mentored|Coordinated|Automated|Simplified|Enhanced|Administered|Analyzed|Built|Championed|Conceptualized|Conducted|Consolidated|Constructed|Consulted|Controlled|Converted|Decreased|Defined|Demonstrated|Diagnosed|Directed|Discovered|Drove|Earned|Edited|Eliminated|Encouraged|Ensured|Established|Evaluated|Exceeded|Excelled|Expanded|Expedited|Facilitated|Focused|Forecasted|Formed|Formulated|Founded|Guided|Handled|Headed|Hired|Hosted|Identified|Illustrated|Impacted|Incorporated|Influenced|Informed|Initiated|Innovated|Inspected|Inspired|Installed|Instituted|Instructed|Integrated|Interpreted|Interviewed|Introduced|Invented|Investigated|Lectured|Maintained|Marketed|Mastered|Measured|Mediated|Modernized|Modified|Monitored|Motivated|Navigated|Negotiated|Operated|Organized|Originated|Overhauled|Oversaw|Performed|Planned|Prevented|Processed|Produced|Programmed|Promoted|Proposed|Provided|Published|Purchased|Recommended|Reconciled|Recorded|Recruited|Redesigned|Re-engineered|Referenced|Refined|Regulated|Rehabilitated|Remodeled|Repaired|Represented|Researched|Resolved|Restructured|Reviewed|Revised|Satisfied|Scheduled|Screened|Secured|Selected|Separated|Served|Shaped|Shared|Showcased|Solved|Sorted|Specified|Sponsored|Standardized|Steered|Strengthened|Studied|Supervised|Supported|Surpassed|Surveyed|Sustained|Synthesized|Systematized|Tabulated|Targeted|Taught|Tested|Trained|Translated|Troubleshot|Uncovered|Undertook|Unified|United|Updated|Upgraded|Utilized|Validated|Verified|Visualized|Won|Wrote)/i.test(
          text
        ),

      // Comprehensive task patterns
      hasTask:
        /(using|with|through|via|by|leveraging|utilizing|applying|implementing|employing|operating|managing|handling|conducting|executing|performing|delivering|building|developing|creating|designing|architecting|engineering|coding|programming|deploying|maintaining|supporting|troubleshooting|analyzing|testing|validating|verifying|monitoring|tracking|measuring|evaluating|assessing|reviewing|auditing|inspecting|investigating|researching|studying|learning|teaching|training|mentoring|coaching|guiding|leading|directing|supervising|overseeing|coordinating|organizing|planning|strategizing|consulting|advising|recommending|suggesting|proposing|presenting|communicating|collaborating|partnering|integrating|synthesizing|combining|merging|unifying|consolidating|standardizing|optimizing|improving|enhancing|upgrading|modernizing|transforming|revolutionizing|innovating|pioneering|spearheading|championing|driving|accelerating|expediting|streamlining|simplifying|automating|mechanizing|systematizing|structuring|restructuring|reengineering|refactoring|redesigning|remodeling|revamping|overhauling|updating|maintaining|sustaining|supporting)\s+[A-Za-z0-9\s,\.\-\/\(\)]+/i.test(
          text
        ),
    };

    // Calculate score based on simplified criteria
    if (criteria.hasMetrics) score += 40;
    if (criteria.hasActionVerb) score += 30;
    if (criteria.hasTask) score += 30;

    return Math.min(score, 100);
  };

  const percentage = analyzeContent(value);

  const getProgressColor = (percent) => {
    if (percent < 40) return "#FF6B6B"; // Red - Needs improvement
    if (percent < 70) return "#FFD93D"; // Yellow - Good
    return "#4CAF50"; // Green - Excellent
  };

  const tips = [
    "Begin with powerful action verbs (e.g., 'Spearheaded', 'Orchestrated', 'Pioneered', 'Transformed', 'Revolutionized')",
    "Quantify achievements with specific metrics (e.g., 'Increased efficiency by 45%', 'Managed $2M budget', 'Led team of 15+')",
    "Highlight technical skills and outcomes (e.g., 'using AWS to reduce costs by 30%', 'leveraging React.js to improve load times 5x')",
    "Focus on business impact (e.g., 'resulting in 25% revenue growth', 'driving 40% increase in user engagement')",
    "Include industry keywords relevant to the job (e.g., 'Agile methodology', 'CI/CD pipeline', 'user research')",
  ];

  const isAchievement = percentage >= 90;
  const color = getProgressColor(percentage);

  const handleImproveClick = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleTextChange = (e) => {
    const newText = e.target.value;
    onUpdateValue(index, newText);
  };

  return (
    <div className={styles.progress_container}>
      <div className={styles.progress_track_wrapper}>
        <motion.div
          className={styles.progress_track}
          style={{
            background: "rgba(255, 255, 255, 0.9)",
            borderRadius: "10px",
            height: "5px",
            width: "100%",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <motion.div
            className={styles.progress_fill}
            style={{
              backgroundColor: color,
              height: "100%",
              borderRadius: "10px",
              boxShadow: isAchievement ? `0 0 15px ${color}` : "none",
            }}
            initial={{ width: 0 }}
            animate={{
              width: `${percentage}%`,
              // Stronger pulsing for lower percentages
              scale:
                percentage < 50
                  ? [1, 1.15, 1]
                  : percentage < 100
                  ? [1, 1.05, 1]
                  : 1,
            }}
            transition={{
              width: { duration: 0.8, ease: "easeOut" },
              scale: {
                duration: percentage < 50 ? 1.5 : 2, // Faster pulse for lower percentages
                repeat: Infinity,
                repeatType: "reverse",
                ease: "easeInOut",
              },
            }}
          >
            {percentage < 100 && (
              <motion.div
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  width: percentage < 50 ? "30px" : "20px", // Wider glow effect for lower percentages
                  height: "100%",
                  background: `linear-gradient(90deg, transparent, ${color})`,
                  opacity: percentage < 50 ? 0.7 : 0.5, // More visible for lower percentages
                }}
                animate={{
                  x: [-30, 0],
                  opacity: percentage < 50 ? [0.3, 0.7] : [0.2, 0.5],
                }}
                transition={{
                  duration: percentage < 50 ? 1.2 : 1.5, // Faster animation for lower percentages
                  repeat: Infinity,
                  repeatType: "reverse",
                  ease: "easeInOut",
                }}
              />
            )}
          </motion.div>
        </motion.div>
      </div>

      <div className={styles.controls_wrapper}>
        {/* {value?.length > 0 && ( */}
        <motion.button
          className={styles.improve_button}
          onClick={handleImproveClick}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <RiMagicLine className={styles.improve_icon} />
          <span>Improve</span>
        </motion.button>
        {/* )} */}
      </div>

      {/* Modal */}
      {showModal && (
        <motion.div
          className={styles.modal_overlay}
          onClick={handleModalClose}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.div
            className={styles.modal_content}
            onClick={(e) => e.stopPropagation()}
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.9, opacity: 0 }}
            transition={{ type: "spring", damping: 20 }}
          >
            <div className={styles.modal_header}>
              <div className={styles.header_content}>
                <RiMagicLine className={styles.modal_icon} />
                <div className={styles.header_text}>
                  <h3>Enhance Your Experience</h3>
                  <p>Make your bullet point more impactful</p>
                </div>
              </div>
              <motion.button
                className={styles.close_button}
                onClick={handleModalClose}
                whileHover={{ rotate: 90 }}
                transition={{ duration: 0.2 }}
              >
                ×
              </motion.button>
            </div>

            <div className={styles.modal_body}>
              <div className={styles.modal_section}>
                <div className={styles.section_header}>
                  <h4>Your Experience</h4>
                  <span
                    className={`${styles.text_counter} ${
                      value?.length > 150 ? styles.text_counter_warning : ""
                    }`}
                  >
                    {value?.length || 0}/150
                  </span>
                </div>
                <textarea
                  value={value}
                  onChange={handleTextChange}
                  className={styles.editable_text}
                  placeholder="Enter your experience point here..."
                  rows={3}
                />
              </div>

              <div className={styles.modal_section}>
                <div className={styles.section_header}>
                  <h4>Pro Tips</h4>
                  <span className={styles.pro_badge}>PRO</span>
                </div>
                <ul className={styles.tips_list}>
                  {tips.map((tip, i) => (
                    <motion.li
                      key={i}
                      initial={{ x: -20, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      transition={{ delay: i * 0.1 }}
                    >
                      <span className={styles.tip_bullet}>•</span>
                      {tip}
                    </motion.li>
                  ))}
                </ul>
              </div>

              <div className={styles.modal_section}>
                <AIImprovisePoints
                  value={value}
                  onUpdateValue={(newText) => {
                    handleTextChange({ target: { value: newText } });
                  }}
                />
              </div>
            </div>

            <div className={styles.modal_footer}>
              <motion.button
                className={styles.close_button_secondary}
                onClick={handleModalClose}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                Cancel
              </motion.button>
              <motion.button
                className={styles.save_button}
                onClick={handleModalClose}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                Done
              </motion.button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </div>
  );
};

const DynamicListStringExperience = ({
  name,
  desc,
  sectionKey,
  section,
  element,
  partiCularName,
}) => {
  const { handleResumeChange, setLoading } = useResume();
  const itemRefs = useRef([]);
  const handleInputChange = (e, index) => {
    e.stopPropagation();
    handleResumeChange(`${sectionKey}.${index}`, e.target.value);
  };

  const handleAdd = (e) => {
    e.stopPropagation();
    handleResumeChange(sectionKey, [...(section || []), ""]);
  };

  const handleDelete = (e, index) => {
    e.stopPropagation();

    // Ensure itemRefs.current is correctly defined and holds references to DOM elements
    if (itemRefs.current[index]) {
      // const element = itemRefs.current[index];
      // element.classList.add(styles.slidefadeout);

      // Delay the state update to allow the fade-out animation to complete
      setTimeout(() => {
        handleResumeChange(sectionKey, [
          ...section.slice(0, index),
          ...section.slice(index + 1),
        ]);
      }, 480); // Match this duration with the CSS animation duration
    } else {
      console.warn("Element reference not found for index:", index);
    }
  };

  const handleInputChangeWithDebounce = (e, index) => {
    e.stopPropagation();
    setLoading(true);
    handleInputChange(e, index);
  };

  return (
    <>
      <div className={styles.input_list_label_container}>
        <label htmlFor={name} className={styles.input_list_label}></label>
        {/* {desc && <p className={styles.desc}>{desc}</p>} */}
        <AIPoints
          element={element}
          sectionKey={sectionKey}
          name={name}
          section={section}
          x
          partiCularName={partiCularName}
        />
      </div>
      <div className={styles.input_list_elements} style={{ gap: "0px" }}>
        {section?.map((element, index) => (
          <div
            ref={(el) => (itemRefs.current[index] = el)}
            key={index}
            className={styles.input_list_String_element}
          >
            <div className={styles.point_container}>
              <div className={styles.input_wrapper} style={{position: "relative"}}>
                <textarea
                  rows="2"
                  value={element}
                  onChange={(e) => handleInputChangeWithDebounce(e, index)}
                  style={{
                    width: "100%",
                    resize: "none",
                    padding: "8px 12px",
                    lineHeight: "1.5",
                    minHeight: "60px",
                    border: "1px solid #e0e0e0",
                    borderRadius: "4px",
                    fontFamily: "inherit",
                    fontSize: "14px",
                  }}
                />
                <button 
                  onClick={(e) => handleDelete(e, index)} 
                  style={{
                    position: "absolute", 
                    right: "-25px", 
                    top: "50%",
                    transform: "translateY(-50%)"
                  }}
                >
                  <Trash2 size={15} />
                </button>
              </div>
              <div className={styles.progress_wrapper}>
                <ProgressBar
                  value={element}
                  index={index}
                  onUpdateValue={(index, newValue) => {
                    const newElements = [...section]; // Assuming 'section' is your array of points
                    newElements[index] = newValue;
                    handleResumeChange(sectionKey, newElements); // Assuming you have a handleResumeChange function
                  }}
                />
              </div>
            </div>
           
          </div>
        ))}
      </div>
      {section && section.length < 4 && (
        <div className={styles.section_actions}>
          <span className={styles.addButtons} onClick={handleAdd}>
            <FilePlus2 style={{ height: "15px" }} /> Add {name}
          </span>
        </div>
      )}
    </>
  );
};

const handleElementAddUtil = (
  e,
  sectionKey,
  newElement,
  handleResumeChange,
  section = []
) => {
  if (e) {
    e.preventDefault();
    e.stopPropagation();
  }
  handleResumeChange(sectionKey, [...(section || []), newElement]);
};

const handleElementDeleteUtil = (
  e,
  sectionKey,
  index,
  handleResumeChange,
  section = []
) => {
  if (e) {
    e.stopPropagation();
  }
  const newSection = [
    ...(section?.slice(0, index) || []),
    ...(section?.slice(index + 1) || []),
  ];
  handleResumeChange(sectionKey, newSection);
};

// Add these shared components at the top of the file
const ExperienceHeader = ({ index, exp, sectionKey, handleDelete }) => {
  const { handleResumeChange } = useResume();
  
  return (
    <div className={styles.expHeaderSection}>
      <div className={styles.expBasicInfo}>
        <div className={styles.inputGroup}>
          <label htmlFor={`company-${index}`} className={styles.expLabel}>
            Company Name
          </label>
          <input
            id={`company-${index}`}
            type="text"
            placeholder={INPUT_PLACEHOLDERS.COMPANY}
            value={exp.company || ""}
            onChange={(e) => handleResumeChange(`${sectionKey}.${index}.company`, e.target.value)}
            className={styles.expCompanyInput}
          />
        </div>
        <div className={styles.inputGroup}>
          <label htmlFor={`role-${index}`} className={styles.expLabel}>
            Role
          </label>
          <input
            id={`role-${index}`}
            type="text"
            placeholder="Ex: Software Engineer, Product Manager"
            value={exp.role || ""}
            onChange={(e) => handleResumeChange(`${sectionKey}.${index}.role`, e.target.value)}
            className={styles.expRoleInput}
          />
        </div>
      </div>
      <div className={styles.expSecondaryInfo}>
        <DynamicDate
          name={`exp-${index}-date`}
          inputKey={`${sectionKey}.${index}.timePeriod`}
          value={exp.timePeriod || ""}
          label="Duration"
        />
        <div className={styles.inputGroup}>
          <label htmlFor={`location-${index}`} className={styles.expLabel}>
            Location
          </label>
          <input
            id={`location-${index}`}
            type="text"
            placeholder="Ex: San Francisco, CA"
            value={exp.location || ""}
            onChange={(e) => handleResumeChange(`${sectionKey}.${index}.location`, e.target.value)}
            className={styles.expLocationInput}
          />
        </div>
      </div>
      {/* <button className={styles.expDeleteBtn} onClick={(e) => handleDelete(e, index)}>
        <Trash2 size={15} />
      </button> */}
    </div>
  );
};

const AddButton = ({ onClick, text = "Experience" }) => (
   
   <div className={styles.section_actions}>
        <span className={styles.addButtons} onClick={onClick}>
          {" "}
          <Plus color={"#4D4DFF"} size={16} /> Add {text}
        </span>
      </div>
);

// Update the existing ExperiencePSI component
const ExperiencePSI = ({
  section = [],
  sectionKey,
  fields,
  handleElementDelete,
  handleElementAdd,
}) => {
  const { handleResumeChange } = useResume();
  const sectionRefs = useRef([]);
  const [openIndex, setOpenIndex] = useState(0);

  useEffect(() => {
    if (!section) return;
    sectionRefs.current = sectionRefs.current.slice(0, section?.length);
  }, [section]);

  useEffect(() => {
    if (section?.length === 0) {
      setOpenIndex(null);
    } else if (openIndex === null && section?.length > 0) {
      setOpenIndex(0);
    }
  }, [section?.length]);
  useEffect(() => {
    if (!section || section.length === 0) {
      handleResumeChange(sectionKey, [INITIAL_STATES[EXPERIENCE_TYPES.PSI]]);
    }
  }, []);

  const handleAdd = (e) => {
    handleElementAddUtil(
      e, 
      sectionKey, 
      INITIAL_STATES[EXPERIENCE_TYPES.PSI], 
      handleResumeChange, 
      section
    );
    // Set the openIndex to the new item's index
    setOpenIndex(section.length);
  };

  const handleDelete = (e, index) => {
    handleElementDeleteUtil(e, sectionKey, index, handleResumeChange, section);
  };

  const handleSummaryClick = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className={styles.expList}>
      {section.map((exp, index) => (
        <details 
        ref={(el) => (sectionRefs.current[index] = el)}
        key={index}
        className={`${styles.section_fields} ${styles.details_collaFpsed}`}
        open={openIndex === index}
        onClick={(e) => e.preventDefault()}>
           <summary
              className={styles.summary_container}
              onClick={(e) => {
                e.preventDefault();
                handleSummaryClick(index);
              }}
            >
              <div className={styles.section_element_actions}>
                <div className={styles.section_element_actions_name}>
                  Experience #{index + 1}
                </div>
                <div className={styles.action_buttons}>
                  <button onClick={(e) => handleElementDelete(e, index)}>
                    <Trash2 size={15} />
                  </button>
                  <ChevronDown size={18} className={styles.chevron_icon} />
                </div>
              </div>
            </summary>
            <div className={styles.bar}></div>
          <motion.div
            className={styles.expItemContainer}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
          >
            <ExperienceHeader
              index={index}
              exp={exp}
              sectionKey={sectionKey}
              handleDelete={handleDelete}
            />
            <div className={styles.expPsiContainer}>
              <div className={styles.expPsiSection}>
                <div className={styles.expPsiSectionHeader}>
                  <h4>{SECTION_LABELS.PROBLEM.title}</h4>
                  <p className={styles.expPsiDescription}>
                    {SECTION_LABELS.PROBLEM.description}
                  </p>
                </div>
                <textarea
                  placeholder={INPUT_PLACEHOLDERS.PROBLEM}
                  value={exp.problem || ""}
                  onChange={(e) =>
                    handleResumeChange(
                      `${sectionKey}.${index}.problem`,
                      e.target.value
                    )
                  }
                  className={styles.expPsiInput}
                />
              </div>
              <div className={styles.expPsiSection}>
                <div className={styles.expPsiSectionHeader}>
                  <h4>{SECTION_LABELS.SOLUTION.title}</h4>
                  <p className={styles.expPsiDescription}>
                    {SECTION_LABELS.SOLUTION.description}
                  </p>
                </div>
                <textarea
                  placeholder={INPUT_PLACEHOLDERS.SOLUTION}
                  value={exp.solution || ""}
                  onChange={(e) =>
                    handleResumeChange(
                      `${sectionKey}.${index}.solution`,
                      e.target.value
                    )
                  }
                  className={styles.expPsiInput}
                />
              </div>
              <div className={styles.expPsiSection}>
                <div className={styles.expPsiSectionHeader}>
                  <h4>{SECTION_LABELS.IMPACT.title}</h4>
                  <p className={styles.expPsiDescription}>
                    {SECTION_LABELS.IMPACT.description}
                  </p>
                </div>
                <textarea
                  placeholder={INPUT_PLACEHOLDERS.IMPACT}
                  value={exp.impact || ""}
                  onChange={(e) =>
                    handleResumeChange(
                      `${sectionKey}.${index}.impact`,
                      e.target.value
                    )
                  }
                  className={styles.expPsiInput}
                />
              </div>
            </div>
          </motion.div>
          {index < section.length - 1 && (
            <ExperienceDivider index={index} total={section.length} />
          )}
        </details>
      ))}
      <AddButton onClick={handleAdd} text={BUTTON_LABELS.ADD_EXPERIENCE} />
    </div>
  );
};
 

const ExperienceSimpleList = ({
  section = [],
  sectionKey,
  handleElementAdd
}) => {
  const { handleResumeChange } = useResume();
  const sectionRefs = useRef([]);
  const [openIndex, setOpenIndex] = useState(0);

  useEffect(() => {
    if (!section) return;
    sectionRefs.current = sectionRefs.current.slice(0, section?.length);
  }, [section]);

  useEffect(() => {
    if (section?.length === 0) {
      setOpenIndex(null);
    } else if (openIndex === null && section?.length > 0) {
      setOpenIndex(0);
    }
  }, [section?.length]);
  useEffect(() => {
    if (!section || section.length === 0) {
      handleResumeChange(sectionKey, [INITIAL_STATES[EXPERIENCE_TYPES.SIMPLE]]);
    }
  }, []);

  const handleAdd = (e) => {
    handleElementAddUtil(
      e, 
      sectionKey, 
      INITIAL_STATES[EXPERIENCE_TYPES.SIMPLE], 
      handleResumeChange, 
      section
    );
    // Set the openIndex to the new item's index
    setOpenIndex(section.length);
  };

  const handleElementDelete = (e, index) => {
    handleElementDeleteUtil(e, sectionKey, index, handleResumeChange, section);
  };

  const handleSummaryClick = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className={styles.expList}>
      {section.map((exp, index) => (
        <details 
          ref={(el) => (sectionRefs.current[index] = el)}
          key={index}
          className={`${styles.section_fields} ${styles.details_collaFpsed}`}
          open={openIndex === index}
          onClick={(e) => e.preventDefault()}
        >
           <summary
              className={styles.summary_container}
              onClick={(e) => {
                e.preventDefault();
                handleSummaryClick(index);
              }}
            >
              <div className={styles.section_element_actions}>
                <div className={styles.section_element_actions_name}>
                  Experience #{index + 1}
                </div>
                <div className={styles.action_buttons}>
                  <button onClick={(e) => handleElementDelete(e, index)}>
                    <Trash2 size={15} />
                  </button>
                  <ChevronDown size={18} className={styles.chevron_icon} />
                </div>
              </div>
            </summary>
            <div className={styles.bar}></div>
          <motion.div
            className={styles.expItemContainer}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
          >
            <ExperienceHeader
              index={index}
              exp={exp}
              sectionKey={sectionKey}
              handleDelete={handleElementDelete}
            />
            <DynamicListStringExperience
              name="Experience Points"
              sectionKey={`${sectionKey}.${index}.points`}
              section={exp.points || []}
              element={exp}
              handleElementAdd={handleElementAdd}
            />
          </motion.div>
          {/* {index < section.length - 1 && (
            <ExperienceDivider index={index} total={section.length} />
          )} */}
        </details>
      ))}
      <AddButton onClick={handleAdd} />
    </div>
  );
};

const ExperienceResponsibilities = ({
  section = [],
  sectionKey,
}) => {
  const { handleResumeChange } = useResume();


  const sectionRefs = useRef([]);
  const [openIndex, setOpenIndex] = useState(0);

  useEffect(() => {
    if (!section) return;
    sectionRefs.current = sectionRefs.current.slice(0, section?.length);
  }, [section]);

  useEffect(() => {
    if (section?.length === 0) {
      setOpenIndex(null);
    } else if (openIndex === null && section?.length > 0) {
      setOpenIndex(0);
    }
  }, [section?.length]);

  useEffect(() => {
    if (!section || section.length === 0) {
      handleResumeChange(sectionKey, [INITIAL_STATES[EXPERIENCE_TYPES.RESPONSIBILITIES]]);
    }
  }, []);

  const handleAdd = (e) => {
    handleElementAddUtil(
      e, 
      sectionKey, 
      INITIAL_STATES[EXPERIENCE_TYPES.RESPONSIBILITIES], 
      handleResumeChange, 
      section
    );
    // Set the openIndex to the new item's index
    setOpenIndex(section.length);
  };

  const handleDelete = (e, index) => {
    handleElementDeleteUtil(e, sectionKey, index, handleResumeChange, section);
  };

  const handleSummaryClick = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className={styles.expList}>
      {section.map((exp, index) => (
        <details 
        ref={(el) => (sectionRefs.current[index] = el)}
        key={index}
        className={`${styles.section_fields} ${styles.details_collaFpsed}`}
        open={openIndex === index}
        onClick={(e) => e.preventDefault()}>
          <summary
              className={styles.summary_container}
              onClick={(e) => {
                e.preventDefault();
                handleSummaryClick(index);
              }}
            >
              <div className={styles.section_element_actions}>
                <div className={styles.section_element_actions_name}>
                  Experience #{index + 1}
                </div>
                <div className={styles.action_buttons}>
                  <button onClick={(e) => handleDelete(e, index)}>
                    <Trash2 size={15} />
                  </button>
                  <ChevronDown size={18} className={styles.chevron_icon} />
                </div>
              </div>
            </summary>
            <div className={styles.bar}></div>
          <motion.div
            className={styles.expItemContainer}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
          >
            <ExperienceHeader
              index={index}
              exp={exp}
              sectionKey={sectionKey}
              handleDelete={handleDelete}
            />
            <div className={styles.expRaContainer}>
              <div className={styles.expRaSection}>
                <div className={styles.expRaSectionHeader}>
                  <h4>{SECTION_LABELS.RESPONSIBILITIES.title}</h4>
                  <p className={styles.expRaDescription}>
                    {SECTION_LABELS.RESPONSIBILITIES.description}
                  </p>
                </div>
                <DynamicListStringExperience
                  name="Responsibilities"
                  sectionKey={`${sectionKey}.${index}.responsibilities`}
                  section={exp.responsibilities || []}
                  element={exp}
                  partiCularName="responsibilities"
                />
              </div>
              <div className={styles.expRaSection}>
                <div className={styles.expRaSectionHeader}>
                  <h4>{SECTION_LABELS.ACCOMPLISHMENTS.title}</h4>
                  <p className={styles.expRaDescription}>
                    {SECTION_LABELS.ACCOMPLISHMENTS.description}
                  </p>
                </div>
                <DynamicListStringExperience
                  name="Accomplishments"
                  sectionKey={`${sectionKey}.${index}.accomplishments`}
                  section={exp.accomplishments || []}
                  element={exp}
                  partiCularName="accomplishments"
                />
              </div>
            </div>
          </motion.div>
          
        </details>
      ))}
      <AddButton onClick={handleAdd} />
    </div>
  );
};

const ExperienceDetailedGroups = ({
  section = [],
  sectionKey,
  tabIndex
}) => {
  const { handleResumeChange, currentSector, currentExperience } = useResume();
  
  const sectionRefs = useRef([]);
  const [openIndex, setOpenIndex] = useState(0);

  useEffect(() => {
    if (!section) return;
    sectionRefs.current = sectionRefs.current.slice(0, section?.length);
  }, [section]);

  useEffect(() => {
    if (section?.length === 0) {
      setOpenIndex(null);
    } else if (openIndex === null && section?.length > 0) {
      setOpenIndex(0);
    }
  }, [section?.length]);

  useEffect(() => {
    if (!section || section[0]?.groups[0]?.groupName === "") {
      const initialState = {
        ...INITIAL_STATES[EXPERIENCE_TYPES.DETAILED],
        groups: occupationalData.getSectorGroups(currentSector, currentExperience)
      };
      handleResumeChange(sectionKey, [initialState]);
    }
  }, [tabIndex]);

  const handleAddNewCompany = (e) => {
    const newElement = {
      company: "",
      role: "",
      timePeriod: "",
      location: "",
      groups: [{
        groupName: "",
        description: ""
      }]
    };
    handleElementAddUtil(e, sectionKey, newElement, handleResumeChange, section);
    // Set the openIndex to the new item's index
    setOpenIndex(section.length);
  };

  const handleAddGroup = (e, companyIndex) => {
    e.preventDefault();
    const newGroup = {
      groupName: "",
      description: ""
    };
    const updatedSection = [...section];
    if (!updatedSection[companyIndex].groups) {
      updatedSection[companyIndex].groups = [];
    }
    updatedSection[companyIndex].groups.push(newGroup);
    handleResumeChange(sectionKey, updatedSection);
  };

  const handleDeleteCompany = (e, index) => {
    handleElementDeleteUtil(e, sectionKey, index, handleResumeChange, section);
  };

  const handleDeleteGroup = (e, companyIndex, groupIndex) => {
    e.preventDefault();
    const updatedSection = [...section];
    updatedSection[companyIndex].groups.splice(groupIndex, 1);
    handleResumeChange(sectionKey, updatedSection);
  };
  const handleSummaryClick = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  return (
    <div className={styles.expList}>
      {section.map((company, companyIndex) => (
        <details 
        ref={(el) => (sectionRefs.current[companyIndex] = el)}
        key={companyIndex}
        className={`${styles.section_fields} ${styles.details_collaFpsed}`}
        open={openIndex === companyIndex}
        onClick={(e) => e.preventDefault()}>
          
              <summary
              className={styles.summary_container}
              onClick={(e) => {
                e.preventDefault();
                handleSummaryClick(companyIndex);
              }}
            > 
              
              <div className={styles.section_element_actions}>
                <div className={styles.section_element_actions_name}>
                  Experience #{companyIndex + 1}
                </div>
                <div className={styles.action_buttons}>
                  <button onClick={(e) => handleDeleteCompany(e, companyIndex)}>
                    <Trash2 size={15} />
                  </button>
                  <ChevronDown size={18} className={styles.chevron_icon} />
                </div>
              </div>
            </summary>
            <div className={styles.bar}></div>
            <motion.div
            className={styles.expItemContainer}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
          >
            <ExperienceHeader
              index={companyIndex}
              exp={company}
              sectionKey={sectionKey}
              handleDelete={handleDeleteCompany}
            />
            {company.groups?.map((group, groupIndex) => (
              <div key={groupIndex} className={styles.expPsiContainer}>
                <div className={styles.expHeaderSection}>
                  <div className={styles.expBasicInfo}>
                    <div className={styles.inputGroup}>
                      <label
                        htmlFor={`groupName-${companyIndex}-${groupIndex}`}
                        className={styles.expLabel}
                      >
                        Name
                      </label>
                      <input
                        id={`groupName-${companyIndex}-${groupIndex}`}
                        type="text"
                        placeholder="Ex: Technical Skills, Leadership Experience"
                        value={group.groupName || ""}
                        onChange={(e) =>
                          handleResumeChange(
                            `${sectionKey}.${companyIndex}.groups.${groupIndex}.groupName`,
                            e.target.value
                          )
                        }
                        className={styles.expCompanyInput}
                      />
                    </div>
                  </div>
                  <button
                    className={styles.expDeleteBtn}
                    onClick={(e) => handleDeleteGroup(e, companyIndex, groupIndex)}
                  >
                    <Trash2 size={15} />
                  </button>
                </div>
                <div className={styles.expPsiSection}>
                  <div className={styles.expPsiSectionHeader}>
                    <h4>Description</h4>
                    <p className={styles.expPsiDescription}>
                      Describe the key aspects of this group
                    </p>
                  </div>
                  <textarea
                    placeholder="Describe the details and achievements related to this group..."
                    value={group.description || ""}
                    onChange={(e) =>
                      handleResumeChange(
                        `${sectionKey}.${companyIndex}.groups.${groupIndex}.description`,
                        e.target.value
                      )
                    }
                    className={styles.expPsiInput}
                  />
                </div>
              </div>
            ))}
            <AddButton 
              onClick={(e) => handleAddGroup(e, companyIndex)} 
              text="Add Group"
            />
          
          </motion.div>
        </details>
      ))}
      <AddButton onClick={handleAddNewCompany} text="Company" />
    </div>
  );
};

// Add this with the other shared components at the top
const ExperienceDivider = ({ index, total }) => (
  <div className={styles.experienceDivider}>
    <span className={styles.experienceDividerWithBadge}>
      Experience {index + 2} of {total}
    </span>
  </div>
);

 

 

