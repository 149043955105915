import React, { useState } from 'react'
import RightTop from '../ResumeBuilder/builder/RightTop'
import { useAuth } from '../ResumeBuilder/builder/components/context/auth'
import styles from '../assets/Dashboard/DashboardNew.module.css'
import Header from '../components/DashBoard.js/Header'
import Status from '../components/DashBoard.js/Status'
import Resume from '../components/DashBoard.js/Resume'
import Modeluploadscratch from '../components/Modeluploadscratch'
import ModelAskExp from '../components/ModelAskExp'
import ModelChoseTemplate from '../components/ModelChoseTemplate'
import JobRecon from '../components/DashBoard.js/JobRecon'
import Upgrade from '../components/DashBoard.js/Upgrade'
const Dashboard = () => {
    const { auth } = useAuth();
    const [imgSrc, setImgSrc] = useState(
        "https://res.cloudinary.com/dvepwlz9j/image/upload/v1729186227/all/vzw4bjslemzuuvznzdgs.svg"
      );
      const [modalShow,setModalShow] = useState(false); 
      const [SecondmodalShow,setSecondModalShow] = useState(false); 
      const [ThirdmodalShow,setThirdModalShow] = useState(false);
      const [FourthmodalShow,setFourthModalShow] = useState(false);
      const [selectedExperience, setSelectedExperience] = useState(null);
      const [selectedSector, setSelectedSector] = useState(null);
      const [selectedDomain, setSelectedDomain] = useState(null);
  return (
    <div className={styles.container}>
        <RightTop auth={auth} imgSrc={imgSrc} activeNav={"Dashboard"} /> 
        <div className={styles.ComponentContainer}>
            <Header modalShow={modalShow} setModalShow={setModalShow} />  
            <Status />
            <Resume modalShow={modalShow} setModalShow={setModalShow} SecondmodalShow={SecondmodalShow} setSecondModalShow={setSecondModalShow}/>
            <JobRecon/> 
             <Upgrade/>
        </div>
        <Modeluploadscratch modalShow={modalShow} setModalShow={setModalShow} setSecondModalShow={setFourthModalShow}  /> 
        <ModelAskExp modalShow={FourthmodalShow} setModalShow={setFourthModalShow} setSecondModalShow={setSecondModalShow} setSelectedExperience={setSelectedExperience} setSelectedSector={setSelectedSector} setSelectedDomain={setSelectedDomain}  selectedExperience={selectedExperience} selectedSector={selectedSector} selectedDomain={selectedDomain} /> 
        <ModelChoseTemplate modalShow={SecondmodalShow} setModalShow={setSecondModalShow} selectedExperience={selectedExperience} selectedSector={selectedSector} selectedDomain={selectedDomain} /> 
    </div>
  )
}

export default Dashboard