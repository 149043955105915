import React from "react";
import { useResume } from "../context/Resume";
import styles from "./PreviewImage.module.css";

const PreviewImage = ({ values, template, isGridView = true }) => {
  const { templates } = useResume();
  
  const getTemplateComponent = (key) => {
    if (!key || !templates[key]) {
      console.log('Template not found for key:', key);
      return null;
    }
    return templates[key].resume;
  };

  const ResumeTemplate = getTemplateComponent(template?.key);

  if (!ResumeTemplate || !values) {
    return <div>Loading...</div>;
  }
  return (
    <>
    <div className={styles.gridContainer}>
      <div className={styles.gridWrapper}>
        <div className={styles.gridScaler}>
          <div 
            id="print_content"
            className={styles.gridContent}
          >
            <ResumeTemplate sections={values.sections} />
          </div>
        </div>
      </div>
    </div>  
    <h1>{template.name}</h1>
    </>
  );
};

export default PreviewImage;