import React from "react";
import Modal from "react-bootstrap/Modal";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { CheckCircle, ChevronRight } from "lucide-react";
import { useNavigate } from "react-router-dom";
import styles from "../assets/Model.module.css";

const features = [
  {
    title: "Professional Resume Templates",
    description: "Access to 100+ ATS-friendly templates"
  },
  {
    title: "AI-Powered Content Creation",
    description: "Generate optimized content instantly"
  },
  {
    title: "Resume Optimization",
    description: "Smart suggestions to improve visibility"
  },
  {
    title: "Job Description Matching",
    description: "AI-powered job matching system"
  },
  {
    title: "Personalized Job Recommendations",
    description: "Tailored job suggestions daily"
  },
  {
    title: "Career Progress Tracking",
    description: "Monitor your career growth"
  }
];

function Model({ modalShow, setModalShow }) {
  const navigate = useNavigate();

  return (
    <Modal
      show={modalShow}
      onHide={() => setModalShow(false)}
      size="lg"
      centered
      className={styles.modalWrapper}
    >
      <Modal.Body className={styles.modernBody}>
        <button
          className={styles.closeBtn}
          onClick={() => setModalShow(false)}
        >
          <FontAwesomeIcon icon={faClose} />
        </button>

        {/* Modal background sparkles */}
        <div className={styles.modalSparkle}></div>
        <div className={styles.modalSparkle}></div>
        <div className={styles.modalSparkle}></div>
        <div className={styles.modalSparkle}></div>
        <div className={styles.modalSparkle}></div>
        <div className={styles.modalSparkle}></div>

        <div className={styles.contentWrapper}>
          <div className={styles.leftSection}>
            <div className={styles.leftContent}>
              <div className={styles.premiumIconContainer}>
                <div className={styles.sparkleEffect}>
                  <div className={styles.sparkle}></div>
                  <div className={styles.sparkle}></div>
                  <div className={styles.sparkle}></div>
                  <div className={styles.sparkle}></div>
                  <div className={styles.sparkle}></div>
                </div>
                <img 
                  src="https://res.cloudinary.com/dmsbvsful/image/upload/v1741875702/Untitled_Artwork_31_3_p2oxon.svg" 
                  alt="Premium" 
                  className={styles.premiumImage}
                />
              </div>
              
              <h1 className={styles.title}>
                Upgrade to Premium
              </h1>

              <div className={styles.pricingBox}>
                <div className={styles.priceTag}>
                  <span className={styles.currency}>₹</span>
                  <span className={styles.amount}>247</span>
                  <span className={styles.period}>/ 3 months</span>
                </div>
                <div className={styles.savings}>Save 40% with annual billing</div>
              </div>

              <button
                className={styles.upgradeBtn}
                onClick={() => navigate('/pricing')}
              >
                Get Premium Access
                <ChevronRight className={styles.buttonIcon} size={16} />
              </button>
            </div>
          </div>

          <div className={styles.rightSection}>
            <h3 className={styles.featuresTitle}>
              <div className={styles.proRibbon}>PRO</div>
              Premium Features
            </h3>
            <div className={styles.featuresList}>
              {features.map((feature, index) => (
                <div
                  key={feature.title}
                  className={styles.featureItem}
                >
                  <CheckCircle className={styles.checkIcon} size={16} />
                  <span className={styles.featureTitle}>{feature.title}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default Model;
